import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailIcon from "@mui/icons-material/Mail";
import InfoIcon from '@mui/icons-material/Info';


import { simpleCss } from "./Theme";
import { BrowserRouter as Router, Link } from "react-router-dom";

import HomeIcon from "@mui/icons-material/Home";
import CastForEducationIcon from "@mui/icons-material/CastForEducation";
import FaceIcon from "@mui/icons-material/Face";
import WebIcon from '@mui/icons-material/Web';

export default function TemporaryDrawer(props) {
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    props.setDrawer(!props.drawer);
  };

  const iconMap = {
    Home: { icon: <HomeIcon />, link: "/" },
    "Lesson Demos": {
      icon: <CastForEducationIcon />,
      link: "/lesson-demos",
    },
    Dashboard: {
      icon: <FaceIcon />,
      link: "https://mathcart.com/tutor/learner-dashboard/",
    },
    "About MathCart" : {
      icon: <InfoIcon />,
      link: "/about",
    },
  };
  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {" "}
        {["Home", "Lesson Demos", "Dashboard", "About MathCart", "Blog"].map((text, index) =>
          text !== "Dashboard" && text !== "Blog" ? (
            <Link
              key={text}
              to={(iconMap[text] && iconMap[text].link) || "/"}
              style={simpleCss}
            >
              {" "}
              <ListItem disablePadding key={text}>
                <ListItemButton>
                  <ListItemIcon>
                    {(iconMap[text] && iconMap[text].icon) || <MailIcon />}
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItemButton>
              </ListItem>
            </Link>
          ) : (
            <a
              key={text}
              href={(iconMap[text] && iconMap[text].link) || "/"}
              style={simpleCss}
            >
              {" "}
              <ListItem disablePadding key={text}>
                <ListItemButton>
                  <ListItemIcon>
                    {(iconMap[text] && iconMap[text].icon) || <MailIcon />}
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItemButton>
              </ListItem>
            </a>
          )
        )}
      </List>
      <Divider />
    </Box>
  );

  const anchor = "left";
  return (
    <div>
      <Drawer
        anchor={anchor}
        open={props.drawer}
        onClose={toggleDrawer(anchor, false)}
      >
        {list(anchor)}
      </Drawer>
    </div>
  );
}
