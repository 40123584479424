import React, { useState } from "react";
import { Grid } from "@mui/material";
import { InlineMath } from "react-katex";
import { range } from "lodash";
import { Buttons } from "../Tools/components";
import { scaleLinear } from "d3";
import { Practice } from "../practice/PracticeAddSubtractSimple";

const AddSubCleanNumber = props => {
  const [start, setStart] = useState(4);
  const [subtract, setSubtract] = useState(null);
  const [add, setAdd] = useState(5);

  const changeAdd = v => {
    setSubtract(null);
    setAdd(v);
  };

  const changeSub = v => {
    setSubtract(v);
    setAdd(null);
  };
  const pos = add !== null;
  

  const result = pos ? start + add : start - subtract;

  const append =
    (pos
      ? " \\color{black} + \\color{blue}" + add
      : " -  \\color{blue}" + subtract) +
    "\\color{black} = " +
    result;
  const startButton = (
    <Buttons buttonArray={11} func={setStart} keyString="start" />
  );
  const addButton = (
    <Buttons buttonArray={11} func={changeAdd} keyString="add" />
  );
  const subtractButton = (
    <Buttons buttonArray={11} func={changeSub} keyString="subtract" />
  );

  const eq = "\\color{red} " + start + append;

  const startArray = new Array(start).fill(start);
  const addArray = new Array(add).fill(add);
  const subtractArray = new Array(subtract).fill(subtract);
  const subDiff = !pos ? Math.min(subtract, start) : 0;
  const subMoreThanArray = subtract > start ? new Array(subtract - start).fill(subtract) : [];
  const startArrayTotal = startArray.concat(subMoreThanArray);

  
  const startDots = startArrayTotal.map((v, i) => {
    const r = 6;
    return (
      <g key={"start-dots-" + i}>
        <circle
          cx={r + 2.2 * r * (i % 8)}
          cy={r + 2.2 * r * Math.floor(i / 8)}
          r={r}
          fill={i < subDiff ? "blue" : i >= start ? "black" : "red"}
          opacity={i < subDiff ? 0.2 : 1}
        />
      </g>
    );
  });

  const addDots =
    pos &&
    addArray.map((v, i) => {
      const r = 6;
      return (
        <g key={"add-dots-" + i}>
          <circle
            cx={15 * start + r + 2.2 * r * (i % 8)}
            cy={r + 2.2 * r * Math.floor(i / 8)}
            r={r}
            fill="blue"
          />
        </g>
      );
    });

  const min = pos ? 0 : Math.min(0, start - subtract);
  const max = pos ? add + start : start;

  const scale = scaleLinear()
    .domain([min, max])
    .range([10, 290]);

  const startDot = <circle cx={scale(start)} cy={20} fill={"red"} r={10} />;
  const zeroDot = <circle cx={scale(0)} cy={20} fill={"green"} r={10} opacity = {0.5} />;
  const resultDot = result !== start && <circle cx={scale(result)} cy={20} fill={"black"} r={10} />;

  const ticks = range(min, max + 1).map((v, i) => (
    <text
      key={"tick-" + v}
      x={scale(v) - 10}
      y={45}
      fontSize={max - min > 10 ? 10 : 20}
    >
      {v}
    </text>
  ));

  const distance = (
    result !== start && <line
      x1={scale(start) + (result > start ? 6 : -6)}
      x2={scale(result)  - (result > start ? 6 : -6)}
      y1={20}
      y2={20}
      stroke={"blue"}
      strokeWidth={5}
      markerEnd="url(#arrow)"
    />
  );

  return (
    <>
      <Grid container spacing={5}>
        <Grid item xs={12} md={4}>
          <h6>Start</h6>
          {startButton}
          <h6>Add</h6>
          {addButton}
          <h6>Subtract</h6>
          {subtractButton}
        </Grid>
        <Grid item style={{ fontSize: 25 }}>
          <InlineMath math={eq} />
        </Grid>
        <Grid item>
          <h6>Visualize with Object</h6>
          <svg width={300} height={50}>
            {startDots}
            {addDots}
          </svg>

          <h6>Visualize on Number Line</h6>
          <svg width={300} height={50}>
            <defs>
              <marker
                id="arrow"
                markerWidth="10"
                markerHeight="10"
                refX="7.5"
                refY="3"
                orient="auto"
                markerUnits="strokeWidth"
                viewBox="0 0 20 20"
              >
                <path d="M0,0 L0,6 L9,3 z" fill="blue" />
              </marker>
            </defs>
            {startDot}
            {resultDot}
            {zeroDot}
            {ticks}
            {distance}
            {result !== start && <text y={15} x={scale((result + start)/2)}>
              {pos ? add : "-" + subtract}
            </text>}
          </svg>
        </Grid>
      </Grid>
      <Practice />
    </>
  );
};

export default AddSubCleanNumber;
