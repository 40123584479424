import React, { useState, useEffect, useRef } from "react";
import { Grid, Slider, Paper, Button } from "@mui/material";
import "katex/dist/katex.min.css";
import {  InlineMath } from "react-katex";
import { makeCurlyBrace } from "../../Tools/MakeBrackets";
function Dimensions() {
  const linearGraphRef = useRef();

  const defaultX = 2;
  const defaultY = 5;
  const [x, setX0] = useState(defaultX);
  const [y, setY] = useState(defaultY);
  const [dx, setDx] = useState(10);
  const [dy, setDy] = useState(10);

  const [curlyPath, setCurlyPath] = useState();
  const [everyPointX, setEveryPointX] = useState(true);

  const change0 = (o, value) => {
    setX0(value);
  };

  const xShift = 60;
  const xCut = 3;
  useEffect(() => {
    setCurlyPath(
      makeCurlyBrace(
        -xShift + (xShift * (x + 3)) / xCut,
        30,
        (xShift * (x + 3)) / xCut + 6 * dx - 60,
        30,
        8,
        0.6
      )
    );

  }, [x, dx]);

  const styles = {
    red: {
      color: "red"
    },
    blue: {
      color: "blue"
    },
    paddingLeftSide: {
      paddingTop: 35
    }
  };

  const zero0 = -xShift + (xShift * (x + 3)) / xCut - 20;

  const oneD = (
    <>
      <Grid item xs={12}>
        <h4 style={styles.blue}>
          Freedom of movement in{" "}
          <span style={{ fontSize: 20 }}> two dimension</span>
        </h4>
        <b>Control the location of the points</b>
        <Slider
          style={styles.paddingLeftSide}
          defaultValue={defaultX}
          onChange={change0}
          aria-labelledby="discrete-slider"
          step={1}
          marks
          min={1}
          max={10}
          valueLabelDisplay="auto"
          valueLabelFormat={v => v - 1}
        />
        <Slider
          style={styles.paddingLeftSide}
          defaultValue={defaultY}
          onChange={(o, v) => setY(v)}
          aria-labelledby="discrete-slider"
          step={1}
          marks
          min={1}
          max={10}
          valueLabelDisplay="auto"
          valueLabelFormat={v => v - 1}
        />
        <b>
          Control the <b>distance</b> between points in the points
        </b>
        <Slider
          style={styles.paddingLeftSide}
          defaultValue={10}
          onChange={(o, v) => setDx(v)}
          aria-labelledby="discrete-slider"
          step={1}
          marks
          min={0}
          max={10}
          valueLabelDisplay="auto"
        />
        <Slider
          style={styles.paddingLeftSide}
          defaultValue={10}
          onChange={(o, v) => setDy(v)}
          aria-labelledby="discrete-slider"
          step={1}
          marks
          min={0}
          max={10}
          valueLabelDisplay="auto"
        />
        <span style={{ fontSize: 15 }}>
          <InlineMath
            math={
              "\\color{purple} A  \\color{black} = " +
              (everyPointX
                ? dx +
                  "  \\times " +
                  dy +
                  " \\color{black} =  \\color{purple}" +
                  dx * dy +
                  "\\quad \\quad"
                : " 0  \\quad \\quad \\quad \\quad \\quad \\quad")
            }
          />
        </span>
        <input
          type="checkbox"
          name="vehicle1"
          value="Bike"
          checked={everyPointX}
          onChange={() => setEveryPointX(!everyPointX)}
        />{" "}
        Every point between
      </Grid>
      <svg height={130} width={300} id="xSvg">
        <rect
          x={6 * x + 5}
          y={65 - 6 * y}
          width={dx === 0 && everyPointX ? 1 : 6 * dx}
          height={dy === 0 && everyPointX ? 1 : 6 * dy}
          style={{
            fill: everyPointX ? "purple" : "white"
          }}
        />
        <circle cx={6 * x + 5} cy={65 - 6 * y} r={5} style={{ fill: "red" }} />
        <circle
          cx={6 * x + 5 + 6 * dx}
          cy={65 - 6 * y}
          r={5}
          style={{ fill: "green" }}
        />
        <circle
          cx={6 * x + 5}
          cy={65 - 6 * y + 6 * dy}
          r={5}
          style={{ fill: "blue" }}
        />
        <circle
          cx={6 * x + 5 + 6 * dx}
          cy={65 - 6 * y + 6 * dy}
          r={5}
          style={{ fill: "grey" }}
        />
      </svg>
      <p>
        points{" "}
        <InlineMath
          math={"\\enspace \\color{red} (" + x + "," + (y - 1) + ") "}
        />
        <InlineMath
          math={"\\enspace \\color{green} (" + (x + dx) + "," + (y - 1) + ") "}
        />
        <InlineMath
          math={
            " \\enspace \\color{grey} (" + (x + dx) + "," + (y - 1 + dy) + ") "
          }
        />
        <InlineMath
          math={"\\enspace \\color{red} (" + x + "," + (y - 1 + dy) + ") "}
        />
      </p>
      <p>
        <InlineMath math={"\\color{red} " + dx} /> is a fixed distance between
        left two points and the right two points
      </p>

      <p>
        <InlineMath math={"\\color{blue} " + dy} /> is a fixed distance between
        bottom two points and the top two points
      </p>
      <p style={{ color: "purple" }}>
        {" "}
        We can enclose the area between to the points to form a plane. This
        represents every single point within the boundary. It is a flat surface
        with width, height, but not depth
      </p>
    </>
  );

  return <>{oneD}</>;
}

export default Dimensions;
