import React, { useState, useEffect, useRef } from "react";
import { Grid } from "@mui/material";

import { select, drag } from "d3";
import "katex/dist/katex.min.css";
import { InlineMath } from "react-katex";
import range from "lodash/range";

const Factor = props => {
  const factorSvg = useRef();
  const [order, setOrder] = useState(["red", "blue", "green"]);
  const [firstLoad, setFirstLoad] = useState(1000);

  const handleDrag = drag()
    .subject(function(event) {
      const me = select(this);
      
      return { x: event.x, y: 40 };
    })
    .on("drag", function(event) {
      const me = select(this);
      me.attr("transform", "translate(" + event.x + ",40)");
    })
    .on("end", function(event) {
      const me = select(this);
      const id = me.attr("id").split("-")[0];
      const temp = [];
      if (event.x <= 85) {
        temp.push(id);
        if (order[0] !== id) temp.push(order[0]);
        if (order[1] !== id) temp.push(order[1]);
        if (order[2] !== id) temp.push(order[2]);
      }
      if (event.x > 85 && event.x <= 185) {
        if (order[0] !==id) temp.push(order[0]);
        else if (order[1] !==id) temp.push(order[1]);
        else if (order[1] !==id) temp.push(order[1]);
        temp.push(id);
        if (order[0] !==id && order[0] !==temp[0]) temp.push(order[0]);
        if (order[1] !==id && order[1] !==temp[0]) temp.push(order[1]);
        if (order[2] !==id && order[2] !==temp[0]) temp.push(order[2]);
      }
      if (event.x > 185) {
        if (order[0] !==id) temp.push(order[0]);
        if (order[1] !==id) temp.push(order[1]);
        if (order[2] !==id) temp.push(order[2]);
        temp.push(id);
      }
      setOrder(temp);
    });

  const numberSvg = (
    <svg width={300} height={100} ref={factorSvg}>
      <g transform={"translate(-40,40)"} id="red-group">
        <circle r={40} style={{ fill: "red" }} />
        <text x={-10} y={10} style={{ fontSize: 40, fill: "white" }}>
          2
        </text>
      </g>

      <text x={85} y={50} style={{ fontSize: 25 }}>
        x
      </text>

      <g transform={"translate(-40,40)"} id="blue-group">
        <circle r={40} style={{ fill: "blue" }} />
        <text x={-10} y={10} style={{ fontSize: 40, fill: "white" }}>
          3
        </text>
      </g>

      <text x={185} y={50} style={{ fontSize: 25 }}>
        x
      </text>

      <g transform={"translate(-40,40)"} id="green-group">
        <circle r={40} style={{ fill: "green" }} />
        <text x={-10} y={10} style={{ fontSize: 40, fill: "white" }}>
          5
        </text>
      </g>
    </svg>
  );

  useEffect(() => {
    const factorCurrent = select(factorSvg.current);
    handleDrag(factorCurrent.selectAll("g"));

    const redGroup = factorCurrent.select("#red-group");
    const blueGroup = factorCurrent.select("#blue-group");
    const greenGroup = factorCurrent.select("#green-group");
    const groups = { red: redGroup, blue: blueGroup, green: greenGroup };
    
    const duration = firstLoad;
    setFirstLoad(500);
    groups[order[0]]
      .transition()
      .attr("transform", "translate(40, 40)")
      .duration(duration);
    groups[order[1]]
      .transition()
      .attr("transform", "translate(140, 40)")
      .duration(duration);
    groups[order[2]]
      .transition()
      .attr("transform", "translate(240, 40)")
      .duration(duration);
  }, [order]);

  const colorToNumber = { red: 2, blue: 3, green: 5 };

  const circleSize = 20;
  const greenCircle = (
    <svg width={circleSize * 1.2} height={circleSize * 1.2}>
      <circle
        cx={circleSize / 2}
        cy={circleSize / 2}
        r={circleSize / 2}
        fill={"green"}
      />
    </svg>
  );
  const redCircle = (
    <svg width={circleSize * 1.2} height={circleSize * 1.2}>
      <circle
        cx={circleSize / 2}
        cy={circleSize / 2}
        r={circleSize / 2}
        fill={"red"}
      />
    </svg>
  );
  const blueCircle = (
    <svg width={circleSize * 1.2} height={circleSize * 1.2}>
      <circle
        cx={circleSize / 2}
        cy={circleSize / 2}
        r={circleSize / 2}
        fill={"blue"}
      />
    </svg>
  );

  const circleObj = { red: redCircle, green: greenCircle, blue: blueCircle };

  const secondDiv = objects => (
    <Grid
      item
      style={{
        padding: 5
      }}
    >
      {objects}
    </Grid>
  );

  const firstDiv = objects =>
    new Array(colorToNumber[order[1]]).fill(false).map((v, i) => {
      return (
        <Grid
          item
          xs={3}
          key={"middle-" + i}
          style={{
            borderColor: order[1],
            borderStyle: "dotted",
            borderWidth: 4,
            padding: 5,
            margin: 5
          }}
        >
          {objects}
        </Grid>
      );
    });
  const zeroDiv = objects =>
    new Array(colorToNumber[order[0]]).fill(false).map((v, i) => {
      return (
        <Grid
          container
          key={"first-" + i}
          style={{
            borderColor: order[0],
            borderStyle: "solid",
            borderWidth: 4,
            padding: 5,
            margin: 5
          }}
        >
          {objects}
        </Grid>
      );
    });

  return (
    <>
      <h3>Associative Property of Multiplication</h3>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4} md={6}>
          <p>
            Drag a number to a new position and notice how it regroups the
            objects. No matter the associative grouping, the total remains the
            same.
          </p>
          {numberSvg}
          <p style={{ fontSize: 30, paddingLeft: 40 }}>
            {" "}
            <InlineMath
              math={
                "\\color{" +
                order[0] +
                "} " +
                colorToNumber[order[0]] +
                "\\color{black} \\times" +
                "\\color{" +
                order[1] +
                "} " +
                colorToNumber[order[1]] +
                "\\color{black} \\times" +
                "\\color{" +
                order[2] +
                "} " +
                colorToNumber[order[2]] +
                "\\color{black} = " +
                "\\color{" +
                order[2] +
                "} 30"
              }
            />
          </p>
          <hr />
          <p style={{ fontSize: 30, paddingLeft: 10 }}>
            {" "}
            <InlineMath
              math={"\\color{" + order[0] + "} " + colorToNumber[order[0]]}
            />{" "}
            groups of:
          </p>
          <p style={{ fontSize: 30, paddingLeft: 10 }}>
            {" "}
            <InlineMath
              math={"\\color{" + order[1] + "} " + colorToNumber[order[1]]}
            />{" "}
            other groups with:
          </p>
          <p style={{ fontSize: 30, paddingLeft: 10 }}>
            {" "}
            <InlineMath
              math={"\\color{" + order[2] + "} " + colorToNumber[order[2]]}
            />{" "}
            objects inside each for a total of{" "}
            <InlineMath math={"\\color{" + order[2] + "} 30 "} /> objects
          </p>
        </Grid>
        <Grid item xs={12} sm={4} md={6}>
          <Grid
            item
            style={{
              borderStyle: "double",
              padding: 10,
              minHeight: 200
            }}
          >
            {zeroDiv(
              firstDiv(
                secondDiv(
                  new Array(colorToNumber[order[2]]).fill(false).map((v, i) => {
                    return <span key={"last-" + i}>{circleObj[order[2]]}</span>;
                  })
                )
              )
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Factor;
