import React from "react";

import { Grid, Paper } from "@mui/material";
import "katex/dist/katex.min.css";
import { InlineMath } from "react-katex";

import OneDimension from "./OneDimension";
import TwoDimension from "./TwoDimension";
import ThreeDimension from "./ThreeDimension";
function Dimensions() {
  return (
    <>
      <h4>Visualizing bits of information: location and quantity</h4>
      <Paper style={{ backgroundColor: "#eee", padding: 10, marginBottom: 10 }}>
        <span style={{ fontSize: 25 }}>
          <InlineMath
            math={"\\color{red} \\bullet \\quad  x \\enspace "}
          ></InlineMath>
        </span>
        A single point with no dimension.{" "}
        <span style={{ marginLeft: 50 }}>
          A point is a reference to location, but that location does not mean
          much without other points of reference. To show more than one point,
          we need space. Our goal is to find a way to organize space and
          quantity so that we can equate the two.
        </span>
      </Paper>
      <Grid container spacing={4}>
        <OneDimension />
        <Grid item xs={12} md={4}>
          <Paper style={{ backgroundColor: "#fafafa", padding: 10 }} ><TwoDimension /></Paper>
        </Grid>
        <Grid item xs={12} md={4}><ThreeDimension /></Grid>
        
      </Grid>
    </>
  );
}

export default Dimensions;
