import React, { useState, useEffect, useRef } from "react";
import { select } from "d3";
import JXG from "jsxgraph";
import { Grid, Slider, Paper, Button } from "@mui/material";
import "katex/dist/katex.min.css";
import { BlockMath, InlineMath } from "react-katex";
import { Rectangle } from "../Tools/Rectangle";
import useWidth from "../Tools/UseWidth";

function LinearGraphObject(props) {
  const linearGraphRef = useRef();
  const handleChangeM = value => {};

  const svgWidth = useWidth() !== "lg" ? 260 : 400;
  const svgRef = useRef();
  const [m, setM] = useState(2);
  const [x, setX] = useState(3);
  const changeA = (o, value) => {
    setM(value);
  };
  const changeB = (o, value) => {
    setX(value);
  };

  const makeBrd = () => {
    const brd = JXG.JSXGraph.initBoard("graph", {
      boundingbox: [-1, 100, 10, -3],
      showCopyright: false,
      axis: true
    });

    const px = brd.create("point", [x, x * m], {
      name: x + ", " + m * x,
      visible: true,
      fillColor: "blue",
      strokeColor: "green"
    });

    const p0 = brd.create("point", [0, 0], {
      name: "",
      visible: false
    });

    const p1 = brd.create("point", [12, 12 * m], {
      name: "",
      visible: false
    });

    const pAxisX = brd.create("point", [x, 0], {
      name: "",
      visible: false
    });

    const pAxisY = brd.create("point", [0, m * x], {
      name: "",
      visible: false
    });

    brd.create("segment", [px, pAxisX], {
      strokeColor: "black",
      fixed: false,
      dash: 2
    });

    brd.create("segment", [px, pAxisY], {
      strokeColor: "black",
      fixed: false,
      dash: 2
    });

    brd.create("line", [p0, p1], { strokeColor: "blue", fixed: false });
  };

  useEffect(() => {
    
    makeBrd();
  }, [m, x]);

  const styles = {
    red: {
      color: "red"
    },
    green: {
      color: "green"
    },
    paddingLeftSide: {
      paddingTop: 35
    }
  };

  const math1 =
    "\\color{blue} f (\\color{green}x \\color{blue}) \\color{black} = \\color{red}m \\color{green} x ";
  const math2 =
    " \\color{blue} y \\color{black} =  \\color{red}" +
    m +
    " \\color{black}( \\color{green}" +
    x +
    "\\color{black}) = " +
    m * x;
  //const mathTotal = math1 + math2;

  return (
    <>
      <h4>Linear Rectangle and Graph</h4>

      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <Grid item xs={12}>
            <p style={{ fontSize: 20 }}>
              <InlineMath math={math1 + "\\color{black} + b \\quad"} /> with{" "}
              <InlineMath>\quad b = 0</InlineMath>
            </p>
            <h4 style={styles.red}>
              Slope <InlineMath math={"\\color{red}m = " + m} /> this is the{" "}
              <u>constant</u>
            </h4>

            <Slider
              style={styles.paddingLeftSide}
              defaultValue={5}
              onChange={changeA}
              aria-labelledby="discrete-slider"
              step={1}
              marks
              min={0}
              max={10}
              valueLabelDisplay="on"
            />
          </Grid>

          <Grid item xs={12}>
            <h4 style={styles.green}>
              {" "}
              Input <InlineMath math={"\\color{green}x = " + x} /> this is the{" "}
              <u>variable</u>
            </h4>
            <Slider
              style={styles.paddingLeftSide}
              defaultValue={7}
              onChange={changeB}
              aria-labelledby="discrete-slider"
              step={1}
              marks
              min={0}
              max={10}
              valueLabelDisplay="on"
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <svg width={svgWidth} height={svgWidth} ref={svgRef}>
            <Rectangle
              w={m + 1}
              l={x + 1}
              width={svgWidth}
              fill={"yellow"}
              lastFill={"blue"}
            />
          </svg>
          <p style={{ fontSize: 20 }}>
            <InlineMath math={math1 + "\\quad"} />

            <InlineMath math={math2} />
          </p>
        </Grid>
        <Grid item xs={12} md={4}>
          <div
            id="graph"
            ref={linearGraphRef}
            style={{ width: 300, height: 300 }}
          />
          <p style={{ fontSize: 25 }}>
            <InlineMath
              math={
                "\\frac{ \\color{blue}y }{\\color{green}x \\color{black}} = \\color{red}m \\quad  \\frac{ \\color{blue}" +
                m * x +
                " }{\\color{green}" +
                x +
                " \\color{black}} = \\color{red}" +
                m
              }
            />
          </p>
        </Grid>
      </Grid>
    </>
  );
}

export default LinearGraphObject;
