import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { select, drag } from "d3";
import { Grid, Slider, Hidden } from "@mui/material";
import "katex/dist/katex.min.css";
import { InlineMath } from "react-katex";

const SimilarTriangles = props => {
  const A = [0, 300]; //common left, fixed point
  const [small, setSmall] = useState(2);
  const [long, setLong] = useState(3);
  const bx = small * 50;
  const [B, setB] = useState([bx, A[1]]); // small lower right
  const [D, setD] = useState([long * 50, A[1]]); // larger lower right
  const C = [small * 50, 300 - small * 50];
  const E = [long * 50, 300 - long * 50];

  const reset = () => {
    setD([long * 50, A[1]]);
    setB([bx, A[1]]);
    setLong(3);
    setSmall(2);
  };

  const foreignRef = useRef();
  const svgRef = useRef();

  const AB =
    Math.round(
      100 *
        Math.sqrt((B[0] - A[0]) * (B[0] - A[0]) + (B[1] - A[1]) * (B[1] - A[1]))
    ) / 100;
  const AD =
    Math.round(
      100 *
        Math.sqrt((D[0] - A[0]) * (D[0] - A[0]) + (D[1] - A[1]) * (D[1] - A[1]))
    ) / 100;

  const DE =
    Math.round(
      100 *
        Math.sqrt((D[0] - E[0]) * (D[0] - E[0]) + (D[1] - E[1]) * (D[1] - E[1]))
    ) / 100;

  const BC =
    Math.round(
      100 *
        Math.sqrt((B[0] - C[0]) * (B[0] - C[0]) + (B[1] - C[1]) * (B[1] - C[1]))
    ) / 100;

  useEffect(() => {
    setB([bx, A[1]]);
    setD([long * 50, A[1]]);
    console.log("bx,long", bx, long, A, small);
  }, [small, long, bx]);

  const handleDrag = drag()
    .subject(function() {
      const me = select(this);
      const svg = select(svgRef.current);

      //current nodes
      const A = svg.select("#A");
      const B = svg.select("#B");
      const D = svg.select("#D");
      const pA = [parseInt(A.attr("cx")), parseInt(A.attr("cy"))];
      const pB = [parseInt(B.attr("cx")), parseInt(B.attr("cy"))];
      const pD = [parseInt(D.attr("cx")), parseInt(D.attr("cy"))];

      return {
        x: me.attr("cx"),
        y: me.attr("cy"),
        /*     A,
        B,
        D, */
        pA,
        pB,
        pD
      };
    })
    .on("drag", function(event) {
      const me = select(this);
      const id = me.attr("id");
      if (id === "B") {
        const Ax = event.subject.pA[0];
        const Bx = event.x;
        const Ay = event.subject.pA[1];
        const By = event.y;
        const d0x = (long * (Bx - Ax) + small * Ax) / small;
        const d0y = (long * (By - Ay) + small * Ay) / small;

        setD([d0x, d0y]);
        setB([event.x, event.y]);
      }
    });

  useEffect(() => {
    const svg = select(svgRef.current);
    handleDrag(svg.selectAll("circle"));
  }, [small, long]);

  const ACAE1 = <InlineMath math={"\\color{blue} \\frac{AC }{AE} "} />;

  const BCDE1 = <InlineMath math={" = \\color{green} \\frac{BC }{DE} "} />;

  const ABAD1 = <InlineMath math={"= \\color{red} \\frac{AB }{AD}  "} />;

  const ACAE = (
    <InlineMath
      math={"=  \\color{blue} \\frac{" + 50 * small + " }{" + 50 * long + "}"}
    />
  );

  const BCDE = (
    <InlineMath math={"  =  \\color{green} \\frac{" + BC + " }{" + DE + "}"} />
  );

  const ABAD = (
    <InlineMath math={" =  \\color{red}  \\frac{" + AB + " }{" + AD + "}"} />
  );

  const ACAE2 = (
    <InlineMath
      math={
        "  =   \\frac{\\color{black}" +
        small +
        "  \\times \\color{blue} 50  }{\\color{black}" +
        long +
        " \\color{black} \\times \\color{blue} 50 }"
      }
    />
  );

  const BCDE2 = (
    <InlineMath
      math={
        "  =   \\frac{\\color{black}" +
        small +
        " \\color{black} \\times \\color{green} " +
        Math.round((100 * BC) / small) / 100 +
        " }{\\color{black}" +
        long +
        " \\color{black} \\times \\color{green}" +
        Math.round((100 * DE) / long) / 100 +
        "}"
      }
    />
  );

  const ABAD2 = (
    <InlineMath
      math={
        "  =   \\frac{\\color{black}" +
        small +
        " \\color{black} \\times \\color{red} " +
        Math.round((100 * AB) / small) / 100 +
        " }{\\color{black}" +
        long +
        " \\color{black} \\times \\color{red}" +
        Math.round((100 * AD) / long) / 100 +
        "}"
      }
    />
  );

  const total = (
    <>
      <InlineMath math={"\\frac{x}{y}"} />
      {ACAE2}
      {BCDE2}
      {ABAD2}
    </>
  );

 /*  useEffect(() => {
    try {
      const node = select(foreignRef.current);
      ReactDOM.render(total, node.node());
    } catch (error) {}
  }); */
  return (
    <>
      <Grid container spacing={4}>
        <Grid item>
          <svg width={300} height={300} ref={svgRef}>
            

            <g transform="translate(20 -20)">
              <line
                id="ab"
                x1={A[0]}
                y1={A[1]}
                x2={B[0]}
                y2={B[1]}
                stroke="red"
                strokeWidth={3}
              />
              <line
                id="bc"
                x1={B[0]}
                y1={B[1]}
                x2={C[0]}
                y2={C[1]}
                stroke="green"
                strokeWidth={3}
              />
              <line
                id="ac"
                x1={A[0]}
                y1={A[1]}
                x2={C[0]}
                y2={C[1]}
                stroke="blue"
                strokeWidth={3}
              />
              <line
                id="bd"
                x1={B[0]}
                y1={B[1]}
                x2={D[0]}
                y2={D[1]}
                stroke="red"
                strokeWidth={3}
                opacity={0.6}
              />
              <line
                id="ed"
                x1={E[0]}
                y1={E[1]}
                x2={D[0]}
                y2={D[1]}
                stroke="green"
                strokeWidth={3}
                opacity={0.6}
              />
              <line
                id="ce"
                x1={C[0]}
                y1={C[1]}
                x2={E[0]}
                y2={E[1]}
                stroke="blue"
                strokeWidth={3}
                opacity={0.6}
              />
              <circle id="A" cx={A[0]} cy={A[1]} r={3} fill="purple" />
              <circle id="D" cx={D[0]} cy={D[1]} r={3} fill="teal" />
              <circle id="E" cx={E[0]} cy={E[1]} r={2} fill="blue" />
              <text id="textA" x={A[0]} y={A[1] - 10}>
                A
              </text>
              <text id="textB" x={B[0] + 5} y={B[1] - 10}>
                B
              </text>
              <text id="textC" x={C[0]} y={C[1] - 10}>
                C
              </text>
              <text id="textD" x={D[0]} y={D[1] - 10}>
                D
              </text>
              <text id="textE" x={E[0]} y={E[1] - 10}>
                E
              </text>
              <circle id="C" cx={C[0]} cy={C[1]} r={2} fill="green" />
              <circle id="B" cx={B[0]} cy={B[1]} r={10} fill="red" />
            </g>
            <g>
              <circle
                r={12}
                cx={12}
                cy={13}
                strokeWidth={1}
                stroke={"black"}
                fill="white"
                onClick={() => reset()}
              />
              <path
                onClick={() => reset()}
                d="M12 5V1L7 6l5 5V7c3.31 0 6 2.69 6 6s-2.69 6-6 6-6-2.69-6-6H4c0 4.42 3.58 8 8 8s8-3.58 8-8-3.58-8-8-8z"
              />
            </g>
          </svg>
        </Grid>
        <Grid item md={2}>
          <h4>
            Short{" "}
            <InlineMath
              math={"( \\color{blue}AC  =  " + 50 * small + " \\color{black} )"}
            />
          </h4>
          <Slider
            key={"small-" + small}
            style={{ padding: 5 }}
            defaultValue={small}
            value = {small}
            //getAriaValueText={changeB}
            onChange={(o, v) => {
              setSmall(v);
              setLong(v + 1);
            }}
            aria-labelledby="discrete-slider"
            step={0.5}
            marks
            min={1}
            max={4}
            valueLabelDisplay="auto"
            valueLabelFormat={v => v * 50}
          />
          <h4>
            Long{" "}
            <InlineMath
              math={"( \\color{blue}AE  = " + 50 * long + " \\color{black} )"}
            />{" "}
          </h4>
          <Slider
            key={"big-" + small}
            style={{ padding: 5 }}
            defaultValue={small + 1}
            value = {long}
            //getAriaValueText={changeB}
            onChange={(o, v) => setLong(v)}
            aria-labelledby="discrete-slider"
            step={0.5}
            marks
            min={small}
            max={small + 4}
            valueLabelDisplay="auto"
            valueLabelFormat={v => v * 50}
          />
        </Grid>
        <Grid item style={{ fontSize: 20 }}>
          <h5>Short : Long</h5>
          <p>
            {ACAE1}
            {BCDE1}
            {ABAD1}
            <InlineMath math={" = \\frac{" + small + "}{" + long + "}"} />
          </p>
          <p>
            {ACAE}
            {BCDE}
            {ABAD}
          </p>

          <p>
            {ACAE2}
            {BCDE2}
            {ABAD2}
          </p>
        </Grid>
      </Grid>
    </>
  );
};

export default SimilarTriangles;
