import React, { useState, useRef, useEffect } from "react";
import { Grid, Slider, Button } from "@mui/material";
import "katex/dist/katex.min.css";
import { InlineMath } from "react-katex";

const MultiplyBeforeDivide = (props) => {
  const defaultW = 400;
  const defaultH = 400;

  const [insideDots, setInsideDots] = useState(4);
  const [containers, setContainers] = useState(2);
  const [outSideDots, setOutSideDots] = useState(3);
  const [showAnswer, setShowAnswer] = useState(true);

  const insideDotsArray = new Array(insideDots).fill(insideDots);
  const containersArray = new Array(containers).fill(containers);
  const outSideDotsArray = new Array(outSideDots).fill(outSideDots);
  //const centerX = scale()

  const containerWidth = 25;
  //const containerHeight = 100;

  const outSideDotMap = outSideDotsArray.map((v, i) => {
    const r = containerWidth / 5;
    return (
      <circle
        key={"outside-" + i}
        cx={2.5 * r * (0.8 + i)}
        cy={10}
        r={r}
        fill={"blue"}
        stroke={"red"}
      />
    );
  });

  const insideDotMap = insideDotsArray.map((v, i) => {
    const r = containerWidth / 5;
    return (
      <circle
        key={"outside-" + i}
        cx={2.2 * r * (0.8 + (i % 4))}
        cy={2.2 * r * (1 + Math.floor(i / 4))}
        r={r}
        fill={"blue"}
      />
    );
  });

  const width = 50;

  const SingleContainer = (props) => (
    <svg width={50 * 1.2} height={50 * 1.2}>
      <rect width={width} height={width} fill={"red"} opacity={0.3} />
      {props.dots}{" "}
    </svg>
  );

  const containersMap = containersArray.map(() => {
    return <SingleContainer dots={insideDotMap} />;
  });

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <h6>Objects outside containers</h6>
          <Slider
            defaultValue={outSideDots}
            onChange={(o, v) => setOutSideDots(v)}
            //getAriaValueText={hadleSliderA}
            value={outSideDots}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            step={1}
            marks
            min={0}
            max={20}
            style={{ marginTop: 20 }}
          />
          <h6>Containers</h6>
          <Slider
            defaultValue={containers}
            onChange={(o, v) => setContainers(v)}
            //getAriaValueText={hadleSliderA}
            value = {containers}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            step={1}
            marks
            min={0}
            max={10}
            style={{ marginTop: 20 }}
          />
          <h6>Objects inside container</h6>
          <Slider
            defaultValue={insideDots}
            onChange={(o, v) => setInsideDots(v)}
            //getAriaValueText={hadleSliderA}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            step={1}
            marks
            min={0}
            max={10}
            style={{ marginTop: 20 }}
          />
        </Grid>
        <Grid item>
          <p>Outside containers</p>
          <svg width={300} height={50}>
            {outSideDotMap}
          </svg>
          <p>Inside containers</p>
          {containersMap}
          <p style={{ fontSize: 25 }}>
            {" "}
            <InlineMath
              math={
                "\\color{blue} " +
                outSideDots +
                " \\color{black} + \\color{red} " +
                containers +
                "\\color{black} \\times \\color{blue}" +
                insideDots
              }
            />
          </p>
          <p style={{ fontSize: 25 }}>
            {" "}
            {showAnswer && (
              <InlineMath
                math={
                  " \\color{black} =\\color{blue} " +
                  outSideDots +
                  " \\color{black} + " +
                  containers * insideDots +
                  " = " +
                  (outSideDots + containers * insideDots)
                }
              />
            )}{" "}
            <Button
              variant="outlined"
              onClick={() => setShowAnswer(!showAnswer)}
            >
              {showAnswer ? "Hide Answer" : "Show Answer"}
            </Button>
          </p>
        </Grid>
      </Grid>
    </>
  );
};

export default MultiplyBeforeDivide;
