import React, { useEffect, useRef, useState } from "react";
import NumberLine from "../Tools/NumberLine";
import useMediaQuery from '@mui/material/useMediaQuery';
import {  useTheme } from '@mui/material/styles';
import { select, scaleLinear, max, min, path as d3path } from "d3";
import { Grid, Slider, Paper } from "@mui/material";
import "katex/dist/katex.min.css";
import { BlockMath, InlineMath } from "react-katex";
//import Skyline from "../../assets/skyline";
function useWidth() {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || 'xs'
  );
}

function BasicNumberLine(props) {
  const divRef = useRef(null);
  const skyRef = useRef();
  const defaultA = 5;
  const defaultB = 3;
  const [width, setWidth] = useState( useWidth() === "xs" ? 350 : 600);
  const initialData = [-10, 10];
  //const [data, setData] = useState(initialData);
  const xShift = 0.05 * width;
  const [valueA, setValueA] = useState(defaultA);
  const [valueB, setValueB] = useState(defaultB);
  const total = valueA + valueB;
  const data = [valueA - 10, valueA + 10];

  const skyLinePath =
    "v-10 h 10 v 10 h1 v-15 h 6 v15 h1 v-8 l 2 -4 2 4 v 8 h1 v-8  q 10 -8 22  0 v 8 h1 v -16 h 3 v16h1v-9 l 6 -8 6 9 v8 h6 v -3 h4 v3 h1 v-6 h5 v6h1 v-11 h8v11h1v-14 q 5 -8 10 0 v14 h1 v-1-20 h3v21";

 /*  useEffect(() => {
    setWidth( useWidth() === "xs" ? 350 : 600);
  }, [props.width, width]); */

  useEffect(() => {
    //setData([valueA - 1, valueA + 10])
    const sky = select(skyRef.current).select("svg");
    sky.attr("viewBox", 10 * valueA + " 175 500 40");

    const scale = scaleLinear()
      .domain([min(data), max(data)])
      .range([0, 0.9 * width]);

    const scaleSky = scaleLinear()
      .domain([0, 15])
      .range([0, 0.9 * width]);

    select(divRef.current)
      .select("svg")
      .remove();

    const svg = select(divRef.current)
      .append("svg")
      .attr("width", width)
      .attr("height", 22)
      .attr("transform", "translate(0, 10)");

    svg
      .append("circle")
      .attr("id", "circle-total")
      .attr("r", 10)
      .attr("cx", scale(total))
      .attr("cy", 10)
      .style("fill", "red")
      .style("stroke", "red")
      .attr("transform", "translate(" + xShift + ", 1)");

    svg
      .append("circle")
      .attr("id", "circle-ValueA")
      .attr("r", 10)
      .attr("cx", scale(valueA))
      .attr("cy", 10)
      .style("fill", "purple")
      .style("stroke", valueB === 0 ? "red" : "purple")
      .attr("transform", "translate(" + xShift + ", 1)");

    sky.attr("viewBox", scaleSky(valueA) + " 0 600 100");
    let start = valueA;
    const path = d3path();

    const steps = Math.abs(valueB);

    const times = n => f => {
      let iter = i => {
        if (i === n) return;
        f(i);
        iter(i + 1);
      };
      return iter(0);
    };

    if (valueB >= 0) {
      times(steps)(i => {
        path.moveTo(scale(start), 5);

        path.bezierCurveTo(
          scale(start),
          5,
          scale(start + 0.5),
          0,
          scale(start + 1),
          5
        );
        start++;
      });
    } else {
      times(steps)(i => {
        path.moveTo(scale(start), 5);
        //path.arcTo(scale(start - 0.6), -5, scale(start - 1.3), 10, 30);
        path.bezierCurveTo(
          scale(start),
          5,
          scale(start - 0.5),
          0,
          scale(start - 1),
          5
        );
        start--;
      });
    }
    //path.moveTo(scale(valueA), 10);
    //path.arcTo(scale(valueA + 0.5), 0, scale(valueA + 1), 10, 30);
    // path.arcTo(scale(valueA+1), 0, scale(valueA + 1.5), 10, 0);

    svg
      .append("path")
      .attr("d", path.toString())
      .attr("stroke", "blue")
      .attr("stroke-width", 1)
      .attr("fill", "none")
      .attr("transform", "translate(" + (xShift - 1) + ", 1)")
      .attr("stroke-width", 2);
  }, [data, valueA, valueB, width, total, xShift]);

  const handleChangeNumberA = (o, value) => {
    setValueA(value);
  };

  const handleChangeNumberB = (o, value) => {
    setValueB(value);
  };

  const styles = {
    purple: { color: "purple", marginBottom: 50 },
    red: { color: "red", marginBottom: 50 },
    blue: { color: "blue", marginBottom: 50 },
    paddingLeft: { paddingLeft: 10 },
    marginBottom: { marginBottom: 50 }
  };

  return (
    <>
      <h3>Modeling moving through a city using POSITIVE and NEGATIVE</h3>
      <Grid container spacing={3}>
        <Grid item sm={12} md={6}>
          <Grid item style={styles.paddingLeft}>
            <h4 style={styles.purple}>
              {" "}
              First Number - start here relative to the city
            </h4>
            <Slider
              defaultValue={defaultA}
              onChange={handleChangeNumberA}
              aria-labelledby="discrete-slider"
              step={1}
              marks
              min={-2}
              max={10}
              valueLabelDisplay="on"
            />
          </Grid>
          <Grid item style={styles.paddingLeft}>
            <h4 style={styles.blue}>
              {" "}
              Second Number - move to the{" "}
              {valueB >= 0 ? " right (increase) " : " left (decrease) "} by{" "}
              <InlineMath>| this \enspace amount | </InlineMath>
            </h4>
            <Slider
              defaultValue={defaultB}
              onChange={handleChangeNumberB}
              aria-labelledby="discrete-slider"
              step={1}
              marks
              min={-10}
              max={10}
              valueLabelDisplay="on"
            />
          </Grid>{" "}
        </Grid>
        <Grid item sm={12} md={6}>
          <p style={{ fontSize: 20 }} align="center">
            <InlineMath math={"\\color{blue} " + valueB} /> more than
            <InlineMath math={"\\enspace \\color{purple} " + valueA} /> is
            <InlineMath math={"\\enspace \\color{red} " + total + "\\quad"} />
            <span style={{ fontSize: 10 }}>or </span>
            <InlineMath math={"\\quad \\color{blue} " + -valueB} /> less than
            <InlineMath math={"\\enspace \\color{purple} " + valueA} /> is
            <InlineMath math={"\\enspace \\color{red} " + total} />
          </p>
          <div ref={divRef} width={width}></div>
          <NumberLine data={data} width={width} value={total} />
          <div ref={skyRef}>
            <svg width={width} height={100}>
              <path
                d={"m 0 20 " + skyLinePath.repeat(10)}
                fill="#DAC8C4"
                stroke="black"
              />{" "}
            </svg>
          </div>
          <p style={{ fontSize: 30 }}>
            <InlineMath
              math={
                "\\color{purple}" +
                valueA +
                "\\color{black} + ( \\color{blue} " +
                valueB +
                "\\color{black} ) = \\color{red} " +
                total +
                " \\quad "
              }
            />{" "}
            <span style={{ fontSize: 10 }}>simplifies to</span>{" "}
            <InlineMath
              math={
                " \\quad \\color{purple}" +
                valueA +
                " \\color{black} " +
                (valueB >= 0 ? " + " : " - ") +
                "  \\color{blue} " +
                Math.abs(valueB) +
                "\\color{black}  = \\color{red} " +
                total
              }
            />
          </p>
        </Grid>
      </Grid>
      <Grid container>
        <Paper
          elevation={20}
          variant="outlined"
          style={{
            padding: 5,
            backgroundColor: "rgb(240, 240, 240)",
            marginBottom: 20,
            fontSize: 20
          }}
        >
          <p>
            We will travel the city skyline{" "}
            <i style={{ color: "purple" }}>starting at the point </i> marked at{" "}
            <InlineMath math={"\\color{purple} x = " + valueA} />. We will{" "}
            <i style={{ color: "blue" }}>
              move to the {valueB >= 0 ? "right" : "left"} by
            </i>{" "}
            a distance of <InlineMath math={"\\color{blue} x = " + valueB} />.
            After walking a bit,
            <i style={{ color: "red" }}> our new locations </i> will be marked
            at <InlineMath math={"\\color{red} x = " + total} />
          </p>
          <hr />
          <h5>Reference and Perspectives</h5>
          <p>
            Notice how changing the{" "}
            <i style={{ color: "purple" }}>starting point</i> does seem to
            change the number line as much without reference to the skyline. The{" "}
            <i style={{ color: "purple" }}>purple</i> and
            <i style={{ color: "red" }}> red </i> circles, and the{" "}
            <i style={{ color: "blue" }}>blue</i> arcs stay the same relative to
            each other. Changing the{" "}
            <i style={{ color: "purple" }}>starting point</i> changes our
            location in <b>reference</b> to the city skyline.
          </p>
          <p>
            <b>Adding</b> represents <b>movement</b> to the left. We start at{" "}
            <i style={{ color: "purple" }}> point </i>{" "}
            <InlineMath math={"\\color{purple} x = " + valueA} />,{" "}
            <i style={{ color: "blue" }}> move by </i>{" "}
            <InlineMath math={"\\color{blue} " + valueB} /> and
            <i style={{ color: "red" }}> end up </i> at point{" "}
            <InlineMath math={"\\color{red} y = " + (valueA + valueB)} />{" "}
          </p>
          <BlockMath math="\color{purple}x \color{black}  + \color{blue} \Delta x \color{black} = \color{red}y" />
          <BlockMath
            math={
              "\\color{purple}" +
              valueA +
              " \\color{black} +( \\color{blue} " +
              valueB +
              " \\color{black} ) = \\color{red}" +
              total
            }
          />
          <BlockMath
            math={
              " \\quad \\color{purple}" +
              valueA +
              " \\color{black} " +
              (valueB >= 0 ? " + " : " - ") +
              "  \\color{blue} " +
              Math.abs(valueB) +
              "\\color{black}  = \\color{red} " +
              total
            }
          />
        </Paper>
      </Grid>
    </>
  );
}

export default BasicNumberLine;
