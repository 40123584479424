import React, { useState } from "react";
import { Grid, Slider, Button } from "@mui/material";
import "katex/dist/katex.min.css";
import { InlineMath } from "react-katex";

const OneStepEq = props => {
  const handleButton = i => {};
  const buttonArray = new Array(11).fill(false);
  // const [buttonSelected, setButtonSelected] = useState(2);

  const [xValue, setXvalue] = useState(2);
  const [yValue, setYvalue] = useState(3);
  const totalValue = xValue + yValue;
  const [hide, setHide] = useState(1);
  const [show, setShow] = useState(false);
  const min = hide === 1 ? xValue : hide === 2 ? yValue : 0;
  const max = min + 10;

  const [sub, setSub] = useState(0);

  const x = hide === 1 ? "x" : xValue;
  const y = hide === 2 ? "y" : yValue;
  const total = hide === 3 ? "t" : totalValue;
  const totalSub = hide === 3 ? "t - " + sub : totalValue - sub;

  const subtotal =
    sub === yValue
      ? "\\overline{\\quad \\quad  \\color{red} x \\color{black} = " +
        totalSub +
        "}"
      : " \\overline{ \\color{red} x \\color{black} + " +
        (yValue - sub) +
        " = \\enspace " +
        (totalValue - sub) +
        " }";

  const xArray = new Array(xValue).fill(xValue);

  const xCircles = xArray.map((v, i) => {
    const r = 6;
    return (
      <circle
        cx={2.6 * (1 + (i % 4)) * r}
        cy={2.6 * r * (1 + Math.floor(i / 4))}
        r={r}
        key={"xCircle-" + i}
        fill={"red"}
        //opacity={i < sub ? 0.3 : 1}
      />
    );
  });

  const xText = (
    <text fill="red" x={50} y={30} fontSize="25">
      ?
    </text>
  );
  const xOut = sub === yValue || show === true ? xCircles : xText;

  const yArray = new Array(yValue).fill(yValue);

  const yCircles = yArray.map((v, i) => {
    const r = 6;
    return (
      <circle
        cx={120 + 2.6 * (1 + (i % 4)) * r}
        cy={2.6 * r * (1 + Math.floor(i / 4))}
        r={r}
        key={"yCircle-" + i}
        fill={"blue"}
        opacity={i < sub ? 0.3 : 1}
      />
    );
  });

  const yText = (
    <text fill="blue" x={140} y={30} fontSize="25">
      ?
    </text>
  );
  const yOut = hide === 2 ? yText : yCircles;

  const tArray = new Array(yValue + xValue).fill(yValue + xValue);
  const tCircles = tArray.map((v, i) => {
    const r = 6;
    return (
      <circle
        cx={230 + 2.6 * (1 + (i % 4)) * r}
        cy={2.6 * r * (1 + Math.floor(i / 4))}
        r={r}
        key={"yCircle-" + i}
        fill={"black"}
        opacity={i < sub ? 0.3 : 1}
      />
    );
  });

  const tText = (
    <text fill="black" x={240} y={30} fontSize="25">
      ?
    </text>
  );
  const tOut = hide === 2 ? tText : tCircles;

  const eq =
    "\\begin{aligned} \\color{red} " +
    x +
    " \\color{black} & +  \\color{blue}  " +
    y +
    " \\color{black} =  " +
    total +
    "\\\\ &-" +
    sub +
    " =  - " +
    sub +
    "\\end{aligned}";

  /*  \begin{aligned}
   a&=b+c \\
   d+e&=f
\end{aligned} */

  const setTotal = v => {
    /* const bool = Math.random() >= 0.5;
    bool ? setXvalue(v - yValue) : setYvalue(v - xValue); */
    hide === 1 ? setYvalue(v - xValue) : setXvalue(v - yValue);
  };

  const buttons = item =>
    buttonArray.map((v, i) => {
      return (
        <span
          key={"button-" + item + "-" + i}
          style={{
            margin: 1
          }}
        >
          <Button onClick={() => setXvalue(i)} variant="outlined">
            {" "}
            {i}{" "}
          </Button>{" "}
        </span>
      );
    });
  return (
    <>
      {" "}
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          {hide !== 1 ? (
            <div>
              <h6>X</h6>
              <Slider
                defaultValue={xValue}
                onChange={(o, v) => {
                  setXvalue(v);
                }}
                value = {xValue}
                aria-labelledby="discrete-slider"
                step={1}
                marks
                min={0}
                max={10}
                valueLabelDisplay="on"
              />
            </div>
          ) : (
            buttons("x")
          )}
          {hide !== 2 ? (
            <div>
              <h6>Y</h6>
              <Slider
                defaultValue={yValue}
                value = {yValue}
                onChange={(o, v) => {
                  setYvalue(v);
                }}
                aria-labelledby="discrete-slider"
                step={1}
                marks
                min={0}
                max={10}
                valueLabelDisplay="on"
              />
            </div>
          ) : (
            buttons("y")
          )}
          {hide !== 3 ? (
            <div>
              <h6>Total</h6>
              <Slider
                key={"total-slider-" + min}
                defaultValue={total}
                value = {total}
                onChange={(o, v) => {
                  setTotal(v);
                }}
                aria-labelledby="discrete-slider"
                step={1}
                marks
                min={min}
                max={max}
                valueLabelDisplay="on"
              />
            </div>
          ) : (
            buttons("t")
          )}

          <p>
            <Button onClick={() => setShow(!show)}>
              {show ? "Hide" : "Show"}
            </Button>
          </p>
        </Grid>
        <Grid item style={{ fontSize: 25 }}>
          <InlineMath
            math={
              "\\color{red} " +
              x +
              " \\color{black} +  \\color{blue}  " +
              y +
              " \\color{black} =  " +
              total
            }
          />
          { (show === true || sub === yValue) && <p><InlineMath
            math={
              "\\color{red} " +
              xValue +
              " \\color{black} +  \\color{blue}  " +
              y +
              " \\color{black} =  " +
              total
            }
          /></p>}
        </Grid>
        <Grid item>
          <svg width={310} height={150}>
            <rect width={310} height={150} fill={"#ddd"} />
            <text x={90} y={30} fontSize="25">
              +
            </text>
            <text x={210} y={30} fontSize="25">
              =
            </text>
            {xOut} {yOut} {tOut}
          </svg>
          <h6>Subtract</h6>
          <Slider
            key={
              "sub-slider-" +
              (hide === 1 ? yValue : hide === 2 ? xValue : xValue + yValue)
            }
            defaultValue={sub}
            value = {sub}
            onChange={(o, v) => {
              setSub(v);
            }}
            aria-labelledby="discrete-slider"
            step={1}
            marks
            min={0}
            max={hide === 1 ? yValue : hide === 2 ? xValue : xValue + yValue}
            valueLabelDisplay="on"
          />
        </Grid>
        <Grid item style={{ fontSize: 25 }}>
          <InlineMath math={eq} />
          <br />
          <InlineMath math={subtotal} />
        </Grid>
      </Grid>
    </>
  );
};

export default OneStepEq;
