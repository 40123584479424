import React, { useState } from "react";

import { Slider, Grid, Box, Divider } from "@mui/material";
import { InlineMath } from "react-katex";
import RiemannSum from "./RiemannSum";

export default function FlexGrow() {
  const [x, setX] = useState(4);
  const multiplyArray = new Array(x).fill(1);
  const [contact, setContact] = useState(false);

  const circle3 = (
    <g>
      <circle r={5} fill={"red"} cx={7.5} cy={7.5} />
      <circle r={5} fill={"red"} cx={19} cy={7.5} />
      <circle r={5} fill={"red"} cx={30.5} cy={7.5} />
    </g>
  );

  const rectangle = (
    <svg width={45} height={15}>
      <rect width={40} height={15} fill="blue" opacity={0.5} />
      {circle3}
    </svg>
  );

  const rectangles = multiplyArray.map((v, i) => (
    <span key={"rect-" + i}>{rectangle} </span>
  ));

  const liClass = { padding: 4 };

  return (
    <div style={{ width: "100%" }}>
      <Grid container sx={{ padding: 2 }} spacing={3}>
        <Grid
          item
          md={7}
          sm={12}
          sx={{
            flexGrow: 1,
            flexWrap: "wrap",
            border: 1,
            borderRadius: 2,
            marginRight: { xs: 0, md: 2 },
          }}
        >
          <h2>Mission</h2>
          <p>
            MathCart’s mission is to inspire and empower learners to achieve
            success by developing a deeper understanding of mathematical
            concepts.{" "}
          </p>
          <Box sx={{ textAlign: "left" }}>
            <ul>
              <li>
                <h3 style={{ margin: 0 }}>Start with the Learner</h3>
                <ul>
                  <li style={liClass}>
                    Learning cannot happen without a proper learning mindset.{" "}
                  </li>
                  <li style={liClass}>
                    If the learner has anxiety about math, we have to remove it
                    first.
                  </li>
                  <li style={liClass}>
                    Teach the learner how to self-evaluate their ability and
                    move forward step by step
                  </li>
                </ul>
              </li>
              <li>
                <h3 style={{ margin: 0 }}>Build Math Intuition</h3>
                <ul>
                  <li style={liClass}>Adapt to learning styles. </li>
                  <li style={liClass}>
                    Build a fluid and sensible connection between problems and
                    math concepts
                  </li>
                  <li style={liClass}>
                    Provide multiple conceptual perspectives with interactive
                    looks at mathematics.
                  </li>
                </ul>
              </li>
              <li>
                <h3 style={{ margin: 0 }}>
                  Understand (and Love) Math for All Its Wonder
                </h3>
                <ul>
                  <li style={liClass}>
                    Math is nothing to be afraid of, it is one of the best
                    descriptive languages known to humans.{" "}
                  </li>
                  <li style={liClass}>
                    We have never met anyone who dislikes math once they
                    understand it!
                  </li>
                </ul>
              </li>
            </ul>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{ flexGrow: 1, border: 1, borderRadius: 2 }}
        >
          <Box mb={2}>Example 1</Box> {rectangles}
          <h3>Slide to Visualize</h3>
          <p>
            <InlineMath math={"\\color{blue} " + x} /> sets of{" "}
            <InlineMath math={"\\color{red}3 "} />
          </p>
          <p style={{ paddingBottom: 15 }}>
            <InlineMath
              math={
                "\\large \\color{blue}" +
                x +
                "\\color{black} \\times \\color{red} 3 \\color{black} = " +
                3 * x
              }
            />
          </p>
          <Slider
            defaultValue={x}
            value={x}
            onChange={(o, v) => setX(v)}
            aria-labelledby="discrete-slider"
            step={1}
            marks
            min={0}
            max={10}
            valueLabelDisplay="auto"
            mt={5}
          />{" "}
          <p style={{ paddingTop: 15 }}>
            <InlineMath
              math={
                "\\large \\frac{" +
                3 * x +
                "}{\\color{red}" +
                3 +
                "} \\color{black} = \\color{blue}" +
                x
              }
            />
          </p>
          <p style={{ paddingTop: 15 }}>
            <InlineMath
              math={
                "\\large \\frac{\\text{total}}{\\color{red} \\text{size of grouping}} \\color{black} = \\color{blue} \\text{number per group}"
              }
            />
          </p>
        </Grid>
      </Grid>
      <Grid container sx={{ padding: 2 }} spacing={3}>
        <Grid
          item
          md={7}
          sm={12}
          sx={{
            flexGrow: 1,
            flexWrap: "wrap",
            border: 1,
            borderRadius: 2,
            marginRight: { xs: 0, md: 2 },
          }}
        >
          <h2>History</h2>
          <p style={{ textAlign: "left", paddingRight: 10 }}>
            MathCart.com grew out of a classroom necessity first observed in a
            university setting. Too many students were literally dropping out of
            school due to failure at math. These were students who graduated
            from high school but still had little concept of the math they
            learned. Instead they memorized procedures to get them through the
            next exams. So MathCart was created to provide a fresh new way of
            interaction with math and was actively used in the classroom. In
            2020, during the Covid pandemic, the founder and creator of MathCart
            left his university position and has continued to develop MathCart
            for learners of all ages with a goal to help as many students as
            possible achieve a better understanding of math.{" "}
          </p>
          <Divider />
          <Divider />
          <h3>Benefits</h3>
          <Box sx={{ textAlign: "left" }}>
            <ul>
              <li>
                <h3 style={{ margin: 0 }}>Stop the Bleeding</h3>
                <ul>
                  <li style={liClass}>
                    Underperforming in a class? A MathCart tutor can help.{" "}
                  </li>
                </ul>
              </li>

              <li>
                <h3 style={{ margin: 0 }}>Conceptualize Math Properly</h3>
                <ul>
                  <li style={liClass}>
                    Struggling with math? MathCart can help by giving you a
                    better understanding of how math works so it makes sense and
                    becomes easier to get.{" "}
                  </li>
                </ul>
              </li>
              <li>
                <li>
                  <h3 style={{ margin: 0 }}>Improve Performance</h3>
                  <ul>
                    <li style={liClass}>
                      Loving the concepts of math is great, but most often
                      learners have a real need to improve their math
                      performance, namely, some sort of math exam in class or
                      standardized like the SAT. Our tutors will help you get
                      the results you desire.
                    </li>
                  </ul>
                </li>
              </li>
            </ul>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{ flexGrow: 1, border: 1, borderRadius: 2 }}
        >
          <Box mb={2}>Example 2</Box> 
          <b>Riemann Sum</b>
          
          <RiemannSum />
        </Grid>
      </Grid>
    </div>
  );
}
