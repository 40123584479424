import React, { useState } from "react";
import Header from "./Header";
import Body from "./Body";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
export default function Layout() {
  const [drawer, setDrawer] = useState(false);
  const [penColor, setPenColor] = useState("red");
  const [pen, setPen] = useState(false);
  
  return (
    <Router basename={"/classroom"} >
      <Header
        pen={pen}
        setPen={setPen}
        drawer={drawer}
        setDrawer={setDrawer}
        setPenColor={setPenColor}
        penColor = {penColor}
      />
      <Body
        pen={pen}
        setPen = {setPen}
        drawer={drawer}
        setDrawer={setDrawer}
        penColor={penColor}
      />
    </Router>
  );
}
