import React from "react";
import { Box } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { BrowserRouter as Router, Link } from "react-router-dom";
import { theme, simpleCss } from "./Theme";
import logo from "../../Images/logo.gif";
import MenuIcon from "@mui/icons-material/Menu";
export default function Header(props) {
  const makePen = (color) => {
    props.setPenColor(color);
    if (color === props.penColor && props.pen) props.setPen(false);
    else props.setPen(true);
  };

  const pen = props.pen;
  const penColor = props.penColor;
  const penDots = (
    <>
      <svg width={150} height={72}>
        <circle
          cx={15}
          cy={37}
          fill="red"
          r={8}
          opacity={pen && penColor !== "red" ? 0.3 : 1}
          onClick={() => makePen("red")}
        />
        <circle
          cx={35}
          cy={37}
          fill="green"
          r={8}
          opacity={pen && penColor !== "green" ? 0.3 : 1}
          onClick={() => makePen("green")}
        />
        <circle
          cx={15}
          cy={57}
          fill="blue"
          r={8}
          opacity={pen && penColor !== "blue" ? 0.3 : 1}
          onClick={() => makePen("blue")}
        />
        <circle
          cx={35}
          cy={57}
          fill="black"
          r={8}
          opacity={pen && penColor !== "black" ? 0.3 : 1}
          onClick={() => makePen("black")}
        />
        <circle
          cx={55}
          cy={37}
          fill="salmon"
          r={8}
          opacity={pen && penColor !== "salmon" ? 0.3 : 1}
          onClick={() => makePen("salmon")}
        />
        <circle
          cx={55}
          cy={57}
          fill="purple"
          r={8}
          opacity={pen && penColor !== "purple" ? 0.3 : 1}
          onClick={() => makePen("purple")}
        />
        <text x={80} y={53}>
          &#8592; Pen
        </text>
      </svg>
    </>
  );

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          borderRadius: 2,
          p: 2,
          minWidth: 300,
          border: 1,
          display: "flex",
          margin: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box sx={{ flexGrow: 1, width: 100 }}>
          {" "}
          <Link to="/">
            <img src={logo} style={{ width: 150 }} />
          </Link>
        </Box>
        <Box
          sx={{
            color: "black",
            flexGrow: 1,
          }}
        >
          <Button
            sx={{ color: "black" }}
            onClick={() => props.setDrawer(!props.drawer)}
            startIcon={<MenuIcon />}
          >
            Menu
          </Button>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            display: { xs: "none", sm: "block" },
            transform: "translate(0, -10px)",
          }}
        >
          {penDots}
        </Box>

        <Box
          sx={{
            color: "text.secondary",
            display: "inline",
            fontSize: 14,
            flexGrow: 1,
            display: { xs: "none", sm: "block" },
          }}
        >
          <Link to="lesson-demos" style={{ textDecoration: "none" }}>
            <Button sx={{ color: "black" }}>Lesson Demos</Button>
          </Link>
        </Box>

        <Box
          sx={{
            color: "text.secondary",
            display: "inline",
            fontSize: 14,
            flexGrow: 1,
            display: { xs: "none", sm: "block" },
          }}
        >
          <a href="https://mathcart.com/tutor/schedule-a-session/"  style={{textDecoration : "none"}}>
            <Button
              color="primary"
              variant="contained"
              sx={{ marginBottom: { xs: 5, md: 0 } }}
              mb={5}
            >
              Schedule a Session
            </Button>
          </a>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
