import React, { useState, useEffect, useRef } from "react";

import {
  Grid,
  Slider,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  FormHelperText
} from "@mui/material";

import "katex/dist/katex.min.css";
import { InlineMath } from "react-katex";
import range from "lodash/range";

const styles = {
  green: {
    color: "green"
  },
  blue: {
    color: "blue"
  },
  paddingLeftSide: {
    paddingTop: 35
  }
};

function DemoPage() {
  const svgRefNots = useRef();
  const handleChangeM = value => {};
  const buttonClick = () => {};
  const changeContainers = (o, value) => {
    setN(value);
  };

  const defaultGreen = 2;
  const [green, setGreen] = useState(defaultGreen);
  const defaultBlue = 3;
  const [blue, setBlue] = useState(defaultBlue);

  const [n, setN] = useState(3);
  const containers = new Array(n).fill(0);
  const [greenArray, setGreenArray] = useState(range(1, 9));
  const [blueArray, setBlueArray] = useState(range(1, 9));
  //const [greee, setChangeOfGreen]

  const greenDotArray = new Array(green).fill("green");
  const blueDotArray = new Array(blue).fill("blue");

  const Rectangle = props => {
    return <h1>rect</h1>;
  };

  const formStyle = { minWidth: 120, marginBottom: 20, marginRight: 10 };

  const greenMenuItems = greenArray.map(v => (
    <MenuItem value={v} key={"dGreen-" + v}>
      {v}
    </MenuItem>
  ));
  const changeGreen = event => {
    setGreen(event.target.value);
  };

  const selectGreen = (
    <Select onChange={e => changeGreen(e)} value={green}>
      {greenMenuItems}
    </Select>
  );

  const blueMenuItems = blueArray.map(v => (
    <MenuItem value={v} key={"dy-" + v}>
      {v}
    </MenuItem>
  ));

  const changeBlue = event => {
    setBlue(event.target.value);
  };

  const selectBlue = (
    <Select onChange={e => changeBlue(e)} value={blue}>
      {blueMenuItems}
    </Select>
  );


  const form = (
    <>
      <FormControl style={formStyle}>
        <InputLabel style={{ color: "green" }}> green</InputLabel>
        {selectGreen}
        <FormHelperText style={{ color: "green" }}>
          green per container
        </FormHelperText>
      </FormControl>{" "}
      <FormControl style={formStyle}>
        <InputLabel style={{ color: "green" }}> blue</InputLabel>
        {selectBlue}
        <FormHelperText style={{ color: "green" }}>
          blue per container
        </FormHelperText>
      </FormControl>
    </>
  );

  const simpleRatio = (
    <InlineMath
      math={
        "\\quad \\color{green}G_{0} : \\color{blue}B_{0} =  \\color{green}" +
        green +
        " : \\color{blue}" +
        blue
      }
    />
  );

  const expandedRatio = (
    <InlineMath
      math={
        "\\quad \\color{green}G_{" +
        n +
        "} : \\color{blue}B_{" +
        n +
        "} =  \\color{red} " +
        n +
        "\\color{green}(" +
        green +
        ") : \\color{red} " +
        n +
        "  \\color{blue}(" +
        blue +
        ") "
      }
    />
  );

  const distributedRatio = (
    <InlineMath
      math={
        "\\quad \\color{green}G_{" +
        n +
        "} : \\color{blue}B_{" +
        n +
        "}   =  \\color{green}" +
        green * n +
        " : \\color{blue}" +
        blue * n
      }
    />
  );

  const ratioEqual = (
    <InlineMath
      math={
        "\\quad \\frac{" +
        " \\color{green}" +
        green +
        "}{ \\color{blue}" +
        blue +
        " } =  \\frac{" +
        "\\color{red}" +
        n +
        "\\color{black} \\times" +
        " \\color{green}" +
        green +
        "}{ " +
        "\\color{red}" +
        n +
        "\\color{black} \\times" +
        "\\color{blue}" +
        blue +
        " } =  \\frac{" +
        " \\color{green}" +
        green * n +
        "}{ \\color{blue}" +
        blue * n +
        " }"
      }
    />
  );

  const greenDots0 = greenDotArray.map((v, i) => {
    return (
      <circle
        key={"green-dot-" + i}
        cx={8 + i * 18}
        cy={10}
        r={8}
        fill="green"
      />
    );
  });



  const blueDots0 = blueDotArray.map((v, i) => {
    return (
      <circle
        key={"blue-dot-" + i}
        cx={green * 18 + 20 + i * 18}
        cy={10}
        r={8}
        fill="blue"
      />
    );
  });

  const container = containers.map((v, i) => {
    return (
      <div key={"blue-dot-" + i}>
        {" "}
        <svg width={300} height={20}>
          <rect
            width={300}
            height={22}
            style={{ fill: i % 2 ? "red" : "teal", fillOpacity: 0.2 }}
          />
          {greenDots0}
          <circle cx={green * 18 + 5} cy={5} r={3} />
          <circle cx={green * 18 + 5} cy={15} r={3} />
          {blueDots0}
        </svg>
      </div>
    );
  });

  return (
    <>
      <h4>Constant (direct) Ratio</h4>
      <Grid container style={{ padding: 10 }}>
        <Grid item xs={12} md={6}>
          <Grid container spacing={3}>
            <Grid item xs={4} sm={6}>
              <div style={{ fontSize: 25 }}>{form}</div>
            </Grid>
            <Grid item xs={3}>
              <p>{simpleRatio}</p>
              <p>{expandedRatio}</p>
              <p>{distributedRatio}</p>
              <p style={{ fontSize: 25 }}>{ratioEqual}</p>
            </Grid>
            <Grid item xs={12}>
              <svg ref={svgRefNots} width="300" height="25">
                {greenDots0}
                <circle cx={green * 18 + 5} cy={5} r={3} />
                <circle cx={green * 18 + 5} cy={15} r={3} />
                {blueDots0}
              </svg>
            </Grid>
          </Grid>
          <Grid item xs={10}>
            <h4 style={{ color: "red" }}>
              Number of containers = <InlineMath>n</InlineMath>
            </h4>
            <Slider
              style={styles.paddingLeftSide}
              defaultValue={n}
              value = {n}
              //getAriaValueText={changeB}
              onChange={(o, v) => changeContainers(o, v)}
              aria-labelledby="discrete-slider"
              step={1}
              marks
              min={0}
              max={10}
              valueLabelDisplay="on"
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          {container}
          <Grid item xs={12}>
            <p>
              Total Dots for <InlineMath math={"\\color{red}" + n} />{" "}
              containers:
            </p>
            <p>
              {" "}
              <InlineMath
                math={
                  "\\color{red}n \\color{black} (\\color{green}  g  \\color{black} + \\color{blue} b  \\color{black}) = \\color{red} total"
                }
              />{" "}
            </p>
            <p>
              {" "}
              <InlineMath
                math={
                  " \\color{red}" +
                  n +
                  " \\color{black} (\\color{green}  " +
                  green +
                  " \\color{black} + \\color{blue}  " +
                  blue +
                  "  \\color{black}) =  \\color{red}" +
                  n +
                  " \\color{black} (" +
                  (blue + green) +
                  "  \\color{black})  \\color{black} = \\color{red}  " +
                  n * (green + blue)
                }
              />{" "}
            </p>
            <hr />
            <p>
              {" "}
              <InlineMath
                math={
                  "\\color{red}n \\color{green}( g ) \\color{black} +  \\color{red}n \\color{blue}( b ) \\color{black} = \\color{red} total"
                }
              />{" "}
            </p>

            <p>
              {" "}
              <InlineMath
                math={
                  "\\color{red}" +
                  n +
                  " \\color{green}(  " +
                  green +
                  " ) \\color{black} +  \\color{red}" +
                  n +
                  " \\color{blue}(  " +
                  blue +
                  " ) \\color{black} = \\color{red} " +
                  n * green +
                  " + " +
                  n * blue +
                  " \\color{black} = \\color{red}  " +
                  n * (green + blue)
                }
              />{" "}
            </p>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default DemoPage;
