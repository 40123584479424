import React, { useEffect, useRef, useState } from "react";
import { select, scaleLinear, axisBottom, max, min } from "d3";

function NumberLine(props) {
  const lineRef = useRef(null);
  const initialWidth = props.width || 400;
  const [width, setWidth] = useState(initialWidth)
  const height = props.height || 50;
  const data = props.data || [-10, 10];
  const ticks = props.ticks || max(data) - min(data);
  const yShift = 0.5 * height;
  const xShift = 0.05 * width;
  const value = props.value;
  const fontSize = props.fontSize || 15;
  const color = props.color || "red"


  function makeNumberLine() {
    select(lineRef.current).select("svg").remove();
    const scale = scaleLinear()
        .domain([min(data), max(data)])
        .range([0, 0.9 * width]);
    const x_axis = axisBottom().scale(scale);
    const svg = select(lineRef.current)
        .append("svg")
        .attr("width", width)
        .attr("height", height);
    svg
        .append("g")
        .attr("width", 0.9 * width)
        .attr("transform", "translate(" + xShift + ", " + yShift + ")")
        .call(x_axis.ticks(ticks)).style("font-size",fontSize+ "px");
    // arrows
    const rightArrowX = 0.97 * width;
    const rightArrowY = yShift - (height * 5.5) / 50;
    const leftArrowX = 0.04 * width;
    const leftArrowY = rightArrowY;
    const rightArrowArray = [
        rightArrowX - (10 * width) / 500,
        rightArrowY + (6 * height) / 50,
        rightArrowX,
        rightArrowY + (6 * height) / 50,
        rightArrowX,
        rightArrowY,
        rightArrowX + (12 * width) / 500,
        rightArrowY + (6 * height) / 50,
        rightArrowX,
        rightArrowY + (12 * height) / 50,
        rightArrowX,
        rightArrowY + (6 * height) / 50
    ];
    const leftArrowArray = [
        leftArrowX + (10 * width) / 500,
        leftArrowY + (6 * height) / 50,
        leftArrowX,
        leftArrowY + (6 * height) / 50,
        leftArrowX,
        leftArrowY,
        leftArrowX - (12 * width) / 500,
        leftArrowY + (6 * height) / 50,
        leftArrowX,
        leftArrowY + (12 * height) / 50,
        leftArrowX,
        leftArrowY + (6 * height) / 50
    ];
    const rightArrowPath = "M " + rightArrowArray.join(" ");
    const leftArrowPath = "M " + leftArrowArray.join(" ");
    svg
        .append("g")
        .append("path")
        .attr("d", leftArrowPath)
        .style("fill", "black")
        .style("stroke", "black");
    svg
        .append("g")
        .append("path")
        .attr("d", rightArrowPath)
        .style("fill", "black")
        .style("stroke", "black");

        svg.selectAll("text").attr("id", function(d,i) {return "axisText" + d});
        if (value !== null) {
            select("#axisText" + value).style("font-size",(fontSize + height/10)+ "px" ).style("color", color)
        }
}

  useEffect(() => {
    makeNumberLine();
  }, [data, width, value]);

  useEffect(() => {
      setWidth(props.width);

  })

  return <div ref={lineRef} width={width} height={height}></div>;
}

export default NumberLine;


