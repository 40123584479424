import React, { useState, useEffect, useRef } from "react";
import { select } from "d3";
import { Grid, Slider, Paper, Button } from "@mui/material";
import "katex/dist/katex.min.css";
import { BlockMath, InlineMath } from "react-katex";

const Positive = props => {
  const cx = props.cx || 0;
  const cy = props.cy || 0;
  const r = props.r || 15;
  const id = props.id || "circle-pos";
  const xPosition = props.xPosition || 0;
  const yPosition = props.yPosition || 0;

  return (
    <g id={id} transform={"translate(" + xPosition + "," + yPosition + ")"}>
      <circle
        cx={cx}
        cy={cy}
        r={r}
        stroke="black"
        strokeWidth="3"
        fill="red"
        id={id}
      />
      <line
        x1={cx - 0.6 * r}
        y1={cy}
        x2={cx + 0.6 * r}
        y2={cy}
        strokeWidth="3"
        stroke="black"
      />
      <line
        x1={cx}
        y1={cy - 0.6 * r}
        x2={cx}
        y2={cy + 0.6 * r}
        strokeWidth="3"
        stroke="black"
      />
    </g>
  );
};

const Negative = props => {
  const cx = props.cx || 0;
  const cy = props.cy || 0;
  const r = props.r || 15;
  const id = props.id || "circle-neg";
  const xPosition = props.xPosition || 0;
  const yPosition = props.yPosition || 0;
  const opacity = props.opacity || 1;

  return (
    <g id={id} transform={"translate(" + xPosition + "," + yPosition + ")"}>
      <circle
        cx={cx}
        cy={cy}
        r={r}
        stroke="black"
        strokeWidth="3"
        fill="blue"
        opacity={opacity}
      />
      <line
        x1={cx - 0.6 * r}
        y1={cy}
        x2={cx + 0.6 * r}
        y2={cy}
        strokeWidth="3"
        stroke="black"
      />
    </g>
  );
};

function PositiveNegativeCancel() {
  const defaultP = 2;
  const defaultN = 2;
  const defaultArrayP = new Array(defaultP).fill(1);
  const defaultArrayN = new Array(defaultN).fill(1);
  const [p, setP] = useState(defaultP);
  const [n, setN] = useState(defaultP);
  const [pArray, setArrayP] = useState(defaultArrayP);
  const [nArray, setArrayN] = useState(defaultArrayN);
  const svgRef = useRef();
  const svgWidth = 250;
  const svgHeight = 400;
  const ySpacing = svgHeight / 11;

  const positives = pArray.map((v, i) => {
    console.log("p", i);
    return (
      v !== 0 && (
        <Positive
          key={"positive-" + i}
          yPosition={(i + 1) * ySpacing}
          xPosition={50}
          id={"circle-pos-" + i}
        />
      )
    );
  });

  const negatives = nArray.map((v, i) => {
    return (
      v !== 0 && (
        <Negative
          key={"negative-" + i}
          yPosition={(i + 1) * ySpacing}
          xPosition={200}
          id={"circle-neg-" + i}
        />
      )
    );
  });

  useEffect(() => {
    const tempArray = new Array(p).fill(1);
    setArrayP(tempArray);
    const tempArray2 = new Array(n).fill(1);
    setArrayN(tempArray2);
  }, [n, p]);

  const styles = {
    positive: {
      color: "red"
    },
    negative: {
      color: "blue"
    },
    paddingLeft: {
      paddingTop: 35,
      position: "-webkit-sticky",
      position: "sticky",
      top: 0,
      zIndex: 5
    },
    sticky: {
      // paddingTop: 35,
      position: "-webkit-sticky",
      position: "sticky",
      top: 0,
      zIndex: 5
    }
  };

  const simplify = () => {
    const arr = n < p ? nArray : pArray;
    arr.forEach((v, i) => {
      const pos = select("#circle-pos-" + i);
      pos
        .transition()
        .attr("transform", "translate(125, " + (i + 1) * ySpacing + ")")
        .duration(1000)
        .on("end", () => pos.attr("opacity", 0.03));

      const neg = select("#circle-neg-" + i);
      neg
        .transition()
        .attr("transform", "translate(125, " + (i + 1) * ySpacing + ")")
        .duration(1000)
        .on("end", () => neg.attr("opacity", 0.06));
    });
  };

  const fixOpacity = () => {
    pArray.map((v, i) => {
      const pos = select("#circle-pos-" + i);
      pos
        .attr("opacity", 1)
        .attr("transform", "translate(50, " + (i + 1) * ySpacing + ")");
    });

    nArray.map((v, i) => {
      const neg = select("#circle-neg-" + i);
      neg
        .attr("opacity", 1)
        .attr("transform", "translate(200, " + (i + 1) * ySpacing + ")");
    });
  };

  const changeP = (o, value) => {
    if (p !== value) {
      setP(value);
      fixOpacity();
    }
  };

  const changeN = (o, value) => {
    if (n !== value) {
      setN(value);
      fixOpacity();
    }
  };

  return (
    <div>
      <h3>Simplify positive and negative addition</h3>
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <Grid item xs={12}>
            
            <h4 style={styles.positive}>Number of Positive</h4>
            <div style={styles.sticky}>
              <Slider
                style={styles.paddingLeft}
                defaultValue={p}
                onChange={changeP}
                value = {p}
                aria-labelledby="discrete-slider"
                step={1}
                marks
                min={0}
                max={10}
                valueLabelDisplay="on"
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <h4 style={styles.negative}>Number of Negatives</h4>
            <Slider
              style={styles.paddingLeft}
              defaultValue={p}
              onChange={changeN}
              aria-labelledby="discrete-slider"
              value = {n}
              step={1}
              marks
              min={0}
              max={10}
              valueLabelDisplay="on"
            />
          </Grid>
          <Paper
            elevation={20}
            variant="outlined"
            style={{
              padding: 5,
              backgroundColor: "rgb(240, 240, 240)",
              marginBottom: 20,
              fontSize: 20
            }}
          >
            Each pair of <span style={styles.positive}>positive</span> and
            <span style={styles.negative}> negative </span> will cancel.
            <p>When combining </p> <InlineMath math={"\\color{red} " + p} />{" "}
            with <InlineMath math={"\\color{blue} -" + n} /> ,{" "}
            <u>
              <InlineMath math={Math.min(p, n) + "\\enspace"} />
              particles will cancel each other.
            </u>
            <hr />
            {p > n && (
              <p>
                Since there {p - n === 1 ? " is " : " are "}{" "}
                <InlineMath math={" " + (p - n)} /> more{" "}
                <span style={styles.positive}>
                  positive{p - n === 1 ? " s " : " "}
                </span>
                , the result will be
                <InlineMath math={"\\quad \\color{red} +" + (p - n)} />
              </p>
            )}
            {p < n && (
              <p>
                Since there {n - p === 1 ? " is " : " are "}{" "}
                <InlineMath math={" " + (n - p)} /> more{" "}
                <span style={styles.negative}>
                  negative{p - n === 1 ? " s " : " "}
                </span>
                , the result will be
                <InlineMath math={"\\quad \\color{blue} -" + (n - p)} />
              </p>
            )}
            {p === n && (
              <p>
                Since there are the same amount of{" "}
                <span style={styles.positive}>positive</span> and
                <span style={styles.negative}> negative </span>, they all will
                cancel.
              </p>
            )}
          </Paper>
        </Grid>

        <Grid item xs={12} md={6} style={{ fontSize: 20, textAlign: "center" }}>
          <p>
            <span style={styles.positive}>Positive</span>:{" "}
            <InlineMath math={"\\color{red}" + p + " \\quad"} />
            <span style={styles.negative}>Negative</span>:{" "}
            <InlineMath math={"\\color{blue}" + n + " \\quad"} />
          </p>
          <p>
            <InlineMath
              math={
                "\\color{red}" +
                p +
                "\\color{black}+ (\\color{blue} -" +
                n +
                " \\color{black}) = \\color{red}" +
                p +
                "\\color{black}- \\color{blue} " +
                n +
                "\\color{green} = " +
                (p - n)
              }
            />
          </p>
          
          <svg
            height={svgHeight}
            width={svgWidth}
            ref={svgRef}
            style={{ borderStyle: "solid" }}
          >
            {positives}
            {negatives}
          </svg>
          <br />
          <Button
              onClick={() => simplify()}
              variant="outlined"
              color="secondary"
              style={{ marginRight: 10 }}
            >
              Simplify
            </Button>
            <Button
              onClick={fixOpacity}
              variant="outlined"
              //color="secondary"
            >
              Reset
            </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export default PositiveNegativeCancel;
