import React, { useEffect, useRef, useState } from "react";
import { Grid, Slider, Button } from "@mui/material";
import "katex/dist/katex.min.css";
import { InlineMath } from "react-katex";

const OneStepEq = props => {
  const [m, setM] = useState(2);
  const [x, setX] = useState(3);
  const [b, setB] = useState(5);
  const [show, setShow] = useState(true);
  const [subB, setSubB] = useState(0);
  const y = m * x + b;

  const mArray = new Array(m).fill(m);

  const mObjects = (
    <svg width={150} height={30 * (Math.floor(m / 4) + 1)}>
      {mArray.map((v, i) => {
        return (
          <rect
            key={"rect-" + i}
            width={25}
            height={25}
            x={(i % 4) * 30}
            y={Math.floor(i / 4) * 30}
            fill={"red"}
          />
        );
      })}
    </svg>
  );
  const xArray = new Array(x).fill(1);
  const width = Math.ceil(Math.sqrt(x));

  const oneGroup = (
    <svg width={15 * width + 4} height={15 * width + 4}>
      <rect width={15 * width + 4} height={15 * width + 4} fill={"red"} />
      {xArray.map((v, i) => (
        <circle
          key={"x-circle-" + i}
          r={6}
          cx={((i % width) + 0.5) * 15}
          cy={(Math.floor(i / width) + 0.5) * 15}
          fill="green"
        />
      ))}
    </svg>
  );

  const mRect = mArray.map((v, i) => {
    return (
      <span style={{ margin: 2 }} key={"mRect-" + i}>
        {oneGroup}
      </span>
    );
  });

  const bArray = new Array(Math.max(b, subB)).fill(b);

  const bObjects = (
    <svg width={150} height={40 * (Math.floor(m / 5) + 1)}>
      {bArray.map((v, i) => {
        return (
          <circle
            key={"b-circe-" + i}
            r={6}
            cx={((i % 5) + 1) * 15}
            cy={(Math.floor(i / 5) + 1) * 15}
            fill={i >= b ? "black" : "blue"}
            opacity={i <= subB - 1 && i < b ? 0.3 : 1}
          />
        );
      })}
    </svg>
  );

  const buttonArray = new Array(11).fill(1);

  const buttonsX = buttonArray.map((v, i) => {
    return (
      <span
        key={"x-button-" + "-" + i}
        style={{
          margin: 1
        }}
      >
        <Button onClick={() => setX(i)} variant="outlined">
          {" "}
          {i}{" "}
        </Button>{" "}
      </span>
    );
  });

  const buttonArrayB = new Array(11).fill(1);

  const buttonsB = buttonArrayB.map((v, i) => {
    return (
      <span
        key={"b-button-" + "-" + i}
        style={{
          margin: 1
        }}
      >
        <Button onClick={() => setB(i)} variant="outlined">
          {" "}
          {i}{" "}
        </Button>{" "}
      </span>
    );
  });

  const buttonsM = buttonArray.map((v, i) => {
    return (
      <span
        key={"m-button-" + "-" + i}
        style={{
          margin: 1
        }}
      >
        <Button onClick={() => setM(i)} variant="outlined">
          {" "}
          {i}{" "}
        </Button>{" "}
      </span>
    );
  });

  const yArray = new Array(Math.max(y, subB)).fill(y);

  const yObjects = (
    <svg width={300} height={30 * Math.floor(y / 10 + 1)}>
      {" "}
      {yArray.map((v, i) => (
        <circle
          r={6}
          key={"y-" + i}
          cx={((i % 10) + 1) * 20}
          cy={(Math.floor(i / 10) + 1) * 20}
          opacity={i < subB ? 0.3 : 1}
        />
      ))}
    </svg>
  );

  const eq =
    "\\begin{aligned} \\color{red} " +
    m +
    "\\color{green}x" +
    " \\color{black} & +  \\color{blue}  " +
    b +
    " \\color{black} =  " +
    y +
    "\\\\ &-" +
    subB +
    " =  - " +
    subB +
    "\\end{aligned}";

  return (
    <>
      {" "}
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <h2>Solve Two Step Equation</h2>
          <h6>Subtract from both sides</h6>
          <Slider
            defaultValue={subB}
            value = {subB}
            onChange={(o, v) => {
              setSubB(v);
            }}
            aria-labelledby="discrete-slider"
            step={1}
            marks
            min={0}
            max={10}
            valueLabelDisplay="on"
          />
          <h6>
            <InlineMath math={"\\color{blue}x"} /> value
          </h6>
          {buttonsX}{" "}
          <h6>
            <InlineMath math={"\\color{red}M"} />
          </h6>
          {buttonsM}
          <h6>
            <InlineMath math={"\\color{green}B"} />
          </h6>
          {buttonsB}
        </Grid>
        <Grid item style={{ fontSize: 25 }}>
          <InlineMath math={eq} />
          {b === subB && show && (
            <>
              <hr />
              <p>
                <InlineMath
                  math={
                    "\\color{red}" +
                    m +
                    "\\color{green}x  \\color{black} + 0  = " +
                    m * x
                  }
                />
              </p>
              <p>
                <InlineMath
                  math={
                    "\\color{red}" +
                    m +
                    "\\color{green}x  \\color{black} = " +
                    m * x
                  }
                />
              </p>
              <p>
                <InlineMath
                  math={
                    "\\color{green}x  \\color{black}  = \\frac{" +
                    m * x +
                    "}{" +
                    m +
                    "} = " +
                    (m === 0 ? " ? " : x)
                  }
                />
              </p>
            </>
          )}
        </Grid>
        <Grid item>
          <h6>Left Side of Equation</h6>
          {mObjects} {bObjects}
          <hr />
          <h6>Right Side of Equation - {y - subB} objects </h6>
          <Grid item xs={2}>
            {yObjects}
          </Grid>
          <hr />
          <h6>
            <InlineMath math={"\\color{red}" + m} /> groups of{" "}
            <InlineMath math={"\\color{blue}" + x} /> objects plus{" "}
            <InlineMath math={"\\color{green}" + (b - subB)} /> extra
            <Button
              variant="outlined"
              onClick={() => setShow(!show)}
              style={{ marginLeft: 20 }}
            >
              {show ? " hide " : " show "}
            </Button>
          </h6>
          {show && (
            <Grid container spacing={2}>
              {mRect} {bObjects}
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default OneStepEq;
