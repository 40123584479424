import React, { useRef, useEffect, useState } from "react";
import { select } from "d3";
import "katex/dist/katex.min.css";
import { InlineMath } from "react-katex";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

const DivideBySubtract = props => {
  const svgRef = useRef();
  const [times, setTimes] = useState(0);
  const [start1, setStart1] = useState(true);

  useEffect(() => {
    selectAction(start1);
    
  }, []);

  const yOff = 20;

  const timesArray = new Array(times).fill("\\color{red} 3 \\color{black}");
  const sub =
    " - " +
    (times === 0 ? 0 : timesArray.join(" - ")) +
    " = " +
    (12 - 3 * times);

  const stop = () => {
    const svg = select(svgRef.current);
    const circles = svg.selectAll("g").transition();
  };

  const selectAction = select => {
    if (select) move();
    else stop();
    setStart1(select);
  };

  const move = () => {
    const svg = select(svgRef.current);
   
    const g1 = svg.select("#g1");
    const g2 = svg.select("#g2");
    const g3 = svg.select("#g3");
    const g4 = svg.select("#g4");
    //if (!start1) return;
    g1.transition()
      .delay(2000)
      .duration(1000)
      .attr("transform", "translate(0, 0)");
    g2.transition()
      .delay(2000)
      .duration(1000)
      .attr("transform", "translate(0, 0)");
    g3.transition()
      .delay(2000)
      .duration(1000)
      .attr("transform", "translate(0, 0)");
    g4.transition()
      .delay(2000)
      .duration(1000)
      .attr("transform", "translate(0, 0)")
      .on("end", function () {
        setTimes(0);
        g1.transition()
          .delay(2000)
          .duration(1000)
          .attr("transform", "translate(55, 10)")
          .on("end", function () {
            setTimes(1);
            g2.transition()
              .delay(2000)
              .duration(1000)
              .attr("transform", "translate(55, 10)")
              .on("end", function () {
                setTimes(2);
                g3.transition()
                  .delay(2000)
                  .duration(1000)
                  .attr("transform", "translate(55, 10)")
                  .on("end", function () {
                    setTimes(3);
                    g4.transition()
                      .delay(2000)
                      .duration(1000)
                      .attr("transform", "translate(55, 10)")
                      .on("end", function () {
                        setTimes(4);
                        move();
                      });
                  });
              });
          });
      });
  };
  return (
    <>
      <Grid container spacing={3}>
        <Grid item>
          <svg width={100} height={70 + 7.5 + yOff} ref={svgRef}>
            <text y={15} x={50}>
              finish
            </text>
            <rect
              x={50}
              y={7.5 + yOff}
              width={50}
              height={65}
              strokeWidth={2}
              stroke={"black"}
              fill={"white"}
            />
            <text y={15}>start</text>
            <g id="g1">
              <circle r={5} fill={"red"} cx={7.5} cy={7.5 + yOff} />
              <circle r={5} fill={"red"} cx={19} cy={7.5 + yOff} />
              <circle r={5} fill={"red"} cx={30.5} cy={7.5 + yOff} />
            </g>
            <g id="g2">
              <circle r={5} fill={"red"} cx={7.5} cy={20 + yOff} />
              <circle r={5} fill={"red"} cx={19} cy={20 + yOff} />
              <circle r={5} fill={"red"} cx={30.5} cy={20 + yOff} />
            </g>
            <g id="g3">
              <circle r={5} fill={"red"} cx={7.5} cy={32.5 + yOff} />
              <circle r={5} fill={"red"} cx={19} cy={32.5 + yOff} />
              <circle r={5} fill={"red"} cx={30.5} cy={32.5 + yOff} />
            </g>
            <g id="g4">
              <circle r={5} fill={"red"} cx={7.5} cy={45 + yOff} />
              <circle r={5} fill={"red"} cx={19} cy={45 + yOff} />
              <circle r={5} fill={"red"} cx={30.5} cy={45 + yOff} />
            </g>
          </svg>
        </Grid>
        <Grid item>
          <p>
            <InlineMath math={"12 \\div 3 = 4"} />
          </p>
          <p>
            <InlineMath math={"12 " + sub} />
          </p>
        </Grid>
      </Grid>
      <Grid container style={{ textAlign: "center" }}>
        <Grid item style={{ textAlign: "center" }}>
          <h3>Division as Subtraction</h3>
          <p>
            Have you ever considered division as the number of times you must
            subtract?{" "}
          </p>
        </Grid>
      </Grid>
      <p>
        <Button
          variant="outlined"
          onClick={() => {
            selectAction(!start1);
          }}
        >
          {start1 ? "stop" : "start"}
        </Button>
      </p>
    </>
  );
};

export default DivideBySubtract;
