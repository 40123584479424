import React from "react";
import PropTypes from "prop-types";
import range from "lodash/range";

const SignedSquaresInRectangle = props => {

  const w = props.labelX ? props.w + 1 : props.w;
  const h = props.labelY ? props.h + 1 : props.h;
  const width = props.width || 300;
  const columnsPerRow = props.columnsPerRow || 10;

  const squareWidth = (0.9 * width) / columnsPerRow;
  const height = squareWidth * h;

  const x0 = width / w; // standard unit in x
  const y0 = height / h; // standard unit in y
  const defaultArrayW = range(w);
  const defaultArrayH = range(h);

  const Square = props => {
    const fillNegative = props.fillNegative || "aqua";
    const fillPositive = props.fillPositive || "lime";
    const style = {
      fill: props.signed ? fillNegative : fillPositive,
      strokeWidth: props.strokeWidth || 3,
      stroke: props.stroke || "black"
    };

    const { dx, dy } = props;

    const heightFactor = (dx !== 1 && dy > dx ) ? dy / dx : 1 ;

    const parts =
      dx &&
      dx > 1 &&
      range(dx).map(v => (
        <rect
          key={"parts-" + v}
          x={props.x}
          y={props.y + (v * props.squareWidth) / dx}
          //y1={props.y + props.squareWidth }
          width={props.squareWidth}
          height={props.squareWidth / dx }
          style={{
            ...style,
            fill:
              v <= dy - 1
                ? props.signed
                  ? fillNegative
                  : fillPositive
                : "#ffefed",
            stroke: v = dx -1 ? "101010" : "#aaa" ,
            strokeWidth: 1
          }}
        />
      ));

    return (
      <g>
        <rect
          x={props.x}
          y={props.y}
          width={props.squareWidth}
          height={props.squareWidth*heightFactor}
          style={style}
        />
        {parts}
      </g>
    );
  };

  const squares = defaultArrayW.map((vc, ic) =>
    defaultArrayH.map((vr, ir) => {

      return (
        <Square
          key={"inside-square-" + ir + " - " + ic}
          x={squareWidth * ic}
          y={squareWidth * ir}
          squareWidth={squareWidth}
          signed={props.signed}
          dx={props.dx}
          dy={props.dy}
        />
      );
    })
  );

  return (
    <svg width={width} height={height}>
      <g>{squares}</g>
    </svg>
  );

  SignedSquaresInRectangle.propTypes = {
    //value: PropTypes.node,
    w: PropTypes.number.isRequired, // number of inner squares
    h: PropTypes.number.isRequired,
    labelX: PropTypes.bool,
    labelY: PropTypes.bool,
    text: PropTypes.node,
    width: PropTypes.number, // dimension of outer rectangle
    height: PropTypes.number,
    signed: PropTypes.bool, // colors to represent signs
    columnsPerRow: PropTypes.number
  };
};

export default SignedSquaresInRectangle;
