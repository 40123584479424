import React, { useEffect, useRef, useState } from "react";
import { Grid } from "@mui/material";
import "katex/dist/katex.min.css";
import { InlineMath } from "react-katex";
import {
  scaleOrdinal,
  schemeCategory10,
  select,
  selectAll
} from "d3";

const ExpandMultiply = props => {
  const [a2, setA2] = useState(2);
  const [a1, setA1] = useState(1);
  const [a0, setA0] = useState(0);
  const [b2, setB2] = useState(1);
  const [b1, setB1] = useState(2);
  const [b0, setB0] = useState(5);

  const inc = (item, power) => {
    // if (power < 0 || power > 9)  return
    switch (item) {
      case 1:
        switch (power) {
          case 2:
            if (a2 !== 9) setA2(a2 + 1);
            break;
          case 1:
            if (a1 !== 9) setA1(a1 + 1);
            break;
          case 0:
            if (a0 !== 9) setA0(a0 + 1);
            break;

          default:
            break;
        }
        break;

      case 2:
        switch (power) {
          case 2:
            if (b2 !== 9) setB2(b2 + 1);
            break;
          case 1:
            if (b1 !== 9) setB1(b1 + 1);
            break;
          case 0:
            if (b0 !== 9) setB0(b0 + 1);
            break;

          default:
            break;
        }
        break;

      default:
        break;
    }
  };

  const dec = (item, power) => {
    if (power < 0 || power > 9) return;
    switch (item) {
      case 1:
        switch (power) {
          case 2:
            if (a2 !== 0) setA2(a2 - 1);
            break;
          case 1:
            if (a1 !== 0) setA1(a1 - 1);
            break;
          case 0:
            if (a0 !== 0) setA0(a0 - 1);
            break;

          default:
            break;
        }
        break;

      case 2:
        switch (power) {
          case 2:
            if (b2 !== 0) setB2(b2 - 1);
            break;
          case 1:
            if (b1 !== 0) setB1(b1 - 1);
            break;
          case 0:
            if (b0 !== 0) setB0(b0 - 1);
            break;

          default:
            break;
        }
        break;

      default:
        break;
    }
  };

  const color = scaleOrdinal(schemeCategory10);

  const svgRef = useRef();

  useEffect(() => {
    const svg = selectAll(".pointer");

    svg.on(
      "mouseover",
      function(d) {
        
        select(this).style("cursor", "pointer");
      },
      "mouseout",
      function(d) {
        select(this).style("cursor", "default");
      }
    );
  }, []);

  const total =
    b0 * a0 +
    b0 * a1 * 10 +
    b0 * a2 * 100 +
    b1 * a0 * 10 +
    b1 * a1 * 100 +
    a2 * b1 * 1000 +
    b2 * a0 * 100 +
    b2 * a1 * 1000 +
    b2 * a2 * 10000;

  return (
    <>
      <h4>Multiply Expanded</h4>
      <Grid container>
        <Grid item xs={12} md={4}>
          <Grid container>
            <Grid item style={{ fontSize: 15 }}>
              <InlineMath math={"10^2 = 100"} /> <br />
              <svg width={100} height={60} ref={svgRef}>
                <circle
                  cx={38}
                  cy={10}
                  onClick={() => inc(1, 2)}
                  r={10}
                  fill={color(1)}
                  className="pointer"
                  /* onMouseOver */
                />
                <text
                  x={29.5}
                  y={16.5}
                  fontSize={25}
                  onClick={() => inc(1, 2)}
                  className="pointer"
                >
                  +
                </text>
                <circle
                  cx={38}
                  cy={40}
                  onClick={() => dec(1, 2)}
                  r={10}
                  fill={color(1)}
                  className="pointer"
                />
                <text
                  className="pointer"
                  x={31.5}
                  y={50}
                  fontSize={35}
                  onClick={() => dec(1, 2)}
                >
                  -
                </text>
              </svg>
            </Grid>
            <Grid item style={{ fontSize: 15 }}>
              <InlineMath math={"10^1 = 10"} /> <br />
              <svg width={100} height={60}>
                <circle
                  className="pointer"
                  cx={38}
                  cy={10}
                  onClick={() => inc(1, 1)}
                  r={10}
                  fill={color(2)}
                />
                <text
                  className="pointer"
                  x={29.5}
                  y={16.5}
                  fontSize={25}
                  onClick={() => inc(1, 1)}
                >
                  +
                </text>
                <circle
                  className="pointer"
                  cx={38}
                  cy={40}
                  onClick={() => dec(1, 1)}
                  r={10}
                  fill={color(2)}
                />
                <text
                  className="pointer"
                  x={31.5}
                  y={50}
                  fontSize={35}
                  onClick={() => dec(1, 1)}
                >
                  -
                </text>
              </svg>
            </Grid>
            <Grid item style={{ fontSize: 15 }}>
              <InlineMath math={"10^0 = 1"} /> <br />
              <svg width={100} height={60}>
                <circle
                  className="pointer"
                  cx={38}
                  cy={10}
                  onClick={() => inc(1, 0)}
                  r={10}
                  fill={color(3)}
                />
                <text
                  className="pointer"
                  x={29.5}
                  y={16.5}
                  fontSize={25}
                  onClick={() => inc(1, 0)}
                >
                  +
                </text>
                <circle
                  className="pointer"
                  cx={38}
                  cy={40}
                  onClick={() => dec(1, 0)}
                  r={10}
                  fill={color(3)}
                />
                <text
                  className="pointer"
                  x={31.5}
                  y={50}
                  fontSize={35}
                  onClick={() => dec(1, 0)}
                >
                  -
                </text>
              </svg>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item style={{ fontSize: 15 }}>
              <InlineMath math={"10^2 = 100"} /> <br />
              <svg width={100} height={60}>
                <circle
                  className="pointer"
                  cx={38}
                  cy={10}
                  onClick={() => inc(2, 2)}
                  r={10}
                  fill={color(4)}
                />
                <text
                  className="pointer"
                  x={29.5}
                  y={16.5}
                  fontSize={25}
                  onClick={() => inc(2, 2)}
                  fill="white"
                >
                  +
                </text>
                <circle
                  className="pointer"
                  cx={38}
                  cy={40}
                  onClick={() => dec(2, 2)}
                  r={10}
                  fill={color(4)}
                />
                <text
                  className="pointer"
                  x={31.5}
                  y={50}
                  fontSize={35}
                  onClick={() => dec(2, 2)}
                  fill="white"
                >
                  -
                </text>
              </svg>
            </Grid>
            <Grid item style={{ fontSize: 15 }}>
              <InlineMath math={"10^1 = 10"} /> <br />
              <svg width={100} height={60}>
                <circle
                  className="pointer"
                  cx={38}
                  cy={10}
                  onClick={() => inc(2, 1)}
                  r={10}
                  fill={color(5)}
                />
                <text
                  className="pointer"
                  x={29.5}
                  y={16.5}
                  fontSize={25}
                  onClick={() => inc(2, 1)}
                  fill="white"
                >
                  +
                </text>
                <circle
                  className="pointer"
                  cx={38}
                  cy={40}
                  onClick={() => dec(2, 1)}
                  r={10}
                  fill={color(5)}
                />
                <text
                  className="pointer"
                  x={31.5}
                  y={50}
                  fontSize={35}
                  onClick={() => dec(2, 1)}
                  fill="white"
                >
                  -
                </text>
              </svg>
            </Grid>
            <Grid item style={{ fontSize: 15 }}>
              <InlineMath math={"10^0 = 1"} /> <br />
              <svg width={100} height={60}>
                <circle
                  className="pointer"
                  cx={38}
                  cy={10}
                  onClick={() => inc(2, 0)}
                  r={10}
                  fill={color(6)}
                />
                <text
                  className="pointer"
                  x={29.5}
                  y={16.5}
                  fontSize={25}
                  onClick={() => inc(2, 0)}
                  fill="white"
                >
                  +
                </text>
                <circle
                  className="pointer"
                  cx={38}
                  cy={40}
                  onClick={() => dec(2, 0)}
                  r={10}
                  fill={color(6)}
                />
                <text
                  className="pointer"
                  x={31.5}
                  y={50}
                  fontSize={35}
                  onClick={() => dec(2, 0)}
                  fill="white"
                >
                  -
                </text>
              </svg>
            </Grid>
          </Grid>
        </Grid>
        <Grid item style={{ fontSize: 25 }}>
          <InlineMath
            math={
              "\\color{" +
              color(1) +
              "}" +
              a2 +
              "\\color{" +
              color(2) +
              "}" +
              a1 +
              "\\color{" +
              color(3) +
              "}" +
              a0 +
              " \\color{black} \\times" +
              "\\color{" +
              color(4) +
              "}" +
              b2 +
              "\\color{" +
              color(5) +
              "}" +
              b1 +
              "\\color{" +
              color(6) +
              "}" +
              b0 +
              " = "
            }
          />
          <hr />
          <Grid container style={{ fontSize: 25, marginTop: 10 }}>
            <Grid item style={{ margin: 10 }}>
              <InlineMath
                math={
                  "\\color{" +
                  color(6) +
                  "}" +
                  b0 +
                  " \\color{black} \\times" +
                  "\\color{" +
                  color(3) +
                  "}" +
                  a0 +
                  "\\color{black} \\enspace + \\enspace \\color{" +
                  color(6) +
                  "}" +
                  b0 +
                  " \\color{black} \\times" +
                  "\\color{" +
                  color(2) +
                  "}" +
                  a1 +
                  "0  "
                }
              />
            </Grid>
            <Grid item style={{ margin: 10 }}>
              <InlineMath
                math={
                  "\\color{black} + \\enspace  \\color{" +
                  color(6) +
                  "}" +
                  b0 +
                  " \\color{black} \\times" +
                  "\\color{" +
                  color(1) +
                  "}" +
                  a2 +
                  "00   \\color{black} \\enspace + "
                }
              />
            </Grid>
          </Grid>

          <Grid container style={{ fontSize: 25, marginTop: 10 }}>
            <Grid item style={{ margin: 10 }}>
              <InlineMath
                math={
                  "\\color{" +
                  color(5) +
                  "}" +
                  b1 +
                  " 0 " +
                  " \\color{black} \\times" +
                  "\\color{" +
                  color(3) +
                  "}" +
                  a0 +
                  "\\color{black} \\enspace + \\enspace \\color{" +
                  color(5) +
                  "}" +
                  b1 +
                  " 0 " +
                  " \\color{black} \\times" +
                  "\\color{" +
                  color(2) +
                  "}" +
                  a1 +
                  "0  "
                }
              />
            </Grid>
            <Grid item style={{ margin: 10 }}>
              <InlineMath
                math={
                  "\\color{black} + \\enspace  \\color{" +
                  color(5) +
                  "}" +
                  b1 +
                  " 0 " +
                  " \\color{black} \\times" +
                  "\\color{" +
                  color(1) +
                  "}" +
                  a2 +
                  "00   \\color{black} \\enspace + "
                }
              />
            </Grid>
          </Grid>

          <Grid container style={{ fontSize: 25, marginTop: 10 }}>
            <Grid item style={{ margin: 10 }}>
              <InlineMath
                math={
                  "\\color{" +
                  color(4) +
                  "}" +
                  b2 +
                  " 00 " +
                  " \\color{black} \\times" +
                  "\\color{" +
                  color(3) +
                  "}" +
                  a0 +
                  "\\color{black} \\enspace + \\enspace \\color{" +
                  color(4) +
                  "}" +
                  b2 +
                  " 00 " +
                  " \\color{black} \\times" +
                  "\\color{" +
                  color(2) +
                  "}" +
                  a1 +
                  "0  "
                }
              />
            </Grid>
            <Grid item style={{ margin: 10 }}>
              <InlineMath
                math={
                  "\\color{black} + \\enspace  \\color{" +
                  color(4) +
                  "}" +
                  b2 +
                  " 00 " +
                  " \\color{black} \\times" +
                  "\\color{" +
                  color(1) +
                  "}" +
                  a2 +
                  "00   \\color{black} \\enspace = "
                }
              />
            </Grid>
          </Grid>
          {/* 616593194 */}
          <Grid container style={{ fontSize: 25, marginTop: 10 }}>
            <Grid item style={{ margin: 10 }}>
              <hr />
              <InlineMath
                math={
                  b0 * a0 +
                  " + " +
                  b0 * a1 * 10 +
                  " + " +
                  b0 * a2 * 100 +
                  " + " +
                  b1 * a0 * 10 +
                  " + " +
                  b1 * a1 * 100 +
                  " + " +
                  a2 * b1 * 1000 +
                  " \\enspace  + "
                }
              />
              <br />
              <br />
              <InlineMath
                math={
                  b2 * a0 * 100 +
                  " + " +
                  b2 * a1 * 1000 +
                  " + " +
                  b2 * a2 * 10000 +
                  " = " +
                  total
                }
              />
              <hr />
              <h6>Vertically</h6>
              <InlineMath
                math={
                  "\\begin{aligned} " +
                  b0 * a0 +
                  " & \\\\ " +
                  b0 * a1 * 10 +
                  "  & \\\\ " +
                  b0 * a2 * 100 +
                  "  & \\\\ " +
                  b1 * a0 * 10 +
                  "  & \\\\ " +
                  b1 * a1 * 100 +
                  "  & \\\\ " +
                  b1 * a2 * 1000 +
                  "  & \\\\ " +
                  b2 * a0 * 100 +
                  "  \\\\ " +
                  b2 * a1 * 1000 +
                  "  & \\\\  \\underline{" +
                  b2 * a2 * 10000 +
                  "}  &   \\\\ " +
                  total +
                  " &   \\end{aligned}"
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>{" "}
    </>
  );
};

export default ExpandMultiply;
