import React, { useState, useEffect } from "react";
import { Grid, Slider } from "@mui/material";
import "katex/dist/katex.min.css";
import { InlineMath } from "react-katex";
import { gcd } from "../Tools/functions";

const CommonDenominator = props => {
  const width = 200;
  const top = 0;
  const height = 200;
  const bottom = height;

  const [numeratorVertical, setNumeratorVertical] = useState(1);
  const [denominatorVertical, setDenominatorVertical] = useState(2);
  const [factorVertical, setFactorVertical] = useState(1);
  const denominatorVerticalArray = new Array(denominatorVertical).fill(
    denominatorVertical
  );
  const factorVerticalArray = new Array(factorVertical).fill(factorVertical);
  const numeratorFactorVerticalArray = new Array(numeratorVertical).fill(
    numeratorVertical
  );
  const verticalFills = numeratorFactorVerticalArray.map((v, i) => {
    const factor = width / denominatorVertical;
    return (
      <rect
        key={"vertical-fill-" + i}
        width={factor}
        height={bottom - top}
        x={i * factor}
        fill={"red"}
        opacity={0.5}
        stroke="black"
        strokeWidth={4}
      />
    );
  });
  const factorVerticalLines = factorVerticalArray.map((v, i) => {
    const factor = (bottom - top) / factorVertical;
    return (
      <line
        key={"vertical-lines-" + i}
        y1={i * factor}
        y2={i * factor}
        x1={0}
        x2={width}
        stroke={"black"}
        strokeWidth={2}
      />
    );
  });

  const verticalLines = denominatorVerticalArray.map((v, i) => {
    const factor = width / denominatorVertical;
    return (
      <line
        key={"vertical-factors-" + i}
        y1={top}
        y2={bottom}
        x1={i * factor}
        x2={i * factor}
        stroke={"black"}
        strokeWidth={2}
      />
    );
  });

  const [hide1, setHide1] = useState(false);
  const [hide2, setHide2] = useState(false);
  const [hide3, setHide3] = useState(false);

  const [numeratorHorizontal, setNumeratorHorizontal] = useState(1);
  const [denominatorHorizontal, setDenominatorHorizontal] = useState(2);
  const [factorHorizontal, setFactorHorizontal] = useState(1);

  const denominatorHorizontalArray = new Array(denominatorHorizontal).fill(
    denominatorHorizontal
  );
  const factorHorizontalArray = new Array(factorHorizontal).fill(
    factorHorizontal
  );
  const numeratorFactorHorizontalArray = new Array(numeratorHorizontal).fill(
    numeratorHorizontal
  );
  const horizontalFills = numeratorFactorHorizontalArray.map((v, i) => {
    const factor = (bottom - top) / denominatorHorizontal;
    return (
      <rect
        key={"Horizontal-fill-" + i}
        width={width}
        height={factor}
        y={i * factor}
        fill={"blue"}
        opacity={0.5}
        stroke="black"
        strokeWidth={4}
      />
    );
  });
  const factorHorizontalLines = factorHorizontalArray.map((v, i) => {
    const factor = width / factorHorizontal;
    return (
      <line
        key={"Horizontal-lines-" + i}
        x1={i * factor}
        x2={i * factor}
        y1={bottom}
        y2={top}
        stroke={"black"}
        strokeWidth={2}
      />
    );
  });

  const horizontalLines = denominatorHorizontalArray.map((v, i) => {
    const factor = (bottom - top) / denominatorHorizontal;
    return (
      <line
        key={"Horizontal-factors-" + i}
        x1={width}
        x2={0}
        y1={i * factor}
        y2={i * factor}
        stroke={"black"}
        strokeWidth={2}
      />
    );
  });

  useEffect(() => {
    setNumeratorVertical(Math.min(denominatorVertical, numeratorVertical));
  }, [denominatorVertical]);

  useEffect(() => {
    setNumeratorHorizontal(
      Math.min(numeratorHorizontal, denominatorHorizontal)
    );
  }, [denominatorHorizontal]);

  const g = gcd(denominatorVertical, denominatorHorizontal);
  const vPrime = denominatorVertical / g;
  const hPrime = denominatorHorizontal / g;
  const nPrime = vPrime * numeratorHorizontal + hPrime * numeratorVertical;
  const dPrime = g * vPrime * hPrime;

  const gTotal = gcd(nPrime, dPrime);

  return (
    <div style = {{padding:5}}>
      <h3>Common Denominator</h3>
      <Grid container spacing={4} p = {5}>
        {!hide1 && (
          <Grid item style={{ padding: 10 }}>
            <svg width={width} height={height}>
              <rect width={width} height={height} stroke="red" fill="white" />
              {verticalLines} {factorVerticalLines} {verticalFills}
            </svg>
            <p style={{ fontSize: 30 }}>
              <InlineMath
                math={
                  "\\color{red} \\frac{" +
                  numeratorVertical +
                  "}{" +
                  denominatorVertical +
                  "}"
                }
              />
            </p>
            <h6>Numerator 1</h6>
            <p>
              <Slider
                key={"numerator-vertical-slider-" + "-" + denominatorVertical}
                defaultValue={numeratorVertical}
                onChange={(o, v) => {
                  setNumeratorVertical(v);
                }}
                value = {numeratorVertical}
                aria-labelledby="discrete-slider"
                step={1}
                marks
                min={0}
                max={denominatorVertical}
                valueLabelDisplay="on"
              />
            </p>
            <h6>Denominator 1</h6>
            <p>
              <Slider
                defaultValue={denominatorVertical}
                onChange={(o, v) => {
                  setDenominatorVertical(v);
                }}
                value = {denominatorVertical}
                aria-labelledby="discrete-slider"
                step={1}
                marks
                min={1}
                max={10}
                valueLabelDisplay="on"
              />
            </p>
            <h6>Factor</h6>
            <p>
              <Slider
                defaultValue={1}
                onChange={(o, v) => {
                  setFactorVertical(v);
                }}
                value = {factorVertical}
                aria-labelledby="discrete-slider"
                step={1}
                marks
                min={1}
                max={12}
                valueLabelDisplay="on"
              />
            </p>
          </Grid>
        )}
        {!hide2 && (
          <Grid item style={{ padding: 10 }}>
            <svg width={width} height={height}>
              <rect width={width} height={height} stroke="red" fill="white" />
              {horizontalLines} {factorHorizontalLines} {horizontalFills}
            </svg>
            <p style={{ fontSize: 30 }}>
              <InlineMath
                math={
                  "\\color{blue} \\frac{" +
                  numeratorHorizontal +
                  "}{" +
                  denominatorHorizontal +
                  "}"
                }
              />
            </p>
            <h6>Numerator 2</h6>
            <p>
              <Slider
                key={
                  "numerator-Horizontal-slider-" + "-" + denominatorHorizontal
                }
                defaultValue={numeratorHorizontal}
                onChange={(o, v) => {
                  setNumeratorHorizontal(v);
                }}
                aria-labelledby="discrete-slider"
                step={1}
                marks
                min={0}
                max={denominatorHorizontal}
                valueLabelDisplay="on"
              />
            </p>
            <h6>Denominator 2</h6>
            <p>
              <Slider
                defaultValue={denominatorHorizontal}
                onChange={(o, v) => {
                  setDenominatorHorizontal(v);
                }}
                aria-labelledby="discrete-slider"
                step={1}
                marks
                min={1}
                max={10}
                valueLabelDisplay="on"
              />
            </p>
            <h6>Factor</h6>
            <p>
              <Slider
                defaultValue={1}
                onChange={(o, v) => {
                  setFactorHorizontal(v);
                }}
                aria-labelledby="discrete-slider"
                step={1}
                marks
                min={1}
                max={12}
                valueLabelDisplay="on"
              />
            </p>
          </Grid>
        )}
        {!hide1 && !hide2 && !hide3 && (
          <Grid item style={{ padding: 10 }} xs={12} md={4}>
            <svg width={width} height={height}>
              <rect width={width} height={height} stroke="red" fill="white" />
              <rect
                width={width / denominatorVertical}
                height={(bottom - top) / factorVertical}
                fill={"red"}
                opacity={0.5}
              />
              {horizontalLines} {verticalLines} {denominatorVertical}{" "}
              {verticalFills}
              {factorVerticalLines} {factorHorizontalLines}
            </svg>
            <p style={{ fontSize: 25 }}>
              <InlineMath
                math={
                  "\\color{red} \\frac{" +
                  numeratorVertical +
                  "}{" +
                  denominatorVertical +
                  "} \\color{black} \\times  \\color{red} \\frac{" +
                  factorVertical +
                  "}{" +
                  factorVertical +
                  "}  \\color{black} =  \\color{red} \\frac{" +
                  factorVertical * numeratorVertical +
                  "}{" +
                  factorVertical * denominatorVertical +
                  "} \\quad \\quad  \\color{blue} \\frac{" +
                  numeratorHorizontal +
                  "}{" +
                  denominatorHorizontal +
                  "}  \\color{black} \\times  \\color{blue} \\frac{" +
                  factorHorizontal +
                  "}{" +
                  factorHorizontal +
                  "}  \\color{black} =  \\color{blue} \\frac{" +
                  factorHorizontal * numeratorHorizontal +
                  "}{" +
                  factorHorizontal * denominatorHorizontal +
                  "} "
                }
              />{" "}
            </p>
            <p style={{ fontSize: 25 }}>
              <InlineMath
                math={
                  " \\color{red} \\frac{" +
                  factorVertical * numeratorVertical +
                  "}{" +
                  factorVertical * denominatorVertical +
                  "}  \\color{black} +  \\color{blue} \\frac{" +
                  factorHorizontal * numeratorHorizontal +
                  "}{" +
                  factorHorizontal * denominatorHorizontal +
                  "} "
                }
              />
            </p>

            <hr />
            <svg width={width} height={height}>
              <rect width={width} height={height} stroke="red" fill="white" />
              <rect
                width={width / factorHorizontal}
                height={(bottom - top) / denominatorHorizontal}
                fill={"blue"}
                opacity={0.5}
              />
              {horizontalLines} {verticalLines} {horizontalFills}
              {factorVerticalLines} {factorHorizontalLines}
            </svg>
            <p style={{ fontSize: 25 }}>
              <InlineMath
                math={
                  " \\color{red} \\frac{" +
                  numeratorVertical +
                  "}{" +
                  denominatorVertical +
                  "}  \\color{black} +  \\color{blue} \\frac{" +
                  numeratorHorizontal +
                  "}{" +
                  denominatorHorizontal +
                  "}  \\color{black} =  \\color{red} \\frac{" +
                  numeratorVertical +
                  "}{ \\color{black} " +
                  g +
                  "\\times \\color{red}" +
                  vPrime +
                  "}  \\color{black} +  \\color{blue} \\frac{" +
                  numeratorHorizontal +
                  "}{ \\color{black} " +
                  g +
                  "\\times \\color{blue}" +
                  hPrime +
                  "}  \\color{black} =  \\frac{" +
                  nPrime +
                  "}{" +
                  dPrime +
                  "}"
                }
              />
              {gTotal > 1 && (
                <InlineMath
                  math={
                    " = " +
                    (dPrime === gTotal
                      ? nPrime / gTotal
                      : "\\frac{" +
                        nPrime / gTotal +
                        "}{" +
                        dPrime / gTotal +
                        "}")
                  }
                ></InlineMath>
              )}
            </p>
            <hr />
            <p style={{ fontSize: 25 }}>
            <InlineMath
                math={
                  " \\color{red} \\frac{" +
                  numeratorVertical +
                  "}{ \\color{black} " +
                  g +
                  "\\times \\color{red}" +
                  vPrime +
                  "}  \\color{black} \\times  \\color{blue} \\frac{" +
                  hPrime +
                  "} {" +
                  hPrime +
                  "}  \\color{black} +  \\color{blue} \\frac{" +
                  numeratorHorizontal +
                  "}{ \\color{black} " +
                  g +
                  "\\times \\color{blue}" +
                  hPrime +
                  "}  \\color{black} \\times  \\color{red} \\frac{" +
                  vPrime +
                  "} {" +
                  vPrime +
                  "}  \\color{black}   =  \\enspace "
                }
              />
              <InlineMath
                math={
                  " \\frac{\\color{blue}" +
                  hPrime +
                  "\\color{black} \\times \\color{red}" +
                  numeratorVertical +
                  " \\color{black} \\enspace  +  \\enspace  \\color{red}" +
                  vPrime +
                  "\\color{black} \\times \\color{blue}" +
                  numeratorHorizontal +
                  "  }{ \\color{black} " +
                  g +
                  "\\times \\color{blue}" +
                  hPrime +
                  " \\color{black} \\times  \\color{red} " +
                  vPrime +
                  "}"
                }
              />
            </p>
            <p  style={{ fontSize: 25 }} ><InlineMath
                math={
                  "  = \\frac{" +
                  hPrime* numeratorVertical +
                  "  \\enspace  +  \\enspace  " +
                  vPrime * numeratorHorizontal +
                  "  }{  " +
                  g +
                  "\\times \\color{blue}" +
                  hPrime +
                  " \\color{black} \\times  \\color{red} " +
                  vPrime +
                  "} =  \\frac{" +
                  nPrime +
                  "}{" +
                  dPrime +
                  "}"
                }
              />
            </p>...........
          </Grid>
        )}
      </Grid>
      <span style={{ color: "grey" }} onClick={() => setHide1(!hide1)}>
        {hide1 ? "o" : "x"}
      </span>
      <span style={{ color: "grey" }} onClick={() => setHide2(!hide2)}>
        {hide2 ? "o" : "x"}
      </span>
      <span style={{ color: "grey" }} onClick={() => setHide3(!hide3)}>
        {hide3 ? "o" : "x"}
      </span>
    </div>
  );
};

export default CommonDenominator;
