import React, { useState } from "react";

import { Grid, Slider, Button } from "@mui/material";
import "katex/dist/katex.min.css";
import { InlineMath } from "react-katex";

const MultiplyDivide = props => {
  const [parts, setParts] = useState(5);
  const [containers, setContainers] = useState(2);
  const asAddition = new Array(containers).fill(parts);

  const [total, setTotal] = useState(parts * containers);
  const [divider, setDivider] = useState(containers);

  const [moved, setMoved] = useState(0);
  const asSubtraction = new Array(moved).fill(divider);
  const [notMoved, setNotMoved] = useState(total);
  const apples = new Array(notMoved).fill(false);

  const Container = props => {
    const nArray = new Array(props.n).fill(false);
    if (props.n < 1)
      return (
        <Grid item key={"container-0"}>
          <svg width={50} height={70}>
            <rect width={50} height={70} fill={"#ddd"} opacity="0.6" />
            {props.circles}
          </svg>
        </Grid>
      );
    return nArray.map((v, i) => {
      return (
        <Grid key={"circle-" + i} item>
          <svg width={50} height={70} key={"container-" + i}>
            <rect width={50} height={70} fill={"green"} opacity="0.6" />
            {props.circles}
          </svg>
        </Grid>
      );
    });
  };

  const Circles = props => {
    const nArray = new Array(props.n).fill(false);
    //console.log(props);
    return (
      <g>
        {nArray.map((v, i) => {
          return (
            <circle
              key={"circle-" + i}
              cx={(1 + (i % 3)) * 12}
              cy={(1 + Math.floor(i / 3)) * 15}
              r={5}
              fill={"red"}
              opacity={props.opacity ? props.opacity : 1}
            />
          );
        })}
      </g>
    );
  };

  const circle = (
    <svg width={10} height={10}>
      <circle cx={5} cy={5} r={5} fill={"red"} />
    </svg>
  );

  const setIntervalX = (delay, repetitions) => {
    let x = 0;
    const intervalID = window.setInterval(function () {
      if (++x === repetitions) {
        window.clearInterval(intervalID);
        
      }
      if (notMoved < divider) return;
      setMoved(x);
      setNotMoved(notMoved - x * divider);
      
    }, delay);
  };

  const moveOnce = () => {
    if (notMoved < divider) return;
    setMoved(moved + 1);
    setNotMoved(notMoved - divider);
  };

  const divide = () => {
    //setMoved(divider)
    const times = Math.floor(total / divider);
    setIntervalX(1000, times);
  };

  const tryAgain = newTotal => {
    const t = newTotal || total;
    setMoved(0);
    setNotMoved(t);
  };

  const setContainers2 = v => {
    setContainers(v);
    setDivider(v);
    setTotal(parts * v);
    tryAgain(parts * v);
  };

  const setParts2 = v => {
    setParts(v);
    setTotal(v * containers);
    setDivider(containers);
    tryAgain(v * containers);
  };

  const gcd = (a, b) => {
    if (!b) {
      return a;
    }
    return gcd(b, a % b);
  };

  const remainder = total % divider;

  const g = gcd(remainder, divider);

  const reducedRemainder =
    remainder === 0
      ? "  "
      : "= " +
        Math.floor(total / divider) +
        " \\frac{" +
        remainder / g +
        "}{" +
        divider / g +
        " }";
  console.log("reducedRemainder", remainder, reducedRemainder);

  return (
    <>
      <Grid container spacing={5}>
        <Grid item xs={12} md={4}>
          <h4>Multiply</h4>
          <p style={{ fontSize: 25 }}>
            <InlineMath
              math={
                "\\color{green} " +
                containers +
                " \\color{black} \\times \\color{red}" +
                parts +
                "\\color{black}  = \\enspace ?"
              }
            />
          </p>

          <div style={{ padding: 10 }}>
            <h6>Containers</h6>
            <Slider
              defaultValue={containers}
              onChange={(o, v) => setContainers2(v)}
              aria-labelledby="discrete-slider"
              step={1}
              marks
              min={0}
              max={10}
              valueLabelDisplay="on"
              style = {{marginBottom : 40}}
              value = {containers}
            />
            <h6>Objects</h6>
            <Slider
              defaultValue={parts}
              onChange={(o, v) => setParts2(v)}
              aria-labelledby="discrete-slider"
              step={1}
              marks
              min={0}
              max={10}
              valueLabelDisplay="on"
              style = {{marginBottom : 40}}
              value = {parts}
            />
          </div>
          <Grid container spacing={3}>
            <Container
              n={containers}
              circles={<Circles n={parts} opacity={containers > 0 ? 1 : 0.1} />}
            />
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} style={{ fontSize: 25 }}>
              <hr />
              <InlineMath
                math={
                  "\\color{green}" +
                  containers +
                  "\\color{black} \\times  \\color{red}" +
                  parts +
                  "\\color{black} = " +
                  containers * parts
                }
              />
              <hr />
              <p>
                <InlineMath
                  math={
                    "\\color{red}" +
                    asAddition.join(" \\color{black} +  \\color{red}") +
                    "  \\color{black} = " +
                    containers * parts
                  }
                />
              </p>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <h4>Divide</h4>
          <p style={{ fontSize: 25 }}>
            <InlineMath
              math={
                "\\color{red}" +
                total +
                "\\div  \\color{green}" +
                divider +
                "\\color{black} = " +
                (true ? " \\enspace ? " : 0)
              }
            />
          </p>

          <div style={{ padding: 10 }}>
            <h6>Containers</h6>
            <Slider
              key={"divider-" + containers}
              defaultValue={containers}
              onChange={(o, v) => {
                setDivider(v);
                tryAgain();
              }}
              aria-labelledby="discrete-slider"
              step={1}
              marks
              min={1}
              max={10}
              valueLabelDisplay="on"
              style = {{marginBottom : 40}}
              value = {divider}
            />
            <h6>Objects</h6>
            <Slider
              key={"divider-objects-" + parts * containers}
              defaultValue={total}
              onChange={(o, v) => {
                setTotal(v);
                tryAgain(v);
              }}
              aria-labelledby="discrete-slider"
              step={1}
              marks
              min={0}
              max={100}
              valueLabelDisplay="on"
              style = {{marginBottom : 40}}
              value = {total}
            />
          </div>
          <Grid container>
            <Grid item xs={12}>
              How {total === 1 ? " does " : " do "}{" "}
              <InlineMath math={"\\color{red} " + total} /> total objects{" "}
            </Grid>
            <Grid
              container
              style={{ backgroundColor: "#efe", margin: 5, padding: 5 }}
            >
              {apples.map((v, i) => (
                <Grid key={"totalApple-" + i} style={{ padding: 3 }}>
                  {circle}
                </Grid>
              ))}{" "}
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                fit into <InlineMath math={"\\color{green} " + divider} />{" "}
                container{divider === 1 ? " " : "s"}?{" "}
                <p>
                  <InlineMath
                    math={
                      "\\color{red}  " +
                      total +
                      "  - \\color{black}  " +
                      (moved === 0 ? moved : asSubtraction.join(" - ")) +
                      " = " +
                      (total - moved * divider)
                    }
                  />
                </p>
              </Grid>
              <Container n={divider} circles={<Circles n={moved} />} />
            </Grid>
          </Grid>
          <Grid item>
            <p>
              <InlineMath
                math={
                  "\\quad \\color{red}" +
                  total +
                  "\\div  \\color{green}" +
                  divider +
                  "\\color{black} = " +
                  (notMoved >= divider
                    ? " \\enspace ? "
                    : Math.floor(total / divider) +
                      (notMoved === 0
                        ? " "
                        : "\\frac{" +
                          notMoved +
                          "}{" +
                          divider +
                          "}" +
                          (g !== 1 ? reducedRemainder : " ")))
                }
              />
            </p>
            <Button
              variant="outlined"
              onClick={() => moveOnce()}
              color="secondary"
              style={{ margin: 10 }}
              disabled={notMoved < divider}
            >
              Add 1 object per container
            </Button>

            {false && (
              <Button
                variant="outlined"
                onClick={() => divide()}
                color="secondary"
                style={{ margin: 10 }}
              >
                Complete Division
              </Button>
            )}
            <Button
              variant="outlined"
              onClick={() => tryAgain()}
              color="primary"
              style={{ margin: 10 }}
            >
              Try again
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default MultiplyDivide;
