import React, { useState } from "react";
import { Grid, Slider } from "@mui/material";
import "katex/dist/katex.min.css";
import { InlineMath } from "react-katex";
import PieFraction from "../Tools/PieFraction";

const Radians = props => {
  const [numerator, setNumerator] = useState(1);
  const [denominator, setDenominator] = useState(4);

  // take out 2 pi
  const strip2pi = (n, d) => {
    //if (true) return [n, d];
    if (n / d <= 2) return [n, d];
    return strip2pi(n - 2 * d, d);
  };
  //const [numeratorAdjusted, denominatorAdjusted] = []
  const numeratorAdjusted =
    numerator % denominator === 0 ? numerator : numerator % denominator;

  const gcd = (a, b) => {
    if (!b) {
      return a;
    }
    return gcd(b, a % b);
  };

  const g = gcd(2 * numerator, denominator);

  const simplifiedText =
    denominator / g !== 1
      ? "  \\color{red} \\frac{" +
        (2 * numerator) / g +
        "}{" +
        denominator / g +
        "} \\color{black}  \\pi  "
      : "\\enspace" + denominator / g === 1
      ? "   \\color{black}  = \\pi"
      : "  \\color{red}  " + (2 * numerator) / g + "\\color{black}  \\pi";

const simplified = <InlineMath math = {" = " +  simplifiedText} />

  return (
    <>
      <h3> Circle: Degrees and Radians</h3>
      <Grid container spacing={4}>
        <Grid item xs = {12} md = {4}>
          <PieFraction
            denominator={denominator}
            numerator={numeratorAdjusted}
            svgHeight={300}
          />
          <h6 style = {{marginBottom: 40}}>Numerator</h6>
          <Slider
            defaultValue={numerator}
            onChange={(o, v) => {
              setNumerator(v);
            }}
            value = {numerator}
            
            aria-labelledby="discrete-slider"
            step={1}
            marks
            min={0}
            max={15}
            valueLabelDisplay="on"
          />
          <h6 style = {{marginBottom: 40}}>Denominator</h6>
          <Slider
            defaultValue={denominator}
            onChange={(o, v) => {
              setDenominator(v);
            }}
            value = {denominator}
            
            aria-labelledby="discrete-slider"
            step={1}
            marks
            min={1}
            max={15}
            valueLabelDisplay="on"
          />
        </Grid>
        <Grid style={{ fontSize: 25 }} item xs = {12} md = {3}>
          <p>
            <InlineMath
              math={
                "\\color{red} \\frac{" + numerator + "}{" + denominator + "}  "
              }
            />{" "}
            of a circle
          </p>

          {numerator > denominator && (
            <p style={{ fontSize: 15, color: "red" }}>
              more than one complete rotation!
            </p>
          )}
          <h6 style={{ fontSize: 15 }}>As Radians</h6>
          <p>
            <InlineMath
              math={
                "\\color{red} \\frac{" +
                numerator +
                "}{" +
                denominator +
                "} \\color{black} \\times 2 \\pi = \\color{red} \\frac{" +
                2 * numerator +
                "}{" +
                denominator +
                "} \\color{black} \\times  \\pi \\enspace    "
              }
            />
            {simplified}
          </p>
          <h6 style={{ fontSize: 15 }}>As Degrees</h6>
          <p>
            <InlineMath
              math={
                "\\color{red} \\frac{" +
                numerator +
                "}{" +
                denominator +
                "} \\color{black} \\times \\color{green} 360 \\degree = \\color{green}" +
                Math.round((100 * 360 * numerator) / denominator) / 100 +
                " \\degree"
              }
            />
          </p>
          <h6 style={{ fontSize: 15 }}>Conversion of Degrees to Radians</h6>
          <p>
            <InlineMath
              math={
                "  \\color{green}" +
                Math.round((100 * 360 * numerator) / denominator) / 100 +
                " \\degree"
              }
            />
            {simplified}
          </p>
        </Grid>
        <Grid style={{ fontSize: 25 }} item xs = {12} md = {4}>
       <p> <InlineMath
          math={"\\frac{ \\color{red}\\pi }{ \\color{green} 180 \\degree } = \\frac{\\color{red} R}{\\color{green}D \\degree } "}
        /></p>
        <p> <InlineMath
          math={"\\frac{  \\color{red} \\pi }{\\color{green} 180 \\degree } = \\frac{" + simplifiedText + "  }{ \\color{green} " +
          Math.round((100 * 360 * numerator) / denominator) / 100 +
          " \\degree } "}
        /></p>
      </Grid>
      </Grid>
     
    </>
  );
};

export default Radians;
