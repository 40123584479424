import React, { useEffect, useRef, useState } from "react";
import JXG from "jsxgraph";
import { Grid, Slider } from "@mui/material";
import { select } from "d3";
import "katex/dist/katex.min.css";
import { BlockMath, InlineMath } from "react-katex";
import Cube from "./dimensions/Cube";

const styles = {
  linearGraph: {},
  linearSvg: {},
  quadraticGraph: {},
  quadraticSvg: {}
};
function PerimeterAreaGraph() {
  const linearGraphRef = useRef();
  const quadraticGraphRef = useRef();
  const linearSvgRef = useRef();
  const quadraticSvgRef = useRef();
  const cubeSvgRef = useRef();
  const cubeGraphRef = useRef();
;
  const defaultWidth = 20;
  const [width, setWidth] = useState(defaultWidth/2);

  useEffect(() => {
    const linearSvg = select(linearSvgRef.current);
    const lines = linearSvg.selectAll("line");
    console.log("linearSvg", linearSvg, lines);
    lines.attr("x2", 5*width + 20);

    const square = select(quadraticSvgRef.current).select("rect");
    //const square = linearSvg.selectAll("line");
    console.log("linearSvg", linearSvg, lines);
    square.attr("width", width*5);
    square.attr("height", width*5);

    const linearBrd = JXG.JSXGraph.initBoard("perimeterGraph", {
      boundingbox: [-5, 88, 22, -5],
      showCopyright: false,
      axis: true,
      shownavigation: false
    });

    const quadraticBrd = JXG.JSXGraph.initBoard("quadraticGraph", {
      boundingbox: [-5, 22*22, 22, -5],
      showCopyright: false,
      axis: true
    });

    const cubeBoard = JXG.JSXGraph.initBoard("cubeGraph", {
      boundingbox: [-5, 22*22*22, 22, -5],
      showCopyright: false,
      axis: true
    });

    const p0 = linearBrd.create("point", [-1, -4], {
      name: "",
      visible: false,
      fixed: true
    });

    const p = linearBrd.create("point", [width, 4 * width], {
      name: "w = " + width + " perimeter = " + 4 * width,
      visible: true,
      strokeColor: "black",
      fillColor: "black",
      fixed: true
    });

    linearBrd.create("line", [p0, p], {
      strokeColor: "red"
    });

    quadraticBrd.create(
      "functiongraph",
      [
        function(t) {
          return t * t;
        },
        -2,
        100
      ],
      { strokeColor: "blue", fixed: false }
    );

    const q = quadraticBrd.create("point", [width, width * width], {
      name: "w = " + width + " area = " + width * width,
      visible: true,
      strokeColor: "black",
      fillColor: "black",
      fixed: true
    });


    cubeBoard.create(
      "functiongraph",
      [
        function(t) {
          return t * t*t;
        },
        -2,
        100
      ],
      { strokeColor: "blue", fixed: false }
    );

    cubeBoard.create("point", [width, width * width* width], {
      name: "w = " + width + " volume = " + width * width* width,
      visible: true,
      strokeColor: "black",
      fillColor: "black",
      fixed: true
    });


  }, [width]);

  const handleChange = (o, value) => {
    setWidth(value);
  };

  return (
    <div>
      <h3> Perimeter, Area, and Volume Modeling</h3>
      <Slider
        defaultValue={defaultWidth/2}
        onChange={handleChange}
        aria-labelledby="discrete-slider"
        step={1}
        marks
        min={0}
        max={defaultWidth}
        valueLabelDisplay="on"
        style={{ marginBottom: 20, marginTop: 20 }}
      ></Slider>
      <i style={{ fontSize: 15 }}>
        change the width and observe how it changes the perimeter, area, and volume
      </i>
      <Grid container style={{ paddingBottom: 20, fontSize: 20 }}>
        <Grid item>
          <h4 style={{ fontSize: 15 }}>Perimeter Object</h4>
          <svg width="200" height="100" ref={linearSvgRef}>
            <line
              x1="20"
              y1="20"
              x2="50"
              y2="20"
              style={{ stroke: "rgb(255,0,0)", strokeWidth: 2 }}
            />
            <line
              x1="20"
              y1="40"
              x2="50"
              y2="40"
              style={{ stroke: "rgb(255,0,0)", strokeWidth: 2 }}
            />
            <line
              x1="20"
              y1="60"
              x2="50"
              y2="60"
              style={{ stroke: "rgb(255,0,0)", strokeWidth: 2 }}
            />
            <line
              x1="20"
              y1="80"
              x2="50"
              y2="80"
              style={{ stroke: "rgb(255,0,0)", strokeWidth: 2 }}
            />
          </svg>
          <BlockMath math={"p = 4 \\times \\color{red} w"} />
          <BlockMath math={"p = 4 \\times \\color{red}" + width} />
          <BlockMath math={"p  = " + 4 * width} />
        </Grid>

        <Grid item>
          <h4 style={{ fontSize: 15 }}>Perimeter Graph</h4>
          <p style={{ fontSize: 10 }}>
            perimeter <InlineMath>p</InlineMath> as a function of{" "}
            <InlineMath math={"\\color{red}w"} />
          </p>
          <div
            id="perimeterGraph"
            ref={linearGraphRef}
            style={{ width: 300, height: 300 }}
          />
          <BlockMath math={"(\\color{red} w \\color{black} , p)"} />
          <BlockMath
            math={
              "(\\color{red}" + width + "\\color{black} , " + 4 * width + ")"
            }
          />
        </Grid>

        <Grid item style={styles.linearGraph}>
          <h4 style={{ fontSize: 15 }}>Area Object</h4>
          <svg width="200" height="112" ref={quadraticSvgRef}>
            <rect
              width={defaultWidth}
              height={defaultWidth}
              x="50"
              y="10"
              style={{
                fill: "rgb(0,0,255)",
                strokeWidth: 3,
                stroke: "rgb(255,0,0)"
              }}
            />
          </svg>
          <BlockMath math={"A = (\\color{red} w \\color{black})^2"} />
          <BlockMath
            math={"A = (\\color{red}" + width + " \\color{black})^2"}
          />
          <BlockMath math={"A  = " + width * width} />
        </Grid>
        <Grid item>
          <h4 style={{ fontSize: 15 }}>Area Graph</h4>
          <p style={{ fontSize: 10 }}>
            area <InlineMath>A</InlineMath> as a function of{" "}
            <InlineMath math={"\\color{red}w"} />
          </p>
          <div
            id="quadraticGraph"
            ref={quadraticGraphRef}
            style={{ width: 300, height: 300 }}
          />
          <BlockMath math={"(\\color{red} w \\color{black} , A)"} />
          <BlockMath
            math={
              "(\\color{red}" +
              width +
              "\\color{black} , " +
              width * width +
              ")"
            }
          />
        </Grid>
      
        <Grid item style={styles.linearGraph}>
          <h4 style={{ fontSize: 15 }}>Volume Object</h4>
          <Cube
          dx={width * 3}
          dy={width * 3}
          dz={width * 3}
          rotate={20}
          opacity={0.5}
        />
          <BlockMath math={"A = (\\color{red} w \\color{black})^2"} />
          <BlockMath
            math={"A = (\\color{red}" + width + " \\color{black})^2"}
          />
          <BlockMath math={"A  = " + width * width} />
        </Grid>
        <Grid item>
          <h4 style={{ fontSize: 15 }}>Volume Graph</h4>
          <p style={{ fontSize: 10 }}>
            volume <InlineMath>A</InlineMath> as a function of{" "}
            <InlineMath math={"\\color{red}w"} />
          </p>
          <div
            id="cubeGraph"
            ref={cubeGraphRef}
            style={{ width: 300, height: 300 }}
          />
          <BlockMath math={"(\\color{red} w \\color{black} , V)"} />
          <BlockMath
            math={
              "(\\color{red}" +
              width +
              "\\color{black} , " +
              width * width * width +
              ")"
            }
          />
        </Grid>
      </Grid>
      <Slider
        defaultValue={defaultWidth/2}
        onChange={handleChange}
        aria-labelledby="discrete-slider"
        step={1}
        marks
        min={0}
        max={defaultWidth}
        valueLabelDisplay="on"
        style={{ marginBottom: 20, marginTop: 20 }}
      ></Slider>
    </div>
  );
}

export default PerimeterAreaGraph;
