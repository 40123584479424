import React, { useState, useEffect, useRef } from "react";
import { select } from "d3";
import sample from "lodash/sample";
import sampleSize from "lodash/sampleSize";

import { Grid, Paper, Button } from "@mui/material";
import "katex/dist/katex.min.css";
import { InlineMath } from "react-katex";
import Rad from "../Tools/RadicalPath";

function RootSvg() {
  const redSetStatus = new Array(9).fill(false);
  const [opacities, setOpacities] = useState(new Array(9).fill(1));
  const data = redSetStatus.map(v => sample(["red", "blue", "green"]));
  const [allCircles, setAllCircles] = useState(data);
  const [allCirclesStatus, setAllCirclesStatus] = useState(redSetStatus);
  const [allSets, setAllSets] = useState();
  const [statusObj, setStatusObj] = useState({ red: [], blue: [], green: [] });
  const simplified = useRef();
  const outSideRef = useRef();
  const [currentPosition, setCurrentPosition] = useState(1);
  const [numbers, setNumbers] = useState(
    sampleSize([2, 3, 5, 7, 11, 13, 17], 3)
  );
  const [mathString, setMathString] = useState();
  const [products, setProducts] = useState([1, 1]);
  const [eliminated, setEliminated] = useState([]);
  const [outSide, setOutside] = useState([]);

  const red = "\\color{red}(" + numbers[0] + ")";
  const blue = "\\color{blue}(" + numbers[1] + ")";
  const green = "\\color{green}(" + numbers[2] + ")";
  //const colorKeys ={"red"}
  const makeNew = () => {
    let mathString = "";
    let productIn = 1;
    let productOut = 1;
    const temp = allCircles.map((v, i) => {
      const currentValue =
        v === "red" ? numbers[0] : v === "blue" ? numbers[1] : numbers[2];
      const newColor = v === "red" ? red : v === "blue" ? blue : green;
      if (!eliminated.includes(i)) {
        mathString += newColor;
        productIn = productIn * currentValue;
      } else {
        productOut = productOut * currentValue;
      }

      return (
        <circle
          key={"circle-" + allCirclesStatus.join("-") + i}
          className={v + "-" + i}
          cx={130 + 36 * (i % 3)}
          cy={50 + 55 * Math.floor(i / 3)}
          r={15}
          style={{
            fill: v,
            strokeWidth: 5,
            stroke: allCirclesStatus[i] ? "black" : v,
            opacity: opacities[i]
          }}
          onClick={() => handleClick(i, opacities[i])}
        />
      );
    });
    setAllSets(temp);

    setMathString(mathString);
    setProducts([productIn, productOut]);
  };

  const [directions, setDirections] = useState(true);
  const handleClick = (i, opacity) => {
    setDirections(false);
    if (opacity < 1) return;
    const temp = allCirclesStatus.map((v, x) => (x === i ? !v : v));
    const color = allCircles[i];
    if (!statusObj[color].includes(i) && temp[i]) statusObj[color].push(i);
    if (statusObj[color].includes(i) && !temp[i])
      statusObj[color].splice(statusObj[color].indexOf(i), 1);

    const temp3 = eliminated;
    if (statusObj[color].length === 2) {
      const svg = select(simplified.current);
      for (let w of statusObj[color]) {
        opacities[w] = 0.2;
        temp3.push(w);
      }

      const temp4 = outSide;
      temp4.push(color === "red" ? red : color === "blue" ? blue : green);
      setOutside(temp4);
      statusObj[color] = [];
      svg
        .select("#c" + currentPosition)
        .style("fill", color)
        .style("stroke", "black");
      setCurrentPosition(currentPosition + 1);
    }
    const temp2 = [...opacities];

    setEliminated(temp3);
    setOpacities(temp2);
    setAllCirclesStatus(temp);
  };

  useEffect(() => {
    makeNew();
  }, [allCirclesStatus]);

  const buttonClick = () => {
    const svg = select(outSideRef.current)
      .selectAll("circle")
      .style("fill", "white")
      .style("stroke", "white");

    const redSetStatus = new Array(9).fill(false);
    //setAllCirclesStatus(redSetStatus);
    setOpacities(new Array(9).fill(1));
    const data = redSetStatus.map(v => sample(["red", "blue", "green"]));
    setOutside([]);
    setAllCircles(data);
    setStatusObj({ red: [], blue: [], green: [] });
    setCurrentPosition(1);
    setNumbers(sampleSize([2, 3, 5, 7, 11, 13, 17], 3));
    setEliminated([]);
    setAllCirclesStatus(redSetStatus);

    //const [allSets, setAllSets] = useState();
  };
  return (
    <>
      <h4>Simplify Factored Square Roots</h4>
      <p>Click on each pair of the same color</p>
      <br />
      <Grid container spacing={3} p = {3}>
        <Button 
          onClick={() => buttonClick()}
          variant="outlined"
          color="secondary"
          //style={{ marginRight: 10 }}
        >
          Reset
        </Button>
        <Grid item xs={12} md={4}>
          <svg width={300} height={210} ref={simplified}>
            <g ref={outSideRef}>
              <circle
                id="c1"
                cx={20}
                cy={105}
                r={15}
                style={{
                  fill: "white",
                  strokeWidth: 5,
                  stroke: "white"
                }}
              />
              <circle
                id="c2"
                cx={60}
                cy={105}
                r={15}
                style={{
                  fill: "white",
                  strokeWidth: 5,
                  stroke: "white"
                }}
              />
              <circle
                id="c3"
                cx={20}
                cy={155}
                r={15}
                style={{
                  fill: "white",
                  strokeWidth: 5,
                  stroke: "white"
                }}
              />
              <circle
                id="c4"
                cx={60}
                cy={155}
                r={15}
                style={{
                  fill: "white",
                  strokeWidth: 5,
                  stroke: "white"
                }}
              />
            </g>

            <g transform="translate(80 0)">
              <Rad height={200} length={120} />
              {allSets}
            </g>
            <text x={115} y={205}>
              {directions ? " Click on dots to select pairs" : " "}
            </text>
          </svg>
        </Grid>
        <Grid item xs={12} md={6}>
          <h5>Mathematical Model of the Objects</h5>
          <p style={{ fontSize: 10 }}>Factored </p>
          <p style={{ fontSize: 25 }}>
            <InlineMath
              math={
                outSide.join(" ") + "\\color{black} \\sqrt{ " + mathString + "}"
              }
            />
          </p>
          <p style={{ fontSize: 10 }}>Not Factored </p>
          <p style={{ fontSize: 25 }}>
            <InlineMath
              math={
                " = " +
                (products[1] !== 1 ? Math.sqrt(products[1]) : " ") +
                "\\color{black} \\sqrt{ " +
                products[0] +
                "}"
              }
            />
          </p>
        </Grid>
      </Grid>
      <br />
      <Paper style={{ padding: 10, backgroundColor: "#f2f2f2" }}>
        <h3>How to simplify?</h3>
        <p>
          To simplify a square root, look inside the radical{" "}
          <InlineMath math={"\\sqrt{ \\quad} "} />
        </p>
      </Paper>
    </>
  );
}

export default RootSvg;

const hide = (
  <div>
    <Grid item xs={12} md={1}>
      <svg width={40} height={20}>
        <line
          x1={0}
          x2={40}
          y1={0}
          y2={0}
          style={{ strokeWidth: 10, stroke: "black" }}
        />
        <line
          x1={0}
          x2={40}
          y1={20}
          y2={20}
          style={{ strokeWidth: 10, stroke: "black" }}
        />
      </svg>
    </Grid>
  </div>
);
