import React, { useEffect, useRef, useState } from "react";
import NumberLine from "../Tools/NumberLine";
//import withWidth from "@mui/material/withWidth";
import { select, scaleLinear, max, min } from "d3";
import { Grid, Slider } from "@mui/material";
import "katex/dist/katex.min.css";
import { BlockMath } from "react-katex";
import useWidth  from "../Tools/UseWidth";



function BasicNumberLine(props) {
  const divRef = useRef(null);
  const [width, setWidth] = useState(useWidth() === "xs" ? 350 : 600);
  const data = [-10, 10];
  //const [data, setData] = useState(initialData);
  const xShift = 0.05 * width;
  const [value, setValue] = useState(0);

 /*  useEffect(() => {
    setWidth(useWidth() === "xs" ? 350 : 600);

  }); */

  useEffect(() => {
    const scale = scaleLinear()
      .domain([min(data), max(data)])
      .range([0, 0.9 * width]);

    select(divRef.current)
      .select("svg")
      .remove();

    const svg = select(divRef.current)
      .append("svg")
      .attr("width", width)
      .attr("height", 22)
      .attr("transform", "translate(0, 10)");

    svg
      .append("circle")
      .attr("id", "sizeOfRadius")
      .attr("r", 10)
      .attr("cx", scale(value))
      .attr("cy", 10)
      .style("fill", "red")
      .style("stroke", "red")
      .attr("transform", "translate(" + xShift + ", 1)");
  }, [data, value]);

  const handleChangeNumberA = (o, value) => {
    setValue(value);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} >
        <h6>Change the Value</h6>
          <Slider
            defaultValue={value}
            onChange={handleChangeNumberA}
            aria-labelledby="discrete-slider"
            step={1}
            marks
            min={min(data)}
            max={max(data)}
            valueLabelDisplay="on"
          />
        </Grid>
        <Grid item sm={12} md={6} style={{ paddingTop: 36 }}>
          <div ref={divRef} width={width}></div>
          <NumberLine data={data} width={width}  value = {value}/>
          <span style={{ fontSize: 30 }}>    
            <BlockMath math={"x = \\color{red} " + value} />
          </span>
        </Grid>
      </Grid>
    </>
  );
}

export default BasicNumberLine;

