import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";

import { Typography, Grid, Tooltip, Hidden, Paper } from "@mui/material"; // ToolTip used to view latex slider with  ValueLabelComponent
import Slider from "@mui/material/Slider";

import { select } from "d3";
import "katex/dist/katex.min.css";
import { InlineMath } from "react-katex";

/* const useStyles = makeStyles(theme => ({
  root: {},
  margin: {
    height: theme.spacing(5)
  },
  equivalent: {
    [theme.breakpoints.up("md")]: {
      transform: "translate(-10px, -50px)",
      display: "inline-block"
    }
  }
})); */

/**
 * Theme the tooltip that follows the slider value
 */


/**
 *
 * @param {*} props
 * this will follow the slider value , better for displaying math
 */
const FormatTooltip = props => {
  const { children, value, open } = props;
  return (
    children
  );
};

export default function DiscreteSlider() {
  /**
   *   initial values in object inits
   *
   *  cx, cy:  center values
   *  dw: change in radius
   *  h: height
   *  w:  width
   *
   * states:
   *  dw, dh: changes in width and height
   *
   */
  const inits = { cx: 100, cy: 100, h: 200, w: 100, dh: 0, dw: 2 , d: 2 };

  const scale = inits.w / 10; // slider is set to 0 to 10

  const [dw, setDw] = useState(2);

  const classes = {};

  useEffect(() => {
    const rect = select("#outerRectangle");
    const border = select("#borderRectangle");
    const total = select("#totalRectangle");

    if (dw >= 0) {
      rect.attr("width", inits.w + dw * scale);
      border.attr("width", inits.w + dw * scale + 4);
      total.attr("width", inits.w + dw * scale + 4);
      const deltaText = select("#deltaForeignObject");
      const w = "\\color{black} \\Delta w = " + dw;
      ReactDOM.render(<InlineMath math={w} />, deltaText.node());

      const areaText = select("#areaForeignObject");
      const a1 =
        "\\color{black}  = 20 \\times ( \\color{white} 10 \\color{black}   + \\color{red}  " +
        dw +
        " )";
      const dist =
        "\\color{black}  = 120   + 20 \\times (\\color{red}  " +
        dw +
        " \\color{black})";
      const a2 = (
        <div>
          Combined area <InlineMath math="A" /> <br />
          <InlineMath math="=h  \times (\color{white} w  \color{black} + \color{red} \Delta w ) " />
          <br />
          <InlineMath math={a1} />
          <br />
          <InlineMath math={dist} />
          <br />
          <InlineMath math={" = " + (10 + dw) * 20} />
          <br />
          change of area
          <br />
          <InlineMath
            math={"\\color{purple} \\Delta A = " + ((10 + dw) * 20 - 200)}
          />
          <br />
          <InlineMath math={"\\color{white} \\Delta w = " + dw} />
        </div>
      );
      ReactDOM.render(a2, areaText.node());
    }
  }, [dw, inits.w, scale]);

  useEffect(() => {
    const fixedText = select("#fixedForeignObject");
    const w = "\\color{white} w = 10";
    const inlineMath = (
      <div>
        <InlineMath math="\color{white} h = 20 " />
        <br />
        <InlineMath math={w} />
        <br />
        <InlineMath math="\color{white} A_{o} = 200" />
      </div>
    );
    ReactDOM.render(inlineMath, fixedText.node());
  }, []);

  // console.log("fixedText", fixedText);

  const widthChanged = (o, value) => {
    // console.log("widthChanged", value, radius);
    setDw(value);
  };

  const left = (
    <>
      {" "}
      <p>
        The <span style={{ color: "blue" }}>original width </span> is fixed at{" "}
        <InlineMath math="\color{blue} w = 10" />{" "}
      </p>
      <div style={{ paddingTop: 40, paddingLeft: 40 }}>
        <Slider
          defaultValue={inits.dw}
          onChange={widthChanged}
          aria-labelledby="discrete-slider"
          step={1}
          marks
          min={0}
          max={10}
          valueLabelDisplay="on"
          valueLabelFormat={x => <InlineMath math={"\\Delta w =   " + x} />}
          //ValueLabelComponent={FormatTooltip}
        />
      </div>
      <Typography id="discrete-slider" gutterBottom>
        <span style={{ color: "red" }}>Change of width </span>{" "}
        <InlineMath math={" \\color{red} w "} /> by the value{" "}
        <InlineMath math={"\\color{red} \\Delta w =   " + dw} />
      </Typography>
      <Hidden mdUp>
        {" "}
        <hr />{" "}
      </Hidden>
    </>
  );

  const deltaSvg = (
    <svg width={inits.w * 2 + 30} height={inits.h + 20} id="leftSvg">
      <rect
        id="borderRectangle"
        width={inits.w + inits.dw * scale + 4}
        height={inits.h + 6}
        style={{ fill: "rgb(0,225,0)" }}
      />
      <rect
        id="outerRectangle"
        width={inits.w + inits.dw * scale}
        height={inits.h}
        style={{ fill: "rgb(255,0,0)", transform: "translate(2px, 2px)" }}
      />

      <rect
        id="fixedRectangle"
        width={inits.w}
        height={inits.h}
        style={{ fill: "rgb(0,0,255)", transform: "translate(2px, 2px)" }}
      />
      <foreignObject
        x="5"
        y="5"
        width="80"
        height="80"
        id="fixedForeignObject"
      />
      <foreignObject
        x="110"
        y="170"
        width="80"
        height="40"
        id="deltaForeignObject"
      />
    </svg>
  );

  const totalSvg = (
    <svg width={inits.w * 2 + 10} height={inits.h + 20}>
      <rect
        id="totalRectangle"
        width={inits.w + inits.dw * scale + 4}
        height={inits.h + 6}
        style={{ fill: "rgb(75,225,75)" }}
      />
      <foreignObject
        x="5"
        y="5"
        width="200"
        height="200"
        id="areaForeignObject"
      />
    </svg>
  );

  const widthCalculations = (
    <div>
      <InlineMath
        math={
          " \\color{green} w_{new} \\color{black} = \\color{blue} w_{original} \\color{black}   + \\color{red} \\Delta w   "
        }
      />
      <br />
      <InlineMath
        math={
          "  = \\color{blue} 10 \\color{black}   + \\color{red} \\Delta w  =  "
        }
      />
      <br />
      <InlineMath
        math={
          " =  \\color{blue} 10 \\color{black}   + \\color{red}  " +
          dw +
          " \\color{black}=  \\color{green}" +
          (10 + dw)
        }
      />
    </div>
  );

  const linearEq = (
    <div>
      <InlineMath math={"y = m \\color{red}x \\color{black} + b"} />
      <br />
      <InlineMath math={"A = m ( \\color{red}w \\color{black}) + b"} />
      <br />
      <InlineMath
        math={
          "A = 20 (\\color{red}" +
          dw +
          " \\color{black}) + 120 = " +
          20 * (10 + dw)
        }
      />
      <br />{" "}
      <i style={{ fontSize: 10 }}>
        *The area has a linear relationship with the width because we only
        change 1 dimension
      </i>
    </div>
  );

  return (
    <div className={classes.root}>
      <h3>Change of Width, Area in a Rectangle</h3>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          {left}
        </Grid>
        <Grid item xs={12} md={6}>
          {deltaSvg}

          {totalSvg}
          <Grid container spacing={5}>
            <Grid item>{widthCalculations}</Grid>
            <Grid item>{linearEq}</Grid>
          </Grid>
        </Grid>
      </Grid>
      <Paper
       // elevation={20}
        variant="outlined"
        style={{
          padding: 5,
          backgroundColor: "#EEE",
          marginBottom: 5,
          marginTop: 5
        }}
      >
        <h3>Key Takeaways</h3>
        <p>
          Consider the relationship between <InlineMath>width</InlineMath> and{" "}
          <InlineMath>area</InlineMath> .
        </p>
        <h4>Width</h4>
        <p>
          We will keep track of both the{" "}
          <span style={{ color: "blue" }}>
            original width{" "}
            <InlineMath math=" \color{black} (  \color{blue} w = 10 \color{black} )" />
          </span>{" "}
          and{" "}
          <span style={{ color: "red" }}>
            {" "}
            the change in the width{" "}
            <InlineMath
              math={
                " \\color{black} (  \\color{red} \\Delta w = " +
                dw +
                " \\color{black} )"
              }
            />
          </span>{" "}
          . Changing the width will also change the area.
        </p>
        <h4>Area</h4>
        <InlineMath>A = w \times h</InlineMath> where the height{" "}
        <InlineMath>h = 20. \quad </InlineMath>
        As <InlineMath>w</InlineMath> changes so does <InlineMath>A</InlineMath>
        .{" "}
        <InlineMath
          math={
            "\\quad \\color{green} A = 20 \\times{" +
            (dw + 10) +
            "} = " +
            20 * (dw + 10)
          }
        >
          {" "}
        </InlineMath>
        <InlineMath
          math={
            "\\quad \\color{purple} \\Delta A = 20 \\times{" +
            dw +
            "} = " +
            20 * dw
          }
        >
          {" "}
        </InlineMath>
        <p>This is a linear relationship because we only change one dimension.</p>
        <hr />
        <p>
          Notice the relationship between the width and area. This is a linear
          relationship. To find the area, multiply the width by the height{" "}
          <InlineMath>h = 20</InlineMath>. Because this is a linear
          relationship, the change in area will always be equal to the same
          factor of times 20
        </p>
      </Paper>
    </div>
  );
}
