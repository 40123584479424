import React, { useState, useEffect, useRef } from "react";
import { select, scaleOrdinal, schemeCategory10, pie, arc } from "d3";

const Fraction = props => {
  const { denominator, numerator } = props;
  const data = new Array(denominator).fill(1);
  const svgRef = useRef();
  const pieCircle = pie().value(function(d) {
    return d[1];
  });

  const data_ready = pieCircle(Object.entries(data));
  const color = scaleOrdinal(schemeCategory10);
  const svgHeight = props.svgHeight || 75;

  useEffect(() => {
    const svg = select(svgRef.current);
    //svg.remove(".remove");
    svg.selectAll("path").remove();
    svg
      .selectAll("path")
      .data(data_ready)
      .enter()
      .append("path")
      .attr("class", "remove")
      .attr(
        "d",
        arc()
          .innerRadius(0)
          .outerRadius((30 * svgHeight) / 75)
      )
      .attr(
        "transform",
        "translate(" + svgHeight / 2 + ", " + svgHeight / 2 + ")"
      )
      .attr("fill", function(d) {
        return color(d.data[0]);
      })
      .attr("stroke", "black")
      .style("stroke-width", "2px")
      .style("opacity", function(d) {
        return d.data[0] < numerator ? 0.8 : 0.1;
      });
  }, [numerator, denominator]);

  return (
    <>
      <svg width={svgHeight} height={svgHeight} ref={svgRef}>
        {" "}
      </svg>
    </>
  );
};

export default Fraction;
