import React, { useState } from "react";
import { Grid, Slider, Button } from "@mui/material";
import "katex/dist/katex.min.css";
import { InlineMath } from "react-katex";
import { factorToPrimes, gcd } from "../Tools/functions";
import { scaleOrdinal, schemeCategory10 } from "d3";
import { Practice } from "../practice/PracticeReduce";

const ReduceFractions = props => {
  const [num, setNum] = useState(12);
  const [den, setDen] = useState(8);
  const numFactorArray = factorToPrimes(num);
  const denFactorArray = factorToPrimes(den);
  const [algebraOnly, setAlgebraOnly] = useState(false);
  const g = gcd(num, den);

  const color = scaleOrdinal(schemeCategory10);

  let denTemp = den;
  const cancelArray = [];
  const uniqueNum = numFactorArray.concat([]);
  const uniqueDen = denFactorArray.concat([]);

  const numFactors = numFactorArray.map((v, i) => {
    if (denTemp % v === 0) {
      denTemp = denTemp / v;
      cancelArray.push("\\cancel{" + v + "}");
      uniqueDen.splice(uniqueDen.indexOf(v), 1);
      uniqueNum.splice(uniqueNum.indexOf(v), 1);
    }
  });

  const denArray = new Array(den).fill(num);

  const h = den <= 25 ? 75 : 150;

  const dotsNotReduced = (
    <svg width={75} height={h}>
      <rect fill={"green"} width={75} height={h} opacity={0.2} />{" "}
      {denArray.map((v, i) => (
        <circle
          key={"complicated-" + i}
          r={6}
          cx={10 + 1.2 * 12 * (i % 5)}
          cy={10 + 1.2 * 12 * Math.floor(i / 5)}
          fill={color(Math.floor(i / g))}
          opacity={i < num % den ? 1 : 0.2}
        />
      ))}
    </svg>
  );

  const filled = denArray.map((v, i) => (
    <circle
      key={"complicated-" + i}
      r={6}
      cx={10 + 1.2 * 12 * (i % 5)}
      cy={10 + 1.2 * 12 * Math.floor(i / 5)}
      fill="red"
    />
  ));
  const simplifiedNumArray = new Array(num / g).fill(1);
  const simplifiedDenArray = new Array(den / g).fill(1);

  const floor = Math.floor(num / den);
  const floorArray = new Array(floor).fill(num);

  const wholes = floorArray.map((v, i) => {
    return (
      <svg width={79} height={h + 2} key={"whole-" + i}>
        <rect fill={"green"} x={2} y={2} width={75} height={h} />
        {filled}
      </svg>
    );
  });

  const factoredNumString = cancelArray.concat(uniqueNum).join("\\times");
  const factoredDenString = cancelArray.concat(uniqueDen).join("\\times");

  const problem =
    "\\frac{ \\color{red}  " + num + "}{\\color{blue} " + den + "} ";

  const problemSimp =
    "\\frac{ \\color{red}  " + num / g + "}{\\color{blue} " + den / g + "} ";

  const simplifiedFraction =
    g === den
      ? num / g
      : "\\frac{ \\color{red}" + num / g + "}{ \\color{blue} " + den / g + "}";

  const split =
    problem +
    " = \\frac{ \\color{red} " +
    Math.floor(num / den) * den +
    " + " +
    (num % den) +
    "}{ \\color{blue}" +
    den +
    "} = \\frac{ \\color{red} " +
    Math.floor(num / den) * den +
    "}{ \\color{blue}" +
    den +
    "} + \\frac{ \\color{red} " +
    (num % den) +
    "}{ \\color{blue}" +
    den +
    "} ";

  const splitSimp =
    g !== 1 &&
    problemSimp +
      " = \\frac{ \\color{red} " +
      Math.floor(num / g / (den / g)) * (den / g) +
      " + " +
      ((num / g) % (den / g)) +
      "}{ \\color{blue}" +
      den / g +
      "} = \\frac{ \\color{red} " +
      Math.floor(num / g / (den / g)) * (den / g) +
      "}{ \\color{blue}" +
      den / g +
      "} + \\frac{ \\color{red} " +
      ((num / g) % (den / g)) +
      "}{ \\color{blue}" +
      den / g +
      "} ";

  const last =
    " = " +
    (Math.floor(num / den) > 0 ? Math.floor(num / den) : " ") +
    (num % den !== 0
      ? "\\frac{" + ((num / g) % (den / g)) + "}{" + den / g + "}"
      : " ");
  return (
    <>
      <Grid container spacing={5}>
        <Grid item xs={12} md={3}>
          <h4>Reduce Fractions</h4>
          <h6>Numerator</h6>
          <Slider
            defaultValue={num}
            value = {num}
            onChange={(o, v) => {
              setNum(v);
            }}
            aria-labelledby="discrete-slider"
            step={1}
            marks
            min={1}
            max={50}
            valueLabelDisplay="on"
          />

          <h6>Denominator </h6>
          <Slider
            defaultValue={den}
            value={den}
            onChange={(o, v) => {
              setDen(v);
            }}
            aria-labelledby="discrete-slider"
            step={1}
            marks
            min={1}
            max={50}
            valueLabelDisplay="on"
          />
        </Grid>
        <Grid item style={{ fontSize: 25 }}>
          <InlineMath
            math={
              problem +
              "= \\frac{ \\color{red} " +
              (factoredNumString.length > 0 ? factoredNumString : 1) +
              "}{\\color{blue}" +
              (factoredDenString.length > 0 ? factoredDenString : 1) +
              "} = " +
              simplifiedFraction
            }
          />
          {!algebraOnly && (
            <>
              <p>
                <InlineMath math={split} />
              </p>
              {splitSimp && (
                <p>
                  <InlineMath math={splitSimp} />
                </p>
              )}
              <p>
                <InlineMath math={last} />
              </p>
            </>
          )}
          <p>
            <Button
              onClick={() => setAlgebraOnly(!algebraOnly)}
              variant="outlined"
            >
              {algebraOnly ? "Show Calculations" : "Do Not Show Calculations"}
            </Button>
          </p>
        </Grid>
        <Grid style={{ marginTop: 10 }} item xs={12} md={4}>
          {wholes} {dotsNotReduced}
        </Grid>
      </Grid>
      <Grid container>
        <Grid item></Grid>
      </Grid>
      <Practice />
    </>
  );
};

export default ReduceFractions;
