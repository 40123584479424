import React, { useEffect, useState, useRef } from "react";
import { select, selectAll, geoOrthographic } from "d3";

const Sphere = props => {
  const [theta, setTheta] = useState(0);
  function tick() {
    // savedCallback.current();
    setTheta(theta > 360 ? 0 : theta + 1);
  }
  const ref = useRef();
  useEffect(() => {
    if (true) {
      let id = setInterval(tick, 20);
      return () => clearInterval(id);
    }
  }, [theta]);

  return (
    <>
      <div
        style={{
          backgroundColor: theta < 90 || theta > 270 ? "red" : "blue",
          width: 75,
          height: 75,
          transform: "rotate3d(0, 1, 0, " + theta + "deg",
          textAlign: "center",
          margin: "auto",
          //paddingTop: 10,
          fontSize: 15,
          color: "white"
        }}
      >
        {theta < 90 || theta > 270 ? "front" : "back"}
        <br />
        <span style={{ fontSize: 25 }}>
          {theta < 90 || theta > 270 ? "+" : "-"}
        </span>
        <br />
        {theta < 90 || theta > 270 ? "positive" : "negative"}
      </div>
    </>
  );
};

export default Sphere;
