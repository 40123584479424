import React from "react";
import { Square } from "./Square";
export const Rectangle = props => {
  const width = props.width || 480;
  const defaultW = props.w || (10 * width) / 480;
  const defaultArrayW = new Array(defaultW).fill(1);
  const defaultL = props.l || (10 * width) / 480;
  const defaultArrayL = new Array(defaultL).fill(1);
  const count = props.count || true;
  const defaultColor = props.color || "black"
  let counting = 0;
  let extra = 0;
  let defaultFill = props.fill;
  let fill, stroke, text, color;
  const lastFill = props.lastFill || defaultFill
  const squares = defaultArrayL.map((v, k) => {
    return defaultArrayW.map((v2, i) => {
      counting++;
      
      stroke = null;
      color = defaultColor;
      if (k===0 && i === 0 ) color = "black"
      text = counting - extra + 1;
      fill = text===(defaultW-1)*(defaultL-1) ? lastFill : defaultFill;
      if (k === 0) {
        // first row outside of color
        //counting = i;
        fill = "white";
        stroke = "white";
        if (i !== 0)
          color = "red";
        text = i;
        extra++;
      }
      if (i === 0) {
        text = k;
        fill = "white";
        stroke = "white";
        extra++;
        if (k !== 0)
          color = "green";
      }
      return (<Square key={"square-" + i + "-" + k} x={((i + 1) * 40 * width) / 480} y={((k + 1) * 40 * width) / 480} text={count && text} fill={fill} stroke={stroke} color={color} width={width} />);
    });
  });
  return squares;
};
