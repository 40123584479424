import React, { useState } from "react";
import { Grid, Slider } from "@mui/material/";
import { InlineMath } from "react-katex";
import { scaleOrdinal, schemeCategory10 } from "d3";
import { gcd } from "../Tools/functions";

const color = scaleOrdinal(schemeCategory10);

const MixedNumber = props => {
  //const [whole, setWhole] = useState(2);
  const [part, setPart] = useState(28);
  const [divider, setDivider] = useState(8);

  const whole = Math.floor(part / divider);
  const remainder = part % divider;

  const r = 5;

  const partArray = new Array(part).fill(1);
  const partDots = partArray.map((v, i) => {
    return (
      <circle
        key={"partDot-" + i}
        r={r}
        cx={((i % divider) + 1) * r * 2.2}
        cy={(Math.floor(i / divider) + 1) * r * 2.2}
        fill={color(Math.floor(i / divider))}
      />
    );
  });

  const g = gcd(remainder, divider);
  const lcd = divider / g;

  //console.log("g", g, lcd)
  const remainderArray = new Array(divider).fill(1);
  const remainderDots = remainderArray.map((v, i) => {
    return (
      <circle
        key={"remainder-" + i}
        r={r}
        cx={((i % divider) + 1) * r * 2.2}
        cy={(Math.floor(i / divider) + 1) * r * 2.2}
        fill={color(Math.floor(i / g))}
        opacity={i < remainder ? 1 : 0.3}
      />
    );
  });
  return (
    <>
      <h3>Mixed Numbers</h3>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <h4 style = {{marginBottom: 40}}>Parts</h4>
          <Slider
            defaultValue={part}
            onChange={(o, v) => {
              setPart(v);
            }}
            aria-labelledby="discrete-slider"
            step={1}
            marks
            min={0}
            max={100}
            valueLabelDisplay="auto"
          />

          <h4 style = {{marginBottom: 40}}>Divider</h4>
          <Slider
            defaultValue={divider}
            onChange={(o, v) => {
              setDivider(v);
            }}
            aria-labelledby="discrete-slider"
            step={1}
            marks
            min={1}
            max={20}
            valueLabelDisplay="auto"
          />
          <div style={{ fontSize: 25 }}>
            <InlineMath
              math={
                "\\frac{\\color{blue}" +
                part +
                "}{\\color{red}" +
                divider +
                "} = \\color{green} " +
                whole +
                "\\frac{\\color{purple}" +
                remainder +
                "}{\\color{red}" +
                divider +
                "}"
              }
            />
            {g !== 1 && (
              <InlineMath
                math={
                  " = \\color{green} " +
                  whole +
                  "\\frac{\\color{purple}" +
                  remainder / g +
                  "}{\\color{red}" +
                  divider / g +
                  "}"
                }
              />
            )}
          </div>
          <div><p>
            Let's check if it all adds up!</p></div>
          <InlineMath style ={{fontSize: 25}}
                math={
                  " = \\color{green} (" +
                  whole +
                  ")(\\color{red}" +
                  divider +
                  ") \\color{black} + \\color{purple} " +
                  remainder  +
                  " = \\color{blue}" +
                  part 
                }
              />
        </Grid>
        <Grid item>
          <h4>All Parts</h4>
          <svg width={r * 2.2 * (divider + 1)} height={(whole + 2) * r * 2.2}>
            {partDots}
          </svg>
        </Grid>
        <Grid item>
          <h4>Remainder Only</h4>
          <svg width={r * 2.2 * (divider + 1)} height={(whole + 2) * r * 2.2}>
            <rect
              width={r * 2.2 * (divider + 1)}
              height={(whole + 2) * r * 2.2}
              fill={color(whole)}
              opacity={0.1}
            />
            {remainderDots}
          </svg>
        </Grid>
      </Grid>
    </>
  );
};

export default MixedNumber;
