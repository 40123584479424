import React, { useState, useEffect } from "react";
import { Grid, Slider, Button } from "@mui/material";
import "katex/dist/katex.min.css";
import { InlineMath } from "react-katex";
import JXG from "jsxgraph";

const BasicExponent = props => {
  const [base, setBase] = useState(2);
  const [exponent, setExponent] = useState(3);

  const baseArray = new Array(exponent).fill(base);

  const [board, setBoard] = useState(false);

  const makeGraph = () => {
    const max = Math.pow(base, 10) * 1.1;
    const brd = JXG.JSXGraph.initBoard("graph", {
      boundingbox: [-2, base <= 1 ? 3 : max, 11, base <= 1 ? -2 : (-1 * max) / 10],
      showCopyright: false,
      axis: true,
      showNavigation: false
    });

    brd.create(
      "functiongraph",
      [
        function(t) {
          return Math.pow(base, t);
        },
        0,
        11
      ],
      { strokeColor: "blue", fixed: false }
    );

    brd.create("point", [exponent, Math.pow(base, exponent)], {
      name: base + "," + Math.pow(base, exponent),
      visible: true,
      fixed: true
    });
  };

  useEffect(() => {
    makeGraph();
  });
  return (
    <>
      <Grid container spacing={5}>
        <Grid item xs={12} md={3}>
          <h4>Basic Exponent</h4>
          <h6 style = {{marginBottom : 40}}>Base</h6>
          <Slider
            defaultValue={base}
            onChange={(o, v) => {
              setBase(v);
            }}
            value = {base}
            aria-labelledby="discrete-slider"
            step={1}
            marks
            min={0}
            max={10}
            valueLabelDisplay="on"
          />
          <h6 style = {{marginBottom : 40}}>Exponent</h6>
          <Slider
            defaultValue={exponent}
            onChange={(o, v) => {
              setExponent(v);
            }}
            value = {exponent}
            aria-labelledby="discrete-slider"
            step={1}
            marks
            min={0}
            max={10}
            valueLabelDisplay="on"
          />
        </Grid>
        <Grid item style={{ fontSize: 25 }}>
          <InlineMath
            math={"\\color{red}" + base + "^{\\color{blue}" + exponent + "}"}
          />
          <InlineMath
            math={
              exponent === 0
                ? " = 1"
                : "\\color{black} = " + baseArray.join("\\times")
            }
          />
          <p>
            <InlineMath
              math={
                " = " +
                Math.pow(base, exponent) +
                (base === 0 && exponent === 0 ? " = 0 = ????" : "")
              }
            />
          </p>
          <div
            id="graph"
            //ref={linearGraphRef}
            style={{ width: board ? 300 : 0, height: board ? 300 : 0 }}
          />
          <p>
            <Button onClick={() => setBoard(!board)}>
              {board ? " hide " : " show "} graph
            </Button>
          </p>
        </Grid>
      </Grid>
    </>
  );
};

export default BasicExponent;
