import React, { useState } from "react";
import { Grid, Slider, Button } from "@mui/material";
import "katex/dist/katex.min.css";
import { InlineMath } from "react-katex";
import { scaleOrdinal, schemeCategory10 } from "d3";
import { gcd } from "../Tools/functions";

const OneStepMultEq = props => {
  const [m, setM] = useState(2);
  const [x, setX] = useState([5, 0]);
  const [product, setProduct] = useState(m * x[0]);
  const total = product + x[1];
  const buttonArray = new Array(10).fill(x);
  const [lastChanged, setLastChange] = useState(1);
  const color = scaleOrdinal(schemeCategory10);
  const [show, setShow] = useState(true);

  const changeX = v => {
    setX([v, 0]);
    setProduct(v * m);
    setLastChange(0);
  };

  const changeProduct = v => {
    const floor = Math.floor(v / m);
    const remainder = v % m;
    setX([floor, remainder]);
    setProduct(floor * m);
    setLastChange(1);
  };

  const Circle = props => {
    const i = props.i;
    const r = props.r || 6;
    const fill = color(Math.floor(i / m)) || "black";
    const opacity = 1;

    return (
      <svg width={2.2 * r} height={2.2 * r}>
        <circle cx={r} cy={r} r={r} fill={fill} opacity={opacity} />
      </svg>
    );
  };

  const totalCircleArray = new Array(total).fill(m);

  const circles = totalCircleArray.map((v, i) => {
    return (
      <span key={"circle-" + i}>
        {" "}
        {i % m === 0 && <br />}
        <Circle i={i} key={"circle-" + i} />
      </span>
    );
  });

  const buttons = buttonArray.map((v, i) => {
    return (
      <span
        key={"button-" + "-" + i}
        style={{
          margin: 1
        }}
      >
        <Button onClick={() => changeX(i)} variant="outlined">
          {" "}
          {i}{" "}
        </Button>{" "}
      </span>
    );
  });

  const g = gcd(x[1], m);
  console.log("g", g);

  const reduced =
    total % m !== 0 ? "\\frac{" + x[1] / g + "}{" + m / g + "}" : null;

  return (
    <>
      <Grid container spacing={5}>
        <Grid item xs={12} md={3}>
          <h2>Solve One Step Equation</h2>
          <h6>
            Value of Unknown <InlineMath math={"\\color{red} x"} />
          </h6>
          {buttons}
          <h6>M</h6>
          <Slider
            defaultValue={m}
            value = {m}
            onChange={(o, v) => {
              setM(v);
            }}
            aria-labelledby="discrete-slider"
            step={1}
            marks
            min={0}
            max={10}
            valueLabelDisplay="on"
          />
        </Grid>
        <Grid item style={{ fontSize: 25 }}>
          <InlineMath
            math={
              "\\color{purple}" +
              m +
              "\\color{red} x  \\color{black} = " +
              total
            }
          />
          {show && (
            <div>
              <p>
                <InlineMath
                  math={
                    "\\color{red} x \\color{black}= \\frac{" +
                    total +
                    "}{\\color{purple}" +
                    m +
                    "}"
                  }
                />
              </p>
              {total % m !== 0 && (
                <p>
                  <InlineMath
                    math={
                      "\\color{red} x \\color{black}=  \\frac{" +
                      Math.floor(total / m) * m +
                      "  +  " +
                      (total % m) +
                      "}{" +
                      m +
                      "}"
                    }
                  />
                </p>
              )}
              {total % m !== 0 && (
                <p>
                  <InlineMath
                    math={
                      "\\color{red} x \\color{black}=  \\frac{" +
                      Math.floor(total / m) * m +
                      "}{" +
                      m +
                      "} + \\frac{" +
                      +(total % m) +
                      "}{" +
                      m +
                      "}"
                    }
                  />
                </p>
              )}
              <p>
                <InlineMath
                  math={
                    "\\color{red} x \\color{black}= " +
                    Math.floor(total / m) +
                    (total % m === 0
                      ? " "
                      : " + \\frac{" + (total % m) + "}{" + m + "}")
                  }
                />
              </p>
              {reduced && (
                <p>
                  <InlineMath
                    math={
                      "\\color{red} x \\color{black}= " +
                      Math.floor(total / m) +
                      reduced
                    }
                  />
                </p>
              )}
            </div>
          )}
          <p>
            <Button
              size="small"
              onClick={() => setShow(!show)}
              variant="outlined"
            >
              {show ? "hide" : "show"} work
            </Button>
          </p>
          <hr />
          {show && (
            <div>
              check:
              <p>
                <InlineMath
                  math={
                    "\\color{purple} " +
                    m +
                    " \\color{black} \\times (\\color{red}" +
                    x[0] +
                    (total % m === 0
                      ? " "
                      : " + \\frac{" + (total % m) + "}{" + m + "}") +
                    " ) \\color{black} = " +
                    m * x[0] +
                    (total % m === 0
                      ? " "
                      : " + \\color{purple}" +
                        m +
                        "\\color{black} \\times \\frac{" +
                        (total % m) +
                        "}{" +
                        m +
                        "} = " +
                        (m * x[0] + " + " + x[1]) +
                        " = " +
                        total)
                  }
                />
              </p>
            </div>
          )}
        </Grid>
        <Grid item xs={4}>
          {circles}
        </Grid>
      </Grid>
      <h6>Total</h6>
      <Slider
        key={"total-slider-" + (lastChanged === 0 ? total : "")}
        defaultValue={total}
        value = {total}
        onChange={(o, v) => {
          changeProduct(v);
        }}
        aria-labelledby="discrete-slider"
        step={1}
        marks
        min={0}
        max={100}
        valueLabelDisplay="on"
      />
    </>
  );
};

export default OneStepMultEq;
