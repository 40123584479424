import React, { useState } from "react";
import { Grid, Slider } from "@mui/material";
import { InlineMath } from "react-katex";
import { Buttons } from  "../Tools/components";
import { scaleOrdinal, schemeCategory10 } from "d3";

const Bases = props => {

  const [base, setBase] = useState(10);
  const [base0, setBase0] = useState(3);
  const [base1, setBase1] = useState(2);
  const [base2, setBase2] = useState(4);
  const [base3, setBase3] = useState(0);
  const color = scaleOrdinal(schemeCategory10);
  color(-1)

  const b3 = base3 > 0 ? base3 : "\\enspace";
  const b2 =
    base2 + base3 > 0 ? "\\color{" + color( base2 - 1) + "}" + base2 : "\\enspace";
  const b1 = base2 + base3 + base1 > 0 ? "\\color{blue}" + base1 : "\\enspace";
  const digits = b3 + b2 + b1 + "\\color{green}" + base0;
  const baseArray = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];

  const [currentValue, setCurrentValue] = useState(
    base0 + base1 * base + base2 * base * base
  );

  //console.log("parse", currentValue, base, parseInt(currentValue, base), 21..toString(2))
  const setAll = newBase => {
    const value = parseInt(currentValue, base); // base 10
    const string = value.toString(newBase);
  };
  const baseButtons = (
    <Buttons buttonArray={baseArray} func={setBase} keyString="base" />
  );

  const onesArray = new Array(base0).fill(1);
  const tensArray = new Array(base1).fill(base);
  const hundredsArray = new Array(base2).fill(base * base);

  const horizontalDots = (array, fill, v) => {
    const a = Array.isArray(array) ? array : new Array(array).fill(v);
    return a.map((v, i) => {
      const r = 6;
      return (
        <circle
          cx={r + 2.2 * i * r}
          cy={r}
          fill={fill || "green"}
          key={"dot-zero-" + i}
          r={r}
        />
      );
    });
  };

  const onesDots = horizontalDots(onesArray);

  const tenDots = tensArray.map((v, i) => {
    //const y = 10;
    return (
      <g
        transform={"translate(0, " + (i + 2.2) * 15 + ")"}
        key={"ten-group-" + i}
      >
        <rect
          width={6 + 2.2 * 10 * 6}
          height={12}
          fill={"blue"}
          opacity={0.3}
        />
        {horizontalDots(10, "blue")}
      </g>
    );
  });

  const hundredArray = new Array(100).fill(1);

  const hundredDots = color => (
    <g transform={"translate(0, 200)"}>
      <rect width={138} height={140} fill="red" opacity={0.2} />
      {hundredArray.map((v, i) => {
        const r = 6;
        return (
          <circle
            cx={r + 2.2 * (i % 10) * r}
            cy={r + 2.2 * Math.floor(i / 10) * r}
            key={"dot-100-" + i}
            r={r}
            fill={color}
          />
        );
      })}{" "}
    </g>
  );

  const hundredsDots = hundredsArray.map((v, i) => {
    return (
      <g
        transform={"translate(" + i * 10 + ", " + i * 10 + ")"}
        key={"hundred-group-" + i}
      >
        {hundredDots(color(i))}
      </g>
    );
  });

  return (
    <>
      <Grid container spacing={5}>
        <Grid item xs={12} md={3}>
          <h2>Decimal - Powers of Ten</h2>
          <h6 style = {{marginBottom : 40}}>Ones</h6>
          <Slider
            defaultValue={base0}
            onChange={(o, v) => {
              setBase0(v);
            }}
            value = {base0}
            aria-labelledby="discrete-slider"
            step={1}
            marks
            min={0}
            max={base - 1}
            valueLabelDisplay="on"
          />
          <h6 style = {{marginBottom : 40}}>Tens</h6>
          <Slider
            defaultValue={base1}
            onChange={(o, v) => {
              setBase1(v);
            }}
            value = {base1}
            aria-labelledby="discrete-slider"
            step={1}
            marks
            min={0}
            max={base - 1}
            valueLabelDisplay="on"
          />

          <h6 style = {{marginBottom : 40}}>Hundreds</h6>
          <Slider
            defaultValue={base2}
            onChange={(o, v) => {
              setBase2(v);
            }}
            value = {base2}
            aria-labelledby="discrete-slider"
            step={1}
            marks
            min={0}
            max={base - 1}
            valueLabelDisplay="on"
          />
        </Grid>
        <Grid item style={{ fontSize: 25 }}>
          <InlineMath math={" " + digits} />

          <p>
            <InlineMath
              math={
                " = \\color{" +
                color(base2 - 1) +
                "}" +
                base2 * 100 +
                " \\color{black} + \\color{blue} " +
                base1 * 10 +
                "\\color{black} + \\color{green}" +
                base0
              }
            />
          </p>
          <hr />
          <p>
            <InlineMath
              math={
                " \\color{" +
                color(base2 - 1) +
                "}" +
                base2 +
                " \\times 100  = \\color{" +
                color(base2 - 1) +
                "}" +
                base2 +
                " \\times 10^2 "
              }
            />
          </p>
          <p>
            <InlineMath
              math={
                "\\color{blue}" +
                base1 +
                " \\times 10  = \\color{blue}" +
                base1 +
                " \\times 10^1 "
              }
            />
          </p>
          <p>
            <InlineMath
              math={
                "\\color{green}" +
                base0 +
                " \\times 1  = \\color{green}" +
                base0 +
                " \\times 10^0 "
              }
            />
          </p>
        </Grid>
        <Grid item>
          <svg width={300} height={500}>
            {onesDots}
            {tenDots}
            {hundredsDots}
          </svg>
        </Grid>
      </Grid>{" "}
    </>
  );
};

export default Bases;
