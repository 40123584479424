export const demoList = [
  {
    title: "Basic Exponent",
    link: "basic-exponent",
    math: "\\color{red} 2^ \\color{blue} 3",
  },
  {
    title: "Basic Exponent Multiply",
    link: "basic-exponent-multiply",
    math: "\\color{red} 2^ {\\color{blue} 3 } \\color{black} \\times \\color{red} 2^ \\color{green} 4 ",
  },
  {
    title: "Basic Exponent Divide",
    link: "basic-exponent-divide",
    math:
      "\\frac{ \\color{red} 2^ \\color{blue} 3 }{ \\color{red} 2^ \\color{green} 4 } "
  },
  {
    title: "Mixed Number",
    link: "mixed-number",
    math: "4 \\frac{3}{5} = \\frac{23}{5}",
  },

  {
    title: "Add Place Value",
    link: "add-place-values",
    math: "123.456 + 654.321",
  },
  {
    title: "Subtract Place Value",
    link: "subtract-place-values",
    math: "123.456 - 654.321",
  },
  {
    title: "Tenths, Hundredths, Thousandths",
    link: "tenths",
    math: "0.\\color{blue} 2 \\color{red}3 \\color{green} 4",
  },
  {
    title: "Base N",
    link: "bases",
    math: "\\color{blue} 2 \\color{black} \\times  N^{\\color{red}3} ",
  },
  {
    title: "Base 10",
    link: "base10",
    math: "\\color{blue} 2 \\color{black} \\times  10^{\\color{red}3} ",
  },
  {
    title: "Add on Number Line",
    link: "add-number-line",
    math: "\\color{blue}3 + \\color{red} 7 =  10",
  },
  {
    title: "Subtract on Number Line",
    link: "subtract-number-line",
    math: "\\color{blue}10 - \\color{red} 7 =  3",
  },
  {
    title: "Add and Subtract on Number Line",
    link: "add-subtract-number-line",
    math: "\\color{green}8 \\color{black} +  \\color{red} 2 \\color{black} = 10",
  },
  {
    title: "Add and Subtract Basic Number Line",
    link: "add-subtract-basics",
    math: "\\color{red} 2 \\color{black}  \\times \\color{blue}3 \\color{black} = \\color{green} 6 ",
  },
  {
    title: "Linear Change with Rectangles",
    link: "delta-w-rectangle",
    math: "\\Delta A = l \\times \\Delta w",
  },
  {
    title: "Quadratic Change with Squares",
    link: "delta-w-square",
    math: "\\Delta A = ( \\Delta w )^2",
  },
  {
    title: "Multiply and Divide",
    link: "multiply-divide-inverse",
    math: "\\color{green}3 \\color{black} \\times \\color{red} 4 \\color{black} = 12",
  },
  {
    title: "Divide By Fraction",
    link: "divide-by-fraction",
    math: "\\frac{1}{ \\frac{1}{2}} ",
  },
  {
    title: "Pi Radians",
    link: "2-pi-radians",
    math: "2 \\pi ",
  },
  {
    title: "Simplify Positive and Negative Addition",
    link: "positive-negative-cancel-addition",
    math: "\\color{green}8 \\color{black} +  \\color{red} -4 \\color{black} = 4",
  },
  {
    title: "Basic Number Line",
    link: "basic-number-line",
    math: "\\color{blue}x =  10",
  },
  {
    title: "Perimeter vs Area",
    link: "area-perimeter",
    math: "\\color{blue}10 - \\color{red} 7 =  3",
  },
  {
    title: "Multiply by Grid",
    link: "multiply-grid",
    math: "\\color{blue}10 \\color{black} \\times  \\color{red} 7 =  70",
  },
  {
    title: "Dimensions",
    link: "dimensions",
    math: "2, \\enspace x, \\enspace x + y , \\enspace x + y + z  ",
  },
  {
    title: "Linear Graph and Object",
    link: "linear-graph-object",
    math: "\\color{blue}y \\color{black} = \\color{red} m \\color{black} x + \\color{green}b",
  },
  {
    title: "Visualize Irrational Square Root",
    link: "visualize-square-root",
    math: "\\sqrt{2}",
  },
  {
    title: "Line to Rectangle",
    link: "line-to-rectangle",
    math: "y = \\color{red}2\\color{black} x + \\color{green} b",
  },
  {
    title: "Multiply Objects with Signs",
    link: "multiply-signed-objects",
    math: "\\color{green} -2 \\color{back} \\times \\color{blue}3 \\color{black} = \\color{red} -6",
  },
  {
    title: "Direct Ratios",
    link: "direct-ratios",
    math: "\\frac{\\color{green}y}{\\color{blue}x} \\color{black} = \\color{red} C",
  },
  {
    title: "Square Root Radicals",
    link: "square-root-radicals",
    math: "\\color{red} \\sqrt{\\color{blue} \\bullet \\bullet} ",
  },
  {
    title: "Common Denominator",
    link: "common-denominator",
    math: "\\frac{1}{\\color{red} 2 } \\color{black} + \\frac{2}{\\color{green} 3 }   ",
  },
  {
    title: "Divide Division",
    link: "divide-division",
    math: "\\frac{1}{\\color{red} 2 } \\color{black} \\times \\frac{2}{\\color{green} 3 }   ",
  },
  {
    title: "LCD",
    link: "lcd-dots",
    math: "\\frac{1}{\\color{red} \\bullet } \\color{black} + \\frac{1}{\\color{green}  \\bullet \\bullet }   ",
  },
  {
    title: "Associative Property of Multiplication",
    link: "associative-property-multiplication",
    math: "\\color{red} \\bullet  \\color{black} \\times \\color{green}  \\bullet    ",
  },
  {
    title: "Statistical Outliers",
    link: "statistical-outliers",
    math: "\\color{red} 1.5 \\color{green}  IQR   ",
  },
  {
    title: "Delta Y Delta X",
    link: "delta-y-x",
    math: "\\frac{\\color{red} \\Delta Y}{\\color{blue} \\Delta X}  ",
  },
  {
    title: "Unit Circle",
    link: "unit-circle",
    math: "\\color{green}2  \\color{red} \\pi \\color{black} ( \\color{blue} r \\color{black} ) =  \\color{green}2  \\color{red} \\pi \\color{black} ( \\color{blue} 1 \\color{black} )",
  },
  {
    title: "Similar Triangle",
    link: "similar-triangle",
    math: "\\Delta \\color{red}A \\color{blue}B \\color{green}C",
  },
  {
    title: "Multiply Before Add",
    link: "multiply-before-add",
    math: "\\color{red}3 \\color{black} +   \\color{blue}3 \\color{black}( \\color{green}4  \\color{black})",
  },
  {
    title: "Long Divide Algorithm",
    link: "long-divide-algorithm",
    math: "\\color{red}337  \\color{black} \\div \\color{blue}6",
  },
  {
    title: "Rectangle Perimeter",
    link: "rectangle-perimeter",
    math: "p = \\color{red}2w  \\color{black} +  \\color{blue} 2l",
  },
  {
    title: "Linear and/or Arithmetic Sequence",
    link: "graphing-explained",
    math: "y = \\color{red}m \\color{green}x  \\color{black} +  \\color{blue} b",
  },
  {
    title: "Missing Length",
    link: "missing-length",
    math: "\\color{blue} 1 \\color{black} + \\color{red} ? \\color{black} + \\color{green} 3 \\color{black} = 6 ",
  },
  {
    title: "Reduce Fractions",
    link: "reduce-fraction",
    math: "\\frac{\\color{red} 12}{\\color{blue} 18} = \\frac{\\color{red} 2}{\\color{blue} 3}  ",
  },
  {
    title: "Expand Multiply",
    link: "expand-multiply",
    math: "\\color{blue} 221 \\color{black} \\times \\color{red} 103"
  },
  {
    title: "Finding Rectangular Areas",
    link: "finding-rectangular-areas",
    math:
      "\\color{blue} A_1 \\color{black} +  \\color{red} A_2  \\color{black} +  \\color{green} A_3"
  },
  {
    title: "Prime Factors",
    link: "prime-factors",
    math:
      "\\color{blue} 2 \\color{black} \\times \\color{red} 3  \\color{black} \\times  \\color{green} 5"
  },
  {
    title: "One Step Add Equation",
    link: "one-step-equation",
    math:
      "\\color{red}x \\color{black} + \\color{green} 2 \\color{black} = \\color{blue} 10"
  },
  {
    title: "One Step Multiply Equation",
    link: "one-step-multiply-equation",
    math: "\\purple 2\\color{red}x \\color{black}  = \\color{blue} 10"
  },
  {
    title: "Two Step Equation",
    link: "two-step-equation",
    math:
      "\\purple 4\\color{red}x \\color{black} + \\color{green} 2 \\color{black} = \\color{blue} 10"
  }
  
];
