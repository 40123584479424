import React, { useState, useEffect, useRef } from "react";
import { Grid, Button } from "@mui/material";

import { select, rgb, scaleLinear, interpolateHcl, sort, ascending } from "d3";
import "katex/dist/katex.min.css";
import { InlineMath } from "react-katex";
import range from "lodash/range";
import sampleSize from "lodash/sampleSize";
import random from "lodash/random";

const Outliers = props => {
  const [again, setAgain] = useState(false);
  const howMany = 10;
  const data = sampleSize(range(0, 20), howMany);

  const mode = data[random(1, howMany - 2)];
  const boardRef = useRef();

  data.splice(random(9), 0, mode);

  const sorted = [...data];
  sorted.sort((a, b) => a - b);

  const iqr = sorted[howMany - 2] - sorted[2];

  let outlier = random(1 === 0) ? sorted[0] : sorted[howMany];
  let outlierIndex;
  if (outlier >= mode) {
    outlierIndex = howMany;
    const index = data.indexOf(sorted[howMany]);
    sorted[howMany] = Math.ceil(sorted[howMany - 2] + iqr * 1.6);
    data[index] = sorted[howMany];
    outlier = sorted[howMany];
  } else {
    outlierIndex = 0;
    const index = data.indexOf(sorted[0]);
    sorted[0] = Math.floor(sorted[2] - iqr * 1.6);
    data[index] = sorted[0];
    outlier = sorted[0];
  }

  const colors = scaleLinear()
    .interpolate(interpolateHcl)
    .domain([sorted[0], sorted[howMany]])
    .range([rgb("#00FFFF"), rgb("#FFF500")]);

  const widthScale = scaleLinear()
    .domain([sorted[2] - iqr * 1.8, sorted[howMany - 2] + iqr * 1.8])
    .range([0, 310]);

  /* const coloredData = sorted.map((v, i) => {
    return (
      <g key={"big-" + i}>
        <circle
          r={20}
          cx={((i % 6) + 1) * 41}
          cy={30 + 45 * Math.floor(i / 6)}
          fill={colors(v)}
        />
        <text x={((i % 6) + 1) * 41 - 10} y={33 + 45 * Math.floor(i / 6)}>
          {v}
        </text>
      </g>
    );
  }); */

  const smallDots1 = sorted.map((v, i) => {
    console.log(v, i, Math.floor(i / 5) + 1);
    return (
      <g key={"big-" + i}>
        <circle r={10} cx={(i + 1) * 25} cy={14} fill={colors(v)} />
        <text x={(i + 1) * 25 - 5} y={18} style={{ fontSize: 10 }}>
          {v}
        </text>
      </g>
    );
  });

  useEffect(() => {
    const svg = select(boardRef.current);
    svg.selectAll("circle").remove();
    svg.selectAll("text").remove();
    svg.selectAll("line").remove();
    const circle = svg.selectAll("circle");
    const text = svg.selectAll("text");

    // unsorted not scaled
    circle
      .data(data)
      .enter()
      .append("circle")
      .attr("fill", d => colors(d))
      .attr("r", 11)
      .attr("cx", (d, i) => (i + 1) * 25)
      .attr("cy", 14)
      .transition()
      .duration(500);

    text
      .data(data)
      .enter()
      .append("text")
      .attr("x", (d, i) => (i + 1) * 25 - 8)
      .attr("y", 19)
      .text(v => v)
      .attr("font-size", 12)
      .transition()
      .duration(500);

    // sorted not scaled
    svg
      .append("text")
      .attr("y", 40)
      .text("sorted");

    circle
      .data(sorted)
      .enter()
      .append("circle")
      .attr("fill", d => colors(d))
      .attr("r", 11)
      .transition()
      .duration(500)
      .attr("cx", (d, i) => (i + 1) * 25)
      .attr("cy", 60)
      .attr("stroke", (d, i) =>
        i === 2 || i === 5 || i === 8
          ? "black"
          : i === outlierIndex
          ? "red"
          : false
      )
      .attr("stroke-width", 2);

    text
      .data(sorted)
      .enter()
      .append("text")
      .attr("x", (d, i) => (i + 1) * 25 - 8)
      .attr("y", 63)
      .text(v => v)
      .attr("font-size", 12)
      .transition()
      .duration(500);

    // sorted  scaled
    svg
      .append("text")
      .attr("y", 90)
      .text(
        "scaled across range of " +
          (sorted[howMany] - sorted[0]) +
          " (" +
          sorted[howMany] +
          " - " +
          sorted[0] +
          ")"
      );
    svg.append("g").attr("id", "scaled-circles");

    const scaledCircles = svg
      .select("#scaled-circles")
      .selectAll("circle")
      .data(sorted)
      .enter()
      .append("circle")
      .attr("fill", (d, i) => colors(d))
      .attr("r", 6)
      .transition()
      .duration(500)
      .attr("cx", d => widthScale(d))
      .attr("cy", 105)
      .attr("stroke", (d, i) =>
        i === 2 || i === 5 || i === 8
          ? "black"
          : i === outlierIndex
          ? "red"
          : false
      )
      .attr("opacity", (d, i) =>
        i === 2 || i === 5 || i === 8 ? "1" : i === outlierIndex ? "1" : 0.6
      )
      .attr("stroke-width", 2);

    svg
      .append("line")
      .attr("stroke", "purple")
      .attr("strokeWidth", 1)
      .attr("x1", widthScale(sorted[howMany - 2] + iqr * 1.5))
      .attr("x2", widthScale(sorted[howMany - 2] + iqr * 1.5))
      .attr("y1", 95)
      .attr("y2", 115);

    svg
      .append("text")
      .attr("x", 217)
      .attr("y", 130)
      .text("upper cutoff")
      .attr("fill", "purple");

    svg
      .append("text")
      .attr("x", 212)
      .attr("y", 150)
      .text("Q3 + 1.5(IQR)")
      .attr("fill", "purple");

    svg
      .append("text")
      .attr("x", 210)
      .attr("y", 170)
      .text("= " + sorted[howMany - 2] + " + 1.5(" + iqr + ")")
      .attr("fill", "purple");

    svg
      .append("text")
      .attr("x", 250)
      .attr("y", 190)
      .text("= " + (sorted[howMany - 2] + 1.5 * iqr))
      .attr("fill", "purple");

    svg
      .append("text")
      .attr("x", 0)
      .attr("y", 170)
      .text("= " + sorted[2] + "  - 1.5(" + iqr + ")")
      .attr("fill", "purple");
    svg
      .append("text")
      .attr("x", 0)
      .attr("y", 190)
      .text("= " + (sorted[2] - 1.5 * iqr))
      .attr("fill", "purple");

    svg
      .append("text")
      .attr("x", 0)
      .attr("y", 150)
      .text("Q1  - 1.5(IQR)")
      .attr("fill", "purple");

    svg
      .append("text")
      .attr("x", 0)
      .attr("y", 130)
      .text("lower cutoff")
      .attr("fill", "purple");

    svg
      .append("line")
      .attr("stroke", "purple")
      .attr("strokeWidth", 1)
      .attr("x1", widthScale(sorted[2] - iqr * 1.5))
      .attr("x2", widthScale(sorted[2] - iqr * 1.5))
      .attr("y1", 95)
      .attr("y2", 115);
  }, [again]);

  return (
    <>
      {" "}
      <h3>Statistical Outliers</h3>
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => setAgain(!again)}
        style={{ marginBottom: 5 }}
      >
        Try again
      </Button>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} style={{ fontSize: 20 }}>
          <u>Data Set</u> <br />
          <InlineMath math={data.join(", ")} />
          <p>
            {" "}
            <InlineMath
              math={
                "\\color{green}" +
                "Q_{1} = " +
                sorted[2] +
                " \\enspace Q_{2} = " +
                sorted[5] +
                "  \\enspace Q_{3} = " +
                sorted[8]
              }
            />
          </p>
          <p>
            {" "}
            Range (max - min):{" "}
            <InlineMath
              math={
                "\\color{blue}" +
                sorted[10] +
                " - " +
                (sorted[0] < 0 ? "(" : "") +
                sorted[0] +
                (sorted[0] < 0 ? ")" : "") +
                " = " +
                (sorted[10] - sorted[0])
              }
            />
          </p>
          <p style={{ color: "purple" }}>
            <InlineMath
              math={
                "IQR = Q_3 - Q_1  =  " +
                sorted[8] +
                " - " +
                sorted[2] +
                " = " +
                (sorted[8] - sorted[2])
              }
            />
          </p>
          <p style={{ color: "purple" }}>
            {" "}
            Lower Cutoff: <InlineMath math={"Q_1 - 1.5IQR  "} />
            <br />
            <InlineMath
              math={
                "= " +
                sorted[2] +
                " -  1.5(" +
                iqr +
                ") = " +
                (sorted[2] - 1.5 * iqr)
              }
            />
          </p>
          <p style={{ color: "purple" }}>
            {" "}
            Upper Cutoff: <InlineMath math={"Q_3 + 1.5IQR  "} />
            <br />
            <InlineMath
              math={
                "= " +
                sorted[8] +
                " +  1.5(" +
                iqr +
                ") = " +
                (sorted[8] + 1.5 * iqr)
              }
            />
          </p>
          <p style={{ color: "red" }}>
            Outlier: <InlineMath>{outlier + " "}</InlineMath>
          </p>
        </Grid>
        <Grid item xs={12} md={6}>
          <u>Data View</u>
          <br />
          <svg width={310} height={200} ref={boardRef}></svg>
          <br />
          mean{" "}
          <span style={{ fontSize: 10 }}>
            ( sum divided by number of data points )
          </span>
          :{" "}
          <InlineMath
            math={
              " " +
              Math.round((data.reduce((sum, x) => sum + x) * 100) / 11) / 100
            }
          />
          <p>mode  <span style={{ fontSize: 10 }}>
            ( occurs the most ) 
          </span> <InlineMath math = {" " + mode } /></p>

          <p>median  <span style={{ fontSize: 10 }}>
            ( in the middle) 
          </span> <InlineMath math = {" " + sorted[5] } /></p>
        </Grid>
      </Grid>
      {}
    </>
  );
};

export default Outliers;
