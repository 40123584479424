import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Slider,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel
} from "@mui/material";
import "katex/dist/katex.min.css";
import { InlineMath } from "react-katex";

const MissingLength = props => {
  const [x1, setX1] = useState(1);
  const [x2, setX2] = useState(2);
  const [x3, setX3] = useState(3);
  const missingArray = [x1, x2, x3];
  const [missing, setMissing] = useState(2);
  const [show, setShow] = useState(false);
  const [buttonSelected, setButtonSelected] = useState(2);

  const total = x1 + x2 + x3;

  const v1 = missing === 1 ? 0 : x1;
  const v2 = missing === 2 ? 0 : x2;
  const v3 = missing === 3 ? 0 : x3;

  const otherTwo = v1 + v2 + v3;

  const changeTotal = v => {
    
    if (missing === 1) setX1(v - x2 - x3);
    if (missing === 2) setX2(v - x1 - x3);
    if (missing === 3) setX3(v - x2 - x1);
  };

  const yOffset = 25;
  const length1 = <rect y={yOffset} width={x1 * 10} height={10} fill={"red"} />;
  const length2 = (
    <rect y={yOffset} x={x1 * 10} width={x2 * 10} height={10} fill={"blue"} />
  );
  const length3 = (
    <rect
      y={yOffset}
      x={x1 * 10 + x2 * 10}
      width={x3 * 10}
      height={10}
      fill={"green"}
    />
  );

  const buttonArray = new Array(11).fill(false);

  const handleButton = i => {
    
    setButtonSelected(i);
    
  };

  const handleChange = event => {
    setMissing(parseInt(event.target.value));
    setButtonSelected(null);
    //setValue(event.target.value);
  };

  const buttons = item =>
    buttonArray.map((v, i) => {
      return (
        <span key={"button-" + item + "-" + i} style={{ margin: 1 }}>
          <Button
            onClick={() => handleButton(i)}
            variant={buttonSelected === i ? "contained" : "outlined"}
          >
            {i}
          </Button>
        </span>
      );
    });

  const radios = (
    <FormControl component="fieldset">
      <FormLabel component="legend">Missing</FormLabel>
      <RadioGroup
        aria-label="missing"
        name="missing"
        value={missing}
        onChange={handleChange}
        row
      >
        <FormControlLabel value={1} control={<Radio />} label="First Part" />
        <FormControlLabel value={2} control={<Radio />} label="Second  Part" />
        <FormControlLabel value={3} control={<Radio />} label="Third  Part" />
      </RadioGroup>
    </FormControl>
  );


  const yourAnswer = buttonSelected !==null && <text x = {20} y = {80} >Your Answer</text>
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          {missing !== 1 ? (
            <div>
              <h6>Length 1</h6>
              <Slider
                defaultValue={x1}
                onChange={(o, v) => {
                  setX1(v);
                  setButtonSelected(null);
                }}
                value={x1}
                aria-labelledby="discrete-slider"
                step={1}
                marks
                min={0}
                max={10}
                valueLabelDisplay="on"
              />
            </div>
          ) : (
            <div>
              <h6>Select Length 1</h6>
              {buttons(1)}{" "}
            </div>
          )}
          {missing !== 2 ? (
            <div>
              <h6>Length 2</h6>
              <Slider
                defaultValue={x2}
                onChange={(o, v) => {
                  setX2(v);
                  setButtonSelected(null);
                }}
                value={x2}
                aria-labelledby="discrete-slider"
                step={1}
                marks
                min={0}
                max={10}
                valueLabelDisplay="on"
              />
            </div>
          ) : (
            <div>
              <h6>Select Length 2</h6>
              {buttons(2)}{" "}
            </div>
          )}
          {missing !== 3 ? (
            <div>
              <h6>Length 3</h6>
              <Slider
                defaultValue={x3}
                onChange={(o, v) => {
                  setX3(v);
                  setButtonSelected(null);
                }}
                value={x3}
                aria-labelledby="discrete-slider"
                step={1}
                marks
                min={0}
                max={10}
                valueLabelDisplay="on"
              />
            </div>
          ) : (
            <div>
              <h6>Select Length 3</h6>
              {buttons(3)}{" "}
            </div>
          )}
          <h6>Total</h6>
          <Slider
            defaultValue={total}
            value={total}
            key={"total" + "-" + otherTwo}
            onChange={(o, v) => changeTotal(v)}
            aria-labelledby="discrete-slider"
            step={1}
            marks
            min={otherTwo}
            max={otherTwo + 10}
            valueLabelDisplay="on"
          />
        </Grid>
        <Grid item>
          {radios}
          <br />
          <svg width={310} height={200}>
            {length1} {length2} {length3}
            <text fill ="red" x={x1 * 5 - 1} y={30 + yOffset} fontSize={20}>
              {missing !== 1 ? x1 : " ? "}
            </text>
            <text x={x1 * 10 + x2 * 5 - 1} y={yOffset - 10} fontSize={20} fill ="blue">
              {missing !== 2 ? x2 : " ? "}
            </text>
            <text
              x={x1 * 10 + x2 * 10 + x3 * 5 - 1}
              y={30 + yOffset}
              fontSize={20}
              fill ="green"
            >
              {missing !== 3 ? x3 : " ? "}
            </text>
            {buttonSelected}
          </svg>
          <span style = {{fontSize: 15}} ><h6>Question</h6></span>
          <p style={{ fontSize: 25 }}>
            
            <InlineMath
              math={
                "\\color{red}" +
                (missing === 1 ? "?" : x1) +
                "\\color{black}  \\enspace + \\color{blue} \\enspace" +
                (missing === 2 ? "?" : x2) +
                "\\color{black} \\enspace  + \\enspace \\color{green}" +
                (missing === 3 ? "?" : x3) +
                " \\color{black} \\enspace = \\enspace" +
                total
              }
            />
          </p>
          <span style = {{fontSize: 15}} ><h6>Your Answer</h6></span>
          {buttonSelected !== null  && <p style={{ fontSize: 25 }}>
          
            <InlineMath
              math={
                "\\color{red}" +
                (missing === 1 ? buttonSelected : x1) +
                "\\color{black}  \\enspace + \\color{blue} \\enspace" +
                (missing === 2 ? buttonSelected : x2) +
                "\\color{black} \\enspace  + \\enspace \\color{green}" +
                (missing === 3 ? buttonSelected : x3) +
                " \\color{black} \\enspace = \\enspace" +
                (otherTwo + buttonSelected)
              }
            />
          </p>}
        </Grid>
      </Grid>
    </>
  );
};

export default MissingLength;
