import { Box, ThemeProvider, createTheme } from "@mui/material/styles";
import { grey, blue , purple} from '@mui/material/colors';
export const theme = createTheme({
  palette: {
    primary: { light: blue[300], main: blue[500], dark: blue[700] },
    secondary: { light: purple[300], main: purple[500], dark: purple[700] },
    
  },
  typography: {
    fontFamily: "Raleway, Arial",
    button: {
      color: "black"
    },
    color : "black"
  },
});

export const simpleCss = {
  color: "black",
  textDecoration: "none"
}
