import React from "react";
import JXG from "jsxgraph";

const RiemannSum = () => {
  const makeGraph = () => {
    //const max = Math.pow(base, 10) * 1.1;
    const brd1 = JXG.JSXGraph.initBoard("riemann-graph", {
      boundingbox: [-2, 3, 7, -3],
      showCopyright: false,
      axis: true,
      showNavigation: false,
    });

    const a = brd1.create(
      "slider",
      [
        [1, 2],
        [5, 2],
        [-1, 0, 2],
      ],
      { name: "start" }
    );

    const s = brd1.create(
      "slider",
      [
        [1, 2.5],
        [5, 2.5],
        [1, 10, 50],
      ],
      { name: "n", snapWidth: 1 }
    );
    const b = brd1.create(
      "slider",
      [
        [1, 1.5],
        [5, 1.5],
        [0, 2 * Math.PI, 10],
      ],
      { name: "end" }
    );
    const f = function (x) {
      return Math.sin(x);
    };
    const plot = brd1.create("functiongraph", [
      f,
      function () {
        return a.Value();
      },
      function () {
        return b.Value();
      },
    ]);

    const os = brd1.create(
      "riemannsum",
      [
        f,
        function () {
          return s.Value();
        },
        function () {
          return "left";
        },
        function () {
          return a.Value();
        },
        function () {
          return b.Value();
        },
      ],
      { fillColor: "#ffff00", fillOpacity: 0.3 }
    );

    brd1.create("text", [
      1,
      -2,
      function () {
        return (
          "Sum=" +
          JXG.Math.Numerics.riemannsum(
            f,
            s.Value(),
            "left",
            a.Value(),
            b.Value()
          ).toFixed(4)
        );
      },
    ]);
  };
  React.useEffect(() => {
    makeGraph();
  });
  return (
    <div>
      <div
        id="riemann-graph"
        //ref={linearGraphRef}
        style={{ width: 400, height: 600 }}
      />
    </div>
  );
};

export default RiemannSum;
