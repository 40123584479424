export const gcd = (a, b) => {
    if (!b) {
      return a;
    }
    return gcd(b, a % b);
  };


  export const factorToPrimes = num => {
    function is_prime(num) {
      for (let i = 2; i <= Math.sqrt(num); i++) {
        if (num % i === 0) return false;
      }
      return true;
    }
    const result = [];
    for (let i = 2; i <= num; i++) {
      while (is_prime(i) && num % i === 0) {
        result.push(i);
        num /= i;
      }
    }
    return result;
  };
  
  export const allFactors = number =>
    Array.from(Array(number + 1), (_, i) => i).filter(i => number % i === 0);
 