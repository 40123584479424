import React from "react";

const RadicalPath = ({length, height }) => {

    const l = length || 350;
    const h = height || 390;

    return <path
    d={
      "M" +
      (193.39 * h) / 390 +
      +(4.86 * h) / 390 +
      " l -" +
      (50.812 * h) / 390 +
      " " +
      (317.374 * h) / 390 +
      " -" +
      (79.094 * h) / 390 +
      " -" +
      (160.718 * h) / 390 +
      " -" +
      (58.78 * h) / 390 +
      " " +
      (29.468 * h) / 390 +
      " " +
      (6.624 * h) / 390 +
      " " +
      (12.5 * h) / 390 +
      " " +
      (38.688 * h) / 390 +
      " -" +
      (17.75 * h) / 390 +
      " " +
      (96.875 * h) / 390 +
      " " +
      (199.407 * h) / 390 +
      " " +
      (58.687 * h) / 390 +
      " -" +
      (366.282 * h) / 390 +
      "h" + l+
      "v -" +
      (14 * h) / 390 +
      "H" +
      (493.39 * h) / 390 +
      ""
    }
  ></path>
}

export default RadicalPath;