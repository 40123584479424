import React, { useEffect, useRef, useState } from "react";
//import NumberLine from "../../components/NumberLine";
//import withWidth from "@material-ui/core/withWidth";
import { select, scaleLinear, drag } from "d3";
import { Grid } from "@mui/material";
import "katex/dist/katex.min.css";
import { InlineMath } from "react-katex";

const DeltaYX = props => {
  const svgRef = useRef();
  const width = 300;
  const height = 300;

  const [dx, setDx] = useState(0);
  const [dy, setDy] = useState(0);
  const [x0, setX0] = useState(20);
  const [y0, setY0] = useState(20);

  const gcd = (a, b) => {
    if (!b) {
      return a;
    }
    return gcd(b, a % b);
  };

  const g = Math.abs(gcd(dy, dx));

  const mSign = -1 * dy * dx < 0 ? "-" : " ";

  const mAbs =
    Math.abs(dx / g) === 1
      ? "\\color{red} " + Math.abs(dy / g)
      : "\\frac{ \\color{red} " +
        Math.abs(dy / g) +
        "}{\\color{blue} " +
        Math.abs(dx / g) +
        "}";

  const mSimplified = mSign + mAbs;

  const handleDrag = drag()
    .subject(function(event) {
      const me = select(this);
      const svg = select(svgRef.current);
      svg.selectAll("text").remove();
      svg
        .append("text")
        .text(
          Math.round(event.x / 10) * 10 +
            " , " +
            (300 - Math.round(event.y / 10) * 10)
        )
        .attr("y", Math.round(event.y / 10) * 10 + 20)
        .attr("x", Math.round(event.x / 10) * 10);
      return {
        x: event.x,
        y: event.y,
        x1: Math.round(event.x / 10) * 10,
        y1: Math.round(event.y / 10) * 10
      };
    })
    .on("start", function(event) {
      const me = select(this);
      const svg = select(svgRef.current);
      svg.select("#clone").remove();
      const clone = svg.append("circle").attr("id", "clone");
      clone.attr("cx", Math.round(event.x / 10) * 10);
      clone.attr("cy", Math.round(event.y / 10) * 10);
      clone
        .attr("r", 10)
        .attr("fill", me.attr("fill"))
        .attr("opacity", 0.4);
    })
    .on("drag", function(event) {
      const me = select(this);
      const svg = select(svgRef.current);
      svg.selectAll("line").remove();
      svg.select("#delta-y").remove();
      svg.select("#delta-x").remove();
      svg
        .append("line")
        .attr("stroke", "green")
        .attr("stroke-width", 1)
        .attr("x1", Math.round(10 * event.subject.x1) / 10)
        .attr("x2", Math.round(10 * event.x) / 10)
        .attr("y1", Math.round(10 * event.subject.y1) / 10)
        .attr("y2", Math.round(10 * event.y) / 10);

      svg
        .append("line")
        .attr("stroke", "red")
        .attr("stroke-width", 1)
        .attr("x1", Math.round(10 * event.subject.x1) / 10)
        .attr("x2", Math.round(10 * event.subject.x1) / 10)
        .attr("y1", Math.round(10 * event.subject.y1) / 10)
        .attr("y2", Math.round(10 * event.y) / 10);

      svg
        .append("line")
        .attr("stroke", "blue")
        .attr("stroke-width", 1)
        .attr("x1", Math.round(10 * event.subject.x1) / 10)
        .attr("x2", Math.round(10 * event.x) / 10)
        .attr("y1", Math.round(10 * event.subject.y1) / 10)
        .attr("y2", Math.round(10 * event.subject.y1) / 10);

      svg
        .append("line")
        .attr("stroke", "red")
        .attr("stroke-width", 1)
        .attr("x1", Math.round(10 * event.x) / 10)
        .attr("x2", Math.round(10 * event.x) / 10)
        .attr("y1", Math.round(10 * event.subject.y1) / 10)
        .attr("y2", Math.round(10 * event.y) / 10);

      svg
        .append("line")
        .attr("stroke", "blue")
        .attr("stroke-width", 1)
        .attr("x1", Math.round(10 * event.subject.x1) / 10)
        .attr("x2", Math.round(10 * event.x) / 10)
        .attr("y1", Math.round(10 * event.y) / 10)
        .attr("y2", Math.round(10 * event.y) / 10);

      svg
        .append("text")
        .attr("id", "delta-y")
        .attr("x", Math.round(10 * event.x) / 10)
        .attr(
          "y",
          Math.round(
            10 * (event.subject.y1 + (event.y - event.subject.y1) / 2)
          ) / 10
        )
        .text(-1 * Math.round((event.y - event.subject.y1) / 10) * 10);

      svg
        .append("text")
        .attr("id", "delta-x")
        .attr(
          "x",
          Math.round(
            10 * (event.subject.x1 + (event.x - event.subject.x1) / 2)
          ) / 10
        )
        .attr("y", Math.round(10 * event.y) / 10)
        .text(Math.round((event.x - event.subject.x1) / 10) * 10);

      if (event.x > 10 && event.x < 300) me.attr("cx", event.x);
      if (event.y > 10 && event.y < 300) me.attr("cy", event.y);
    })
    .on("end", function(event) {
      const me = select(this);

      if (event.y > 20 && event.x < 290)
        me.attr("cx", Math.round(event.x / 10) * 10);
      if (event.y > 20 && event.y < 290)
        me.attr("cy", Math.round(event.y / 10) * 10);
      setDx(Math.round((event.x - event.subject.x1) / 10) * 10);
      setDy(Math.round((event.y - event.subject.y1) / 10) * 10);
      setX0(Math.round(event.subject.x / 10) * 10);
      setY0(Math.round(event.subject.y / 10) * 10);
      const svg = select(svgRef.current);
      svg
        .append("text")
        .text(
          Math.round(event.x / 10) * 10 +
            " , " +
            (300 - Math.round(event.y / 10) * 10)
        )
        .attr("y", Math.round(event.y / 10) * 10 + 10)
        .attr("x", Math.round(event.x / 10) * 10 + 10);
      svg.select("#mainCircle").remove();

      const lastX = event.x < 10 ? 10 : event.x > 290 ? 290 : event.x;
      const lastY = event.y < 10 ? 10 : event.y > 290 ? 290 : event.y;
      const mainCircle = svg.append("circle").attr("id", "mainCircle");
      mainCircle.attr("cx", lastX);
      mainCircle.attr("cy", lastY);
      mainCircle.attr("r", 10).attr("fill", "green");
      handleDrag(svg.selectAll("circle"));
    });

  useEffect(() => {
    const svg = select(svgRef.current)
      .attr("width", width)
      .attr("height", 300);

    const backRect = svg.append("rect").attr("id", "backRect");
    backRect.attr("width", width);
    backRect.attr("height", height);
    backRect.attr("stroke", "black");
    backRect.attr("stroke-width", 1);
    backRect.attr("fill", "#ddd");
    const mainCircle = svg.append("circle").attr("id", "mainCircle");
    mainCircle.attr("cx", x0);
    mainCircle.attr("cy", y0);
    mainCircle.attr("r", 10).attr("fill", "green");

    handleDrag(svg.selectAll("circle"));
  }, []);

  return (
    <>
      <h3>Rise over Run, Change of Y and Change of X</h3>
      <Grid container>
        <Grid item md={4} sm={12}>
          Drag the circle somewhere new <br />
          <svg ref={svgRef} />
          <br />
          <InlineMath
            math={"\\color{blue} 0 \\color{black} , \\color{red} 0 "}
          />{" "}
          bottom left corner is the origin
          <p>
            {" "}
            <InlineMath
              math={"\\color{blue} 0 \\color{black} , \\color{red} 300 "}
            />{" "}
            upper left corner
          </p>
          <p>
            {" "}
            <InlineMath
              math={"\\color{blue} 300 \\color{black} , \\color{red} 300 "}
            />{" "}
            upper right corner
          </p>
          <p>
            {" "}
            <InlineMath
              math={"\\color{blue} 300 \\color{black} , \\color{red} 0 "}
            />{" "}
            lower right corner
          </p>
        </Grid>
        <Grid item md={4} sm={12} style={{ fontSize: 25 }}>
          <InlineMath
            math={
              " \\frac{ \\color{red} \\Delta y}{\\color{blue} \\Delta x \\color{black} }=  \\frac{ \\color{red} " +
              "y_{2}" +
              " - " +
              "y_{1}" +
              "}{\\color{blue} " +
              "x_{2}" +
              " - " +
              "x_{1}}"
            }
          />

          <p>
            <InlineMath
              math={
                " \\frac{ \\color{red} \\Delta y}{\\color{blue} \\Delta x \\color{black} }=  \\frac{ \\color{red} " +
                (300 - y0) +
                " - " +
                (300 - (y0 + dy)) +
                "}{\\color{blue} " +
                (x0 + dx) +
                " - " +
                x0 +
                " \\color{black} } =  \\frac{ \\color{red} " +
                -1 * dy +
                "}{\\color{blue} " +
                dx +
                " \\color{black} } "
              }
            />
          </p>

          <p>
            <InlineMath
              math={"y_{1} + \\color{red} \\Delta y \\color{black} = y_{2}"}
            />
          </p>
          <p>
            <InlineMath
              math={
                300 -
                y0 +
                " + \\color{red} " +
                -1 * dy +
                " \\color{black} = " +
                (300 - (y0 + dy))
              }
            />
          </p>

          <p>
            <InlineMath
              math={"x_{1} + \\color{blue} \\Delta x \\color{black} = x_{2}"}
            />
          </p>
          <p>
            <InlineMath
              math={
                x0 + " + \\color{blue} " + dx + " \\color{black} = " + (x0 + dx)
              }
            />
          </p>
        </Grid>

        <Grid item md={4} sm={12} style={{ fontSize: 25 }}>
          <InlineMath
            math={
              "( \\color{blue} x_{1} \\color{black}, \\color{red} y_{1} \\color{black}) = ( \\color{blue} " +
              x0 +
              "\\color{black}, \\color{red} " +
              (300 - y0) +
              " \\color{black})"
            }
          />
          <p>
            <InlineMath
              math={
                "( \\color{blue} x_{2} \\color{black}, \\color{red} y_{2} \\color{black}) = ( \\color{blue} " +
                (x0 + dx) +
                "\\color{black}, \\color{red} " +
                (300 - (y0 + dy)) +
                " \\color{black})"
              }
            />
          </p>

          <p>
            Slope:{" "}
            {dx !== 0 ? (
              <InlineMath math={"\\enspace m = " + mSimplified} />
            ) : (
              <span>
                Undefined <InlineMath math={"\\color{blue}dx = 0 "} />
              </span>
            )}
          </p>

          <p>
            <InlineMath math={"\\color{red} \\Delta y = " + -1 * dy} /> Change
            of y
          </p>
          <p>
            <InlineMath math={"\\color{blue} \\Delta x = " + dx} /> Change of x
          </p>

          <p style={{ fontSize: 15 }}>
            Moving left means <InlineMath math={"\\color{blue} \\Delta x  "} />{" "}
            is negative
          </p>
          <p style={{ fontSize: 15 }}>
            Moving right means <InlineMath math={"\\color{blue} \\Delta x  "} />{" "}
            is positive
          </p>
          <p style={{ fontSize: 15 }}>
            Moving down means <InlineMath math={"\\color{red} \\Delta x  "} />{" "}
            is negative
          </p>
          <p style={{ fontSize: 15 }}>
            Moving up means <InlineMath math={"\\color{red} \\Delta x  "} />{" "}
            is positive
          </p>
        </Grid>
      </Grid>
    </>
  );
};

export default DeltaYX;
