import React, { useState } from "react";
import { Grid, Slider } from "@mui/material";
import "katex/dist/katex.min.css";
import { InlineMath } from "react-katex";
import { factorToPrimes} from "../Tools/functions";
import { scaleOrdinal, schemeCategory10, schemeSet1 } from "d3";

const PrimeFactors = () => {
  const color = scaleOrdinal(schemeCategory10);
  const colorDark = scaleOrdinal(schemeSet1);
  const [term, setTerm] = useState(30);

  const factorArray = [];
  const reversed = [];
  const products = [];
  const factorToPrimesArray = factorToPrimes(term);
  const l = factorToPrimesArray.length;
  let total = factorToPrimesArray.reduce((a, b) => a * b);
  const totalString = total + " = ";
  let prior = 1;
  const capture = [];

  factorToPrimesArray.map((v, i) => {
    factorArray.push("\\color{" + color(l - i - 1) + "}" + v);
    reversed.push(v);
    if (total / v !== 1)
      capture.push(reversed.join("\\times") + "\\times" + total / v);

    total = total / v;
    prior = prior * v;
    const string =
      "\\color{" + colorDark(i) + "}" + prior + " \\times " + total;
    products.push(string);
  });


  reversed.reverse();

  const padding = factorArray.length >= 5 ? 1.5 : 5;
  
  const makeSecondObj = (obj, number, value, border) => {
    const arr = new Array(number).fill(obj);
    return arr.map((v, i) => {
      return (
        <span
          key={"second-" + i}
          style={{
            margin: 3,
            backgroundColor: color(border),
            padding: padding * border
          }}
        >
          {v}
        </span>
      );
    });
  };

  const large = reversed.shift();
  const remaining = reversed.length > 0 ? reversed.reduce((a, b) => a * b) : 1;
  const sumJoin = new Array(remaining).fill(large).join("+");
  const sum = (
    <InlineMath
      math={
        remaining +
        " \\times" +
        "\\color{" +
        color(0) +
        "} " +
        large +
        "\\color{black}  =  \\color{" +
        color(0) +
        "}  " +
        sumJoin
      }
    />
  );

  const largeObj = <InlineMath math={" " + large} />;

  const second = reversed.shift();
  const secondSumJoin =
    second > 0 &&
    reversed.length > 0 &&
    new Array(reversed.reduce((a, b) => a * b)).fill(large * second).join("+");
  const secondSum = secondSumJoin && (
    <InlineMath
      math={
        remaining / second + "\\times" + large * second + " =  " + secondSumJoin
      }
    />
  );

  const secondObj =
    second > 0 ? makeSecondObj(largeObj, second, large, 0) : largeObj;

  const third = reversed.shift();
  const thirdSumJoin =
    third > 0 &&
    reversed.length > 0 &&
    new Array(reversed.reduce((a, b) => a * b))
      .fill(large * second * third)
      .join("+");
  const thirdSum = thirdSumJoin && (
    <InlineMath
      math={
        remaining / (second * third) +
        "\\times" +
        large * second * third +
        " =  " +
        thirdSumJoin
      }
    />
  );

  const thirdObj =
    third > 0 ? makeSecondObj(secondObj, third, second, 1) : secondObj;

  const fourth = reversed.shift();
  const fourthSumJoin =
    fourth > 0 &&
    reversed.length > 0 &&
    new Array(reversed.reduce((a, b) => a * b))
      .fill(large * second * third * fourth)
      .join("+");
  const fourthSum = fourthSumJoin && (
    <InlineMath
      math={
        remaining / (second * third * fourth) +
        "\\times" +
        large * second * third * fourth +
        " =  " +
        fourthSumJoin
      }
    />
  );
  const fourthObj =
    fourth > 0 ? makeSecondObj(thirdObj, fourth, third, 2) : thirdObj;
  const fifth = reversed.shift();
  const fifthObj =
    fifth > 0 ? makeSecondObj(fourthObj, fifth, fourth, 3) : fourthObj;

  const finalObj = (
    <span
      style={{
        margin: 50,
        padding: factorArray.length * (padding + 1),
        backgroundColor: "#1f77b4"
      }}
    >
      {" "}
      {fifthObj}{" "}
    </span>
  );
  //const secondObj =

  return (
    <>
      <Grid container spacing={5}>
        <Grid item xs={12} md={4}>
          <h4>Factor of Primes</h4>
          <Slider
            defaultValue={term}
            value={term}
            onChange={(o, v) => {
              setTerm(v);
            }}
            aria-labelledby="discrete-slider"
            step={1}
            marks
            min={2}
            max={50}
            valueLabelDisplay="on"
          />
          <p style={{ fontSize: 25 }}>
            <span style={{ fontSize: 15, marginRight: 20 }}>
              {" "}
              Prime Factors:{" "}
            </span>
            <InlineMath
              math={
                term === 0
                  ? "0 = 0 \\times 0 "
                  : " " +
                    term +
                    "  = 1 \\times  " +
                    factorArray.join(" \\times ")
              }
            />{" "}
          </p>
          <p style={{ fontSize: 25 }}>
            <span style={{ fontSize: 15, marginRight: 20 }}> Pairs: </span>{" "}
            <InlineMath math={totalString + products.join(" = ")} />{" "}
          </p>
          <p style={{ fontSize: 25 }}>
            <span style={{ fontSize: 15, marginRight: 20 }}> Tree: </span>{" "}
            <InlineMath math={term + " = " + capture.join(" = ")} />{" "}
          </p>
          <p style={{ fontSize: 25 }}>
            {" "}
            <span style={{ fontSize: 15, marginRight: 20 }}>
              {" "}
              Sum of : {large}{" "}
            </span>
            {sum}
          </p>
          {secondSum && (
            <p style={{ fontSize: 25 }}>
              {" "}
              <span style={{ fontSize: 15, marginRight: 20 }}>
                {" "}
                Sum of : {second * large}{" "}
              </span>{" "}
              {secondSum}
            </p>
          )}
          {thirdSum && <p style={{ fontSize: 25 }}><span style={{ fontSize: 15, marginRight: 20 }}>
                {" "}
                Sum of : {second * large*third}{" "}
              </span>{thirdSum}</p>}
          {fourthSum && <p style={{ fontSize: 25 }}><span style={{ fontSize: 15, marginRight: 20 }}>
                {" "}
                Sum of : {second * large*third*fourth}{" "}
              </span>{fourthSum}</p>}
          {factorArray.length > 1 && (
            <p style={{ fontSize: 25 }}>
              <InlineMath math={"  = " + term} />
            </p>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          style={{ fontSize: factorArray.length >= 5 ? 20 : 25, padding: 50 }}
        >
          {finalObj}
        </Grid>
      </Grid>
    </>
  );
};

export default PrimeFactors;
