import React, { useState, useEffect, useRef } from "react";

import { Grid, Slider, Paper, Box } from "@mui/material";
import "katex/dist/katex.min.css";
import { InlineMath } from "react-katex";
import range from "lodash/range";
import Sphere from "../Tools/PosNegRotatingSphere";

function DemoPage() {
  const linearGraphRef = useRef();
  const handleChangeM = (value) => {};
  const buttonClick = () => {};
  const changeA = (o, value) => {
    setX(value);
  };
  const changeB = (o, value) => {
    setY(value);
  };
  const defaultW = 400;
  const defaultH = 400;
  const svgPadding = 10;
  const defaultY = 2;
  const [y, setY] = useState(defaultY);
  const defaultX = 3;
  const [x, setX] = useState(defaultX);
  const yMap = range(0, y);

  const Rectangle = (props) => {
    const width = props.width || 100;
    const height = props.height || 100;
    // const rectWidth = 0.05 * width;
    // const rectHeight = 0.05 * height;
    const strokeWidth = props.strokeWidth || 3;
    const divider = 5;
    const r = width / (2.5 * divider);
    const X = (props) => {
      const { xPos, yPos } = props;
      const factor = x / y >= 0 ? 0.75 : 0.45;
      return (
        <g>
          <circle
            r={r}
            cx={xPos}
            cy={yPos}
            style={{ fill: y === 0 ? "#efefff " : x > 0 ? "blue" : "#0099FF" }}
          />
          <text x={xPos - factor * r} y={yPos + r / 2} fill="white">
            {x * y < 0 ? " - " : " + "}
          </text>
        </g>
      );
    };

    const numberOfCircles = x;

    const points = range(numberOfCircles).map((value) => {
      const v = Math.abs(value);
      const third = width / 5;

      const xPos = 0.25 * width * (1 + (v % 3));
      const yPos = 0.25 * width * (1 + Math.floor(v / 3));
      return <X key={"x-" + (100 + value)} xPos={xPos} yPos={yPos} />;
    });

    return (
      <svg width={width} height={height}>
        <rect
          x={(props.x || 0) + strokeWidth}
          y={(props.y || 0) + strokeWidth}
          width={width - 2 * strokeWidth}
          height={height - 2 * strokeWidth}
          style={{
            fill: y === 0 ? "#efffef" : y > 0 ? "white" : "#CCFF99",
            stroke: props.stroke || "green",
            strokeWidth: y === 0 ? 0 : strokeWidth,
          }}
        />
        {points}
      </svg>
    );
  };

  const BackView = (props) => {
    const width = props.width || 100;
    const height = props.height || 100;
    // const rectWidth = 0.05 * width;
    // const rectHeight = 0.05 * height;
    const strokeWidth = props.strokeWidth || 3;
    const divider = 5;
    const r = width / (2.5 * divider);
    const X = (props) => {
      const { xPos, yPos } = props;
      const factor = x / y <= 0 ? 0.75 : 0.45;
      return (
        <g>
          <circle
            r={r}
            cx={xPos}
            cy={yPos}
            style={{ fill: y === 0 ? "#efefff " : x < 0 ? "blue" : "#0099FF" }}
          />
          <text x={xPos - factor * r} y={yPos + r / 2} fill="white">
            {x * y > 0 ? " - " : " + "}
          </text>
        </g>
      );
    };

    const numberOfCircles = x;

    const points = range(numberOfCircles).map((value) => {
      const v = Math.abs(value);
      const third = width / 5;

      const xPos = 0.25 * width * (1 + (v % 3));
      const yPos = 0.25 * width * (1 + Math.floor(v / 3));
      return <X key={"x-" + (100 + value)} xPos={xPos} yPos={yPos} />;
    });

    return (
      <svg width={width} height={height}>
        <rect
          x={(props.x || 0) + strokeWidth}
          y={(props.y || 0) + strokeWidth}
          width={width - 2 * strokeWidth}
          height={height - 2 * strokeWidth}
          style={{
            fill: y === 0 ? "#efffef" : y < 0 ? "white" : "#CCFF99",
            stroke: props.stroke || "green",
            strokeWidth: y === 0 ? 0 : strokeWidth,
          }}
        />
        {points}
      </svg>
    );
  };

  const styles = {
    green: {
      color: "green",
    },
    blue: {
      color: "blue",
    },
    paddingLeftSide: {
      paddingTop: 35,
    },
  };

  return (
    <>
      <h4>Multiply Signed Objects</h4>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Grid item xs={12}>
            <p style={{ fontSize: 25 }}>
              Consider the container{" "}
              <InlineMath math={"\\color{green} y = " + y} /> .{" "}
              {y > 0 && (
                <span>
                  Since <InlineMath math={"\\color{green} y "} /> is positive,
                  we do not change the sign of the items
                </span>
              )}
              {y < 0 && (
                <span>
                  Since <InlineMath math={"\\color{green} y "} /> is negative,
                  we do change the sign of the items
                </span>
              )}
              {y === 0 && (
                <span>
                  Since <InlineMath math={"\\color{green} y = 0  "} /> we have
                  no containers and hence no items
                </span>
              )}
              .
            </p>
            <h4 style={styles.blue}>Number of items</h4>
            <Slider
              style={styles.paddingLeftSide}
              defaultValue={defaultX}
              onChange={changeA}
              aria-labelledby="discrete-slider"
              step={1}
              marks
              min={-9}
              max={9}
              valueLabelDisplay="on"
            />
          </Grid>
          <Grid item xs={12}>
            <h4 style={styles.green}>Number of containers</h4>
            <Slider
              style={styles.paddingLeftSide}
              defaultValue={defaultY}
              onChange={changeB}
              aria-labelledby="discrete-slider"
              step={1}
              marks
              min={-9}
              max={9}
              valueLabelDisplay="on"
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <h4>Front View</h4>
          <Grid container spacing={3}>
            {range(y !== 0 ? Math.abs(y) : 1).map((v) => (
              <Rectangle key={"r-" + v} />
            ))}
          </Grid>

          <p style={{ fontSize: 25 }}>
            <InlineMath
              math={
                "(\\color{green}" +
                y +
                ")\\color{black} \\times (\\color{blue} " +
                x +
                "\\color{black} )" +
                (y === -1
                  ? " = - (\\color{blue}" + x + "\\color{black})"
                  : " ") +
                " = \\color{red} " +
                x * y
              }
            />
          </p>
        </Grid>
      </Grid>
      <Paper style={{ padding: 10, backgroundColor: "#f2f2f2" }}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Box m = {5}>
              <h4>Back view</h4>
              You can conceptualize positive and negative as looking at an
              object from two sides. The front side is positive, the back side
              is negative.
            </Box>
            <Box m={5}>
              <Sphere />
            </Box>
            <p>
              Every time you "flip" the object over, you change the sign. If you
              flip twice you end up back to the original sign.{" "}
            </p>
            <p>
              In general, an odd amount of flips (negating) results in a
              negative
            </p>
            <p>An even amount of flips (negating) results in a positive</p>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box m = {5}>Looking at the same objects from the back side (opposite sign)</Box>
            
            <Grid container spacing={3}>
              {range(y !== 0 ? Math.abs(y) : 1).map((v) => (
                <BackView key={"b-" + v} />
              ))}
            </Grid>
            <p>
              Viewing{" "}
              <InlineMath
                math={" \\enspace \\color{red} " + x * y + "\\enspace"}
              />{" "}
              from the front size is the same as viewing
              <InlineMath
                math={" \\enspace \\color{blue} " + -1 * x * y + "\\enspace"}
              />{" "}
              from the back side
            </p>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

export default DemoPage;
