import "./App.css";
import Header from "./Components/Layout/Header";
import Body from  "./Components/Layout/Body";
import Layout from  "./Components/Layout/Layout";

function App() {
  return (
    <div className="App">
      <Layout />
    </div>
  );
}

export default App;
