import React, { useState, useEffect } from "react";
import { Grid, Button } from "@mui/material";
import { InlineMath } from "react-katex";
import { random } from "lodash";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export const Practice = props => {
  const [showAnswer, setShowAnswer] = useState(false);
  const [showPractice, setShowPractice] = useState(false);
  const [limit, setLimit] = useState(10);
  const [start, setStart] = useState(random(limit));
  const [append, setAppend] = useState(random(limit));
  const [op, setOp] = useState("+");
  const answer = showAnswer
    ? op === "+"
      ? start + append
      : start - append
    : " ? ";

  const eq =
    "\\color{red} " +
    start +
    "\\color{black} " +
    op +
    "\\color{blue}" +
    append +
    "\\color{black} = " +
    answer;

  const question = <InlineMath math={eq} />;
  const expand = showPractice ? <ExpandLessIcon /> : <ExpandMoreIcon />;
  const tryAgain = () => {
    setShowAnswer(false);
    setStart(random(limit));
    setAppend(random(limit));
  };
  const changeOp = () => {
    const changeTo = op === "+" ? "-" : "+";
    setOp(changeTo);
    tryAgain();
  };
  const changeLimit = event => {

    setLimit(event.target.value);
  }

  useEffect(() => {
    tryAgain()
  }, [limit])
  return (
    <>
      <Grid
        container
        style={{ border: "solid", borderColor: "#aaa", padding: 10, marginTop: 10 }}
      >
        <Grid item>
          <h4>
            Practice{" "}
            <Button
              onClick={() => setShowPractice(!showPractice)}
              variant="outlined"
            >
              {expand}
            </Button>{" "}
          </h4>

          {showPractice && (
            <div>
              <h6>Practice</h6>
              <Button onClick={() => changeOp()} variant="outlined" style = {{margin: 10}}>
                {op === "+" ? "Change to Subtract" : "Change to Add"}{" "}
              </Button> 
              <FormControl  style = {{minWidth: 140}}>
                <InputLabel id="demo-simple-select-label"> Terms Upper Limit</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={limit}
                  onChange={changeLimit}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                  <MenuItem value={500}>500</MenuItem>
                  <MenuItem value={1000}>1000</MenuItem>
                </Select>
              </FormControl>
              <p style={{ fontSize: 25 }}>{question}</p>
              <Button
                onClick={() => setShowAnswer(!showAnswer)}
                variant="outlined"
              >
                {showAnswer ? "Hide Answer" : "Show Answer"}
              </Button>
              <Button onClick={tryAgain} variant="outlined">
                Try Again
              </Button>
            </div>
          )}
        </Grid>
      </Grid>
    </>
  );
};
