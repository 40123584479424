import React, { useState, useEffect } from "react";
import Background from "../../../Images/woman-tutor.jpg";
import Teacher from "../../../Images/teacherIcon.png";
import Diagnostic from "../../../Images/diagnostic.png";
import Performance from "../../../Images/performance.png";
import Hidden from "@mui/material/Hidden";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";

import { Navigate } from "react-router-dom";
import { select } from "d3";
import { InlineMath } from "react-katex";
import DivideBySubtract from "./widgets/DivideBySubtract";
import DeltaYX from "./widgets/DeltaYX";
import LCD from "./widgets/LCD";

const jStyle = {
  backgroundImage: `url(${Background})`,
  backgroundSize: "cover",
  /*  background-position: 75px -150px; */
  backgroundPosition: "center right",
  boxShadow: "inset 0 0 0 2000px rgba(0, 0, 0, 0.3)",
};

const Example = (props) => {
  const [redirect, setRedirect] = useState(false);
  const [contact, setContact] = useState(false);
  //const [start1, setStart1] = useState(true);
  const [x, setX] = useState(3);
  const eq =
    "\\large \\color{red} " +
    x +
    "\\color{black}  + ( \\color{purple}  -  " +
    (x - 1) +
    "\\color{black} )\\color{black} =  1 ";

  useEffect(() => {
    var c1 = select("#home-c1");
    var c2 = select("#home-c2");
    var c3 = select("#home-c3");
    var c4 = select("#home-c4");
    var c5 = select("#home-c5");

    function repeat() {
      c1.transition().duration(1000).attr("cx", 12).attr("r", 8);
      c2.transition().duration(1000).attr("cx", 32).attr("r", 8);
      c3.transition().duration(1000).attr("cx", 52).attr("r", 8);
      c4.transition().duration(1000).attr("cx", 72).attr("r", 8);
      c5.transition()
        .duration(1000)
        .attr("cx", 92)
        .attr("r", 8)
        .on("end", function () {
          setX(3);
          c1.transition()
            .delay(1000) // apply a transition
            .duration(1000)
            .attr("cx", 72)
            .on("end", function () {
              c1.transition().duration(750).attr("r", 0);
              c4.transition()
                .duration(750)
                .attr("r", 0)
                .on("end", function () {
                  setX(2);
                  c2.transition()
                    .duration(1000)
                    .attr("cx", 92)
                    .on("end", function () {
                      setX(1);
                      c2.transition().duration(750).attr("r", 0);
                      c5.transition()
                        .duration(750)
                        .attr("r", 0)
                        .on("end", function () {
                          c5.transition().delay(1000).on("end", repeat);
                        });
                      //setX(x - 1)
                    });
                });
            });
        });
    }
    repeat();
  }, []);

  return (
    <>
      {redirect && (
        <Navigate
          push
          to={{
            pathname: "/contact",

            state: {
              subject: "Tutoring Services",
              message:
                "I am interested in your tutoring services in the subject of.....",
            },
          }}
        />
      )}
      {contact && <Navigate push to="/contact" />}
      <Grid container>
        <Grid item xs={12} style={jStyle}>
          <Grid container style={{ margin: 15 }}>
            <Hidden smDown>
              <Grid item md={1} lg={1}></Grid>
            </Hidden>
            <Grid
              item
              xs={12}
              sm={8}
              lg={4}
              style={{
                background: "rgba(220, 220, 220, 0.7 )",
                padding: 10,
                color: "#000",
                textAlign: "center",
              }}
            >
              <h1>Learn Math the Right Way </h1>
              <h2>Learn the MathCart Way </h2>
              <div>
                <svg width="140" height="21">
                  <circle r="8" cx="12" cy="10" fill="red" id="home-c1" />
                  <circle r="8" cx="32" cy="10" fill="red" id="home-c2" />
                  <circle r="8" cx="52" cy="10" fill="red" id="home-c3" />
                  <circle r="8" cx="72" cy="10" fill="purple" id="home-c4" />
                  <circle r="8" cx="92" cy="10" fill="purple" id="home-c5" />
                </svg>
                <span
                  className="katex-eq"
                  style={{
                    transform: "translate(0px, -2px)",
                    display: "inline-block",
                  }}
                >
                  <InlineMath math={eq} />
                </span>
              </div>
              <div>
                <h3>Build Intuition</h3>
              </div>
              <div>
                <h3>Visualize Math for Better Understanding</h3>
              </div>
              <div>
                <h3>Get the Help You Need</h3>
              </div>
              <div>
                <h3>Build a Solid Foundation for Your Success</h3>
              </div>
              <p>
                Are you having problems with math? Maybe it is time to take a
                different look. MathCart.com will help you succeed by
                illustrating math in new, sensible and exciting ways!
              </p>
              {false && (
                <p>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => setRedirect(true)}
                  >
                    Find a Tutor Now
                  </Button>
                </p>
              )}
            </Grid>

            <Grid
              lg={3}
              xs={12}
              sm={2}
              item
              sx={{ alignSelf: "flex-end", marginLeft: "auto" }}
            >
              <Box
                sx={{
                  background: "rgba(220, 220, 220, 0.8 )",
                  position: "relative",
                  left: { lg: -30, xs: -10, sm: -100 },
                  borderRadius: 25,
                  padding: { lg: 5, xs: 2 },
                  margin: { lg: 0, xs: 2 },
                }}
                //p={5}
              >
                <h1>Special!!!!</h1>
                <h3 sx={{ display: "in-line" }}>
                  In Person Learning and Private Tutoring Available in Las Vegas
                </h3>
                <p>
                  <a href="https://mathcart.com/tutor/schedule-a-session/">
                    <Button
                      color="primary"
                      variant="contained"
                      sx={{ marginBottom: { xs: 5, md: 0 } }}
                      mb={5}
                    >
                      Schedule
                    </Button>
                  </a>
                </p>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="center" justify="center">
          <Grid item xs={12} style={{ margin: "auto", textAlign: "center" }}>
            <h1>How It Works</h1>{" "}
          </Grid>
          <Grid item xs={12} md={4} style={{ margin: "auto" }}>
            <Card style={{ margin: "auto", textAlign: "center", padding: 20 }}>
              <img src={Teacher} width={150} />
              <h4>Find a Qualified Tutor</h4>
              <p>
                MathCart.com tutors are top of the line with solid teacher
                training and educational experience.
              </p>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} style={{ margin: "auto" }}>
            <Card style={{ margin: "auto", textAlign: "center", padding: 20 }}>
              <img src={Diagnostic} width={150} />
              <h4>Personalize Your Learning </h4>
              <p>
                Your tutor works closely with you to understand your needs.
                MathCart tutors are trained to recognize whatever issues may be
                getting in the way of your math success.
              </p>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} style={{ margin: "auto" }}>
            <Card style={{ margin: "auto", textAlign: "center", padding: 20 }}>
              <img src={Performance} width={150} />
              <h4>Build on Your Success</h4>
              <p>
                The MathCart way is different! Our tutors constantly reinforce
                your progress. With our system, you don't just mimic and forget.
                You understand math on a deeper level, continually revisiting
                key concepts in a visual and interactive way.
              </p>
            </Card>
          </Grid>
        </Grid>
        <Divider sx={{ width: "100%", marginTop: 8 }} />

        {true && (
          <Grid
            container
            spacing={3}
            style={{ margin: "auto", textAlign: "center", marginTop: 20 }}
          >
            <Grid
              item
              xs={12}
              style={{ margin: "auto", textAlign: "center" }}
              alignItems="stretch"
            >
              <h1>Watch a Bit of MathCart Lessons</h1>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Card style={{ padding: 10 }}>
                <DivideBySubtract />
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Card style={{ padding: 10 }}>
                <LCD />
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Card style={{ padding: 10 }}>
                <DeltaYX />
              </Card>
            </Grid>
            <Divider sx={{ width: "100%", marginTop: 8, marginBottom: 8 }} />
            <Grid item xs={12} sm={6} md={4}>
              <Card style={{ padding: 10 }}>
                <iframe
                  src="https://www.youtube.com/embed/ra0LtueGptY"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>{" "}
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Card style={{ padding: 10 }}>
                <iframe
                  src="https://www.youtube.com/embed/4bosW6CZOg4"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>{" "}
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Card style={{ padding: 10 }}>
                <iframe
                  src="https://www.youtube.com/embed/RrRgIfbpn78"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </Card>
            </Grid>
            {false && (
              <Grid item xs={12} sm={6} md={4}>
                <Card style={{ padding: 10 }}>
                  <iframe
                    src="https://www.youtube.com/embed/zBKVo5YihE0"
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </Card>
              </Grid>
            )}
            {false && (
              <Grid item xs={12} sm={6} md={4}>
                <Card style={{ padding: 10 }}>
                  <iframe
                    src="https://www.youtube.com/embed/hhGvLchDnMs"
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </Card>
              </Grid>
            )}
            {false && (
              <Grid item xs={12} sm={6} md={4}>
                <Card style={{ padding: 10 }}>
                  <iframe
                    src="https://www.youtube.com/embed/vkjN64whBW8"
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </Card>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>

      <Divider sx={{ width: "100%", marginTop: 8 }} />
      <Grid container>
        <Grid item xs={12} style={{ margin: "auto", textAlign: "center" }}>
          <Card elevation={4} style={{ margin: 15 }}>
            <h3>Any Questions? </h3>

            <Button
              color="primary"
              variant="contained"
              onClick={() =>
                (window.location = "https://mathcart.com/tutor/contact/")
              }
              style={{ marginBottom: 15 }}
            >
              Contact MathCart.com
            </Button>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default Example;
