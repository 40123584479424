import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
//import { makeStyles } from "@mui/material/styles";
import { Typography, Grid, Tooltip, Hidden, Paper } from "@mui/material"; // ToolTip used to view latex slider with  ValueLabelComponent
import Slider from "@mui/material/Slider";


import { select } from "d3";
import "katex/dist/katex.min.css";
import { InlineMath } from "react-katex";


/**


/**
 *
 * @param {*} props
 * this will follow the slider value , better for displaying math
 */
const FormatTooltip = (props) => {
  const { children, value, open } = props;
  return children
};

export default function DiscreteSlider() {
  /**
   *   initial values in object inits
   *
   *  cx, cy:  center values
   *  dw: change in radius
   *  h: height
   *  w:  width
   *
   * states:
   *  dw, dh: changes in width and height
   *
   */
  const inits = { cx: 100, cy: 100, h: 100, w: 100, dh: 0, dw: 2 };

  const scale = inits.w / 10; // slider is set to 0 to 10

  const [dw, setDw] = useState(2);

  const classes = {};

  useEffect(() => {
    const rect = select("#outerRectangle");
    const border = select("#borderRectangle");
    const total = select("#totalRectangle");
    const horizontal = select("#horizontalRectangle");
    const vertical = select("#verticalRectangle");
    const extra = select("#extraSquare");
    const square = select("#extraRectangle");

    if (dw >= 0) {
      rect.attr("width", inits.w + dw * scale);
      border.attr("width", inits.w + dw * scale + 4);
      total.attr("width", inits.w + dw * scale);
      horizontal.attr("height", dw * scale);
      //horizontal.attr("height", dw * scale );
      vertical.attr("y", dw * scale + 25);
      vertical.attr("height", dw * scale);
      //extra.attr("x", inits.w + dw * scale + 5);
      extra.attr("height", dw * scale);
      extra.attr("width", dw * scale);
      square.attr("height", dw * scale);
      square.attr("width", dw * scale);

      rect.attr("height", inits.w + dw * scale);
      border.attr("height", inits.w + dw * scale + 4);
      total.attr("height", inits.w + dw * scale);

      const deltaText = select("#deltaForeignObject");
      const w = "\\color{black} \\Delta w = " + dw;
      ReactDOM.render(<InlineMath math={w} />, deltaText.node());

      const areaText = select("#areaForeignObject");
      const a1 =
        "\\color{black}  = ( \\color{white} 10 \\color{black}   + \\color{red}  " +
        dw +
        "  \\color{black})^2";
      const a2 = (
        <div>
          Combined area <InlineMath math="A_{n}" /> <br />
          <InlineMath math="=(\color{white} w  \color{black} + \color{red} \Delta w \color{black} ) ^2" />
          <br />
          <InlineMath math={a1} />
          <br />
          <InlineMath math={" = " + (10 + dw) * (10 + dw)} />
          <br />
          <br />
          change of area
          <br />
          <InlineMath math={" \\Delta A = " + ((10 + dw) * (10 + dw) - 100)} />
        </div>
      );
      ReactDOM.render(a2, areaText.node());
    }
  }, [dw, inits.w, scale]);

  useEffect(() => {
    const fixedText = select("#fixedForeignObject");

    const inlineMath = (
      <div>
        <InlineMath math="\color{white} h = w = 10 " />

        <br />
        <InlineMath math="\color{white} A_{o} = 100" />
      </div>
    );
    ReactDOM.render(inlineMath, fixedText.node());
  }, []);

  // console.log("fixedText", fixedText);

  const widthChanged = (o, value) => {
    // console.log("widthChanged", value, radius);
    setDw(value);
  };

  const left = (
    <>
      {" "}
      <p>
        The <span style={{ color: "blue" }}>original width </span> is fixed at{" "}
        <InlineMath math="\color{blue} w = 10" />{" "}
      </p>
      <div style={{ paddingTop: 40, paddingLeft: 40 }}>
        <Slider
          defaultValue={inits.dw}
          onChange={widthChanged}
          aria-labelledby="discrete-slider"
          step={1}
          marks
          min={0}
          max={10}
          valueLabelDisplay="on"
          valueLabelFormat={(x) => <InlineMath math={"\\Delta w =   " + x} />}
          ValueLabelComponent={FormatTooltip}
        />
      </div>
      <Typography id="discrete-slider" gutterBottom>
        <span style={{ color: "red" }}>Change of width </span>{" "}
        <InlineMath math={" \\color{red} w "} /> by the value{" "}
        <InlineMath math={"\\color{red} \\Delta w =   " + dw} />
      </Typography>
      <Hidden mdUp>
        {" "}
        <hr />{" "}
      </Hidden>
    </>
  );

  const deltaSvg = (
    <svg width={inits.w * 2 + 30} height={2 * inits.h + 20} id="leftSvg">
      <rect
        id="borderRectangle"
        width={inits.w + inits.dw * scale + 4}
        height={inits.h + inits.dw * scale + 6}
        style={{ fill: "rgb(0,225,0)" }}
      />
      <rect
        id="outerRectangle"
        width={inits.w + inits.dw * scale}
        height={inits.h}
        style={{ fill: "rgb(255,0,0)", transform: "translate(2px, 2px)" }}
      />

      <rect
        id="fixedRectangle"
        width={inits.w}
        height={inits.h}
        style={{ fill: "rgb(0,0,255)", transform: "translate(2px, 2px)" }}
      />

      <rect
        id="extraRectangle"
        width={inits.dw * scale}
        height={inits.dw * scale}
        x={inits.w}
        y={inits.w}
        style={{ fill: "purple", transform: "translate(2px, 2px)" }}
      />

      <foreignObject
        x="5"
        y="5"
        width="100"
        height="110"
        id="fixedForeignObject"
      />
      <foreignObject
        x="5"
        y="140"
        width="80"
        height="110"
        id="deltaForeignObject"
      />
    </svg>
  );

  const totalSvg = (
    <svg width={2 * inits.w + 10} height={2 * inits.h + 20}>
      <rect
        id="totalRectangle"
        width={inits.w + inits.dw * scale}
        height={inits.h}
        style={{ fill: "rgb(75,225,75)" }}
        y="2"
      />
      <foreignObject
        x="5"
        y="5"
        width="200"
        height="200"
        id="areaForeignObject"
      />
    </svg>
  );

  const svgSum = (
    <svg id="svgSum" width={inits.w * 2 + 30} height={2 * inits.h + 20}>
      <rect
        id="horizontalRectangle"
        y="20"
        width={100}
        height={inits.dw * scale}
        style={{ fill: "rgb(255,0,0)" }}
      />
      <rect
        id="verticalRectangle"
        y={inits.dw * scale + 30}
        height={inits.dw * scale}
        width={100}
        style={{ fill: "rgb(255,0,0)" }}
      />
      <rect
        id="extraSquare"
        x={inits.w + 5}
        y="20"
        width={inits.dw * scale}
        height={inits.dw * scale}
        style={{ fill: "purple" }}
      />
      <text y="15" style={{ font: "italic 13px sans-serif" }}>
        Extra area from additional width
      </text>
    </svg>
  );

  const keyTakeAway = (
    <Paper
      elevation={20}
      variant="outlined"
      style={{
        padding: 5,
        backgroundColor: "#EEE",
      }}
    >
      <h3>Key Takeaways</h3>
      <p>
        Consider the relationship between <InlineMath>width</InlineMath> and{" "}
        <InlineMath>area</InlineMath> .
      </p>
      <h4>Width</h4>
      <p>
        We will keep track of both the{" "}
        <span style={{ color: "blue" }}>
          original width{" "}
          <InlineMath math=" \color{black} (  \color{blue} w = 10 \color{black} )" />
        </span>{" "}
        and{" "}
        <span style={{ color: "red" }}>
          {" "}
          the change in the width{" "}
          <InlineMath
            math={
              " \\color{black} (  \\color{red} \\Delta w = " +
              dw +
              " \\color{black} )"
            }
          />
        </span>{" "}
        . Changing the width will also change the area.
      </p>
      <h4>Area</h4>
      To remain a square, the length and width must be equal. There for the area
      is related to the square of the width.
      <p>
        <InlineMath>A = w^2</InlineMath>{" "}
      </p>
      The area has a quadratic relationship with the width because we change 2
      dimensions
      <hr />
      <p>
        The area has a quadratic relationship to the width. You can see that
        changes in the width have a more dramatic effect than for a linear
        relationship.{" "}
      </p>
    </Paper>
  );

  const calculateAreas = (
    <Grid item>
      <InlineMath math={" \\color{blue} A_{o} = w^2 = 100"} />
      <br />
      <InlineMath math={"\\Delta w =  \\color{red}" + dw} />
      <hr />
      <InlineMath math={"A_{n} = (w + \\Delta w)^2"} />
      <br />
      <InlineMath
        math={
          "= \\color{blue} w^2  \\color{black}+  2  \\color{blue} w  \\color{red}(\\Delta w) \\color{black} + \\color{purple} (\\Delta w)^2"
        }
      />
      <br />
      <InlineMath
        math={
          "= \\color{blue}  A_{o}  \\color{black} +  2  \\color{blue} w  \\color{red} (" +
          dw +
          ") \\color{black} + \\color{purple} (" +
          dw +
          ")^2"
        }
      />
      <br />
      <InlineMath
        math={
          "= \\color{blue}  A_{o} \\color{black} +  2  \\color{blue} w  \\color{red}  (" +
          dw +
          ") \\color{black} + \\color{purple} (" +
          dw +
          ")^2"
        }
      />
      <hr />
      <InlineMath
        math={
          "\\Delta A =    \\color{red}  20  ( " +
          dw +
          ") \\color{black} + \\color{purple} (" +
          dw +
          ")^2  \\color{black} =  " +
          ((10 + dw) * (10 + dw) - 100)
        }
      />
      <br />
      <p>
        {" "}
        <InlineMath
          math={"A_{n} = A_{o} + \\Delta A = " + (10 + dw) * (10 + dw)}
        />
      </p>
      <i style={{ fontSize: 10 }}>
        *The area has a quadratic relationship with the width because we change
        2 dimensions
      </i>
    </Grid>
  );

  return (
    <div className={classes.root}>
      <h3>Change of Width, Area in a Square</h3>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          {left}
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={1}>
            <Grid item>
              {" "}
              <Paper> {deltaSvg} </Paper>
            </Grid>
            <Grid item>
              {" "}
              <Paper> {totalSvg} </Paper>
            </Grid>
            <Grid item>
              {" "}
              <Paper> {svgSum} </Paper>
            </Grid>
            <Grid item> {calculateAreas}</Grid>
          </Grid>
        </Grid>
      </Grid>
      {keyTakeAway}
    </div>
  );
}
