import React, { useEffect, useRef, useState } from "react";
import Drawer from "./Drawer";
import { Box } from "@mui/material";
import SiteRoutes from "./SiteRoutes";

export default function Body(props) {
 /* pen for marking content */
 const [pen, setPen] = useState(true);
 const [penColor, setPenColor] = useState("red");
 /* need two refs.  canvas is the inner dive
 top is the outer */
 let canvas = useRef(null);
 let top = useRef(null);

 const canvasStyle = {
   width: window.innerWidth,
   height: 1.25 * window.outerHeight,
   display: "block",
   position: "absolute",
   pointerEvents: "none",
   zIndex: 10000,
   right: 0,
   top: 0,
 };

 const penOn = (color) => {
   /* configure the canvas when pen is on */
   let ctx = canvas.current.getContext("2d");
   canvas.current.width = window.innerWidth;
   canvas.current.height = canvasStyle.height;
   canvas.current.style.position = "absolute";
   top.current.style.cursor = "crosshair";
   ctx.globalAlpha = 0.5;
   let mouse = {
     x: 0,
     y: 0,
   };
   let last_mouse = {
     x: 0,
     y: 0,
   };

   /* Mouse Capturing Work */
   top.current.addEventListener(
     "mousemove",
     function (e) {
       last_mouse.x = mouse.x;
       last_mouse.y = mouse.y;
       mouse.x = e.pageX - canvas.current.offsetLeft;
       mouse.y = e.pageY;
     },
     false
   );

   top.current.addEventListener(
     "touchmove",
     function (e) {
       last_mouse.x = mouse.x;
       last_mouse.y = mouse.y;
       mouse.x = e.touches[0].pageX - canvas.current.offsetLeft;
       mouse.y = e.touches[0].pageY;
     },
     false
   );

   /* Drawing on Paint App */
   ctx.lineWidth = 5;
   ctx.lineJoin = "round";
   ctx.lineCap = "round";
   ctx.strokeStyle = color || penColor;
   ctx.globalAlpha = 1;

   top.current.addEventListener(
     "mousedown",
     function (e) {
       top.current.addEventListener("mousemove", onPaint, false);
       if (pen) {
         e.preventDefault();
       }
     },
     false
   );

   top.current.addEventListener(
     "touchmove",
     function (e) {
       top.current.addEventListener("touchmove", onPaint, false);
       if (pen) e.preventDefault();
     },
     false
   );

   top.current.addEventListener(
     "touchend",
     function () {
       top.current.removeEventListener("touchmove", onPaint, false);
     },
     false
   );

   top.current.addEventListener(
     "mouseup",
     function () {
       top.current.removeEventListener("mousemove", onPaint, false);
       top.current.removeEventListener("mousedown", onPaint, false);
     },
     false
   );

   const onPaint = function () {
     ctx.beginPath();
     ctx.moveTo(last_mouse.x, last_mouse.y);
     ctx.lineTo(mouse.x, mouse.y);
     ctx.closePath();
     ctx.stroke();
   };
 };

 /* function to toggle pen and clear any marks on toggle */
 const clearDraw = (change, color) => {
   setPen(change);
   switch (change) {
     case false:
       var ctx = canvas.current.getContext("2d");
       ctx.clearRect(0, 0, canvas.current.width, canvas.current.height);
       canvas.current.width = 0;
       canvas.current.height = 0;
       top.current.style.cursor = "auto";
       break;

     default:
       penOn(color);
       break;
   }
 };

 // x, y offsets for making the menu look right */
 const menuOffset = 100;
 const menuOffsetY = 20;

 useEffect(()=>{
    makePen(props.penColor);
 }, [props.penColor, props.pen])

 const makePen = (color) => {
   setPenColor(color);
   //if (ctx) ctx.strokeStyle = color;
   const ctx = canvas.current.getContext("2d");
   ctx.strokeStyle = color;
   if (color === penColor || color === props.color || !pen) clearDraw(!pen, color);
 };


  return (
    <Box
      sx={{
        borderRadius: 2,
        p: 2,
        minWidth: 300,
        border: 1,
        display: "flex",
        margin: 1,
        marginTop: 3,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Drawer drawer={props.drawer} setDrawer={props.setDrawer} />
      <div ref={top} id="big" style={{ width: "100%" }}>
        <canvas ref={canvas} id="paint" style={canvasStyle} />
        <div className={pen ? "deselect" : " "}></div>
        <SiteRoutes />
      </div>
    </Box>
  );
}