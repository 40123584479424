import React, { useState } from "react";
import { Grid, Slider } from "@mui/material";
import "katex/dist/katex.min.css";
import { InlineMath } from "react-katex";

const Perimeter = (props) => {
  const [height, setHeight] = useState(8);
  const [width, setWidth] = useState(4);

  const area = width * height;
  const areaArray = new Array(area).fill(false);
  const areasRectangles = areaArray.map((v, i) => {
    return (
      <Grid item key={"rect-" + i}>
        <svg width={10} height={10}>
          <rect
            width={8}
            height={8}
            fill={"blue"}
            stroke={"grey"}
            strokeWidth={1}
          />
        </svg>
      </Grid>
    );
  });

  const horizontalArray = new Array(width).fill(false);
  const horizontal = horizontalArray.map((v, i) => (
    <line
      key={"horizonatal-" + i}
      x1={i * 10 + 6}
      x2={i * 10 + 6}
      y1={5}
      y2={height * 10 + 3}
      stroke={"#444"}
      strokeWidth={2}
    />
  ));

  const verticalArray = new Array(height).fill(false);
  const vertical = verticalArray.map((v, i) => (
    <line
      key={"vertical-" + i}
      y1={i * 10 + 6}
      y2={i * 10 + 6}
      x1={5}
      x2={width * 10 + 3}
      stroke={"#444"}
      strokeWidth={2}
    />
  ));

  return (
    <>
      <Grid container spacing={3}>
        <Grid item>
          <h6>Rectangle</h6>
          <svg width={240} height={230}>
            <rect
              x={4}
              y={4}
              width={width * 10.2}
              height={height * 10.2}
              fill={"blue"}
              stroke={"red"}
              strokeWidth={4}
            />
            {horizontal}
            {vertical}
            <text
              x={width * 5}
              y={height * 10 + 25}
              fontSize={17}
              fill={"green"}
            >
              {width}
            </text>
            <text
              x={width * 10 + 15}
              y={height * 5}
              fontSize={17}
              fill={"purple"}
            >
              {height}
            </text>
          </svg>
        </Grid>
        <Grid item xs={12} lg={4}>
          <h6>Height</h6>
          <Slider
            defaultValue={height}
            onChange={(o, v) => setHeight(v)}
            value={height}
            aria-labelledby="discrete-slider"
            step={1}
            marks
            min={1}
            max={20}
            valueLabelDisplay="on"
          />
          <h6>Width</h6>
          <Slider
            defaultValue={width}
            onChange={(o, v) => setWidth(v)}
            value={width}
            aria-labelledby="discrete-slider"
            step={1}
            marks
            min={1}
            max={20}
            valueLabelDisplay="on"
          />
        </Grid>
        <Grid item style={{ fontSize: "25" }}>
          <h6>Perimeter: Add the length of all sides</h6>
          <svg width={208} height={50}>
            <rect x={4} y={4} width={width * 10} height={4} fill={"red"} />
            <rect x={4} y={12} width={width * 10} height={4} fill={"red"} />
            <rect x={4} y={20} width={height * 10} height={4} fill={"red"} />
            <rect x={4} y={28} width={height * 10} height={4} fill={"red"} />
          </svg>

          <p>
            <InlineMath
              math={
                "p = \\color{green} " +
                width +
                "  \\color{black} +  \\color{green}" +
                width +
                " \\color{black}  + \\color{purple} " +
                height +
                "   \\color{black} +  \\color{purple} " +
                height
              }
            />
          </p>
          <p>
            <InlineMath
              math={
                "= 2 \\times   \\color{green}" +
                width +
                "   \\color{black} + 2 \\times  \\color{purple} " +
                height
              }
            />
          </p>
          <Grid item>
            <p>
              <InlineMath math={"=" + (2 * width + 2 * height)} />
            </p>
          </Grid>

          <Grid>
            <h6>Area: Multiply length and width to count all unit squares</h6>
            <InlineMath
              math={
                "A = \\color{green}" +
                width +
                "   \\color{black}  \\times  \\color{purple} " +
                height +
                "  \\color{black}   = " +
                width * height
              }
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <h6>Total Perimeter Length</h6>
        <svg width={1200} height={30}>
          <rect
            x={4}
            y={4}
            width={width * 20 + height * 20}
            height={2}
            fill={"blue"}
            stroke={"red"}
            strokeWidth={4}
          />
          <line
            stroke={"black"}
            strokeWidth={2}
            x1={width * 20 + 4}
            x2={width * 20 + 4}
            y1={4}
            y2={8}
          />
          <line
            stroke={"black"}
            strokeWidth={2}
            x1={width * 20 + height * 10 + 4}
            x2={width * 20 + height * 10 + 4}
            y1={4}
            y2={8}
          />
          <line
            stroke={"black"}
            strokeWidth={2}
            x1={width * 10 + 4}
            x2={width * 10 + 4}
            y1={4}
            y2={8}
          />
          <line
            stroke={"black"}
            strokeWidth={2}
            x1={width * 10 + 4}
            x2={width * 10 + 4}
            y1={4}
            y2={8}
          />
        </svg>

        <Grid container>
          {" "}
          <Grid item xs={12}>
            <h6>Total Area</h6>
          </Grid>{" "}
          {areasRectangles}{" "}
        </Grid>
      </Grid>
    </>
  );
};

export default Perimeter;
