import React, { useState } from "react";
import { Grid, Button } from "@mui/material";
import { InlineMath } from "react-katex";
import { factorToPrimes, gcd } from "../Tools/functions";
import { random } from "lodash";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
export const Practice = props => {
  const limit = 20;
  const [numP1, setNumP1] = useState(random(2, 6));
  const [numP2, setNumP2] = useState(random(2, limit));
  const [denP1, setDenP1] = useState(random(2, 6));
  const [denP2, setDenP2] = useState(random(2, limit));
  const [showAnswer, setShowAnswer] = useState(false);
  const [showPractice, setShowPractice] = useState(false);
  const num = numP1 * numP2;
  const den = denP1 * denP2;
  const numFactorArray = factorToPrimes(num);
  const denFactorArray = factorToPrimes(den);
  let denTemp = den;
  const cancelArray = [];
  const uniqueNum = numFactorArray.concat([]);
  const uniqueDen = denFactorArray.concat([]);
  const numFactors = numFactorArray.map((v, i) => {
    if (denTemp % v === 0) {
      denTemp = denTemp / v;
      cancelArray.push("\\cancel{" + v + "}");
      uniqueDen.splice(uniqueDen.indexOf(v), 1);
      uniqueNum.splice(uniqueNum.indexOf(v), 1);
    }
  });
  const factoredNumString = cancelArray.concat(uniqueNum).join("\\times");
  const factoredDenString = cancelArray.concat(uniqueDen).join("\\times");
  const g = gcd(num, den);
  const simplifiedFraction =
    "\\frac{ \\color{red} " +
    (factoredNumString.length > 0 ? factoredNumString : 1) +
    "}{\\color{blue}" +
    (factoredDenString.length > 0 ? factoredDenString : 1) +
    "} = " +
    (g === den
      ? num / g
      : "\\frac{ \\color{red}" + num / g + "}{ \\color{blue} " + den / g + "}");
  const questionString =
    "\\frac{ \\color{red} " + num + "}{ \\color{blue}" + den + "}";
  const answer = showAnswer ? " = " + simplifiedFraction : " = \\enspace ? ";
  const question = <InlineMath math={questionString + answer} />;
  const expand = showPractice ? <ExpandLessIcon /> : <ExpandMoreIcon />;
  const tryAgain = () => {
    setNumP1(random(2, 6));
    setNumP2(random(2, limit));
    setDenP1(random(2, 6));
    setDenP2(random(2, limit));
    setShowAnswer(false);
  };
  return (
    <>
      <Grid
        container
        style={{ border: "solid", borderColor: "#aaa", padding: 10 }}
      >
        <Grid item>
          <h4>
            Practice{" "}
            <Button onClick={() => setShowPractice(!showPractice)}>
              {expand}
            </Button>{" "}
          </h4>
          {showPractice && (
            <div>
              <h6>Reduce and Simplify</h6>
              <p style={{ fontSize: 25 }}>{question}</p>
              <Button
                onClick={() => setShowAnswer(!showAnswer)}
                variant="outlined"
              >
                {showAnswer ? "Hide Answer" : "Show Answer"}
              </Button>
              <Button
                onClick={tryAgain}
                variant="outlined"
              >
                Try Again
              </Button>
            </div>
          )}
        </Grid>
      </Grid>
    </>
  );
};
