import React, { useState, useEffect, useRef } from "react";
//import { select } from "d3";
//import JXG from "jsxgraph";
import { Grid, Slider, Button } from "@mui/material";
import "katex/dist/katex.min.css";
import { BlockMath, InlineMath } from "react-katex";
//import withWidth from "@material-ui/core/withWidth";

import {Rectangle} from "../Tools/Rectangle"
import  useWidth  from "../Tools/UseWidth";

function MultiplyGrid(props) {
  const [lock, setLock] = useState(false);
  const [w, setW] = useState(5);
  const [h, setH] = useState(5);
  const svgWidth = useWidth() === "xs" ? 260 : 480;


  const handleChangeW = (o, value) => {
    setW(value);
  };
  const handleChangeH = (o, value) => {
    setH(value);
  };
  const handleChangeX = (o, value) => {
    setH(value);
    setW(value);
  };
  const svgRef = useRef();

  const styles = {
    w: {
      color: "red"
    },
    h: {
      color: "green"
    },
    paddingLeft: {
      paddingTop: 35
    }
  };

  const changeLock = () => {
    setLock(!lock);
    setH(w)
  };

  return (
    <>
      <h4>Multiplication Grid</h4>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Button variant="outlined" onClick={changeLock}>
            {" "}
            {lock ? "Unlink w & h (Rectangle)" : "force to be a square w=h"}{" "}
          </Button>
          {!lock && <Grid item xs={12}>
            <h3 style={styles.w}>
              Width <InlineMath math={" \\color{red} w =" + w} />
            </h3>
            <Slider
              defaultValue={w}
              onChange={handleChangeW}
              aria-labelledby="discrete-slider"
              step={1}
              value = {w}
              marks
              min={0}
              max={10}
              valueLabelDisplay="on"
              style={{ marginTop: 20 }}
            />
          </Grid>}
          {!lock && <Grid item xs={12}>
            <h3 style={styles.h}>
              Height <InlineMath math={" \\color{green} h =" + h} />
            </h3>
            <Slider
              defaultValue={h}
              onChange={handleChangeH}
              aria-labelledby="discrete-slider"
              step={1}
              value = {h}
              marks
              min={0}
              max={10}
              valueLabelDisplay="on"
              style={{ marginTop: 20 }}
            />
          </Grid>}
          {lock && <Grid item xs={12}>
            <h3 style={styles.w}>
              Width and Height <InlineMath math={" \\color{red} w = " + h  + "\\enspace \\color{green} h = " + h} />
            </h3>
            <Slider
              defaultValue={w}
              onChange={handleChangeX}
              aria-labelledby="discrete-slider"
              step={1}
              marks
              min={0}
              max={10}
              valueLabelDisplay="on"
              style={{ marginTop: 20 }}
            />
          </Grid>}
          <div style={{ fontSize: 25 }}>
            <BlockMath
              math={
                "\\color{red} " +
                w +
                " \\color{black} \\times \\color{green}" +
                h +
                "\\color{black} = \\color{blue} " +
                w * h
              }
            />
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <svg width={svgWidth} height={svgWidth} ref={svgRef}>
            <Rectangle w={w + 1} l={h + 1} width={svgWidth}  color="white"/>
          </svg>
        </Grid>
      </Grid>
    </>
  );
}

export default (MultiplyGrid);
