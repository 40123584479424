import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { select, drag, arc } from "d3";

import { Grid } from "@mui/material";
import "katex/dist/katex.min.css";
import { InlineMath } from "react-katex";

const gcd = (a, b) => {
  if (!b) {
    return a;
  }
  return gcd(b, a % b);
};

const UnitCircle = props => {
  const circleRef = useRef();
  const rayRef = useRef();
  const redRef = useRef();
  const arcLengthRef = useRef();
  const [theta1, setTheta1] = useState(0);
  const degree1 = Math.floor((36 * theta1) / Math.PI) * 5;
  const [rayRadius, setRayRadius] = useState(120);
  const [xy, setXy] = useState([1, 0]);

  const calculateDegree = degree1 <= 0 ? -1 * degree1 : 360 - degree1;

  const handleDrag = drag()
    .subject(function() {
      const me = select(this);
      if (me.attr("r") > 50) return;
      return { x: me.attr("cx"), y: me.attr("cy") };
    })
    /*  .on("touchstart", () => event.preventDefault() )
    .on("touchmove", () => event.preventDefault()) */
    .on("drag", function(event) {
      const me = select(this);
      const x = event.x - 150;
      const y = event.y - 150;
      const theta = Math.atan2(y, x);
      const degree = Math.floor((36 * theta) / Math.PI) * 5;
      const fixedX = 120 * Math.cos((degree * Math.PI) / 180);
      const fixedY = 120 * Math.sin((degree * Math.PI) / 180);
      me.attr("cx", 150 + fixedX);
      me.attr("cy", 150 + fixedY);
      setTheta1(theta);
      setXy([
        Math.round(Math.cos((degree * Math.PI) / 180) * 10000) / 10000,
        Math.round(Math.sin((degree * Math.PI) / 180) * 10000) / 10000
      ]);
    });

  const changeRadius = drag()
    .subject(function() {
      const me = select(this);
      return { x: me.attr("cx"), y: me.attr("cy") };
    })
    .on("drag", function(event) {
      //
      const x = event.x - 150;
      const y = event.y - 150;
      setRayRadius(Math.sqrt(x * x + y * y));
    });

  const gcf = gcd(calculateDegree, 180);
  const radianNumerator = calculateDegree / gcf;
  const radianDenominator = 180 / gcf;

  const pi = degree1 === 0 ? " " : "\\pi";
  const coefficient = radianNumerator !== 1 ? radianNumerator : " ";
  // create different arc paths
  const arc1 = arc()
    .innerRadius(120)
    .outerRadius(120);
  const arcInner = arc()
    .innerRadius(40)
    .outerRadius(40);
  const arcOuter = arc()
    .innerRadius(rayRadius)
    .outerRadius(rayRadius);

  const finalRadian =
    radianDenominator === 1
      ? coefficient + pi
      : "\\frac{" + (coefficient + pi) + "}{" + radianDenominator + "}";

  const calculatedRadians =
    "\\color{red} R \\color{black} = \\frac{\\color{blue}D\\color{black}\\pi}{180} =  \\frac{\\color{blue}" +
    calculateDegree +
    "\\color{black} \\pi}{180} = " +
    finalRadian;

  // use effect to attach drag functions only
  useEffect(() => {
    // d3 selects
    const svg = select(circleRef.current);
    const ray = select(rayRef.current);

    // attach drag functions
    handleDrag(svg.selectAll("circle"));
    changeRadius(ray.selectAll("circle"));
  }, []);

  useEffect(() => {
    // d3 selects
    const svg = select(circleRef.current);
    const ray = select(rayRef.current);
    const radianArc = svg.select("#arc");
    const radianArcInner = ray.select("#innerArc");
    const arcLength = select(arcLengthRef.current);

    // remove anything that needs to be redrawn on state change
    radianArc.selectAll("path").remove();
    radianArcInner.selectAll("path").remove();

    // adjust arc lengths
    theta1 <= 0.1 &&
      radianArc
        .append("path")
        .attr("fill", "none")
        .attr("stroke-width", 5)
        .attr("stroke", "red")
        .attr(
          "d",
          arc1({ startAngle: theta1 + Math.PI / 2, endAngle: Math.PI / 2 })
        );

    theta1 > 0.1 &&
      radianArc
        .append("path")
        .attr("fill", "none")
        .attr("stroke-width", 5)
        .attr("stroke", "red")
        .attr(
          "d",
          arc1({ startAngle: Math.PI / 2, endAngle: -1.5 * Math.PI + theta1 })
        );

    theta1 <= 0.1 &&
      radianArcInner
        .append("path")
        .attr("fill", "none")
        .attr("stroke-width", 2)
        .attr("stroke", "pink")
        .attr(
          "d",
          arcInner({ startAngle: theta1 + Math.PI / 2, endAngle: Math.PI / 2 })
        );

    theta1 > 0.1 &&
      radianArcInner
        .append("path")
        .attr("fill", "none")
        .attr("stroke-width", 2)
        .attr("stroke", "pink")
        //.attr("stroke-dasharray", "5,5")
        .attr(
          "d",
          arcInner({
            startAngle: Math.PI / 2,
            endAngle: -1.5 * Math.PI + theta1
          })
        );

    theta1 <= 0.1 &&
      (rayRadius < 119 || rayRadius > 121) &&
      radianArcInner
        .append("path")
        .attr("fill", "none")
        .attr("stroke-width", 1)
        .attr("stroke", "grey")
        .attr(
          "d",
          arcOuter({ startAngle: theta1 + Math.PI / 2, endAngle: Math.PI / 2 })
        );

    theta1 > 0.1 &&
      (rayRadius < 119 || rayRadius > 121) &&
      radianArcInner
        .append("path")
        .attr("fill", "none")
        .attr("stroke-width", 1)
        .attr("stroke", "grey")
        .attr(
          "d",
          arcOuter({
            startAngle: Math.PI / 2,
            endAngle: -1.5 * Math.PI + theta1
          })
        );

    ReactDOM.render(
      <InlineMath math={" \\color{red} = " + finalRadian} />,
      arcLength.node()
    );
  }, [theta1, rayRadius]);

  const fixedX = 120 * Math.cos((degree1 * Math.PI) / 180);
  const fixedY = 120 * Math.sin((degree1 * Math.PI) / 180);
  const adjustedX = rayRadius * Math.cos((degree1 * Math.PI) / 180);
  const adjustedY = rayRadius * Math.sin((degree1 * Math.PI) / 180);

  return (
    <>
      <h2>Unit Circle</h2>
      <Grid container spacing={3}>
        <Grid item>
          <h4>Control Circle</h4>
          <span style = {{color: "red"}}><b>Drag the red</b></span>  dot to control the angle.
          <br />
          <svg width={300} height={300} ref={circleRef}>
            <circle
              ref={redRef}
              id="red1"
              r={120}
              fill="white"
              cx={150}
              cy={150}
              stroke={"blue"}
              strokeWidth={3}
            />
            <line
              id="lineX"
              x1={150 + fixedX}
              x2={150 + fixedX}
              y1={150}
              y2={150 + fixedY}
              stroke="purple"
              strokeWidth={2}
            />
            <line
              id="lineX"
              x1={150 }
              x2={150 }
              y1={150}
              y2={150 + fixedY}
              stroke="purple"
              strokeWidth={2}
            />
            <line
              id="lineR"
              x1={150}
              x2={150 + fixedX}
              y1={150}
              y2={150 + fixedY}
              stroke="blue"
              strokeWidth={2}
            />
            <line
              id="lineY"
              x1={150}
              x2={150 + fixedX}
              y1={150 + fixedY}
              y2={150 + fixedY}
              stroke="green"
              strokeWidth={2}
            />
             <line
              id="lineY"
              x1={150}
              x2={150 + fixedX}
              y1={150 }
              y2={150 }
              stroke="green"
              strokeWidth={2}
            />
            <line
              x1={0}
              x2={300}
              y1={150}
              y2={150}
              stroke="grey"
              strokeWidth={1}
            />
            <line
              y1={0}
              y2={300}
              x2={150}
              x1={150}
              stroke="grey"
              strokeWidth={1}
            />
            <circle r={10} fill="red" cx={270} cy={150} />
            <g id="arc" transform="translate(150,150)" />

            <text x={55} y={180} fontSize={20} fill="green">
              {" "}
              x = {xy[0]}{" "}
            </text>
            <text x={160} y={180} fontSize={20} fill="purple">
              {" "}
              y = {-1 * xy[1]}{" "}
            </text>
            <text x={280} y={150} fontSize={12}>
              1, 0
            </text>
            <text x={0} y={150} fontSize={12}>
              -1, 0
            </text>
            <text x={152} y={20} fontSize={12}>
              0,1
            </text>
            <text x={152} y={290} fontSize={12}>
              0,-1
            </text>
          </svg>
        </Grid>
        <Grid item style={{ padding: 10, backgroundColor: "#f3f3ff" }}>
          Control the radius by dragging the point <br />
          <svg width={300} height={300} ref={rayRef}>
            <line
              y1={150}
              y2={150}
              x2={150}
              x1={300}
              stroke="grey"
              strokeWidth={1}
            />
            <line
              y1={145}
              y2={155}
              x2={270}
              x1={270}
              stroke="grey"
              strokeWidth={1}
            />
            <line
              id="lineR"
              x1={150}
              x2={150 + adjustedX}
              y1={150}
              y2={150 + adjustedY}
              stroke="blue"
              strokeWidth={1}
            />

            <g id="innerArc" transform="translate(150,150)" />
            <text x={155} y={148} className="katex" fill="blue">
              {calculateDegree}
            </text>
            <text
              x={160}
              y={160}
              className="katex"
              style={{ color: "purple", fontSize: 10 }}
            >
              - {degree1 <= 0 ? 360 + degree1 : degree1}
            </text>
            <circle
              r={6}
              fill="blue"
              cx={150 + adjustedX}
              cy={150 + adjustedY}
            />
          </svg>
          <p>
            <InlineMath
              math={
                " \\color{green} x = \\color{blue} r \\color{black} \\times  cos(\\color{red} \\Theta \\color{black} )  \\color{green}=\\color{blue} r  \\color{black} \\times \\frac{\\color{green} x}{ \\color{blue} r} "
              }
            />{" "}
            <br />
            <InlineMath
              math={
                " = \\color{blue} " +
                Math.round(rayRadius / 1.2) / 100 +
                " \\color{black} \\times cos( \\color{red}" +
                calculateDegree +
                "\\color{black} ^{\\circ}) = \\color{green} " +
                Math.round((100 * xy[0] * rayRadius) / 120) / 100
              }
            />
          </p>
          <p>
            <InlineMath
             math={
              " \\color{purple} y = \\color{blue} r \\color{black} \\times  sin(\\color{red} \\Theta \\color{black})  \\color{purple}=\\color{blue} r  \\color{black} \\times \\frac{\\color{purple} y}{ \\color{blue} r} "
            }
            />
            <br />
            <InlineMath
              math={
                " \\color{blue} = " +
                Math.round(rayRadius / 1.2) / 100 +
                "  \\times sin(\\color{red}" +
                calculateDegree +
                "\\color{black} ^{\\circ} ) =  \\color{purple}" +
                Math.round((-100 * xy[1] * rayRadius) / 120) / 100
              }
            />
          </p>
        </Grid>

        <Grid
          xs={12}
          md={2}
          item
          style={{ padding: 10, backgroundColor: "#fff3f3" }}
        >
          <h4>Constructed Triangle</h4>
          The radius creates a right triangle with the x and y axis
          <br />
          <svg width={300} height={130}>
            <line
              y1={-1 * adjustedY >= 0 ? 125 : 10}
              y2={-1 * adjustedY >= 0 ? 125 : 10}
              x2={adjustedX >= 0 ? 10 : 210}
              x1={
                adjustedX >= 0
                  ? Math.abs(adjustedX) + 10
                  : 210 - Math.abs(adjustedX)
              }
              stroke="green"
              strokeWidth={2}
            />
            <line
              y1={-1 * adjustedY >= 0 ? 125 : 10}
              y2={-1 * adjustedY >= 0 ? 125 + adjustedY : 10 + adjustedY}
              x1={adjustedX >= 0 ? 10 : 210}
              x2={
                adjustedX >= 0
                  ? Math.abs(adjustedX) + 10
                  : 210 - Math.abs(adjustedX)
              }
              stroke="blue"
              strokeWidth={2}
            />
            <line
              y1={-1 * adjustedY >= 0 ? 125 : 10}
              y2={-1 * adjustedY >= 0 ? 125 + adjustedY : 10 + adjustedY}
              x2={
                adjustedX >= 0
                  ? Math.abs(adjustedX) + 10
                  : 210 - Math.abs(adjustedX)
              }
              x1={
                adjustedX >= 0
                  ? Math.abs(adjustedX) + 10
                  : 210 - Math.abs(adjustedX)
              }
              stroke="purple"
              strokeWidth={2}
            />
          </svg>
          <p>
            {" "}
            <InlineMath
              math={
                "r  = \\sqrt{ \\color{green}x^2 \\color{black} + \\color{purple}y^2 }"
              }
            />
          </p>
          <p style={{ fontSize: 12 }}>
            {" "}
            <InlineMath
              math={
                "= \\sqrt{ \\color{green}(" +
                xy[0] +
                ")^2 \\color{black} + \\color{purple}(" +
                -1 * xy[1] +
                ")^2 } = " +
                Math.round(rayRadius / 1.2) / 100
              }
            />
          </p>
          <h4 style={{ color: "red" }}>Radian Arc Length</h4>
          <svg width={320} height={100}>
            <line
              y1={10}
              y2={10}
              x2={0}
              x1={(calculateDegree * 300) / 360}
              stroke="red"
              strokeWidth={5}
            />
            {calculateDegree !== 0 && (
              <text
                fill="red"
                y={35}
                x={(calculateDegree * 300) / 360 - 10}
                fontSize={25}
              >
                {Math.round((calculateDegree * 100 * Math.PI) / 180) / 100}
              </text>
            )}
            <foreignObject
              style={{ padding: 10 }}
              ref={arcLengthRef}
              y={50}
              x={(calculateDegree * 300) / 360 - 10}
              width={50}
              height={50}
            />
          </svg>
          {rayRadius !== 120 && (
            <>
              <p>Adjusted Arc Length</p>
              <p>
                <InlineMath
                  math={
                    "\\color{red} \\Theta  \\color{black}\\times  r = \\color{red}" +
                    finalRadian +
                    "  \\color{black} (" +
                    Math.round(rayRadius / 1.2) / 100 +
                    ") = " +
                    Math.round(
                      (calculateDegree * (Math.PI / 180) * rayRadius) / 1.2
                    ) /
                      100
                  }
                />{" "}
              </p>{" "}
            </>
          )}
        </Grid>
        <Grid item style={{ padding: 10, fontSize: 20 }}>
          <h4>Angle</h4>
          <p>
            {" "}
            Degrees{" "}
            <InlineMath
              math={
                " \\enspace \\Theta = \\color{blue}" +
                calculateDegree +
                "^{\\circ}"
              }
            />{" "}
          </p>
          <p>Convert to Radians</p>
          <p>
            As a ratio:
            <InlineMath
              math={" \\enspace \\frac{180}{D} = \\frac{\\pi}{R}  "}
            />{" "}
          </p>
          <p>
            As a product:
            <InlineMath math={" \\enspace  180R =  D \\pi"} />{" "}
          </p>
          <p>
            <InlineMath math={" \\enspace  " + calculatedRadians} />{" "}
          </p>
        </Grid>
      </Grid>
    </>
  );
};

export default UnitCircle;
