import React, { useRef, useState, useEffect } from "react";
import { select } from "d3";
import "katex/dist/katex.min.css";
import { InlineMath } from "react-katex";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { set } from "lodash";

const LCD = props => {
  //const
  const dist = 100;
  const [start, setStart] = useState(false); //1 start   2 reset
  const [x1, setX1] = useState(2);
  const [y1, setY1] = useState(3);
  const [x2, setX2] = useState(4);
  const [y2, setY2] = useState(2);
  const svgRef = useRef();
  //const start = false;

  useEffect(() => {
    // move();
  }, []);

  const reset = () => {
    const svg = select(svgRef.current);

    const lr1 = svg.select("#lr1");
    const lr2 = svg.select("#lr2");
    const lr3 = svg.select("#lr3");
    const lr4 = svg.select("#lr4");
    const r1 = svg.select("#r1");
    const r2 = svg.select("#r2");
    setX1(2);
    setY2(2);

    lr1.transition().duration(1000).delay(1000).attr("cx", -5).attr("cy", 35);
    lr2.transition().duration(1000).delay(1000).attr("cx", -5).attr("cy", 35);
    lr3.transition().duration(1000).delay(1000).attr("cx", -5).attr("cy", 35);
    lr4.transition().duration(1000).delay(1000).attr("cx", -5).attr("cy", 35);
    r1.transition().duration(1000).delay(1000).attr("cx", -5).attr("cy", 35);
    r2.transition().duration(1000).delay(1000).attr("cx", -5).attr("cy", 35);
  };

  const move = () => {
    const svg = select(svgRef.current);

    const lr1 = svg.select("#lr1");
    const lr2 = svg.select("#lr2");
    const lr3 = svg.select("#lr3");
    const lr4 = svg.select("#lr4");
    const r1 = svg.select("#r1");
    const r2 = svg.select("#r2");

    lr1
      .transition().duration(1000)
      .delay(1000)
      .attr("cx", 10 + moreX)
      .attr("cy", 47);
    lr2
      .transition().duration(1000)
      .delay(1000)
      .attr("cx", 35 + moreX)
      .attr("cy", 10)
      .on("end", function () {
        setX1(3);
        lr3
          .transition().duration(1000)
          .delay(1000)
          .attr("cx", 22 + moreX)
          .attr("cy", 47);
        lr4
          .transition().duration(1000)
          .delay(1000)
          .attr("cx", 47 + moreX)
          .attr("cy", 10)
          .on("end", function () {
            setX1(4);
            r1.transition().duration(1000)
              .delay(1000)
              .attr("cx", 58 + dist + moreX)
              .attr("cy", 47);
            r2.transition().duration(1000)
              .delay(1000)
              .attr("cx", 58 + dist + moreX)
              .attr("cy", 10)
              .on("end", function () {setY2(3)});
          });
      });
  };

  const handleClick = select => {
    if (select) move();
    if (!select) reset();
    setStart(select);
  };

  const moreX = 40;
  return (
    <>
      <svg width={250} height={60} ref={svgRef}>
        <text x={20 + moreX} y={15}>
          1
        </text>
        <line
          x1={10 + moreX}
          x2={60 + moreX}
          y1={25}
          y2={25}
          strokeWidth={1}
          stroke="black"
        />
        <circle r={5} fill={"red"} cx={10 + moreX} cy={35} />
        <circle r={5} fill={"red"} cx={22 + moreX} cy={35} />
        <circle r={5} fill={"green"} cx={34 + moreX} cy={35} />
        <circle r={5} fill={"green"} cx={46 + moreX} cy={35} />
        <circle r={5} fill={"green"} cx={58 + moreX} cy={35} />

        <circle id={"lr1"} r={5} fill={"red"} cx={-5} cy={35} />
        <circle id={"lr2"} r={5} fill={"red"} cx={-5} cy={35} />
        <circle id={"lr3"} r={5} fill={"red"} cx={-5} cy={35} />
        <circle id={"lr4"} r={5} fill={"red"} cx={-5} cy={35} />
        <circle id={"r1"} r={5} fill={"green"} cx={-5} cy={35} />
        <circle id={"r2"} r={5} fill={"green"} cx={-5} cy={35} />

        <text x={80 + moreX} y={25}>
          +
        </text>
        <line
          x1={10 + dist + moreX}
          x2={60 + dist + moreX}
          y1={25}
          y2={25}
          strokeWidth={1}
          stroke="black"
        />
        <text x={20 + dist + moreX} y={15}>
          2
        </text>
        <circle r={5} fill={"red"} cx={10 + dist + moreX} cy={35} />
        <circle r={5} fill={"red"} cx={22 + dist + moreX} cy={35} />
        <circle r={5} fill={"red"} cx={34 + dist + moreX} cy={35} />
        <circle r={5} fill={"red"} cx={46 + dist + moreX} cy={35} />
        <circle r={5} fill={"green"} cx={58 + dist + moreX} cy={35} />
        <circle r={5} fill={"green"} cx={70 + dist + moreX} cy={35} />
      </svg>

      <p>
        <InlineMath
          math={
            "\\Large \\frac{1" +
            (x1 > 2
              ? "(\\color{red} x" +
                (x1 > 3 ? "^{" + (x1 - 2) + "}" : " ") +
                "\\color{black})"
              : "") +
            "}{\\color{red}x^{" +
            x1 +
            "} \\color{green}y^{" +
            y1 +
            "} } + \\frac{2" +
            (y2 > 2 ? "(\\color{green}y \\color{black})" : "") +
            "}{\\color{red}x^{" +
            x2 +
            "} \\color{green}y^{" +
            y2 +
            "} }"
          }
        />{" "}
      </p>
      <div>
        <hr />
        <svg width={250} height={60}>
          {y2 > 2 && (
            <g>
              <line
                x1={50}
                x2={100 + 2 * moreX}
                y1={25}
                y2={25}
                strokeWidth={1}
                stroke="black"
              />
              <circle r={5} fill={"red"} cx={10 + 2 * moreX} cy={35} />
              <circle r={5} fill={"red"} cx={22 + 2 * moreX} cy={35} />
              <circle r={5} fill={"red"} cx={34 + 2 * moreX} cy={35} />
              <circle r={5} fill={"red"} cx={46 + 2 * moreX} cy={35} />
              <circle r={5} fill={"green"} cx={58 + 2 * moreX} cy={35} />
              <circle r={5} fill={"green"} cx={70 + 2 * moreX} cy={35} />
              <circle r={5} fill={"green"} cx={82 + 2 * moreX} cy={35} />
              <circle r={5} fill={"red"} cx={10 + 2 * moreX} cy={10} />
              <circle r={5} fill={"red"} cx={22 + 2 * moreX} cy={10} />
              <text x={35 + 2 * moreX} y={15}>
                +
              </text>
              <text x={50 + 2 * moreX} y={15}>
                2 ({" "}
              </text>
              <circle r={5} fill={"green"} cx={77 + 2 * moreX} cy={10} />

              <text x={85 + 2 * moreX} y={15}>
                )
              </text>
            </g>
          )}
        </svg>
      </div>
      <p>
        <InlineMath
          math={
            "\\huge \\frac{" +
            (y2 > 2
              ? "\\color{red}x^2 \\color{black}+ 2\\color{green}y "
              : "\\quad") +
            "}{" +
            (y2 > 2 ? "\\color{red}x^4 \\color{green}y^3 " : "\\quad") +
            "}"
          }
        />
      </p>
      <p>
        <Button variant="outlined" onClick={() => handleClick(!start)}>
          {start ? " reset " : " start "}  LCD
        </Button>
      </p>
    </>
  );
};

export default LCD;
