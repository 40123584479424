import React, { useEffect, useRef, useState } from "react";

import { select, scaleOrdinal, schemeSet3 } from "d3";
import { _3d } from "d3-3d";

const Cube = props => {
  const gRef = useRef();

  const width = props.width || 300;
  const height = props.height || 300;
  const rotate = props.rotate || 0;

  const dx = props.dx ;
  const dy = props.dy ;
  const dz = props.dz ;
  const opacity = props.opacity;
  

  const [center, setCenter] = useState(
    props.center || [width / 2, height / 2, (width + height) / 4]
  );

  const cubes3D = _3d()
    .shape("CUBE")
    .x(function(d) {
      return d.x;
    })
    .y(function(d) {
      return d.y;
    })
    .z(function(d) {
      return d.z;
    })
    .origin(center)
   //.rotateCenter(center)
    .rotateZ(rotate)
    .rotateY(rotate)
    .rotateX(rotate);

  const color = scaleOrdinal(schemeSet3);

  const centeredCube = () => {
    const [x, y, z] = [0, 0 , 0];
    return [
      { x: x - dx, y: y + dy, z: z + dz }, // FRONT TOP LEFT
      { x: x - dx, y: y - dy, z: z + dz }, // FRONT BOTTOM LEFT
      { x: x + dx, y: y - dy, z: z + dz }, // FRONT BOTTOM RIGHT
      { x: x + dx, y: y + dy, z: z + dz }, // FRONT TOP RIGHT
      { x: x - dx, y: y + dy, z: z - dz }, // BACK  TOP LEFT
      { x: x - dx, y: y - dy, z: z - dz }, // BACK  BOTTOM LEFT
      { x: x + dx, y: y - dy, z: z - dz }, // BACK  BOTTOM RIGHT
      { x: x + dx, y: y + dy, z: z - dz } // BACK  TOP RIGHT
    ];
  };

  useEffect(() => {

    const cubesGroup = select(gRef.current);

    const cubes = cubesGroup
      .selectAll("g.cube")
      .data(cubes3D([centeredCube()]));

    let i = 0;
    const ce = cubes
      .enter()
      .append("g")
      .attr("class", "cube")
      .attr("stroke", "black")
      .merge(cubes)
      .sort(cubes3D.sort);

    cubes.exit().remove();

    const faces = cubes
      .merge(ce)
      .selectAll("path.face")
      .data(
        function(d) {
          return d.faces;
        },
        function(d) {
          return d.face;
        }
      );

    faces
      .enter()
      .append("path")
      .attr("class", "face")
      .attr("fill-opacity", opacity )
      .attr("fill", d => {
        const colorI = color(i++);
        return colorI;
      })
      .classed("_3d", true)
      .merge(faces)
      .transition()
      .duration(200)
      .attr("d", d => {
        return cubes3D.draw(d);
      });

    faces.exit().remove();
  }, [dx, dy, dz, rotate, opacity]);

  return (
    <svg width={width} height={height}>
      <g ref={gRef}></g>
      <circle cx = {width/2} cy = {height/2} r ={props.r || 10} />
      
    </svg>
  );
};

export default Cube;
