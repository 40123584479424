import React, { useState, useEffect, useRef } from "react";
import { select, drag, event } from "d3";
import { Grid, Slider, Paper, Button } from "@mui/material";
import "katex/dist/katex.min.css";
import { InlineMath } from "react-katex";
import { random } from "lodash";

const LcdDots = props => {
  const svgRef = useRef();
  const choiceRef = useRef();

  const [numerator1, setNumerator1] = useState(random(1, 3));
  const [numerator2, setNumerator2] = useState(random(1, 3));
  const [commonRed, setCommonRed] = useState(random(1, 3));
  const [commonBlue, setCommonBlue] = useState(random(1, 3));
  const [extraBlueRight, setExtraBlueRight] = useState(random(1, 4));
  const [extraRedLeft, setExtraRedLeft] = useState(random(1, 4));
  const [addedBlueLeft, setAddedBlueLeft] = useState(0);
  const [addedRedRight, setAddedRedRight] = useState(0);
  const [choices, setChoices] = useState(new Array(12).fill(false));
  const [result, setResult] = useState(false);

  const choiceObj = choices.map((v, i) => {
    return (
      <circle
        key={"choices-" + new Date().getTime() + "-" + i}
        id={"choice-" + (i < 6 ? "red" : "blue") + "-" + i}
        cx={(i % 6) * 20 + 20}
        cy={Math.floor(i / 6) * 20 + 20}
        r={6}
        style={{
          fill: i < 6 ? "red" : "blue",
          strokeWidth: 2,
          stroke: "black"
        }}
      />
    );
  });
  const [choicesDots, setChoicesDots] = useState(choiceObj);

  const currentRedLeft = extraRedLeft + commonRed;
  const currentBlueLeft = addedBlueLeft + commonBlue;
  const currentRedRight = addedRedRight + commonRed;
  const currentBlueRight = extraBlueRight + commonBlue;
  const leftRedDotsArray = new Array(extraRedLeft).fill("extra-left-red");
  const rightBlueDotsArray = new Array(extraBlueRight).fill("extra-right-blue");
  const commonRedArray = new Array(commonRed).fill("common-red");
  const commonBlueArray = new Array(commonBlue).fill("common-blue");

  const finalDenominator = leftRedDotsArray
    .concat(commonRedArray)
    .concat(commonBlueArray)
    .concat(rightBlueDotsArray)
    .map((v, i) => {
      return (
        <circle
          key={v + "-" + i}
          id={v + "-" + i}
          cx={(i % 4) * 20 + 135}
          cy={310 + Math.floor(i / 4) * 20}
          r={4}
          style={{
            fill: v.split("-").pop(),
            strokeWidth: 5,
            stroke: v.split("-").pop()
          }}
        />
      );
    });

  const numerator1Expanded = new Array(numerator1).fill(false).map((v1, i1) => {
    //const rect =
    return rightBlueDotsArray.map((v2, i2) => {
      return (
        <circle
          key={"answers-blue-" + i2 + "-" + i1}
          id={"answers-blue-" + i2 + "-" + i1}
          cx={(i2 % 6) * 20 + 80}
          cy={280 - i1 * 20}
          r={6}
          style={{
            fill: "blue",
            strokeWidth: 2,
            stroke: "black"
          }}
        />
      );
    });
  });

  const numerator2Expanded = new Array(numerator2).fill(false).map((v1, i1) => {
    //const rect =
    return leftRedDotsArray.map((v2, i2) => {
      return (
        <circle
          key={"answers-red-" + i2 + "-" + i1}
          id={"answers-red-" + i2 + "-" + i1}
          cx={(i2 % 6) * 20 + 160}
          cy={280 - i1 * 20}
          r={6}
          style={{
            fill: "red",
            strokeWidth: 2,
            stroke: "black"
          }}
        />
      );
    });
  });

  const leftDotsArray = leftRedDotsArray
    .concat(commonRedArray)
    .concat(commonBlueArray);
  const rightDotsArray = commonRedArray
    .concat(rightBlueDotsArray)
    .concat(commonBlueArray);

  const leftDots = leftDotsArray.map((v, i) => {
    return (
      <circle
        key={v + "-" + i}
        id={v + "-" + i}
        cx={(i % 4) * 20 + 30}
        cy={135 + Math.floor(i / 4) * 20}
        r={4}
        style={{
          fill: v.split("-").pop(),
          strokeWidth: 5,
          stroke: v.split("-").pop()
        }}
      />
    );
  });

  const rightDots = rightDotsArray.map((v, i) => {
    //.log(i, i <= missingRedRight, i <= commonRed + missingRedRight);
    return (
      <circle
        key={v + "-" + i}
        id={v + "-" + i}
        cx={(i % 4) * 20 + 220}
        cy={135 + Math.floor(i / 4) * 20}
        r={4}
        style={{
          fill: v.split("-").pop(),
          strokeWidth: 5,
          stroke: v.split("-").pop()
        }}
      />
    );
  });

  const handleDrag = drag()
    .subject(function(d) {
      //const me = select(this);
     // const me = d;
     // console.log({d, x : d.x})
      clearInterval(flashId);
      //select(svgRef.current).select("#dragText").remove();
      setFlash(-103)
      return { x: d.x, y: d.y };
    })
    .on("drag", function(event ) {
      const me = select(this);
      //console.log("on drag", {event, d})
      me.attr("cx", event.x);
      me.attr("cy", event.y);
      const array = me.attr("id").split("-");
      const id = array.pop();
      const color = array.pop();
    })
    .on("end", function(event) {
      const me = select(this);

      const array = me.attr("id").split("-");
      const id = array.pop();
      const color = array.pop();
      const x = me.attr("cx");
      const y = me.attr("cy");
      if (x < 120 && y > 120) {
        if (color === "blue" && addedBlueLeft !== extraBlueRight) {

          const total = currentRedLeft + currentBlueLeft;
          setAddedBlueLeft(addedBlueLeft + 1);
          me.transition()
            .attr("cx", (total % 4) * 20 + 30)
            .attr("cy", 135 + Math.floor(total / 4) * 20)
            .duration(500);

          select(svgRef.current)
            .append("circle")
            .attr("class", "remove")
            .attr("cx", 70 + addedBlueLeft * 15)
            .attr("cy", 100)
            .attr("r", 6)
            .style("fill", "blue")
            .style("stroke", "black")
            .style("stroke-width", 2);
        } else {
          me.transition()
            .attr("cx", event.subject.x)
            .attr("cy", event.subject.y)
            .duration(500);
        }
      } else if (x > 200 && y > 120) {
        if (color === "red" && addedRedRight !== extraRedLeft) {
          //setRemainingBlueLeft(remainingBlueLeft - 1);
          const total = currentBlueRight + currentRedRight;

          setAddedRedRight(addedRedRight + 1);
          me.transition()
            .attr("cx", (total % 4) * 20 + 220)
            .attr("cy", 135 + Math.floor(total / 4) * 20)
            .duration(500);

          /*  cx={(i % 4) * 20 + 220}
        cy={145 + Math.floor(i / 4) * 20} */

          select(svgRef.current)
            .append("circle")
            .attr("class", "remove")
            .attr("cx", 252 + addedRedRight * 15)
            .attr("cy", 100)
            .attr("r", 6)
            .style("fill", "red")
            .style("stroke", "black")
            .style("stroke-width", 2);
        } else {
          me.transition()
            .attr("cx", event.subject.x)
            .attr("cy", event.subject.y)
            .duration(500);
        }
      } else {
        me.transition()
          .attr("cx", event.subject.x)
          .attr("cy", event.subject.y)
          .duration(500);
      }
    });

  useEffect(() => {
    handleDrag(select(choiceRef.current).selectAll("circle"));
    if (addedRedRight === extraRedLeft && addedBlueLeft === extraBlueRight) {
      setResult(true);
    }
  }, [
    numerator1,
    numerator2,
    commonRed,
    commonBlue,
    extraBlueRight,
    extraRedLeft,
    addedBlueLeft,
    addedRedRight,
    choices,
    choicesDots,
    choiceObj
  ]);

  const tryAgain = () => {
    select(svgRef.current)
      .selectAll(".remove")
      .remove();
    setChoices(new Array(12).fill(false));
    setNumerator1(random(1, 3));
    setNumerator2(random(1, 3));
    setCommonRed(random(1, 3));
    setCommonBlue(random(1, 3));
    setExtraBlueRight(random(1, 4));
    setExtraRedLeft(random(1, 4));
    setAddedBlueLeft(0);
    setAddedRedRight(0);
    setResult(false);
    setChoicesDots(choiceObj);
  };

  const [flash, setFlash] = useState(0);
  const [flashId, setFlashId] = useState();
  function tick() {
    // savedCallback.current();
    flash > -1 && setFlash(flash + 1);
  }
  useEffect(() => {
    if (true) {
      let id = setInterval(tick, 500);
      setFlashId(id);
      return () => clearInterval(id);
    }
  }, [flash]);

  return (
    <>
      {" "}
      <h4>Lowest Common Denominator (LCD) </h4>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button
            style={{ marginRight: 10 }}
            onClick={() => tryAgain()}
            variant="outlined"
            color="secondary"
          >
            Reset
          </Button>
          <Button
            onClick={() => setResult(true)}
            variant="outlined"
            color="secondary"
          >
            Show Result
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <svg width={310} height={450} ref={svgRef}>
            <g ref={choiceRef}>
              <rect width={310} height={60} style={{ fill: "#ddffdd" }} />
              <text x={185} y={15}>
               {flash%5 === 0 ? " " : "reserves" }
              </text>
              <line
                x1={183}
                x2={244}
                y1={17}
                y2={17}
                style={{ strokeWidth: 1, stroke: "black" }}
              />
              <text x={150} y={35}>
                drag to complete{" "}
              </text>
              <text x={153} y={55} style={{ fontWeight: "bold" }}>
                the denominator
              </text>
              {choicesDots}
            </g>
            <rect
              y={70}
              width={310}
              height={125}
              style={{
                fill: "white",
                stroke: "black",
                strokeWidth: 2,
                fillOpacity: 0
              }}
            />
            <line
              id="leftDivide"
              x1={20}
              x2={120}
              y1={120}
              y2={120}
              style={{ strokeWidth: 3, stroke: "black" }}
            />
            <text x={45} y={110} style={{ fontSize: 30 }}>
              {numerator1}
            </text>
            <text x={225} y={110} style={{ fontSize: 30 }}>
              {numerator2}
            </text>
            <line
              id="plusHorizontal"
              x1={145}
              x2={175}
              y1={120}
              y2={120}
              style={{ strokeWidth: 2, stroke: "black" }}
            />
            <line
              id="plusVertical"
              x1={160}
              x2={160}
              y1={105}
              y2={135}
              style={{ strokeWidth: 2, stroke: "black" }}
            />
            <line
              id="rightDivide"
              x1={200}
              x2={300}
              y1={120}
              y2={120}
              style={{ strokeWidth: 3, stroke: "black" }}
            />
            <g>{leftDots}</g>
            <g>{rightDots}</g>
            {result && (
              <g>
                <rect
                  y={200}
                  width={310}
                  height={200}
                  style={{ fill: "pink", opacity: 0.6 }}
                />
                <text y={215} x={40}>
                  result
                </text>
                {numerator1Expanded}
                {numerator2Expanded}
                <line
                  id="answerDivide"
                  x1={20}
                  x2={300}
                  y1={294}
                  y2={294}
                  style={{ strokeWidth: 3, stroke: "black" }}
                />
                {finalDenominator}
              </g>
            )}
            <text id = {"dragText"}
                x={10}
                y={185}
                style={{ stroke: flash % 4 ? "blue" : "red" }}
              >
               {flash % 2 === 0 ?  "Drag from reserves to denominator here" : " "}
              </text>
          </svg>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <span style={{ fontSize: 20 }}>
            Current <span style={{ color: "red" }}>Reds</span> on the left:{" "}
            <span style={{ color: "red" }}> {currentRedLeft}</span>
          </span>
          <p style={{ fontSize: 20 }}>
            Current <span style={{ color: "red" }}>Reds</span> on the right:{" "}
            <span style={{ color: "red" }}> {currentRedRight}</span>
          </p>
          <p style={{ fontSize: 20 }}>
            <span style={{ color: "red" }}>Reds</span> needed on the Right:{" "}
            <span style={{ color: "red" }}>
              {" "}
              {extraRedLeft - addedRedRight}
            </span>
          </p>

          <p style={{ fontSize: 20 }}>
            Current <span style={{ color: "blue" }}>Blues</span> on the right:{" "}
            <span style={{ color: "blue" }}> {currentBlueRight}</span>
          </p>
          <p style={{ fontSize: 20 }}>
            Current <span style={{ color: "blue" }}>Blues</span> on the left:{" "}
            <span style={{ color: "blue" }}> {currentBlueLeft}</span>
          </p>
          <p style={{ fontSize: 20 }}>
            <span style={{ color: "blue" }}>Blues</span> needed on the left:{" "}
            <span style={{ color: "blue" }}>
              {" "}
              {extraBlueRight - addedBlueLeft}
            </span>
          </p>
        </Grid>
        <Grid item xs={12} sm={6} md={4} style={{ fontSize: 40 }}>
          <InlineMath
            math={
              "\\frac{" +
              numerator1 +
              (addedBlueLeft > 0
                ? "\\color{blue} b" +
                  (addedBlueLeft > 1 ? "^" + addedBlueLeft : " ")
                : " ") +
              "}{\\color{red} r^" +
              (commonRed + extraRedLeft) +
              "\\color{blue}b" +
              (commonBlue + addedBlueLeft === 1
                ? " "
                : "^" + (commonBlue + addedBlueLeft)) +
              "\\color{black} } + \\frac{" +
              numerator2 +
              (addedRedRight > 0
                ? "\\color{red} r" +
                  (addedRedRight > 1 ? "^" + addedRedRight : " ")
                : " ") +
              "}{\\color{blue} b^" +
              (commonBlue + extraBlueRight) +
              "\\color{red}r" +
              (commonRed + addedRedRight === 1
                ? " "
                : "^" + (commonRed + addedRedRight)) +
              "\\color{black} }"
            }
          />

          {result && (
            <div>
              <h6 style={{ marginBottom: 15 }}>written as one fraction</h6>

              <InlineMath
                math={
                  "\\frac{" +
                  (numerator1 > 1 ? numerator1 : " ") +
                  "\\color{blue}b " +
                  (extraBlueRight > 1 ? "^" + extraBlueRight : " ") +
                  " \\color{black} \\enspace + \\enspace " +
                  (numerator2 > 1 ? numerator2 : " ") +
                  "\\color{red}r " +
                  (extraRedLeft > 1 ? "^" + extraRedLeft : " ") +
                  "}{ " +
                  "\\color{red}r^" +
                  (commonRed + extraRedLeft) +
                  "\\color{blue}b^" +
                  (commonBlue + extraBlueRight) +
                  "}"
                }
              />
            </div>
          )}
        </Grid>
        <Paper
          style={{
            backgroundColor: "#eee",
            padding: 10,
            marginBottom: 10,
            marginTop: 0
          }}
        >
          Drag the dots so that both fractions have the same amount of each
          color in their respective denominator (bottom of the fraction)
          <p>
            To add fractions, we must make the denominators the same. We do this
            by introducing missing factors to both the top and bottom of the
            fractions.
          </p>
        </Paper>
      </Grid>{" "}
    </>
  );
};

export default LcdDots;
