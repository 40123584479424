import React, { useState, useEffect, useRef } from "react";
import { select } from "d3";
import { Grid, Slider, Paper, Button } from "@mui/material";
import "katex/dist/katex.min.css";
import { BlockMath, InlineMath } from "react-katex";
import { makeCurlyBrace } from "../../Tools/MakeBrackets";
function Dimensions() {
  const linearGraphRef = useRef();

  const defaultX = 2;
  const [x0, setX0] = useState(defaultX);
  const [dx0, setDx0] = useState(10);

  const [curlyPath, setCurlyPath] = useState();
  const [everyPointX0, setEveryPointX0] = useState(false);

  const change0 = (o, value) => {
    setX0(value);
  };

  const x0Shift = 60;
  const x0Cut = 3;
  useEffect(() => {
    setCurlyPath(
      makeCurlyBrace(
        -x0Shift + (x0Shift * (x0 + 3)) / x0Cut,
        30,
        (x0Shift * (x0 + 3)) / x0Cut + 6 * dx0 - 60,
        30,
        8,
        0.6
      )
    );

  }, [x0, dx0]);

  const styles = {
    red: {
      color: "red"
    },
    blue: {
      color: "blue"
    },
    paddingLeftSide: {
      paddingTop: 35
    }
  };

  const zero0 = -x0Shift + (x0Shift * (x0 + 3)) / x0Cut - 20;

  const oneD = <Grid item xs={12} md={4}>
    <Grid item xs={12}>
      <h4 style={styles.red}>
        Freedom of movement in{" "}
        <span style={{ fontSize: 20 }}> one dimension</span> with
        reference to
        <InlineMath math={"\\enspace 0 "} />
      </h4>
      <b>Control the location of the first point</b>
      <Slider style={styles.paddingLeftSide} defaultValue={defaultX} onChange={change0} aria-labelledby="discrete-slider" step={1} marks min={1} max={10} valueLabelDisplay="auto" valueLabelFormat={v => v - 2} />
      <b>Control the distance between points</b>
      <Slider style={styles.paddingLeftSide} defaultValue={10} onChange={(o, v) => setDx0(v)} aria-labelledby="discrete-slider" step={1} marks min={0} max={10} valueLabelDisplay="auto" />
      <InlineMath math={x0 -
        2 +
        " + \\color{red} " +
        dx0 +
        " \\color{black} =  \\color{blue}" +
        (x0 + dx0 - 2) +
        " \\quad"} />{" "}
      <input type="checkbox" name="vehicle1" value="Bike" onChange={() => setEveryPointX0(!everyPointX0)} />{" "}
      Every point between
    </Grid>
    <svg height={60} width={300} id="x0Svg">
      {everyPointX0 && (<line x1={-x0Shift + (x0Shift * (x0 + 3)) / x0Cut} y1={20} x2={-x0Shift + (x0Shift * (x0 + 3)) / x0Cut + 6 * dx0} y2={20} style={{ stroke: "green", strokeWidth: 2 }} />)}
      <path d={curlyPath} />
      <circle cx={-x0Shift + (x0Shift * (x0 + 3)) / x0Cut} cy={20} r={5} style={{ fill: "red" }} />
      <circle cx={-x0Shift + (x0Shift * (x0 + 3)) / x0Cut + 6 * dx0} cy={20} r={5} style={{ fill: "blue" }} />
      <text x={zero0} y="30" fill="black" style={{ fontSize: 20 }}>
        {x0 - 2}
      </text>

      {x0 !== 1 && (<text x={20} y="30" fill="black" style={{ fontSize: 20 }}>
        0
      </text>)}

      <text x={-x0Shift +
        (x0Shift * (x0 + 3)) / x0Cut +
        3 * dx0 -
        (dx0 < 10 ? 7 : 12)} y="55" fill="red" style={{ fontSize: 20 }}>
        {dx0}
      </text>
      <text x={-x0Shift + (x0Shift * (x0 + 3)) / x0Cut + 6 * dx0 + 8} y="30" fill="black" style={{ fontSize: 20 }}>
        {x0 + dx0 - 2}
      </text>
    </svg>
    <p style={{ fontSize: 20 }}>
      <InlineMath math={"\\color{red} " + dx0} /> is a fixed distance
      between 2 points
    </p>
    <p style={{ fontSize: 20 }}>
      <InlineMath math={x0 - 2 + ", \\color{blue} " + (x0 + dx0 - 2)} />{" "}
      represents the location of the points with zero dimension moving along a one dimensional path
      with 0 as a reference.
    </p>
    {everyPointX0 && (<p>
      <span style={{ color: "green" }}>The green line</span>  represents a collection of
      infinite points between the left and the right points
    </p>)}
    <p>
      Because these points are limited to one dimensional movement, we
      use a single bit of information to find their location. Each point
      has one  number associated with it that represents the distance from
      0.  Positive on the right of zero, and negative on the left. 
    </p>
  </Grid>;
  
  return (
    <>
        {oneD}
    </>
  );
}

export default Dimensions;
