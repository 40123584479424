import React from "react";
// import { withStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { BlockMath } from "react-katex";
import Typography from "@mui/material/Typography";

import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Search from "@mui/icons-material/Search";

import { demoList } from "./demoList";
// import * as _ from "lodash";

const useStyles = theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary
  }
});

function DemoLinks(props) {
  const [searchTerm, setSearchTerm] = React.useState("");
  const { classes } = props;
  const width = window.innerWidth;
  const [height, setHeight] = React.useState(window.innerHeight);

  const grid = demoList.map(
    (obj, i) =>
      obj.title.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1 && (
        <Grid item xs={6} md={3} key={"links-" + i + obj.id}>
          <Link to={"/" + obj.link}>
            <Paper sx={{padding: 3}} >
              <Typography gutterBottom>{obj.title}</Typography>
              {width > 680 && <BlockMath math={obj.math} />}
            </Paper>{" "}
          </Link>
        </Grid>
      )
  );

  const search = (
    <Grid container direction="row" alignItems="center">
      <Grid item>
        <Search />
      </Grid>
      <Grid item>search for a problem</Grid>
    </Grid>
  );

  const handleChange = event => {
    setSearchTerm(event.target.value);
  };
  const lessonBlocks = (
    <Grid container>
      <TextField
        id="standard-name"
        label={search}
        fullWidth
        
        //value={this.state.searchTerm}
        onChange={handleChange}
        margin="normal"
      />
      {props.fixed ? (
        <Grid container spacing={5} style={{ minHeight: height }}>
          {grid}
        </Grid>
      ) : (
        <Grid container spacing={5}>
          {grid}
        </Grid>
      )}
    </Grid>
  );

  return <div>{lessonBlocks}</div>;
}

export default (DemoLinks);
