import React from "react";

export const Square = props => {
  let width = (props.width * 40) / 480 || 40;
  //width = width
  const height = (props.width * 40) / 480 || 40;
  const x = props.x || 0;
  const y = props.y || 0;
  const fill = props.fill || "rgb(0,0,255)";
  const strokeWidth = props.strokeWidth || 1;
  const stroke = props.stroke || "rgb(0,0,0)";
  const id = props.id || "square-" + props.text;
  const color = props.color || "rgb(0,0,0)";
  const text = props.text !== null && (
    <text
      x={0}
      y={0.8 * height}
      fontFamily="Verdana"
      fill={color}
      style={{ padding: (5 * width) / 40 }}
      transform={"translate(" + (5 * width) / 40 + ",0)"}
      fontSize={width < 40 ? "x-small" : "small"}
    >
      {props.text}
    </text>
  );
  return (
    <g id={id} transform={"translate(" + x + "," + y + ")"}>
      <rect
        width={width}
        height={width}
        x={0}
        y={0}
        style={{
          fill,
          strokeWidth,
          stroke
        }}
      />
      {text}
    </g>
  );
};
