import React, { useState } from "react";
import { Grid, Slider, Paper } from "@mui/material";
import { InlineMath } from "react-katex";

import { scaleOrdinal, schemeCategory10 } from "d3";

const SubtractPlaceValues = props => {
  /**
   * row1 will may need to adjust incase digits in row2 make n1 - n2 < 0
   * row2   sum = n2
   */
  const [row1, setRow1] = useState([7, 8, 9]);
  const [row2, setRow2] = useState([6, 5, 4]);
  const color = scaleOrdinal(schemeCategory10);

 

  // n2 = value of row2 and will not change
  const n2 = row2[0] + row2[1] * 10 + row2[2] * 100;
  const n1 = row1[0] + 10 * row1[1] + 100 * row1[2] ;
  const positive = n1 - n2;

  const row1String =
    "\\color{" +
    color(2) +
    "}" +
    row1[2] +
    "\\color{" +
    color(1) +
    "}" +
    row1[1] +
    "\\color{" +
    color(0) +
    "}" +
    row1[0];

  const row2String =
    " \\color{" +
    color(2) +
    "}" +
    row2[2] +
    "\\color{" +
    color(1) +
    "}" +
    row2[1] +
    "\\color{" +
    color(0) +
    "}" +
    row2[0];

  const result = ("" + (n1 - n2)).split("");

  // need to reverse result array from least to most significant in case there are less than 4 digits
  result.reverse();

  const resultString =
    "\\color{" +
    color(3) +
    "}" +
    (result[3] ? result[3] : "") +
    "\\color{" +
    color(2) +
    "}" +
    (result[2] || "") +
    "\\color{" +
    color(1) +
    "}" +
    (result[1] || "") +
    "\\color{" +
    color(0) +
    "}" +
    (result[0] || "");

  const row1Hundred = new Array(row1[3]).fill();

  const sliders1 = row1.map((v, i) => {
   
    return (
      <Grid item key={"row1-" + i} xs={12}>
        <Slider
          defaultValue={row1[i]}
          onChange={(o, v) => {
            row1[i] = v;
            //sliced[3]= 0;
            setRow1(row1.concat([]));
          }}
          value = {row1[i]}
          aria-labelledby="discrete-slider"
          step={1}
          marks
          min={0}
          max={9}
          valueLabelDisplay="auto"
        />
      </Grid>
    );
  });

  sliders1.reverse();

  const sliders2 = row2.map((v, i) => {
    return (
      <Grid item key={"row2-" + i} xs={12}>
        <Slider
          defaultValue={row2[i]}
          onChange={(o, v) => {
            row2[i] = v;
            setRow2(row2.concat([]));
            //const oneMoreRow1 = row1temp.concat([]);
            //setRow1temp(oneMoreRow1);
          }}
          value = {row2[i]}
          aria-labelledby="discrete-slider"
          step={1}
          marks
          min={0}
          max={9}
          valueLabelDisplay="auto"
        />
      </Grid>
    );
  });

  sliders2.reverse();

  const row1Adjusted = row1.concat([]);

  const borrowed = { ten: false, hundred: false };

  if (row1Adjusted[0] < row2[0]) {
    row1Adjusted[0] = row1Adjusted[0] + 10;
    row1Adjusted[1] = row1Adjusted[1] - 1;
    borrowed.ten = true;
  }

  if (row1Adjusted[1] < row2[1]) {
    row1Adjusted[1] = row1Adjusted[1] + 10;
    row1Adjusted[2] = row1Adjusted[2] - 1;
    borrowed.hundred = true;
  }

  if (row1Adjusted[2] < row2[2]) {
    // row1Adjusted[2] = row1Adjusted[2] + 10;
    //if (row1Adjusted[3] === 1) row1Adjusted[3] = 0;
  }

  const xNext = 30;
  const r = 8;

  const hundredBeforeArray = new Array(row1Adjusted[2] >= 0 ?  row1Adjusted[2] : 0 ).fill(1);
  const hundredBefore = hundredBeforeArray.map((v, i) => {
    return (
      <circle
        key={"hundredBefore-" + i}
        cx={((i % 4) + 1) * r * 2.5 + 20}
        cy={(Math.floor(i / 4) + 1) * r * 2.5}
        r={r}
        fill={color(2)}
        opacity={i < row2[2] ? 0.3 : 1}
        strokeWidth={i >= row1[2] ? 3 : 0}
        stroke={"black"}
      />
    );
  });

  const tenBeforeArray = new Array(row1Adjusted[1] >= 0 ?  row1Adjusted[1] : 0 ).fill(1);
  const tenBefore = tenBeforeArray.map((v, i) => {
    return (
      <circle
        key={"tenBefore-" + i}
        cx={((i % 4) + 1) * r * 2.5 + 105}
        cy={(Math.floor(i / 4) + 1) * r * 2.5}
        r={r}
        fill={color(1)}
        opacity={i < row2[1] ? 0.3 : 1}
        strokeWidth={i >= row1[1] ? 3 : 0}
        stroke={"black"}
      />
    );
  });

  const oneBeforeArray = new Array(row1Adjusted[0] >= 0 ?  row1Adjusted[0] : 0).fill(1);
  const oneBefore = oneBeforeArray.map((v, i) => {
    return (
      <circle
        key={"oneBefore-" + i}
        cx={((i % 4) + 1) * r * 2.5 + 195}
        cy={(Math.floor(i / 4) + 1) * r * 2.5}
        r={r}
        fill={color(0)}
        opacity={i < row2[0] ? 0.3 : 1}
        strokeWidth={i >= row1[0] ? 3 : 0}
        stroke={"black"}
      />
    );
  });

  const yOff = 120;

  const row2HundredArray = new Array(row2[2]).fill(1);
  const row2Hundred = row2HundredArray.map((v, i) => {
    return (
      <circle
        key={"row2hundred-" + i}
        cx={((i % 4) + 1) * r * 2.5 + 20}
        cy={(Math.floor(i / 4) + 1) * r * 2.5 + yOff}
        r={r}
        fill={color(2)}
        opacity={0.3}
      />
    );
  });

  const row2TenArray = new Array(row2[1]).fill(1);
  const row2Ten = row2TenArray.map((v, i) => {
    return (
      <circle
        key={"row2ten-" + i}
        cx={((i % 4) + 1) * r * 2.5 + 105}
        cy={(Math.floor(i / 4) + 1) * r * 2.5 + yOff}
        r={r}
        fill={color(1)}
        opacity={0.3}
      />
    );
  });

  const row2OneArray = new Array(row2[0]).fill(1);
  const row2One = row2OneArray.map((v, i) => {
    return (
      <circle
        key={"row2one-" + i}
        cx={((i % 4) + 1) * r * 2.5 + 195}
        cy={(Math.floor(i / 4) + 1) * r * 2.5 + yOff}
        r={r}
        fill={color(0)}
        opacity={0.3}
      />
    );
  });

  const borrowHundred = borrowed.hundred && (
    <circle
      cx={((row1Adjusted[2] % 4) + 1) * r * 2.5 + 20}
      cy={(Math.floor((row1Adjusted[2] > 0 ? row1Adjusted[2] :  1 ) / 4) + 1) * r * 2.5}
      r={r}
      fill={color(1)}
      opacity={0.3}
      strokeWidth={row1Adjusted[2] >= row1[2] ? 3 : 0}
      stroke={"black"}
    />
  );

  const borrowTen = borrowed.ten && (
    <circle
      cx={((row1Adjusted[1] % 4) + 1) * r * 2.5 + 105}
      cy={(Math.floor(row1Adjusted[1] / 4) + 1) * r * 2.5}
      r={r}
      fill={color(0)}
      opacity={0.3}
      strokeWidth={row1Adjusted[1] >= row1[1] ? 2 : 0}
      stroke={"black"}
    />
  );

  

  return (
    <>
      <h4>Subtract Place Values</h4>
      <Grid container spacing={3}>
        <Grid item xs={2} md = {3}>
          <div style={{ fontSize: 25 }}>
            <InlineMath math={row1String} />
            <br />
            <InlineMath math={row2String} /> - <br />
            ------------ <br />
            <InlineMath math={resultString} />
            <Paper style = {{padding: 10}}>
              <p>
                <InlineMath
                  math={
                    " \\color{" +
                    color(0) +
                    "} " +
                    row1Adjusted[0] +
                    " - " +
                    row2[0] +
                    " = " +
                    (row1Adjusted[0] - row2[0])
                  }
                />
              </p>

              <p>
                <InlineMath
                  math={
                    "\\color{" +
                    color(1) +
                    "} " +
                    row1Adjusted[1] +
                    "0 - " +
                    row2[1] +
                    "0 = " +
                    (row1Adjusted[1] - row2[1]) +
                    "0"
                  }
                />
              </p>

              <p>
                <InlineMath
                  math={
                    "\\color{" +
                    color(2) +
                    "} " +
                    row1Adjusted[2] +
                    "00 " +
                    " - " +
                    row2[2] +
                    "00 = " +
                    (row1Adjusted[2] - row2[2]) +
                    "00"
                  }
                />
              </p>
            </Paper>
          </div>
        </Grid>
        <Grid item xs={10} md={3}>
          <h4>First Row Digits</h4> {sliders1} <h4>Second Row Digits</h4>
          {sliders2}
        </Grid>
        <Grid item xs={12} md={4}>
          <svg width={300} height={200}>
            
            {hundredBefore} {tenBefore} {oneBefore} {row2Hundred} {row2Ten}{" "}
            {borrowHundred} {borrowTen}
            {row2One}
          </svg>
        </Grid>
      </Grid>
    </>
  );
};

export default SubtractPlaceValues;
