import React, { useState, useEffect, useRef } from "react";
import { select, set } from "d3";
import JXG from "jsxgraph";
import Rectangle from "../Tools/SignedSquaresInRectangle";
import {
  Grid,
  Slider,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  FormHelperText,
  Paper
} from "@mui/material";
import "katex/dist/katex.min.css";
import {  InlineMath } from "react-katex";
// import NumberSelect from "../../components/SelectNumber";
import range from "lodash/range";
//import uniqueId from "lodash/range";
//import { Rectangle } from "../../components/Rectangle";

function DemoPage() {
  const [x, setX] = useState(1);
  const [dx, setDx] = useState(1);
  const [dy, setDy] = useState(2);
  const m = dy / dx;
  const [b, setB] = useState(-1);
  const deltaYs = range(10, -10);
  const deltaXs = range(10, -10);
  const yIntercepts = range(-10, 10);
  const [board, setBoard] = useState();
  const svgWidth = 300;
  const svgRef = useRef();
  const gcd = (a, b) => {
    if (!b) {
      return a;
    }
    return gcd(b, a % b);
  };

  const g = Math.abs(gcd(dy, dx));
  const dyReduced = dy / g;
  const dxReduced = dx / g;

  const makeGraph = () => {
    const xMax = Math.max(
      5 * Math.abs(dxReduced),
      Math.max(
        Math.abs(-5 * m * Math.abs(dxReduced) + b),
        Math.abs(5 * m * Math.abs(dxReduced) + b)
      )
    );

    //const xBound =
    if (board) JXG.JSXGraph.freeBoard(board);
    const brd = JXG.JSXGraph.initBoard("graph", {
      boundingbox: [-xMax, 1.1 * xMax, xMax, -1.1 * xMax],
      showCopyright: false,
      axis: true
    });

    const p1 = brd.create(
      "point",
      [Math.abs(dxReduced), m * Math.abs(dxReduced) + b],
      {
        name: Math.abs(dxReduced) + ", " + (m * Math.abs(dxReduced) + b),
        visible: true,
        snapSizeX: Math.abs(dxReduced),
        //snapSizeY: Math.abs(m) * 0.5,
        snapToGrid: true,
        size: 5
      }
    );

    const p0 = brd.create("point", [-100, -100 * m + b], {
      name: "0, 0",
      visible: false,
      fixed: true
    });

    const pB = brd.create("point", [0, b], {
      name: " b = " + b,
      visible: true,
      fixed: true,
      fillColor: "green",
      strokeColor: "green"
    });

    const line = brd.create("line", [p0, p1]);

    brd.on("move", function() {
      const px =
        p1.X() > 5 * Math.abs(dxReduced)
          ? 5 * Math.abs(dxReduced)
          : p1.X() < -5 * Math.abs(dxReduced)
          ? -5 * Math.abs(dxReduced)
          : p1.X();
      //const px = p1.X();
      p1.moveTo([px, m * px + b]);
      setX(px);
    });

    setBoard(brd);
  };

  useEffect(() => {
    setX(Math.abs(dxReduced));
    makeGraph();
  }, [dx, dy, b]);

  const styles = {
    red: {
      color: "red"
    },
    blue: {
      color: "blue"
    },
    paddingLeftSide: {
      paddingTop: 35
    }
  };

  const handleChangeOfX = value => {
    setX(value);
  };

  const xMenuTimes = deltaXs.map(
    v =>
      v !== 0 && (
        <MenuItem value={v} key={"dx-" + v}>
          {v}
        </MenuItem>
      )
  );

  const changeDx = event => {
    //setX(Math.abs(event.target.value));
    setDx(event.target.value);

    //setY(dy);
  };
  const selectDx = (
    <Select onChange={e => changeDx(e)} value={dx}>
      {xMenuTimes}
    </Select>
  );

  const yMenuTimes = deltaYs.map(v => (
    <MenuItem value={v} key={"dx-" + v}>
      {v}
    </MenuItem>
  ));

  const changeDy = event => {
    setDy(event.target.value);
    //setX(dx);
    //setY(dy);
  };

  const selectDy = (
    <Select onChange={e => changeDy(e)} value={dy}>
      {yMenuTimes}
    </Select>
  );

  const bMenuTimes = yIntercepts.map(v => (
    <MenuItem value={v} key={"dx-" + v}>
      {v}
    </MenuItem>
  ));

  const changeB = event => {
    setB(event.target.value);
  };

  const selectB = (
    <Select onChange={e => changeB(e)} value={b}>
      {bMenuTimes}
    </Select>
  );

  //console.log("m", m, x, m * x, m * x < 0);

  const mReduced =
    Math.abs(dxReduced) === 1
      ? (m < 0 ? " - " : " ") +
        "\\color{red} " +
        Math.abs(dyReduced) +
        "\\color{black}"
      : (m < 0 ? " - " : " ") +
        "\\frac{ \\color{red} " +
        Math.abs(dyReduced) +
        "}{ \\color{blue}" +
        Math.abs(dxReduced) +
        "} \\color{black} ";

  const mixedNumber = Math.abs(dxReduced) !== 1 &&
    Math.floor(Math.abs(dyReduced) / dxReduced) !== 0 && (
      <p>
        <InlineMath
          math={
            mReduced +
            "(" +
            x +
            ") =(" +
            Math.floor(dyReduced / dxReduced) +
            " + \\frac{\\color{green} " +
            (dyReduced % dxReduced) +
            "}{\\color{blue}" +
            dxReduced +
            "\\color{black} } ) (" +
            x +
            ") = " +
            m * x +
            "\\quad "
          }
        />
      </p>
    );

  const bSimplified = b === 0 ? " " : (b < 0 ? " - " : " + ") + Math.abs(b);

  const simplified = mReduced + " x " + bSimplified;

  const total = range(Math.abs(m * x + b)).map(i => (
    <svg width={30} height={30} key={"b-" + i}>
      <rect
        width={30}
        height={30}
        stroke="black"
        fill={m * x + b < 0 ? "aqua" : "lime"}
      />
      <text x="5" y="15" fill="red">
        {i + 1}
      </text>
    </svg>
  ));

  const totalMx = range(Math.abs(m * x)).map(i => (
    <svg width={30} height={30} key={"b-" + i}>
      <rect
        width={30}
        height={30}
        stroke="black"
        fill={m * x < 0 ? "aqua" : "lime"}
      />
    </svg>
  ));
  const formStyle = { minWidth: 120, marginBottom: 20, marginRight: 10 };
  return (
    <>
      <h4>Linear Graph Controls Rectangle</h4>
      <p>
        {" "}
        Move the <span style={{ color: "red" }}>red</span> point on the graph
        and observe the changes
      </p>
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <Grid item xs={12}>
            <div
              id="graph"
              //ref={linearGraphRef}
              style={{ width: 300, height: 300 }}
            />
          </Grid>
          <Grid item xs={12}>
            <p>
              <InlineMath
                math={
                  "y = " +
                  mReduced +
                  " (x) " +
                  bSimplified +
                  "\\quad \\quad \\quad x = " +
                  x
                }
              />
            </p>
            <p>
              <InlineMath
                math={
                  "y = " +
                  mReduced +
                  " (" +
                  x +
                  ") " +
                  bSimplified +
                  "\\quad \\quad \\quad (" +
                  x +
                  "," +
                  (m * x + b) +
                  ")"
                }
              />
            </p>

            <FormControl style={formStyle}>
              <InputLabel>
                {" "}
                <InlineMath>\Delta Y</InlineMath>
              </InputLabel>
              {selectDy}
              <FormHelperText>change of y</FormHelperText>
            </FormControl>
            <FormControl style={formStyle}>
              <InputLabel>
                {" "}
                <InlineMath>\Delta X</InlineMath>
              </InputLabel>
              {selectDx}
              <FormHelperText>change of x</FormHelperText>
            </FormControl>
            <FormControl style={formStyle}>
              <InputLabel>
                {" "}
                <InlineMath>b</InlineMath>
              </InputLabel>
              {selectB}
              <FormHelperText>Y intercept</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <div style={{ fontSize: 25, paddingBottom: 10 }}>
            <InlineMath math={" y = " + simplified} />
            <InlineMath
              math={
                "\\quad \\quad y = \\frac{\\color{red} \\Delta y}{\\color{blue} \\Delta x} \\color{black}  x +  \\color{green} b "
              }
            />

            {(g !== 1 || dx === 1) && (
              <InlineMath
                math={
                  "\\quad \\quad y = \\frac{\\color{red}" +
                  dy +
                  "}{\\color{blue}" +
                  dx +
                  "} \\color{black}  x +  \\color{green}" +
                  b
                }
              />
            )}
          </div>

          <Paper
            style={{ backgroundColor: "#eee", paddingLeft: 10, marginTop: 10 }}
          >
            <Grid container spacing={4}>
              <Grid item style={{ fontSize: 25 }} xs={5}>
                <InlineMath
                  math={
                    mReduced + " x  = " + mReduced + "(" + x + ") =" + m * x
                  }
                />
              </Grid>
              <Grid item>
                {Math.abs(dyReduced) >= Math.abs(dxReduced) && dx !== 0 && (
                  <Rectangle // whole parts
                    w={Math.floor(Math.abs(dyReduced) / Math.abs(dxReduced))}
                    h={Math.abs(x)}
                    signed={m * x < 0 ? true : false}
                    width={
                      Math.ceil(Math.abs(dyReduced) / Math.abs(dxReduced)) * 30
                    }
                    columnsPerRow={Math.ceil(
                      Math.abs(dyReduced) / Math.abs(dxReduced)
                    )}
                    //dx={Math.abs(dxReduced)}
                    //={Math.abs(dyReduced)}
                    // signed={true}
                  />
                )}

                {dyReduced % dxReduced !== 0 && dx !== 0 && (
                  <Rectangle
                    w={1}
                    h={Math.abs(x)}
                    signed={m * x < 0 ? true : false}
                    dx={Math.abs(dxReduced)}
                    dy={Math.abs(dyReduced) % Math.abs(dxReduced)}
                    width={30}
                    columnsPerRow={1}
                  />
                )}
              </Grid>

              {dyReduced % dxReduced !== 0 && dx !== 0 && x !== 0 && (
                <Grid item xs={12} style={{ borderStyle: "dashed" }}>
                  {" "}
                  {Math.floor(Math.abs(dyReduced) / Math.abs(dxReduced)) > 0 &&
                    "Whole with"}{" "}
                  parts together {mixedNumber}
                  {totalMx}
                </Grid>
              )}
            </Grid>
          </Paper>

          <Paper
            style={{ backgroundColor: "#ddd", paddingLeft: 10, marginTop: 10 }}
          >
            <Grid container spacing={4}>
              <Grid item style={{ fontSize: 25 }} xs={4}>
                <InlineMath math={" b  = " + b} />
              </Grid>
              <Grid item>
                <Rectangle
                  w={1}
                  h={Math.abs(b)}
                  signed={b < 0 ? true : false}
                />
              </Grid>
            </Grid>
          </Paper>

          <Paper
            style={{ backgroundColor: "#eee", paddingLeft: 10, marginTop: 10 }}
          >
            <Grid container spacing={4}>
              <Grid item style={{ fontSize: 18 }} xs={4}>
                total:{" "}
                <InlineMath
                  math={
                    "\\enspace y   = " +
                    m * x +
                    (b < 0 ? " - " : " + ") +
                    Math.abs(b) +
                    " = " +
                    (m * x + b)
                  }
                />
              </Grid>
              <Grid item>{total}</Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default DemoPage;
