import React, { useState } from "react";
import { Grid, Slider } from "@mui/material";
import { InlineMath } from "react-katex";

import { scaleOrdinal, schemeCategory10 } from "d3";

const AddPlaceValues = props => {
  /**
   * 6 columns for power of ten
   * 2 rows for 2 numbers
   * another row for carry over
   * another row for total
   * 6x4 matrix
   */

  const [row1, setRow1] = useState([1, 2, 3, 4, 5, 6]);
  const [row2, setRow2] = useState([6, 5, 4, 3, 2, 1]);
  const carry = [0]; // carry over any sum from the i - 1 over 10
  const remain = [];

  const sumAB = Array(row1.length); // sum without carry
  const sumColumn = Array(row1.length); // sum with carry
  for (let i = 0; i < row1.length; ++i) {
    sumAB[i] = row1[i] + row2[i];
    sumColumn[i] = sumAB[i] + carry[i];
    carry[i + 1] = sumColumn[i] < 10 ? 0 : 1;
    remain[i] = sumColumn[i] < 10 ? sumColumn[i] : sumColumn[i] - 10;
  }

  const sliders1 = row1.map((v, i) => {
    return (
      <Grid item key={"row1-" + i} xs={12}>
        <Slider
          defaultValue={row1[i]}
          onChange={(o, v) => {
            row1[i] = v;
            setRow1(row1.concat([]));
          }}
          aria-labelledby="discrete-slider"
          step={1}
          marks
          min={0}
          max={9}
          valueLabelDisplay="auto"
        />
      </Grid>
    );
  });

  const sliders2 = row2.map((v, i) => {
    return (
      <Grid item key={"row1-" + i} xs={12}>
        <Slider
          defaultValue={row2[i]}
          onChange={(o, v) => {
            row2[i] = v;
            setRow2(row2.concat([]));
          }}
          aria-labelledby="discrete-slider"
          step={1}
          marks
          min={0}
          max={9}
          valueLabelDisplay="auto"
        />
      </Grid>
    );
  });

  const color = scaleOrdinal(schemeCategory10);

  const reversed1 = row1.concat([]).reverse();
  let string1 = "\\enspace ";
  for (let i = 0; i < reversed1.length; ++i) {
    string1 +=
      "\\color{" +
      color(i + 1) +
      "} " +
      reversed1[i] +
      (i === 2 ? "\\color{black}." : "");
  }

  const reversed2 = row2.concat([]).reverse();
  let string2 = " \\enspace";
  for (let i = 0; i < reversed2.length; ++i) {
    string2 +=
      "\\color{" +
      color(i + 1) +
      "} " +
      reversed2[i] +
      (i === 2 ? "\\color{black}." : "");
  }

  //sumColumn
  const reverseRemain = remain.concat([]).reverse();
  let stringSum = carry[6] === 0 ? "\\enspace " : "\\color{"+color(0) + "}1";
  for (let i = 0; i < reverseRemain.length; ++i) {
    stringSum +=
      "\\color{" +
      color(i + 1) +
      "} " +
      reverseRemain[i] +
      (i === 2 ? "\\color{black}." : "");
  }

  const reverseCarry = carry.concat([]).reverse();
  let stringCarry = "";
  for (let i = 0; i < reverseCarry.length; ++i) {
    stringCarry +=
      "\\color{#ddd}" + reverseCarry[i] + (i === 3 ? "\\color{black}." : "");
  }

  const firstNumberString = <InlineMath math={string1} />;
  const secondNumberString = <InlineMath math={string2 + "\\enspace \\color{black} + "} />;
  const sumString = <InlineMath math={stringSum} />;
  const carryString = <InlineMath math={stringCarry} />;

  const lastX = 180;
  const diffX = 20;
  const yBetween = 150;

  const dots11Array = new Array(row1[0]).fill(1);
  const dots11 = dots11Array.map((v, i) => (
    <circle
      key={"dot11-" + i}
      cx={lastX}
      cy={(i + 1) * 12}
      r={5}
      fill={color(6)}
      opacity={sumColumn[0] < 10 ? 1 : 0.5}
    />
  ));

  const dots21Array = new Array(row2[0]).fill(1);
  const dots21 = dots21Array.map((v, i) => (
    <circle
      key={"dot21-" + i}
      cx={lastX}
      cy={yBetween + (i + 1) * 12}
      r={5}
      fill={color(6)}
      opacity={sumColumn[0] < 10 ? 1 : row1[0] + i > 9 ? 1 : 0.5}
    />
  ));

  const dots12Array = new Array(row1[1]).fill(1);
  const dots12 = dots12Array.map((v, i) => (
    <circle
      key={"dot12-" + i}
      cx={lastX - diffX}
      cy={(i + 1) * 12}
      r={5}
      fill={color(5)}
      opacity={sumColumn[1] < 10 ? 1 : 0.5}
    />
  ));

  const dots22Array = new Array(row2[1]).fill(1);
  const dots22 = dots22Array.map((v, i) => (
    <circle
      key={"dot22-" + i}
      cx={lastX - diffX}
      cy={(i + 1) * 12 + yBetween}
      r={5}
      fill={color(5)}
      opacity={sumColumn[1] < 10 ? 1 : row1[1] + i > 9 ? 1 : 0.5}
    />
  ));

  const carry2dot = carry[1] > 0 && (
    <circle cx={lastX - diffX} cy={yBetween * 2} r={5} fill={color(6)} />
  );

  const dots13Array = new Array(row1[2]).fill(1);
  const dots13 = dots13Array.map((v, i) => (
    <circle
      key={"dot13-" + i}
      cx={lastX - diffX * 2}
      cy={(i + 1) * 12}
      r={5}
      fill={color(4)}
      opacity={sumColumn[2] < 10 ? 1 : 0.5}
    />
  ));

  const dots23Array = new Array(row2[2]).fill(1);
  const dots23 = dots23Array.map((v, i) => (
    <circle
      key={"dot23-" + i}
      cx={lastX - diffX * 2}
      cy={(i + 1) * 12 + yBetween}
      r={5}
      fill={color(4)}
      opacity={sumColumn[2] < 10 ? 1 : row1[2] + i > 9 ? 1 : 0.5}
    />
  ));

  const carry3dot = carry[2] > 0 && (
    <circle cx={lastX - diffX * 2} cy={yBetween * 2} r={5} fill={color(5)} />
  );

  const dots14Array = new Array(row1[3]).fill(1);
  const dots14 = dots14Array.map((v, i) => (
    <circle
      key={"dot14-" + i}
      cx={lastX - diffX * 3}
      cy={(i + 1) * 12}
      r={5}
      fill={color(3)}
      opacity={sumColumn[3] < 10 ? 1 : 0.5}
    />
  ));

  const dots24Array = new Array(row2[3]).fill(1);
  const dots24 = dots24Array.map((v, i) => (
    <circle
      key={"dot24-" + i}
      cx={lastX - diffX * 3}
      cy={(i + 1) * 12 + yBetween}
      r={5}
      fill={color(3)}
      opacity={sumColumn[3] < 10 ? 1 : row1[3] + i > 9 ? 1 : 0.5}
    />
  ));

  const carry4dot = carry[3] > 0 && (
    <circle cx={lastX - diffX * 3} cy={yBetween * 2} r={5} fill={color(4)} />
  );

  const dots15Array = new Array(row1[4]).fill(1);
  const dots15 = dots15Array.map((v, i) => (
    <circle
      key={"dot15-" + i}
      cx={lastX - diffX * 4}
      cy={(i + 1) * 12}
      r={5}
      fill={color(2)}
      opacity={sumColumn[4] < 10 ? 1 : 0.5}
    />
  ));

  const dots25Array = new Array(row2[4]).fill(1);
  const dots25 = dots25Array.map((v, i) => (
    <circle
      key={"dot25-" + i}
      cx={lastX - diffX * 4}
      cy={(i + 1) * 12 + yBetween}
      r={5}
      fill={color(2)}
      opacity={sumColumn[4] < 10 ? 1 : row1[4] + i > 9 ? 1 : 0.5}
    />
  ));

  const carry5dot = carry[4] > 0 && (
    <circle cx={lastX - diffX * 4} cy={yBetween * 2} r={5} fill={color(3)} />
  );

  const dots16Array = new Array(row1[5]).fill(1);
  const dots16 = dots16Array.map((v, i) => (
    <circle
      key={"dot16-" + i}
      cx={lastX - diffX * 5}
      cy={(i + 1) * 12}
      r={5}
      fill={color(1)}
      opacity={sumColumn[5] < 10 ? 1 : 0.5}
    />
  ));

  const dots26Array = new Array(row2[5]).fill(1);
  const dots26 = dots26Array.map((v, i) => (
    <circle
      key={"dot26-" + i}
      cx={lastX - diffX * 5}
      cy={(i + 1) * 12 + yBetween}
      r={5}
      fill={color(1)}
      opacity={sumColumn[5] < 10 ? 1 : row1[5] + i > 9 ? 1 : 0.5}
    />
  ));

  const carry6dot = carry[5] > 0 && (
    <circle cx={lastX - diffX * 5} cy={yBetween * 2} r={5} fill={color(2)} />
  );

  const carry7dot = carry[6] > 0 && (
    <circle cx={lastX - diffX * 6} cy={yBetween * 2} r={5} fill={color(1)} />
  );

  return (
    <>
    <h4>Add Place Values</h4>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <h4>First row</h4>
          {sliders1}
          <br />
          <div style={{ fontSize: 25 }}>
            {carryString} <br /> {firstNumberString} <br /> {secondNumberString}{" "}
            <br /> ------------ <br /> {sumString}
          </div>
        </Grid>
        <Grid item xs={12} md={3}>
          <h4>Second row</h4>
          {sliders2}
          <br />
          <div style={{ fontSize: 20 }}>
            <InlineMath
              math={
                "\\color{" +
                color(6) +
                "} " +
                remain[0] +
                "\\times 0.001 = 0.00 " +
                remain[0] 
              }
            />
            <br />
            <InlineMath
              math={
                "\\color{" +
                color(5) +
                "} " +
                remain[1] +
                "\\times 0.01 = 0.0 " +
                remain[1] 
              }
            />
            <br />
            <InlineMath
              math={
                "\\color{" +
                color(4) +
                "} " +
                remain[2] +
                "\\times 0.1 = 0." +
                remain[2]
              }
            />
            <br />
            <InlineMath
              math={
                "\\color{" +
                color(3) +
                "} " +
                remain[3] +
                "\\times 1 = " +
                remain[3]
              }
            />
            <br />
            <InlineMath
              math={
                "\\color{" +
                color(2) +
                "} " +
                remain[4] +
                "\\times 10 = " +
                remain[4] * 10
              }
            />
            <br />
            <InlineMath
              math={
                "\\color{" +
                color(1) +
                "} " +
                remain[5] +
                "\\times 100 = " +
                remain[5] * 100
              }
            />
            <br />
            <InlineMath
              math={
                "\\color{" +
                color(0) +
                "} " +
                carry[6] +
                "\\times 1000 = " +
                carry[6] * 1000
              }
            />
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <svg width={200} height={400}>
            {dots11} {dots21} {dots12} {dots22} {carry2dot} {dots13} {dots23}{" "}
            {carry3dot}
            {dots14} {dots24} {carry4dot} {dots15} {dots25} {carry5dot}
            {dots16} {dots26} {carry6dot} {carry7dot}
          </svg>
        </Grid>
      </Grid>
    </>
  );
};

export default AddPlaceValues;
