import React, { useEffect, useRef, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import BasicExponent from "../Pages/BasicExponent";
import Home from "../Pages/Home/Home";
import MixedNumber from "../Pages/MixedNumber";
import AddPlaceValues from "../Pages/AddPlaceValues";
import SubtractPlaceValues from "../Pages/SubtractPlaceValues";
import DemoLinks from "../Pages/DemoLinks/DemoLinks";
import BasicExponentMultiply from "../Pages/BasicExponentMultiply";
import BasicExponentDivide from "../Pages/BasicExponentDivide";
import Tenths from "../Pages/Tenths";
import Bases from "../Pages/Bases";
import Base10 from "../Pages/Base10";
import AddSubtractOnNumberLine from "../Pages/AddSubtractOnNumberLine";
import AddSubCleanNumber from "../Pages/AddSubCleanNumber";
import WidthVsAreaRectangle from "../Pages/WidthVsAreaRectangle";
import WidthVsAreaSquare from "../Pages/WidthVsAreaSquare";
import MultiplyDivide from "../Pages/MultiplyDivide";
import DivideByFraction from "../Pages/DivideByFraction";
import Radians from "../Pages/Radians";
import PositiveNegativeCancel from "../Pages/PositiveNegativeCancel";
import BasicNumberline from "../Pages/BasicNumberLine";
import Dimensions from "../Pages/dimensions/Dimensions";
import PerimeterAreaGraph from "../Pages/PerimeterAreaGraph";
import MultiplyGrid from "../Pages/MultiplyGrid";
import MoreThanNumberLine from "../Pages/MoreThanNumberLine";
import LessThanNumberLine from "../Pages/LessThanNumberLine";
import LinearGraphObject from "../Pages/LinearGraphObject";
import VisualizeSquareRoot from "../Pages/VisualizeSquareRoot";
import LineControlsRectangle from "../Pages/LineControlsRectangle";
import MultiplySingedObjects from "../Pages/MultiplySingedObjects";
import DirectRatio from "../Pages/DirectRatio";
import RootSvg from "../Pages/RootSvg";
import CommonDenominator from "../Pages/CommonDenominator";
import DivideDivision from "../Pages/DivideDivision";
import LCD from "../Pages/LcdDots";
import Factor from "../Pages/Factors";
import StatisticalOutlier from "../Pages/StatisticalOutlier";
import DeltaYX from "../Pages/DeltaYX";
import UnitCircle from "../Pages/UnitCircle";
import SimilarTriangles from "../Pages/SimilarTriangles";
import MultiplyBeforeDivide from "../Pages/MultiplyBeforeDivide";
import LongDivideInt from "../Pages/LongDivideInt";
import Perimeter from "../Pages/Perimeter";
import GraphingExplained from "../Pages/GraphingExplained";
import MissingLength from "../Pages/MissingLength";
import ReduceFractions from "../Pages/ReduceFraction";
import ExpandMultiply from "../Pages/ExpandMultiply";
import AddingRectangles from "../Pages/AddingRectangles";
import PrimeFactors from "../Pages/PrimeFactors";
import OneStepEq from "../Pages/OneStepEq";
import OneStepMultEq from "../Pages/OneStepMultEq";
import TwoStepEq from "../Pages/TwoStepEq";
import About from "../Pages/About/About";

export default function SiteRoutes(props) {
  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/about" element={<About />} />
      <Route
        exact
        path="/login"
        element={() => {
          window.location.href = "https://mathcart.com/tutor/my-account/";
          return null;
        }}
      />
      <Route exact path="/two-step-equation" element={<TwoStepEq />} />
      <Route
        exact
        path="/one-step-multiply-equation"
        element={<OneStepMultEq />}
      />
      <Route exact path="/one-step-equation" element={<OneStepEq />} />
      <Route exact path="/prime-factors" element={<PrimeFactors />} />
      <Route
        exact
        path="/finding-rectangular-areas"
        element={<AddingRectangles />}
      />

      <Route exact path="/expand-multiply" element={<ExpandMultiply />} />

      <Route exact path="/reduce-fraction" element={<ReduceFractions />} />
      <Route exact path="/missing-length" element={<MissingLength />} />
      <Route exact path="/graphing-explained" element={<GraphingExplained />} />
      <Route exact path="/rectangle-perimeter" element={<Perimeter />} />
      <Route exact path="/long-divide-algorithm" element={<LongDivideInt />} />
      <Route
        exact
        path="/multiply-before-add"
        element={<MultiplyBeforeDivide />}
      />
      <Route exact path="/similar-triangle" element={<SimilarTriangles />} />
      <Route exact path="/delta-y-x" element={<DeltaYX />} />
      <Route exact path="/unit-circle" element={<UnitCircle />} />
      <Route
        exact
        path="/statistical-outliers"
        element={<StatisticalOutlier />}
      />
      <Route
        exact
        path="/associative-property-multiplication"
        element={<Factor />}
      />
      <Route exact path="/lcd-dots" element={<LCD />} />
      <Route exact path="/divide-division" element={<DivideDivision />} />
      <Route exact path="/common-denominator" element={<CommonDenominator />} />
      <Route exact path="/square-root-radicals" element={<RootSvg />} />
      <Route exact path="/direct-ratios" element={<DirectRatio />} />
      <Route
        exact
        path="/line-to-rectangle"
        element={<LineControlsRectangle />}
      />
      <Route
        exact
        path="/visualize-square-root"
        element={<VisualizeSquareRoot />}
      />
      <Route
        exact
        path="/linear-graph-object"
        element={<LinearGraphObject />}
      />
      <Route
        exact
        path="/subtract-number-line"
        element={<LessThanNumberLine />}
      />
      <Route exact path="/add-number-line" element={<MoreThanNumberLine />} />
      <Route exact path="/multiply-grid" element={<MultiplyGrid />} />
      <Route exact path="/area-perimeter" element={<PerimeterAreaGraph />} />
      <Route exact path="/Dimensions" element={<Dimensions />} />
      <Route exact path="/basic-number-line" element={<BasicNumberline />} />

      <Route
        exact
        path="/multiply-divide-inverse"
        element={<MultiplyDivide />}
      />
      <Route
        exact
        path="/add-subtract-number-line"
        element={<AddSubtractOnNumberLine />}
      />
      <Route
        exact
        path="/add-subtract-basics"
        element={<AddSubCleanNumber />}
      />
      <Route exact path="/2-pi-radians" element={<Radians />} />
      <Route exact path="/delta-w-square" element={<WidthVsAreaSquare />} />
      <Route
        exact
        path="/delta-w-rectangle"
        element={<WidthVsAreaRectangle />}
      />
      <Route
        exact
        path="/multiply-signed-objects"
        element={<MultiplySingedObjects />}
      />
      <Route
        exact
        path="/positive-negative-cancel-addition"
        element={<PositiveNegativeCancel />}
      />
      <Route exact path="/bases" element={<Bases />} />
      <Route exact path="/base10" element={<Base10 />} />
      <Route exact path="/basic-exponent" element={<BasicExponent />} />
      <Route
        exact
        path="/basic-exponent-multiply"
        element={<BasicExponentMultiply />}
      />
      <Route
        exact
        path="/basic-exponent-divide"
        element={<BasicExponentDivide />}
      />

      <Route exact path="/divide-by-fraction" element={<DivideByFraction />} />
      <Route exact path="/lesson-demos" element={<DemoLinks />} />
      <Route exact path="/mixed-number" element={<MixedNumber />} />
      <Route exact path="/add-place-values" element={<AddPlaceValues />} />
      <Route
        exact
        path="/subtract-place-values"
        element={<SubtractPlaceValues />}
      />
    </Routes>
  );
}
