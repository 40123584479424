import React, { useState } from "react";

import { Grid, Slider, Button } from "@mui/material";
import { scaleOrdinal, schemeCategory10, schemePastel1 } from "d3";
import "katex/dist/katex.min.css";
import { InlineMath } from "react-katex";
import PieFraction from "../Tools/PieFraction";

const DivideByFraction = props => {
  const svgHeight = 120;
  const [numerator1, setNumerator1] = useState(12);
  const [denominator1, setDenominator1] = useState(4);

  const [moved1, setMoved1] = useState(0);
  const notMoved1 = numerator1 - moved1;
  const numerator1Array = new Array(notMoved1).fill(denominator1);

  const denominator1Array = new Array(denominator1).fill(numerator1);
  const numberOfContainers = new Array(
    Math.floor(numerator1 / denominator1)
  ).fill(numerator1);
  const color = scaleOrdinal(schemeCategory10); //schemePastel1
  const divColor = scaleOrdinal(schemePastel1);

  const whole1 = Math.floor(numerator1 / denominator1);
  const part = numerator1 % denominator1;
  const gcd = (a, b) => {
    if (!b) {
      return a;
    }
    return gcd(b, a % b);
  };

  const g = gcd(part, denominator1);

  const reduced =
    part === 0
      ? " = " + whole1
      : g === 1
      ? " "
      : " = " + whole1 + "\\frac{" + part / g + "}{" + denominator1 / g + "}";

  const final1 =
    " \\color{black}= " +
    whole1 +
    "\\frac{" +
    part +
    "}{" +
    denominator1 +
    "}  " +
    reduced;

  const totalObject = (n, level) => {
    const group = Math.floor(n / denominator1);
    const numberInGroup = n % denominator1;
    const start = 10 + group * denominator1 * 20;
    const offset = numberInGroup * 15;
    const position = start + offset;

    return (
      <circle
        r={6}
        cx={position}
        cy={level ? 110 : 40}
        key={"totalObject-" + n + "-" + (level ? "-moved" : "")}
        id={"totalObject-" + n + "-" + (level ? "-moved" : "")}
        fill="red"
      />
    );
  };

  const highlight = n => {
    const howMany = Math.floor(n / denominator1);
    const array = new Array(howMany).fill(denominator1);
    return array.map((v, i) => {
      //const group = Math.floor(i / denominator1);
      const numberInGroup = i % denominator1;
      const start = 3 + i * denominator1 * 20;
      const offset = denominator1 * 15;
      const position = start + offset;
      return (
        <rect
          x={start}
          width={offset}
          y={30}
          height={20}
          fill={color(i)}
          opacity={0.3}
          key={"highlight-" + i}
        />
      );
    });
  };

  const totalObjects = numerator1Array.map((v, i) => totalObject(i));

  const moveObjects = () => {
    setMoved1(moved1 + denominator1);
  };

  const movedObjectsArray = new Array(moved1).fill(denominator1);

  const clicksArray = new Array(moved1 / denominator1).fill(denominator1);

  const movedObjects = movedObjectsArray.map((v, i) => totalObject(i, true));

  const tryAgain = () => {
    setMoved1(0);
  };

  /* below is code for fraction side */
  const [denominator3, setDenominator3] = useState(3);
  const [numerator2, setNumerator2] = useState(4);
  const [numerator3, setNumerator3] = useState(2);
  const [clicked, setClicked] = useState(0);

  const [wholeArray, setWholeArray] = useState(
    new Array(numerator2).fill(denominator3)
  );

  const fractionString =
    " \\color{green} \\frac{ " + numerator3 + "}{" + denominator3 + "}";

  const clicksArrayFraction = new Array(clicked).fill(fractionString);

  const tryAgainFraction = () => {
    setWholeArray(new Array(numerator2).fill(denominator3));
    setClicked(0);
  };

  const moveFraction = () => {
    const removed = numerator3 * (clicked + 1);
    const newArray = wholeArray.map((v, i) => {
      const wholeRemoved =
        i + 1 <= Math.floor(removed / denominator3)
          ? 0
          : i + 1 > Math.ceil(removed / denominator3)
          ? v
          : (numerator2 * denominator3 - removed) % denominator3;
      return wholeRemoved;
    });
    setWholeArray(newArray);
    setClicked(clicked + 1);
  };

  const wholeFractions = wholeArray.map((v, i) => {
    return (
      <Grid
        item
        style={{ backgroundColor: divColor(i) }}
        key={"moved-" + i + "-" + clicked}
      >
        <PieFraction
          denominator={denominator3}
          numerator={v}
          key={"wholeParts-" + i}
        />
      </Grid>
    );
  });

  const wholeFractionGrid = <Grid container>{wholeFractions} </Grid>;

  const finalFractionWholePart = Math.floor(
    (numerator2 * denominator3) / numerator3
  );
  const newNumerator = (numerator2 * denominator3) % numerator3;

  const newDenominator = numerator3;

  const gf = gcd(newDenominator, newNumerator);
  //const adjusted = [numerator3 / gf, denominator3 / gf];

  const finalFractionPart =
    newNumerator === 0
      ? " "
      : newDenominator / gf === 1
      ? newNumerator
      : "\\frac{" + newNumerator / gf + "}{" + newDenominator / gf + "}";

  const finalFraction = (
    <InlineMath
      math={
        " = \\color{purple}  " +
        finalFractionWholePart +
        "\\color{black}" +
        finalFractionPart
      }
    />
  );

  return (
    <>
      {" "}
      <Grid container spacing={5}>
        <Grid item xs={12} md={4}>
          <h4>Divide by Whole Numbers</h4>
          <p style={{ fontSize: 25 }}>
            <InlineMath
              math={
                "\\frac{ \\color{red}" +
                numerator1 +
                "  }{\\color{green}" +
                denominator1 +
                "}  \\color{black} = " +
                "\\color{red}" +
                numerator1 +
                "\\color{black} \\div \\color{green}" +
                denominator1 +
                final1
              }
            />
          </p>
          <svg width={300} height={svgHeight}>
            <rect
              width={300}
              height={60}
              stroke="black"
              strokeWidth={1}
              fill="white"
            />
            <text x={0} y={28} style={{ fontSize: 20, fill: "blue" }}>
              {numerator1 - moved1 < denominator1
                ? "Remainder"
                : "Need to be moved"}
            </text>

            {/* <line
              x1={0}
              x2={300}
              y1={svgHeight / 2}
              y2={svgHeight / 2}
              stroke="grey"
              strokeWidth={1}
            /> */}

            {totalObjects}
            {highlight(numerator1)}

            <rect
              y={svgHeight / 2 + 20}
              width={300}
              height={svgHeight / 2}
              fill="#333"
              opacity={0.1}
            />
            <text
              x={0}
              y={svgHeight / 2 + 40}
              style={{ fontSize: 20, fill: "black" }}
            >
              {moved1 === 0
                ? "Use button below to move"
                : numerator1 - moved1 < denominator1
                ? "Finished"
                : "Moving..."}
            </text>
            {movedObjects}
          </svg>
          <p>
            After {moved1 / denominator1} click
            {moved1 / denominator1 === 1 ? " " : "s"}...
            <br />
            <InlineMath
              math={
                "\\color{red}  " +
                numerator1 +
                " - " +
                (moved1 === 0 ? "  0 " : clicksArray.join(" - ")) +
                "  = " +
                (numerator1 - moved1)
              }
            />{" "}
          </p>
          <p>
            <Button
              variant="outlined"
              color="secondary"
              onClick={moveObjects}
              disabled={numerator1 - moved1 < denominator1}
              style={{ margin: 5 }}
            >
              Move One Set
            </Button>
            {moved1 !== 0 && (
              <Button
                variant="outlined"
                color="primary"
                onClick={() => tryAgain()}
              >
                Reset
              </Button>
            )}
          </p>
          <h6>Numerator</h6>
          <Slider
            defaultValue={numerator1}
            onChange={(o, v) => {
              setNumerator1(v);
              tryAgain();
            }}
            aria-labelledby="discrete-slider"
            step={1}
            marks
            min={0}
            max={15}
            valueLabelDisplay="on"
          />
          <h6>Denominator</h6>
          <Slider
            defaultValue={denominator1}
            onChange={(o, v) => {
              setDenominator1(v);
              tryAgain();
            }}
            aria-labelledby="discrete-slider"
            step={1}
            marks
            min={1}
            max={15}
            valueLabelDisplay="on"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <h4>Divide by Fractions</h4>
          <p style={{ fontSize: 25 }}>
            <InlineMath
              math={
                "\\color{red}  \\frac{" +
                numerator2 +
                "}{ \\color{green} \\frac{ " +
                numerator3 +
                "}{" +
                denominator3 +
                "} } \\color{black} =  \\color{red}" +
                numerator2 +
                " \\color{black} \\div \\color{green} " +
                "\\frac{ " +
                numerator3 +
                "}{" +
                denominator3 +
                "} \\color{black} =  \\color{red}" +
                numerator2 +
                " \\color{black} \\times   \\frac{ " +
                denominator3 +
                "}{" +
                numerator3 +
                "}"
              }
            />
            {finalFraction}
          </p>
          {wholeFractionGrid}
          <p style={{color: "purple"}}>After {clicked} click{clicked > 1 ? "s ":" "}</p>
          <p>
            <InlineMath
              math={
                "\\color{red}" +
                numerator2 +
                " \\color{green} - " +
                (clicked === 0 ? "  0 " : clicksArrayFraction.join(" - "))
              }
            />{" "}
          </p>
          
          <p>
            <Button
              variant="outlined"
              color="secondary"
              onClick={moveFraction}
              disabled={
               clicked >=finalFractionWholePart 
              }
              style={{ margin: 5 }}
            >
              Move One Set
            </Button>
            {clicked !== 0 && (
              <Button
                variant="outlined"
                color="primary"
                onClick={() => tryAgainFraction()}
              >
                Reset
              </Button>
            )}
          </p>
          <h6>Numerator (top)</h6>
          <Slider
            defaultValue={numerator2}
            onChange={(o, v) => {
              setNumerator2(v);
              tryAgainFraction();
            }}
            aria-labelledby="discrete-slider"
            step={1}
            marks
            min={0}
            max={15}
            valueLabelDisplay="on"
          />
          <h6>Denominator of Denominator</h6>
          <Slider
            defaultValue={denominator3}
            onChange={(o, v) => {
              setDenominator3(v);
              tryAgainFraction();
            }}
            aria-labelledby="discrete-slider"
            step={1}
            marks
            min={0}
            max={15}
            valueLabelDisplay="on"
          />

          <h6>Numerator of Denominator(top of bottom)</h6>
          <Slider
            defaultValue={numerator3}
            onChange={(o, v) => {
              setNumerator3(v);
              tryAgainFraction();
            }}
            aria-labelledby="discrete-slider"
            step={1}
            marks
            min={0}
            max={15}
            valueLabelDisplay="on"
          />
        </Grid>
      </Grid>{" "}
    </>
  );
};

export default DivideByFraction;
