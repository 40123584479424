import React, { useState, useRef } from "react";
import { Grid, Slider, Button } from "@mui/material";
import { select, scaleOrdinal, schemeCategory10 } from "d3";
import "katex/dist/katex.min.css";
import { InlineMath } from "react-katex";

const GraphingExplained = props => {
  const color = scaleOrdinal(schemeCategory10);
  const [m, setM] = useState(2);
  const [b, setB] = useState(1);
  const [x, setX] = useState(1);
  const [tempX, setTempX] = useState(0);
  const [moved, setMoved] = useState(0);
  const [animate, setAnimate] = useState(false);
  const svgRef = useRef();
  const [reset, setReset] = useState(false);
  // const [x, setX ] = useState()

  const xArray = new Array(x).fill(1);
  const xBefore = xArray.map((v, i) => {
    return (
      <circle
        key={"xBefore-" + i}
        id={"xBefore-" + i}
        r={5}
        cx={5}
        cy={(i + 1) * 12}
        fill="blue"
      />
    );
  });

  const bArray = new Array(b).fill(1);
  const bDots = bArray.map((v, i) => {
    return (
      <circle
        key={"bArray-" + i}
        id={"bArray-" + i}
        r={5}
        cx={(i + 1) * 12 + 160}
        cy={12}
        fill="black"
      />
    );
  });

  const mArray = new Array(m).fill(1);
  const mBefore = mArray.map((v, i) => {
    return (
      <circle
        key={"mArray-" + i}
        id={"mArray-" + i}
        r={5}
        cx={((i % 2) + 1) * 12 + 60}
        cy={(Math.floor(i / 2) + 1) * 12}
        fill="red"
      />
    );
  });

  const movedArray = new Array(moved).fill(1);
  const movedDots = movedArray.map((v, i) => {
    return (
      <circle
        key={"moved-" + i}
        id={"moved-" + i}
        r={5}
        cx={((i % m) + 1) * 12 + 160}
        cy={(Math.floor(i / m) + 2 + Math.floor(b / 5)) * 12}
        fill={color(Math.floor(i / m))}
      />
    );
  });

  const startAnimationBefore = i => {
    setTempX(i );
    setAnimate(true);
    setReset(true);
    if (i >= x) {
      setAnimate(false);
      return "done";
      //break;
    }
    
    const circle = select(svgRef.current).select("#xBefore-" + i);
    const circleClone = circle.clone();
    circle.attr("r", 0);
    circleClone
      .transition()
      .delay(i > 0 ? 1000 : 0)
      .attr("cx", 80)
      .attr("cy", 20)
      .attr("r", 0)
      .duration(750)
      .on("end", () => {
        startAnimationM(i);
        // setX(tempX - i);
      })
      .remove();
  };

  const startAnimationM = i => {
    const svg = select(svgRef.current);
    const circles = svg.select("#mDotSet").clone(true);
    console.log("circles", circles);
    circles
      .transition()
      .attr("transform", "translate(100, 20)")
      .duration(500)

      .on("end", () => {
        svg.append();
        startAnimationBefore(i + 1);
        setMoved((i + 1) * m);
      })
      .remove();
  };

  const handleReset = () => {
    const svg = select(svgRef.current);
    const xDot = svg.selectAll("circle").attr("r", 5);
    setMoved(0);
    setReset(false);
    setTempX(0);
  };

  const xFactor = 12;
  const yFactor = 8;

  const x0Array = new Array( b).fill(1);
  const x0Dots = x0Array.map((v, i) => {
    return (
      <circle
        key={"x0dots-" + i}
        r={4}
        cx={0}
        cy={350 - (i + 1) * yFactor}
        fill={ "green"}
        stroke="black"
        //strokeWidth={x === 1 || tempX === 1 ? 2 : 0}
      />
    );
  });

  const x1Array = new Array(m + b).fill(1);
  const x1Dots = x1Array.map((v, i) => {
    return (
      <circle
        key={"x1dots-" + i}
        r={4}
        cx={xFactor}
        cy={350 - (i + 1) * yFactor}
        fill={i < m ? "red" : "green"}
        stroke="black"
        strokeWidth={x === 1 || tempX === 1 ? 2 : 0}
      />
    );
  });

  const x2Array = new Array(2 * m + b).fill(1);
  const x2Dots = x2Array.map((v, i) => {
    return (
      <circle
        key={"x2dots-" + i}
        r={4}
        cx={xFactor * 2}
        cy={350 - (i + 1) * yFactor}
        fill={i < m * 2 ? "red" : "green"}
        stroke="black"
        strokeWidth={x === 2 || tempX === 2 ? 2 : 0}
      />
    );
  });

  const x3Array = new Array(3 * m + b).fill(1);
  const x3Dots = x3Array.map((v, i) => {
    return (
      <circle
        key={"x3dots-" + i}
        r={4}
        cx={xFactor * 3}
        cy={350 - (i + 1) * yFactor}
        fill={i < m * 3 ? "red" : "green"}
        stroke="black"
        strokeWidth={x === 3 || tempX === 3 ? 2 : 0}
      />
    );
  });

  const x4Array = new Array(4 * m + b).fill(1);
  const x4Dots = x4Array.map((v, i) => {
    return (
      <circle
        key={"x4dots-" + i}
        r={4}
        cx={xFactor * 4}
        cy={350 - (i + 1) * yFactor}
        fill={i < m * 4 ? "red" : "green"}
        stroke="black"
        strokeWidth={x === 4 || tempX === 4 ? 2 : 0}
      />
    );
  });

  const x5Array = new Array(5 * m + b).fill(1);
  const x5Dots = x5Array.map((v, i) => {
    return (
      <circle
        key={"x5dots-" + i}
        r={4}
        cx={xFactor * 5}
        cy={350 - (i + 1) * yFactor}
        fill={i < m * 5 ? "red" : "green"}
        stroke="black"
        strokeWidth={x === 5 || tempX === 5 ? 2 : 0}
      />
    );
  });

  const x6Array = new Array(6 * m + b).fill(1);
  const x6Dots = x6Array.map((v, i) => {
    return (
      <circle
        key={"x6dots-" + i}
        r={4}
        cx={xFactor * 6}
        cy={350 - (i + 1) * yFactor}
        fill={i < m * 6 ? "red" : "green"}
        stroke="black"
        strokeWidth={x === 6 || tempX === 6 ? 2 : 0}
      />
    );
  });

  const x7Array = new Array(7 * m + b).fill(1);
  const x7Dots = x7Array.map((v, i) => {
    return (
      <circle
        key={"x7dots-" + i}
        r={4}
        cx={xFactor * 7}
        cy={350 - (i + 1) * yFactor}
        fill={i < m * 7 ? "red" : "green"}
        stroke="black"
        strokeWidth={x === 7 || tempX === 7 ? 2 : 0}
      />
    );
  });

  const x8Array = new Array(8 * m + b).fill(1);
  const x8Dots = x8Array.map((v, i) => {
    return (
      <circle
        key={"x8dots-" + i}
        r={4}
        cx={xFactor * 8}
        cy={350 - (i + 1) * yFactor}
        fill={i < m * 8 ? "red" : "green"}
        stroke="black"
        strokeWidth={x === 8 || tempX === 8 ? 2 : 0}
      />
    );
  });

  const x9Array = new Array(9 * m + b).fill(1);
  const x9Dots = x9Array.map((v, i) => {
    return (
      <circle
        key={"x9dots-" + i}
        r={4}
        cx={xFactor * 9}
        cy={350 - (i + 1) * yFactor}
        fill={i < m * 9 ? "red" : "green"}
        stroke="black"
        strokeWidth={x === 9 || tempX === 9 ? 2 : 0}
      />
    );
  });

  const x10Array = new Array(10 * m + b).fill(1);
  const x10Dots = x10Array.map((v, i) => {
    return (
      <circle
        key={"x10dots-" + i}
        r={4}
        cx={xFactor * 10}
        cy={350 - (i + 1) * yFactor}
        fill={i < m * 10 ? "red" : "green"}
        stroke="black"
        strokeWidth={x === 10 || tempX === 10 ? 2 : 0}
      />
    );
  });

  const seq = xArray.map((v, i) => (
    <p key={"seq" + i} style={{ fontSize: i === (tempX - 1) ? 25 : 15 }}>
      <InlineMath
        math={
          "\\color{green}  a_{" +
          (i + 1) +
          "} \\color{black}  =  \\color{blue} a_{" +
          i +
          "} \\color{black} +  \\color{red} " +
          m +
          " \\color{black}  =  \\color{blue} " +
          (i * m + b) +
          "\\color{black} +  \\color{red} " +
          m +
          " \\color{black}  =  \\color{blue}" +
          ((i + 1) * m + b)
        }
      />
    </p>
  ));

  return (
    <>
      <h3>Linear Function, Arithmetic Sequence and Graph</h3>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <h4>m</h4>
          <Slider
            key={"slideM-" + (animate ? "a" : "na")}
            defaultValue={m}
            value = {m}
            onChange={(o, v) => setM(v)}
            aria-labelledby="discrete-slider"
            step={1}
            marks
            min={0}
            max={10}
            valueLabelDisplay="on"
            disabled={animate}
          />
          <h4>x</h4>
          <Slider
            key={"slideX-" + (animate ? "a" : "na")}
            defaultValue={x}
            value = {x}
            onChange={(o, v) => {
              setX(v);
              //setTempX(v);
            }}
            aria-labelledby="discrete-slider"
            step={1}
            marks
            min={0}
            max={10}
            valueLabelDisplay="on"
            disabled={animate}
          />
          <h4>b</h4>
          <Slider
            key={"slideB-" + (animate ? "a" : "na")}
            defaultValue={b}
            value = {b}
            onChange={(o, v) => setB(v)}
            aria-labelledby="discrete-slider"
            step={1}
            marks
            min={0}
            max={10}
            valueLabelDisplay="on"
            disabled={animate}
          />
          <Button
            disabled={animate || reset}
            variant="outlined"
            onClick={() => startAnimationBefore(0)}
          >
            Start
          </Button>
          <Button
            style={{ marginLeft: 10 }}
            variant="outlined"
            onClick={() => handleReset()}
            disabled={animate}
          >
            Reset
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          md={3}
          style={{ border: "solid", borderColor: "#ddd" }}
        >
          <h5>Animation</h5>
          <svg width={300} height={300} ref={svgRef}>
            <text y={280} fill={"blue"}>
              Input
            </text>
            <text y={280} x={70} fill={"red"}>
              M
            </text>
            <text y={280} x={170} fill={"green"}>
              output
            </text>
            <rect
              x={60}
              //y={10}
              stroke={"red"}
              strokeWidth={1}
              width={40}
              height={200}
              fill={"#ccc"}
            />

            <rect
              x={160}
              //y={10}
              stroke={"black"}
              strokeWidth={1}
              width={140}
              height={200}
              fill={"#ddd"}
            />

            <g id="xDotSet">{xBefore}</g>
            {bDots}
            <g id="mDotSet">{mBefore}</g>
            <g id="moveDotSet">{movedDots}</g>
          </svg>
        </Grid>
        <Grid
          item
          xs={6}
          md={2}
          style={{ border: "solid", borderColor: "#ddd" }}
        >
          <h5>Possible Outcomes Depending on X</h5>
          <svg width={200} height={360}>
            <line
              x1="0"
              y1={350 - b * yFactor}
              x2={10 * xFactor}
              y2={350 - (10 * m * yFactor + b * yFactor)}
              stroke={"black"}
              strokeWidth={3}
            />
            {x1Dots} {x2Dots} {x3Dots} {x4Dots} {x5Dots} {x6Dots} {x7Dots}{" "}
            {x8Dots} {x9Dots} {x10Dots} {x0Dots}
            <text x={xFactor - 2} y={360}>
              1
            </text>
            <text x={2 * xFactor - 2} y={360}>
              2
            </text>
            <text x={3 * xFactor - 2} y={360}>
              3
            </text>
            <text x={4 * xFactor - 2} y={360}>
              4
            </text>
            <text x={5 * xFactor - 2} y={360}>
              5
            </text>
            <text x={6 * xFactor - 2} y={360}>
              6
            </text>
            <text x={7 * xFactor - 2} y={360}>
              7
            </text>
            <text x={8 * xFactor - 2} y={360}>
              8
            </text>
            <text x={9 * xFactor - 2} y={360}>
              9
            </text>
            <text x={10 * xFactor - 2} y={360}>
              10
            </text>
          </svg>
        </Grid>
        <Grid item xs={6} md={3} style={{ fontSize: 25 }}>
          <InlineMath
            math={
              "\\color{green}  y = \\color{red}m  \\color{black}( \\color{blue} x \\color{black}) +  b = \\color{red}" +
              m +
              "  \\color{black}( \\color{blue} x \\color{black}) +  " +
              b +
              " "
            }
          />
          <p>
            <InlineMath
              math={
                "\\color{green}  y = \\color{red} " +
                m +
                "\\color{black}( \\color{blue} " +
                (tempX === 0 ? x : tempX) +
                " \\color{black}) + " +
                b +
                " = " +
                (m * (tempX === 0 ? x : tempX) + b)
              }
            />
          </p>
          <hr />
          <p>
            <InlineMath
              math={
                "\\color{green}  a_{n} \\color{black} = \\color{blue} a_{n - 1} \\color{black} +  \\color{red} " +
                m +
                "\\quad \\quad \\color{black} a_{0} =  " +
                b
              }
            />
          </p>
          <hr />
          <p>
            <InlineMath
              math={"\\color{green}  a_{0}  \\color{black} = \\color{blue} " + b}
            />
          </p>
          {seq}
        </Grid>
      </Grid>
    </>
  );
};

export default GraphingExplained;
