import React, { useState, useRef, useEffect } from "react";
import { Grid, Slider, Checkbox } from "@mui/material";
import "katex/dist/katex.min.css";
import { InlineMath } from "react-katex";
import { gcd } from "../Tools/functions";

const CommonDenominator = props => {
  const [numerator1, setNumerator1] = useState(1);
  const [denominator1, setDenominator1] = useState(2);
  const [numerator2, setNumerator2] = useState(1);
  const [denominator2, setDenominator2] = useState(3);
  const width = 300;
  const top = 0;
  const height = 200;
  const bottom = height;
  const [lockFactor, setLockFactor] = useState(false);

  const g = gcd(numerator1 * numerator2, denominator2 * denominator1);
  const simplifiedNumerator = (numerator1 * numerator2) / g;
  const simplifiedDenominator = (denominator1 * denominator2) / g;

  const divideArray1 = new Array(denominator1).fill(denominator1);
  const divideArray2 = new Array(denominator2).fill(denominator2);

  const fillArray1 = new Array(numerator1).fill(numerator1);
  const fillArray2 = new Array(numerator2).fill(numerator2);

  const simplifiedNumeratorArray = new Array(simplifiedNumerator).fill(
    simplifiedNumerator
  );
  const simplifiedDenominatorArray = new Array(simplifiedDenominator).fill(
    simplifiedDenominator
  );

  const complicatedDenominatorArray = new Array(
    denominator1 * denominator2
  ).fill(denominator1 * denominator2);

  const simplifiedFills = simplifiedNumeratorArray.map((v, i) => {
    const factor = (bottom - top) / simplifiedDenominator;

    return (
      <rect
        key={"simplifiedFills-fill-" + i}
        width={width}
        height={factor}
        y={i * factor}
        fill={"blue"}
        opacity={0.5}
        stroke="black"
        strokeWidth={4}
      />
    );
  });

  const simplifiedLines = simplifiedDenominatorArray.map((v, i) => {
    const factor = (bottom - top) / simplifiedDenominator;
    return (
      <line
        key={"simplifiedLines-line-" + i}
        y1={i * factor}
        y2={i * factor}
        x1={0}
        x2={width}
        stroke={"black"}
        strokeWidth={4}
      />
    );
  });

  const complicatedLines = complicatedDenominatorArray.map((v, i) => {
    const factor = (bottom - top) / (denominator1 * denominator2);
    return (
      <line
        key={"complicatedLines-line-" + i}
        y1={i * factor}
        y2={i * factor}
        x1={0}
        x2={width}
        stroke={"black"}
        strokeWidth={1}
      />
    );
  });

  const verticalFills = fillArray1.map((v, i) => {
    const factor = width / denominator1;

    return (
      <rect
        key={"vertical-fill-" + i}
        width={factor}
        height={bottom - top}
        x={i * factor}
        fill={"red"}
        opacity={0.5}
      />
    );
  });

  const horizontalFills = fillArray2.map((v, i) => {
    const factor = (bottom - top) / denominator2;

    return (
      <rect
        key={"vertical-fill-" + i}
        width={width}
        height={factor}
        y={i * factor}
        fill={"blue"}
        opacity={0.5}
      />
    );
  });

  const verticalLines = divideArray1.map((v, i) => {
    const factor = width / denominator1;
    return (
      <line
        key={"vertical-line-" + i}
        x1={i * factor}
        x2={i * factor}
        y1={top}
        y2={bottom}
        stroke={"black"}
        strokeWidth={1}
      />
    );
  });

  const horizontalLines = divideArray2.map((v, i) => {
    const factor = (bottom - top) / denominator2;
    return (
      <line
        key={"horizontal-line-" + i}
        y1={i * factor}
        y2={i * factor}
        x1={0}
        x2={width}
        stroke={"black"}
        strokeWidth={1}
      />
    );
  });

  useEffect(() => {
    if (lockFactor) setNumerator2(denominator2);
    else setNumerator2(Math.min(numerator2, denominator2));
  }, [denominator2]);

  useEffect(() => {
    if (lockFactor) setDenominator2(numerator2);
    //else setNumerator2(Math.min(numerator2, denominator2));
  }, [numerator2]);

  useEffect(() => {
    if (lockFactor) setNumerator2(denominator2);
    //else setNumerator2(Math.min(numerator2, denominator2));
  }, [lockFactor]);

  useEffect(() => {
    setNumerator1(Math.min(numerator1, denominator1));
  }, [denominator1]);

  return (
    <>
      <h3>Divide Division</h3>
      <Grid container spacing={4}>
        <Grid item>
          <svg width={width} height={height}>
            <rect width={width} height={height} stroke="red" fill="white" />
            {verticalFills} {horizontalFills}
            {verticalLines} {horizontalLines}
          </svg>

          <p style={{ fontSize: 30 }}>
            <InlineMath
              math={
                "\\color{red} \\frac{" + numerator1 + "}{" + denominator1 + "}"
              }
            />
            <InlineMath
              math={
                "\\times  \\color{blue} \\frac{" +
                numerator2 +
                "}{" +
                denominator2 +
                "}"
              }
            />

            <InlineMath
              math={
                " =  \\frac{\\color{red}" +
                numerator1 +
                "\\color{black} \\times \\color{blue}" +
                numerator2 +
                "}{\\color{red}" +
                denominator1 +
                "\\color{black} \\times \\color{blue}" +
                denominator2 +
                "} "
              }
            />
            <InlineMath
              math={
                " = \\color{purple} \\frac{" +
                numerator2 * numerator1 +
                "}{" +
                denominator2 * denominator1 +
                "}"
              }
            />
          </p>
          <p>
            Lock to factor of 1{" "}
            <Checkbox
              checked={lockFactor}
              onChange={() => setLockFactor(!lockFactor)}
              value="primary"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </p>
        </Grid>
        <Grid item xs={12} md={4} style={{ padding: 10 }}>
          <h6>Numerator 1</h6>
          <p>
            <Slider
              key={"numerator-1-" + "-" + numerator1}
              defaultValue={numerator1}
              value = {numerator1}
              onChange={(o, v) => {
                setNumerator1(v);
              }}
              aria-labelledby="discrete-slider"
              step={1}
              marks
              min={0}
              max={denominator1}
              valueLabelDisplay="on"
            />
          </p>
          <h6>Denominator 1 = split left to right</h6>
          <p>
            <Slider
              // key={"Denominator-1-" + numerator2 + "-" + denominator1}
              defaultValue={denominator1}
              onChange={(o, v) => {
                setDenominator1(v);
              }}
              value = {denominator1}
              aria-labelledby="discrete-slider"
              step={1}
              marks
              min={1}
              max={10}
              valueLabelDisplay="on"
            />
          </p>
          <h6>Numerator 2</h6>
          <p>
            <Slider
              disabled={lockFactor}
              key={"Numerator-2-" + numerator2}
              defaultValue={numerator2}
              value  = {numerator2}
              onChange={(o, v) => {
                !lockFactor && setNumerator2(v);
                //tryAgain();
              }}
              aria-labelledby="discrete-slider"
              step={1}
              marks
              min={0}
              max={denominator2}
              valueLabelDisplay="on"
            />
          </p>
          <h6>Denominator 2 = split top to bottom</h6>
          <p>
            <Slider
              // key={"Denominator-1-" + numerator2 + "-" + denominator1}
              defaultValue={denominator2}
              value = {denominator2}
              onChange={(o, v) => {
                setDenominator2(v);
                //tryAgain();
              }}
              aria-labelledby="discrete-slider"
              step={1}
              marks
              min={1}
              max={10}
              valueLabelDisplay="on"
            />
          </p>
        </Grid>
        <Grid item>
          <svg width={width} height={height}>
            <rect width={width} height={height} stroke="red" fill="pink" />
            {simplifiedFills}
            {simplifiedLines} {complicatedLines}
          </svg>
          <p style={{ fontSize: 30 }}>
            <span style={{ fontSize: 15 }}>Simplified:</span>{" "}
            <InlineMath
              math={
                " \\frac{" +
                simplifiedNumerator +
                "}{" +
                simplifiedDenominator +
                "}"
              }
            />
          </p>
        </Grid>
      </Grid>
    </>
  );
};

export default CommonDenominator;
