import React, { useState, useEffect, useRef } from "react";
import { select } from "d3";
import JXG from "jsxgraph";
import { Grid, Slider, Paper, Button, Tooltip } from "@mui/material";
import "katex/dist/katex.min.css";
import { BlockMath, InlineMath } from "react-katex";
import { range } from "lodash";

function DemoPage() {
  const defaultW = 300;
  const defaultH = 400;
  const linearGraphRef = useRef();
  const fillColorOuter = "rgb(255,0,0)";
  const fillColorInner = "rgb(0,255,0)";
  const max = 81;

  const buttonClick = () => {};

  const [w, setW] = useState(2);
  // const [brd, setBrd] = useState();
  const wSquared = w * w;

  const floor = Math.floor(Math.sqrt(w));
  const squareBelow = floor;
  const squareAbove = floor + 1;

  const changeW = value => {
    setW(value);
  };
  const changeB = value => {
    // setBound(value);
  };

  const makeBrd = () => {
    const brd = JXG.JSXGraph.initBoard("graph", {
      boundingbox: [-5, 10, 81, -5],
      showCopyright: false,
      axis: true
    });

    //setBrd(temp);

    const p0 = brd.create("point", [w, Math.sqrt(w)], {
      name: w + ", " +  Math.round(Math.sqrt(w)*100)/100,
      visible: true
    });

    brd.create(
      "functiongraph",
      [
        function(t) {
          return Math.sqrt(t);
        },
        -2,
        100
      ],
      { strokeColor: "blue", fixed: false }
    );

    brd.on('move', function(){
      p0.moveTo([w, Math.sqrt(w)]);
   
    });
  };

  useEffect(() => {
    makeBrd();
  }, [w]);



  const styles = {
    red: {
      color: "red"
    },
    blue: {
      color: "blue"
    },
    paddingLeftSide: {
      paddingTop: 100
    }
  };

  const Square = props => {
    const { width, x, y, fillColor } = props;
    return (
      <rect
        width={width}
        height={width}
        x={x}
        y={y}
        style={{
          fill: fillColor,
          strokeWidth: 3,
          stroke: "rgb(100,100,100)"
        }}
      />
    );
  };

  const Text = props => {
    const { width, x, y, fillColor } = props;
    return (
      <text
        x={x}
        y={y}
        fontFamily="Verdana"
        fill="black"
        //style={{ padding: (5 * width) / 40 }}
        //transform={"translate(" + (5 * width) / 40 + ",0)"}
        fontSize="20"
      >
        {props.text}
      </text>
    );
  };

  const outSquare =
    squareAbove > 0 &&
    range(squareAbove).map(y => {
      return range(squareAbove).map(x => {

        const text = y === 1 && x === squareAbove - 1 && (
          <Text
            text={x + 1}
            x={defaultW - defaultW / (3*squareAbove)}
            y={(0.8*y * defaultW) / squareAbove}
          />
        );
        return (
          <g key={"outSquare-" + x + "-" + y} >
            <Square
              key={"square-" + x + "-" + y}
              width={defaultW / squareAbove}
              height={defaultW / squareAbove}
              x={(x * defaultW) / squareAbove}
              y={(y * defaultW) / squareAbove}
              fillColor={fillColorOuter}
            />
            {text}
          </g>
        );
      });
    });

  const innerSquare = range(squareBelow).map(y => {
    return range(squareBelow).map(x => {
      return (
        <Square
          key={"square-" + x + "-" + y}
          width={defaultW / squareAbove}
          height={defaultW / squareAbove}
          x={(x * defaultW) / squareAbove}
          y={(y * defaultW) / squareAbove}
          fillColor={fillColorInner}
        />
      );
    });
  });

 /*  const theme = createMuiTheme({
    overrides: {
      MuiTooltip: {
        tooltip: {
          fontSize: "3em",
          color: "black",
          backgroundColor: "yellow"
        },
        popper: {
          zIndex: 100
        }
      }
    }
  });
 */
  const FormatTooltip = props => {
    const { children, value, open } = props;
    return children
  };

  
  return (
    <>
      <h4>Visualize Size of Square Roots</h4>
      <Grid container>
        <Grid item xs={12}>
          <Slider
            style={styles.paddingLeftSide}
            defaultValue={2}
            getAriaValueText={changeW}
            aria-labelledby="discrete-slider"
            //step={1}
            marks
            min={0}
            max={max}
            valueLabelDisplay="on"
            valueLabelFormat={x => <InlineMath math={"\\sqrt{  " + w + "}"} />}
            ValueLabelComponent={FormatTooltip}
          />
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <Grid item xs={12}>
            <p style={{ fontSize: 25 }}>
              <InlineMath
                math={
                  "\\color{green} \\sqrt{ " +
                  squareBelow * squareBelow +
                  "} \\color{black} " +
                  (Math.floor(Math.sqrt(w)) === Math.sqrt(w) ? " = " : " < ") +
                  "\\sqrt{" +
                  w +
                  "} < \\color{red}  \\sqrt{ " +
                  squareAbove * squareAbove +
                  "}"
                }
              />
            </p>
            <hr />
            <p style={{ fontSize: 25 }}>
              <InlineMath
                math={
                  "\\color{green} " +
                  squareBelow +
                  "\\color{black} " +
                  (Math.floor(Math.sqrt(w)) === Math.sqrt(w) ? " = " : " < ") +
                  " \\sqrt{" +
                  w +
                  "} < \\color{red} " +
                  squareAbove
                }
              />
            </p>
            <hr />
            <p style={{ fontSize: 25 }}>
              <InlineMath
                math={
                  "\\color{green} " +
                  squareBelow +
                  "\\color{black} " +
                  (Math.floor(Math.sqrt(w)) === Math.sqrt(w) ? " = " : " < ") +
                  " " +
                  Math.sqrt(w) +
                  " < \\color{red} " +
                  squareAbove
                }
              />
            </p>
            <hr />
            <p style={{ fontSize: 25 }}>
              <InlineMath math={"A =  " + w} />
            </p>
            <hr />
            <p style={{ fontSize: 25 }}>
              <InlineMath math={"l = \\sqrt{ " + w + "} = " + Math.sqrt(w)} />
            </p>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <svg width={defaultW} height={defaultW}>
            <g>
              {outSquare}
              {innerSquare}
              <rect
                x={0}
                y={0}
                height={(Math.sqrt(w) * defaultW) / squareAbove}
                width={(Math.sqrt(w) * defaultW) / squareAbove}
                style={{
                  fill: "rgb(255,0,255)",
                  strokeWidth: 5,
                  stroke: "rgb(0,0,0)",
                  fillOpacity: "0.4"
                }}
              >
                {" "}
              </rect>
            </g>
          </svg>
        </Grid>
        <Grid item xs={12} md={4} style={{ fontSize: 25 }}>
          <svg
            width={defaultW}
            height={
              ((squareAbove - squareBelow) * 1.8 * defaultW) / squareAbove
            }
          >
            <g>
              <rect
                width={(squareBelow * defaultW) / squareAbove}
                height={((Math.sqrt(w) - squareBelow) * defaultW) / squareAbove}
                style={{
                  fill: "hotPink",
                  strokeWidth: 1,
                  stroke: "rgb(0,0,0)"
                }}
              />
              <rect
                width={((Math.sqrt(w) - squareBelow) * defaultW) / squareAbove}
                height={((Math.sqrt(w) - squareBelow) * defaultW) / squareAbove}
                x={(squareBelow * defaultW) / squareAbove}
                style={{
                  fill: "hotPink",
                  strokeWidth: 1,
                  stroke: "rgb(0,0,0)"
                }}
              />
              <rect
                width={(squareBelow * defaultW) / squareAbove}
                height={((Math.sqrt(w) - squareBelow) * defaultW) / squareAbove}
                y={((squareAbove - squareBelow) * defaultW) / squareAbove}
                style={{
                  fill: "hotPink",
                  strokeWidth: 1,
                  stroke: "rgb(0,0,0)"
                }}
              />
            </g>
          </svg>
          <p>
            <InlineMath
              math={" A_{extra} = " + (w - squareBelow * squareBelow)}
            />
          </p>
          <p>
            <InlineMath math={" l_{extra} = " + (Math.sqrt(w) - squareBelow)} />
          </p>
        </Grid>
        <Grid item xs={12} md={4}>
          <div
            id="graph"
            ref={linearGraphRef}
            style={{ width: 300, height: 300 }}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default DemoPage;
