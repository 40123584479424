import React from "react";
import { Button } from "@mui/material";

export const Buttons = props => {
  const { buttonArray, func, keyString } = props;
  const thisArray = Array.isArray(buttonArray)
    ? buttonArray
    : new Array(buttonArray).fill(buttonArray);
  const buttons = thisArray.map((v, i) => {
    return (
      <span
        key={"button-" + keyString + "-" + i}
        style={{
          margin: 1
        }}
      >
        <Button onClick={() => func(i)} variant={props.variant || "outlined"}>
          {" "}
          {i}{" "}
        </Button>{" "}
      </span>
    );
  });

  return buttons;
};


