import React, { useEffect, useRef, useState } from "react";
import { Grid, Slider } from "@mui/material";
import { select } from "d3";
import "katex/dist/katex.min.css";
import { InlineMath } from "react-katex";

const LongDivideInt = (props) => {
  //const [total, setTotal] = useState(264);
  const [divisor, setDivisor] = useState(3);
  const [hundreds, setHundreds] = useState(200);
  const [tens, setTens] = useState(60);
  const [ones, setOnes] = useState(4);
  const total = hundreds + tens + ones;

  const [hundredChoice, setHundredChoice] = useState(0);
  const [tenChoice, setTenChoice] = useState(0);
  const [oneChoice, setOneChoice] = useState(0);

  const svgRef = useRef();

  useEffect(() => {
    /* look() */
    const svg = select(svgRef.current);
    const foHundred = svg.select("#foHundred");
    console.log("hund");
    if (!select("#input-100").node()) {
      const inputH = foHundred
        .append("xhtml:input")
        .attr("size", 1)
        .attr("id", "input-100");
      inputH.on("keyup", function () {
        const me = select(this);
        const int = parseInt(me.property("value"));
        if (!(int >= 0)) {
          setHundredChoice(0);
          return;
        }

        setHundredChoice(int);
      });
    }

    const foTen = svg.select("#foTen");
    if (!select("#input-10").node()) {
      const inputT = foTen
        .append("xhtml:input")
        .attr("size", 1)
        .attr("id", "input-10");
      inputT.on("keyup", function () {
        const me = select(this);
        const int = parseInt(me.property("value"));
        if (!(int >= 0)) {
          setTenChoice(0);
          return;
        }
        setTenChoice(int);
      });
    }
    const foOne = svg.select("#foOne");
    if (!select("#input-1").node()) {
      const inputO = foOne
        .append("xhtml:input")
        .attr("size", 1)
        .attr("id", "input-1");

      inputO.on("keyup", function () {
        const me = select(this);
        const int = parseInt(me.property("value"));
        if (!(int >= 0)) {
          setOneChoice(0);
          return;
        }
        setOneChoice(int);
      });
    }
  }, []);

  const containerWidth = 60;
  const Rect = (props) => {
    const numberOfDots = props.dots >= 0 ? props.dots : 0;
    const dotArray = new Array(numberOfDots).fill(false);
    const n = numberOfDots === 100 ? 6 : numberOfDots === 10 ? 10 : 5;
    const d = numberOfDots === 100 ? 10 : numberOfDots === 10 ? 5 : 5;
    const start = numberOfDots === 100 ? 2 : numberOfDots === 10 ? 10 : 25;
    const dots = dotArray.map((v, i) => (
      <circle
        fill={"green"}
        key={"dot-" + i}
        r={2}
        cx={start + n * (i % d)}
        cy={start + n * Math.floor(i / d)}
        opacity={props.opacity || 1}
      />
    ));
    return (
      <svg width={1.1 * containerWidth} height={1.1 * containerWidth}>
        <rect
          width={containerWidth}
          height={containerWidth}
          fill={props.fill || "red"}
          opacity={props.opacity || 0.6}
        />
        {dots}
      </svg>
    );
  };

  const rectHundredArray = new Array(hundreds / 100).fill(false);
  const rectsHundred = rectHundredArray.map((v, i) => (
    <Rect key={"hundredRect-" + i} dots={100} />
  ));

  const rectHundredArrayTakeAway = new Array(
    hundredChoice > 0 ? hundredChoice * divisor : divisor
  ).fill(false);

  const rectsHundredTakeAway = rectHundredArrayTakeAway.map((v, i) => (
    <Grid item key={"hundredRectTakeAway" + i}>
      <Rect dots={100} fill={"grey"} opacity={hundredChoice > 0 ? 0.6 : 0.2} />
    </Grid>
  ));

  const tensTotal = tenChoice > 0 ? tenChoice * divisor : divisor;
  const hundredFromTen = Math.floor(tensTotal / 10);
  const tenFromTen = tensTotal - hundredFromTen * 10;

  const rectTenArrayTakeAwayHundred = new Array(hundredFromTen).fill(false);
  const rectsTenTakeAwayHundred = rectTenArrayTakeAwayHundred.map((v, i) => {
    return <Rect key={"tenRectTakeAwayHundred" + i} dots={100} fill={"grey"} />;
  });

  const rectTenArrayTakeAway = new Array(tenFromTen).fill(false);
  const rectsTenTakeAway = rectTenArrayTakeAway.map((v, i) => {
    return (
      <Rect
        key={"tenRectTakeAway" + i}
        dots={10}
        fill={"grey"}
        opacity={tenChoice > 0 ? 0.6 : 0.2}
      />
    );
  });

  const rectTenArray = new Array(tens / 10).fill(false);
  const rectsTen = rectTenArray.map((v, i) => (
    <Rect key={"tenRect-" + i} dots={10} fill={"purple"} />
  ));

  const rectOneArray = new Array(ones).fill(false);
  const rectsOne = rectOneArray.map((v, i) => (
    <Rect key={"oneRect-" + i} dots={1} fill={"blue"} />
  ));

  const onesTotal = oneChoice > 0 ? oneChoice * divisor : divisor;
  const tenFromOne = Math.floor(onesTotal / 10);
  const oneFromOne = onesTotal - tenFromOne * 10;

  const rectOneArrayTakeAwayTen = new Array(tenFromOne).fill(false);
  const rectsOneTakeAwayTen = rectOneArrayTakeAwayTen.map((v, i) => {
    return <Rect key={"oneRectTakeTen" + i} dots={10} fill={"grey"} />;
  });

  const rectOneArrayTakeAway = new Array(oneFromOne).fill(false);
  const rectsOneTakeAway = rectOneArrayTakeAway.map((v, i) => {
    return (
      <Rect
        key={"oneRectTakeAway" + i}
        dots={1}
        fill={"grey"}
        opacity={oneChoice > 0 ? 0.6 : 0.2}
      />
    );
  });

  const divisorArray = new Array(divisor).fill(false);
  const rectsDivisor = divisorArray.map((v, i) => (
    <Rect key={"divisorRect-" + i} fill={"grey"} />
  ));

  return (
    <div className="App">
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <h6>Divisor</h6>
          <Slider
            defaultValue={divisor}
            value={divisor}
            onChange={(o, v) => setDivisor(v)}
            aria-labelledby="discrete-slider"
            step={1}
            marks
            min={1}
            max={10}
            valueLabelDisplay="on"
          />
          <h6>Hundreds</h6>
          <Slider
            defaultValue={hundreds / 100}
            value={hundreds / 100}
            onChange={(o, v) => setHundreds(v * 100)}
            aria-labelledby="discrete-slider"
            step={1}
            marks
            min={0}
            max={9}
            valueLabelDisplay="on"
          />
          <h6>Tens</h6>
          <Slider
            defaultValue={tens / 10}
            value={tens / 10}
            onChange={(o, v) => setTens(v * 10)}
            aria-labelledby="discrete-slider"
            step={1}
            marks
            min={0}
            max={9}
            valueLabelDisplay="on"
          />
          <h6>Ones</h6>
          <Slider
            defaultValue={ones}
            onChange={(o, v) => setOnes(v)}
            value={ones}
            aria-labelledby="discrete-slider"
            step={1}
            marks
            min={0}
            max={9}
            valueLabelDisplay="on"
          />
        </Grid>

        <Grid item style={{ fontSize: 25 }}>
          <p>
            <InlineMath math={total + " \\div \\color{grey} " + divisor} />
          </p>
          <svg
            width={210}
            height={200}
            ref={svgRef} /* onKeyUp={(e) => getValue(e)} */
          >
            <foreignObject x="40" y="0" width="45" height="30" id="foHundred" />
            <foreignObject x="95" y="0" width="45" height="30" id="foTen" />
            <foreignObject x="150" y="0" width="45" height="30" id="foOne" />

            <line
              x1={40}
              x2={40}
              y1={35}
              y2={65}
              stroke={"black"}
              strokeWidth={2}
            />
            <line
              x1={40}
              x2={250}
              y1={35}
              y2={35}
              stroke={"black"}
              strokeWidth={2}
            />
            <text fill="grey" x={20} y={60} fontSize="25">
              {divisor}{" "}
            </text>

            <text fill="red" x={50} y={62} fontSize="25">
              {hundreds}{" "}
            </text>
            <text fill="blue" x={30} y={95} fontSize="25">
              - ({hundredChoice * 100 * divisor}){" "}
            </text>
            <text fill="purple" x={95} y={62} fontSize="25">
              + {tens}{" "}
            </text>
            <text fill="purple" x={95} y={130} fontSize="25">
              - ({tenChoice * divisor * 10}){" "}
            </text>
            <text fill="green" x={155} y={62} fontSize="25">
              + {ones}{" "}
            </text>

            <text fill="green" x={135} y={170} fontSize="25">
              &nbsp; - ({oneChoice * divisor}){" "}
            </text>
            <line
              x1={20}
              x2={210}
              y1={185}
              y2={185}
              stroke={"black"}
              strokeWidth={2}
            />
          </svg>
          <br />
          <span style={{ fontSize: 15 }}>Remainder</span>{" "}
          <InlineMath
            math={
              " \\quad \\quad " +
              (total -
                divisor * (hundredChoice * 100 + tenChoice * 10 + oneChoice))
            }
          />
        </Grid>
        <Grid item xs={12} md={6} style={{ fontSize: 15 }}>
          take away unit (divisor): {rectsDivisor} <hr />
          {rectsHundred}
          <Grid container> {rectsHundredTakeAway}</Grid>
          <hr />
          {rectsTen}
          <br />
          {rectsTenTakeAwayHundred}
          {rectsTenTakeAway}
          <hr />
          {rectsOne} <br />
          {rectsOneTakeAwayTen}
          {rectsOneTakeAway}
        </Grid>
      </Grid>
    </div>
  );
};

export default LongDivideInt;
