import React, { useState } from "react";
import { Grid, Slider } from "@mui/material";
import { InlineMath } from "react-katex";
import { Buttons } from "../Tools/components";
import { scaleOrdinal, schemeCategory10 } from "d3";

const Bases = props => {
  const [base, setBase] = useState(8);
  const [base0, setBase0] = useState(5);
  const [base1, setBase1] = useState(4);
  const [base2, setBase2] = useState(3);
  //const [base3, setBase3] = useState(0);
  const color = scaleOrdinal(schemeCategory10);

  //const b3 = base3 > 0 ? base3 : "\\enspace";
  const b2 = base2 > 0 ? "\\color{purple}" + base2 : "\\enspace";
  const b1 = base2 + base1 > 0 ? "\\color{blue}" + base1 : "\\enspace";
  const digits = b2 + b1 + "\\color{green}" + base0;
  const baseArray = [
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18
  ];

  const [currentValue, setCurrentValue] = useState(
    base0 + base1 * base + base2 * base * base
  );

  //const base0Letter = base0 === 10 ? "A" : base0===11 : "B"
  const letterArray = [
    0,
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    "A",
    "B",
    "C",
    "D",
    "E",
    "F"
  ];
  const base0Letter = "\\color{green}" + letterArray[base0];
  const base1letter =
    base2 + base1 > 0 ? "\\color{blue}" + letterArray[base1] : "\\enspace";
  const base2letter =
    base2 > 0 ? "\\color{purple}" + letterArray[base2] : "\\enspace";

  const digitLetterTemp = base2letter + base1letter + base0Letter;

  const digitLetter = digitLetterTemp !== digits && (
    <p>
      <InlineMath math={" = " + digitLetterTemp} />
    </p>
  );

  const expanded0 =
    "\\color{green} " +
    base0 +
    "\\times 1 = " +
    base0 +
    "\\times " +
    base +
    "^{0}";

  const expanded1 =
    "\\color{blue} " +
    base1 +
    "\\times " +
    base +
    " = " +
    base1 +
    "\\times " +
    base +
    "^{1}";

  const expanded2 =
    "\\color{purple} " +
    base2 +
    "\\times " +
    base * base +
    " = " +
    base2 +
    "\\times " +
    base +
    "^{2}";

  const base10Value = base0 + base1 * base + base2 * base * base;

  const setAll = newBase => {
    if (newBase < 2) return;
    setBase(newBase);
    setBase0(newBase - 1);
    setBase1(newBase - 1);
    setBase2(newBase - 1);
  };
  const baseButtons = (
    <Buttons buttonArray={baseArray} func={setAll} keyString="base" />
  );

  const onesArray = new Array(base0).fill(1);
  const tensArray = new Array(base1).fill(base);
  const hundredsArray = new Array(base2).fill(base * base);

  const horizontalDots = (array, fill, v) => {
    const a = Array.isArray(array) ? array : new Array(array).fill(v);
    return a.map((v, i) => {
      const r = 6;
      return (
        <circle
          cx={r + 2.2 * i * r}
          cy={r}
          fill={fill || "green"}
          key={"dot-zero-" + i}
          r={r}
        />
      );
    });
  };

  const onesDots = horizontalDots(onesArray);

  const tenDots = tensArray.map((v, i) => {
    //const y = 10;
    return (
      <g
        transform={"translate(0, " + (i + 2.2) * 15 + ")"}
        key={"ten-group-" + i}
      >
        <rect
          width={6 + 2.2 * 10 * 6}
          height={12}
          fill={"blue"}
          opacity={0.3}
        />
        {horizontalDots(base, "blue")}
      </g>
    );
  });

  const hundredArray = new Array(base * base).fill(1);

  const hundredDots = color => (
    <g transform={"translate(0, " + (base < 5 ? base * 25 : base * 20) + ")"}>
      <rect width={13 * base} height={13 * base} fill="purple" opacity={0.2} />
      {hundredArray.map((v, i) => {
        const r = 6;
        return (
          <circle
            cx={r + 2.2 * (i % base) * r}
            cy={r + 2.2 * Math.floor(i / base) * r}
            key={"dot-100-" + i}
            r={r}
            fill={color}
          />
        );
      })}{" "}
    </g>
  );

  const hundredsDots = hundredsArray.map((v, i) => {
    return (
      <g
        transform={"translate(" + i * 10 + ", " + i * 10 + ")"}
        key={"hundred-group-" + i}
      >
        {hundredDots(color(i))}
      </g>
    );
  });

  return (
    <>
      <Grid container spacing={5}>
        <Grid item xs={12} md={3}>
          <h2>
            Base N = <span style={{ color: "red" }}>{base}</span>
          </h2>
          <h6 style = {{marginBottom: 40}}>Base 0</h6>
          <Slider
            key={"base0-" + base}
            defaultValue={base0}
            onChange={(o, v) => {
              setBase0(v);
            }}
            value = {base0}
            aria-labelledby="discrete-slider"
            step={1}
            marks
            min={0}
            max={base - 1}
            valueLabelDisplay="on"
          />
          <h6 style = {{marginBottom: 40}}>Digit 1</h6>
          <Slider
            key={"base1-" + base}
            defaultValue={base1}
            onChange={(o, v) => {
              setBase1(v);
            }}
            value = {base1}
            aria-labelledby="discrete-slider"
            step={1}
            marks
            min={0}
            max={base - 1}
            valueLabelDisplay="on"
          />

          <h6 style = {{marginBottom: 40}}>Digit 2</h6>
          <Slider
            key={"base2-" + base}
            defaultValue={base2}
            onChange={(o, v) => {
              setBase2(v);
            }}
            value={base2}
            aria-labelledby="discrete-slider"
            step={1}
            marks
            min={0}
            max={base - 1}
            valueLabelDisplay="on"
          />

          {baseButtons}
        </Grid>
        <Grid item style={{ fontSize: 25 }}>
          <InlineMath math={" " + digits} />
          {digitLetter}
          <hr />
          <InlineMath math={expanded0} />
          <p>
            <InlineMath math={expanded1} />
          </p>
          <p>
            <InlineMath math={expanded2} />
          </p>
          <hr />
          <h6>Base 10 equivalent </h6>
          <InlineMath math={" " + base10Value} />
        </Grid>
        <Grid item>
          <svg width={300} height={600}>
            {onesDots}
            {tenDots}
            {hundredsDots}
          </svg>
        </Grid>
      </Grid>{" "}
    </>
  );
};

export default Bases;
