import React, { useState, useRef } from "react";

import { Grid, Slider,  } from "@mui/material";
import "katex/dist/katex.min.css";
import { BlockMath, InlineMath } from "react-katex";
import Cube from "./Cube";
function Dimensions() {
  const linearGraphRef = useRef();

  const defaultX = 2;
  const defaultY = 5;

  const [dx, setDx] = useState(6);
  const [dy, setDy] = useState(6);
  const [dz, setDz] = useState(6);
  const [rotate, setRotate] = useState(Math.PI / 3);

  const styles = {
    red: {
      color: "red"
    },
    blue: {
      color: "green"
    },
    paddingLeftSide: {
      paddingTop: 35
    }
  };
  const oneD = (
    <>
      <Grid item xs={12}>
        <h4 style={styles.blue}>
          Freedom of movement in{" "}
          <span style={{ fontSize: 20 }}> three dimensions</span>
        </h4>
        <p>
          Center is the black circle at <InlineMath>(0,0,0)</InlineMath>
        </p>
        <b>
          Control the <b>distance</b> between points in the points
        </b>
        <Slider
          style={styles.paddingLeftSide}
          defaultValue={dx}
          onChange={(o, v) => setDx(v)}
          aria-labelledby="discrete-slider"
          step={1}
          marks
          min={0}
          max={10}
          valueLabelDisplay="auto"
        />
        <Slider
          style={styles.paddingLeftSide}
          defaultValue={dy}
          onChange={(o, v) => setDy(v)}
          aria-labelledby="discrete-slider"
          step={1}
          marks
          min={0}
          max={10}
          valueLabelDisplay="auto"
        />

        <Slider
          style={styles.paddingLeftSide}
          defaultValue={dz}
          onChange={(o, v) => setDz(v)}
          aria-labelledby="discrete-slider"
          step={1}
          marks
          min={0}
          max={10}
          valueLabelDisplay="auto"
        />

        <p>
          {" "}
          Distance From Center
          <InlineMath
            math={
              "\\quad \\color{red}" +
              dx +
              " \\color{black}, \\color{blue}" +
              dy +
              " \\color{black}, \\color{green}" +
              dz
            }
          />
        </p>
        <Cube
          dx={dx * 10}
          dy={dy * 10}
          dz={dz * 10}
          rotate={rotate}
          opacity={0.5}
        />
        <h6
          style={{
            transform:
              "rotate3d(1,1,1, " + ((rotate * 45) / Math.PI + 15) + "deg)"
          }}
        >
          Optical Illusion
        </h6>
        <p>
          <span style={{ fontSize: 15 }}>Rotate Object</span>
          <Slider
            style={styles.paddingLeftSide}
            defaultValue={Math.PI / 3}
            onChange={(o, v) => setRotate(v)}
            aria-labelledby="discrete-slider"
            step={Math.PI / 10}
            marks
            min={-Math.PI}
            max={Math.PI}
            valueLabelDisplay="auto"
            valueLabelFormat={v => v / Math.PI}
          />
        </p>
      </Grid>

      <p>
        We have to use some visual tricks to display 3D space on a 2D screen.
        Notice the use of colors to help visualize the depth of the object. This
        uses a mathematical illusion to map the 3D information into a 2D space.
      </p>
    </>
  );

  return <>{oneD}</>;
}

export default Dimensions;
