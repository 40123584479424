import React, { useState, useEffect, useRef } from "react";
import { Grid, Slider, Button } from "@mui/material";
import { scaleOrdinal, schemeCategory10 } from "d3";
import "katex/dist/katex.min.css";
import { InlineMath } from "react-katex";

const AddingRectangles = props => {
  const color = scaleOrdinal(schemeCategory10);
  const factor = 10;
  const v1 = [20, 12 * factor];
  const [x1, setX1] = useState(5 * factor);
  const v2 = [v1[0] + x1, v1[1]];
  const [y1, setY1] = useState(6 * factor);
  const v3 = [v2[0], v2[1] - y1];
  const [x2, setX2] = useState(4 * factor);
  const v4 = [v3[0] + x2, v3[1]];
  const v5 = [v4[0], v1[1]];
  const [x3, setX3] = useState(3 * factor);
  const v6 = [v5[0] + x3, v1[1]];
  const [y2, setY2] = useState(2 * factor);
  const v7 = [v6[0], v6[1] + y2];
  const v8 = [v1[0], v7[1]];
  const strokeWidth = 4;

  const [show1, setShow1 ] = useState(true)
  const [show2, setShow2 ] = useState(true)
  const [show3, setShow3 ] = useState(true)
  const [show4, setShow4 ] = useState(true)

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={4}>
          <h4>Shape from Rectangles</h4>
          <Slider
            defaultValue={x1}
            value = {x1}
            onChange={(o, v) => setX1(v)}
            aria-labelledby="discrete-slider"
            step={1}
            marks
            min={0}
            max={10 * factor}
            //valueLabelDisplay="on"
          />
          <Slider
            defaultValue={y1}
            onChange={(o, v) => setY1(v)}
            value = {y1}
            aria-labelledby="discrete-slider"
            step={1}
            marks
            min={0}
            max={10 * factor}
            //valueLabelDisplay="on"
          />
          <Slider
            defaultValue={x2}
            onChange={(o, v) => setX2(v)}
            value = {x2}
            aria-labelledby="discrete-slider"
            step={1}
            marks
            min={0}
            max={10 * factor}
            //valueLabelDisplay="on"
          />
          <Slider
            defaultValue={x3}
            value = {x3}
            onChange={(o, v) => setX3(v)}
            aria-labelledby="discrete-slider"
            step={1}
            marks
            min={0}
            max={10 * factor}
            //valueLabelDisplay="on"
          />
          <Slider
            defaultValue={y2}
            onChange={(o, v) => setY2(v)}
            value = {y2}
            aria-labelledby="discrete-slider"
            step={1}
            marks
            min={0}
            max={10 * factor}
            //valueLabelDisplay="on"
          />
          <p style={{ fontSize: 25 }}>
            {show1  && <InlineMath
              math={
                "\\color{blue} A_{blue} \\color{black} = \\color{" +
                color(3) +
                "} " +
                x2 +
                "\\color{black} \\times \\color{" +
                color(2) +
                "} " +
                y1 +
                "\\color{black} =  \\color{blue} " +
                x2 * y1
              }
            />} {false && <Button onClick = {()=> setShow1(!show1)} variant = "outlined" >{show1 ? "Hide" : "Show" }</Button>}
          </p>
          <p style={{ fontSize: 25 }}>
            <InlineMath
              math={
                "\\color{red} A_{red} \\color{black} = \\color{" +
                color(7) +
                "}" +
                (x1 + x2 + x3) +
                "\\times \\color{" +
                color(6) +
                "} " +
                y2 +
                "\\color{black} =  \\color{red} " +
                (x1 + x2 + x3) * y2
              }
            />
          </p>
          <p style={{ fontSize: 25 }}>
            <InlineMath
              math={
                " A = \\color{blue} " +
                x2 * y1 +
                " \\color{black} + \\color{red} " +
                (x1 + x2 + x3) * y2 +
                " \\color{black} = " +
                (x2 * y1 + (x1 + x2 + x3) * y2)
              }
            />
          </p>
          <p style={{ fontSize: 25 }}>
            <InlineMath
              math={
                " P \\color{black} = \\color{" +
                color(1) +
                "} " +
                x1 +
                " \\color{black} + \\color{" +
                color(2) +
                "} " +
                y1 +
                " \\color{black} + \\color{" +
                color(3) +
                "} " +
                x2 +
                " \\color{black} + \\color{" +
                color(4) +
                "} " +
                y1 +
                " \\color{black} + \\color{" +
                color(5) +
                "} " +
                x3 +
                " \\color{black} + \\color{" +
                color(6) +
                "} " +
                y2 +
                " \\color{black} + \\color{" +
                color(7) +
                "} " +
                (x1 + x2 + x3) +
                " \\color{black} + \\color{" +
                color(8) +
                "} " +
                y2
              }
            />
          </p>
        </Grid>
        <Grid item>
          <svg width={380} height={225}>
            <text x={(v1[0] + v2[0]) / 2} y={v1[1] - 5} fontSize={20}>
              {x1 === 0 ? "" : x1}
            </text>
            <text x={(v3[0] + v4[0]) / 2} y={v3[1] - 5} fontSize={20}>
              {x2 === 0 ? "" : x2}
            </text>
            <text x={v4[0] + 5} y={(v4[1] + v5[1]) / 2} fontSize={20}>
              {y1 === 0 ? "" : y1}
            </text>
            <text x={(v6[0] + v5[0] - 5) / 2} y={v6[1] - 5} fontSize={20}>
              {x3 === 0 ? "" : x3}
            </text>
            <text x={v6[0] + 5} y={(v7[1] + v6[1]) / 2} fontSize={20}>
              {y2 === 0 ? "" : y2}
            </text>
            <line
              x1={v1[0]}
              x2={v2[0]}
              y1={v1[1]}
              y2={v2[1]}
              stroke={color(1)}
              strokeWidth={strokeWidth}
            />
            <line
              x1={v3[0]}
              x2={v2[0]}
              y1={v3[1]}
              y2={v2[1]}
              stroke={color(2)}
              strokeWidth={strokeWidth}
            />
            <line
              x1={v3[0]}
              x2={v4[0]}
              y1={v3[1]}
              y2={v4[1]}
              stroke={color(3)}
              strokeWidth={strokeWidth}
            />
            <line
              x1={v5[0]}
              x2={v4[0]}
              y1={v5[1]}
              y2={v4[1]}
              stroke={color(4)}
              strokeWidth={strokeWidth}
            />
            <line
              x1={v5[0]}
              x2={v6[0]}
              y1={v5[1]}
              y2={v6[1]}
              stroke={color(5)}
              strokeWidth={strokeWidth}
            />
            <line
              x1={v7[0]}
              x2={v6[0]}
              y1={v7[1]}
              y2={v6[1]}
              stroke={color(6)}
              strokeWidth={strokeWidth}
            />
            <line
              x1={v7[0]}
              x2={v8[0]}
              y1={v7[1]}
              y2={v8[1]}
              stroke={color(7)}
              strokeWidth={strokeWidth}
            />
            <line
              x1={v8[0]}
              x2={v1[0]}
              y1={v8[1]}
              y2={v1[1]}
              stroke={color(8)}
              strokeWidth={strokeWidth}
            />
          </svg>
        </Grid>
        <Grid item>
          <svg width={380} height={260}>
            <rect width={x2} height={y1} fill={"blue"} />
            <rect y={y1 + 10} width={x1 + x2 + x3} height={y2} fill={"red"} />
          </svg>
          <br />
          <svg width={380} height={300}>
            <line
              x1={0}
              x2={x1}
              y1={15}
              y2={15}
              stroke={color(1)}
              strokeWidth={strokeWidth}
            />
            <text x={x1} y={15} fontSize={20}>
              {" "}
              {x1}
            </text>
            <line
              x1={0}
              x2={y1}
              y1={35}
              y2={35}
              stroke={color(2)}
              strokeWidth={strokeWidth}
            />
            <text x={y1} y={35} fontSize={20}>
              {" "}
              {y1}
            </text>
            <line
              x1={0}
              x2={x2}
              y1={55}
              y2={55}
              stroke={color(3)}
              strokeWidth={strokeWidth}
            />
            <text x={x2} y={55} fontSize={20}>
              {" "}
              {x2}
            </text>
            <line
              x1={0}
              x2={y1}
              y1={75}
              y2={75}
              stroke={color(4)}
              strokeWidth={strokeWidth}
            />
            <text x={y1} y={75} fontSize={20}>
              {" "}
              {y1}
            </text>
            <line
              x1={0}
              x2={x3}
              y1={95}
              y2={95}
              stroke={color(5)}
              strokeWidth={strokeWidth}
            />
            <text x={x3} y={95} fontSize={20}>
              {" "}
              {x3}
            </text>
            <line
              x1={0}
              x2={y2}
              y1={115}
              y2={115}
              stroke={color(6)}
              strokeWidth={strokeWidth}
            />
            <text x={y2} y={115} fontSize={20}>
              {" "}
              {y2}
            </text>
            <line
              x1={0}
              x2={x1 + x2 + x3}
              y1={135}
              y2={135}
              stroke={color(7)}
              strokeWidth={strokeWidth}
            />
            <text x={x1 + x2 + x3} y={135} fontSize={20}>
              {" "}
              {x1 + x2 + x3}
            </text>
            <line
              x1={0}
              x2={y2}
              y1={155}
              y2={155}
              stroke={color(8)}
              strokeWidth={strokeWidth}
            />
            <text x={y2} y={155} fontSize={20}>
              {" "}
              {y2}
            </text>
          </svg>
        </Grid>
      </Grid>
    </>
  );
};

export default AddingRectangles;
