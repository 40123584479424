import React, { useState } from "react";
import { Grid, Slider } from "@mui/material";
import "katex/dist/katex.min.css";
import { InlineMath } from "react-katex";

const BasicExponent = props => {
  const [base, setBase] = useState(2);
  const [exponent, setExponent] = useState(3);
  const [exponent2, setExponent2] = useState(4);

  const baseArray = new Array(exponent).fill(base);
  const baseArray2 = new Array(exponent2).fill(base);

  return (
    <>
      <Grid container spacing={5}>
        <Grid item xs={12} md={3}>
          <h4>Basic Exponent: Multiply</h4>
          <h6 sx = {{marginBottom : 40}}>Base</h6>
          <Slider
            defaultValue={base}
            onChange={(o, v) => {
              setBase(v);
            }}
            aria-labelledby="discrete-slider"
            step={1}
            marks
            min={0}
            max={10}
            valueLabelDisplay="on"
          />
          <h6 sx = {{marginBottom : 40}}>Exponent 1</h6>
          <Slider
            defaultValue={exponent}
            onChange={(o, v) => {
              setExponent(v);
            }}
            aria-labelledby="discrete-slider"
            step={1}
            marks
            min={0}
            max={10}
            valueLabelDisplay="on"
          />
          <h6 sx = {{marginBottom : 40}}>Exponent 2</h6>
          <Slider
            defaultValue={exponent2}
            onChange={(o, v) => {
              setExponent2(v);
            }}
            aria-labelledby="discrete-slider"
            step={1}
            marks
            min={0}
            max={10}
            valueLabelDisplay="on"
          />
        </Grid>
        <Grid item style={{ fontSize: 25 }}>
          <InlineMath
            math={
              "\\color{red}" +
              base +
              "^{\\color{blue}" +
              exponent +
              "} \\color{black} \\times \\color{red}" +
              base +
              "^{\\color{green}" +
              exponent2 +
              "}  \\color{black} = (" +
              Math.pow(base, exponent) +
              ") \\times (" +
              Math.pow(base, exponent2) +
              ")"
            }
          />
          <p>
            <InlineMath
              math={
                (exponent === 0
                  ? " = \\color{black}(1)"
                  : "\\color{black} = \\color{blue}( \\color{black} " +
                    baseArray.join("\\times") +
                    "\\color{blue} ) ") +
                (exponent2 === 0
                  ? " \\color{black}(1)"
                  : " \\color{black} \\times \\color{green}(\\color{black}" +
                    baseArray2.join("\\times") +
                    "\\color{green})") 
              }
            />
          </p>
          <p>
            <InlineMath
              math={
                "= \\color{red}" +
                base +
                "^{\\color{blue}" +
                exponent +
                "+ \\color{green}" +
                exponent2 +
                "}\\color{black} =  \\color{red}" +
                base +
                "^{\\color{black}" +
                (exponent + exponent2) +
                "} \\color{black} =  " +
                (exponent + exponent2 === 0
                  ? "1"
                  : Math.pow(base, exponent + exponent2))
              }
            />
          </p>
        </Grid>
      </Grid>
    </>
  );
};

export default BasicExponent;
