import React, { useEffect, useRef, useState } from "react";
//import NumberLine from "../../components/NumberLine";
//import withWidth from "@material-ui/core/withWidth";
import { select, scaleLinear, drag } from "d3";
import { Grid } from "@mui/material";
import "katex/dist/katex.min.css";
import { InlineMath } from "react-katex";

const DeltaYX = props => {
  const svgRef = useRef();
  const width = 275;
  const height = 300;

  const [dx, setDx] = useState(0);
  const [dy, setDy] = useState(0);
  const [x0, setX0] = useState(20);
  const [y0, setY0] = useState(20);

  const gcd = (a, b) => {
    if (!b) {
      return a;
    }
    return gcd(b, a % b);
  };

  const g = Math.abs(gcd(dy, dx));

  const mSign = -1 * dy * dx < 0 ? "-" : " ";

  const mAbs =
    Math.abs(dx / g) === 1
      ? "\\color{red} " + Math.abs(dy / g)
      : "\\frac{ \\color{red} " +
        Math.abs(dy / g) +
        "}{\\color{blue} " +
        Math.abs(dx / g) +
        "}";

  const mSimplified = mSign + mAbs;

  const handleDrag = drag()
    .subject(function (event) {
      const me = select(this);
      const svg = select(svgRef.current);
      svg.selectAll("text").remove();
      svg
        .append("text")
        .text(
          Math.round(event.x / 10) * 10 +
            " , " +
            (300 - Math.round(event.y / 10) * 10)
        )
        .attr("y", Math.round(event.y / 10) * 10 + 20)
        .attr("x", Math.round(event.x / 10) * 10);
      return {
        x: event.x,
        y: event.y,
        x1: Math.round(event.x / 10) * 10,
        y1: Math.round(event.y / 10) * 10
      };
    })
    .on("start", function (event) {
      const me = select(this);
      const svg = select(svgRef.current);
      svg.select("#clone").remove();
      const clone = svg.append("circle").attr("id", "clone");
      clone.attr("cx", Math.round(event.x / 10) * 10);
      clone.attr("cy", Math.round(event.y / 10) * 10);
      clone.attr("r", 10).attr("fill", me.attr("fill")).attr("opacity", 0.4);
    })
    .on("drag", function (event) {
      const me = select(this);
      const svg = select(svgRef.current);
      svg.selectAll("line").remove();
      svg.select("#delta-y").remove();
      svg.select("#delta-x").remove();
      svg
        .append("line")
        .attr("stroke", "green")
        .attr("stroke-width", 1)
        .attr("x1", Math.round(10 * event.subject.x1) / 10)
        .attr("x2", Math.round(10 * event.x) / 10)
        .attr("y1", Math.round(10 * event.subject.y1) / 10)
        .attr("y2", Math.round(10 * event.y) / 10);

      svg
        .append("line")
        .attr("stroke", "red")
        .attr("stroke-width", 1)
        .attr("x1", Math.round(10 * event.subject.x1) / 10)
        .attr("x2", Math.round(10 * event.subject.x1) / 10)
        .attr("y1", Math.round(10 * event.subject.y1) / 10)
        .attr("y2", Math.round(10 * event.y) / 10);

      svg
        .append("line")
        .attr("stroke", "blue")
        .attr("stroke-width", 1)
        .attr("x1", Math.round(10 * event.subject.x1) / 10)
        .attr("x2", Math.round(10 * event.x) / 10)
        .attr("y1", Math.round(10 * event.subject.y1) / 10)
        .attr("y2", Math.round(10 * event.subject.y1) / 10);

      svg
        .append("line")
        .attr("stroke", "red")
        .attr("stroke-width", 1)
        .attr("x1", Math.round(10 * event.x) / 10)
        .attr("x2", Math.round(10 * event.x) / 10)
        .attr("y1", Math.round(10 * event.subject.y1) / 10)
        .attr("y2", Math.round(10 * event.y) / 10);

      svg
        .append("line")
        .attr("stroke", "blue")
        .attr("stroke-width", 1)
        .attr("x1", Math.round(10 * event.subject.x1) / 10)
        .attr("x2", Math.round(10 * event.x) / 10)
        .attr("y1", Math.round(10 * event.y) / 10)
        .attr("y2", Math.round(10 * event.y) / 10);

      svg
        .append("text")
        .attr("id", "delta-y")
        .attr("x", Math.round(10 * event.x) / 10)
        .attr(
          "y",
          Math.round(
            10 * (event.subject.y1 + (event.y - event.subject.y1) / 2)
          ) / 10
        )
        .text(-1 * Math.round((event.y - event.subject.y1) / 10) * 10);

      svg
        .append("text")
        .attr("id", "delta-x")
        .attr(
          "x",
          Math.round(
            10 * (event.subject.x1 + (event.x - event.subject.x1) / 2)
          ) / 10
        )
        .attr("y", Math.round(10 * event.y) / 10)
        .text(Math.round((event.x - event.subject.x1) / 10) * 10);

      if (event.x > 10 && event.x < 300) me.attr("cx", event.x);
      if (event.y > 10 && event.y < 300) me.attr("cy", event.y);
    })
    .on("end", function (event) {
      const me = select(this);

      if (event.y > 20 && event.x < 290)
        me.attr("cx", Math.round(event.x / 10) * 10);
      if (event.y > 20 && event.y < 290)
        me.attr("cy", Math.round(event.y / 10) * 10);
      setDx(Math.round((event.x - event.subject.x1) / 10) * 10);
      setDy(Math.round((event.y - event.subject.y1) / 10) * 10);
      setX0(Math.round(event.subject.x / 10) * 10);
      setY0(Math.round(event.subject.y / 10) * 10);
      const svg = select(svgRef.current);
      svg
        .append("text")
        .text(
          Math.round(event.x / 10) * 10 +
            " , " +
            (300 - Math.round(event.y / 10) * 10)
        )
        .attr("y", Math.round(event.y / 10) * 10 + 10)
        .attr("x", Math.round(event.x / 10) * 10 + 10);
      svg.select("#mainCircle").remove();

      const lastX = event.x < 10 ? 10 : event.x > 290 ? 290 : event.x;
      const lastY = event.y < 10 ? 10 : event.y > 290 ? 290 : event.y;
      const mainCircle = svg.append("circle").attr("id", "mainCircle");
      mainCircle.attr("cx", lastX);
      mainCircle.attr("cy", lastY);
      mainCircle.attr("r", 10).attr("fill", "green");
      handleDrag(svg.selectAll("circle"));
    });

  useEffect(() => {
    const svg = select(svgRef.current).attr("width", width).attr("height", 210);

    const backRect = svg.append("rect").attr("id", "backRect");
    backRect.attr("width", width);
    backRect.attr("height", height);
    backRect.attr("stroke", "black");
    backRect.attr("stroke-width", 1);
    backRect.attr("fill", "#ddd");
    const mainCircle = svg.append("circle").attr("id", "mainCircle");
    mainCircle.attr("cx", x0);
    mainCircle.attr("cy", y0);
    mainCircle.attr("r", 10).attr("fill", "green");

    handleDrag(svg.selectAll("circle"));
  }, []);

  return (
    <>
      <h3>Rise over Run, Change of Y and Change of X</h3>
      <Grid container spacing = {2}>
        <Grid item>
          drag the circle
          <br />
          <svg ref={svgRef} />
          <br />
          <InlineMath
            math={
              " \\frac{ \\color{red} \\Delta y}{\\color{blue} \\Delta x \\color{black} }=  \\frac{ \\color{red} " +
              (300 - y0) +
              " - " +
              (300 - (y0 + dy)) +
              "}{\\color{blue} " +
              (x0 + dx) +
              " - " +
              x0 +
              " \\color{black} } =  \\frac{ \\color{red} " +
              -1 * dy +
              "}{\\color{blue} " +
              dx +
              " \\color{black} } "
            }
          />
        </Grid>
        <Grid item>
         
            <InlineMath math={"\\Large m = " + (!(dy > 0 || dy < 0) ? "\\varnothing" : mSimplified)} />
        
        </Grid>
        
      </Grid>
    </>
  );
};

export default DeltaYX;
